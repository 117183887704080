<template>
    <ul class="list">
        <li class="list__item">
            <button class="user__logout" @click.prevent="profileHandle">
                <v-icon x-small>mdi-cog-outline</v-icon>
                {{ textContent['profile-setting'] }}
            </button>
        </li>
        <li v-if="getAllowed(['admin'])" class="list__item">
            <button class="user__logout" @click.prevent="reportHandle">
                <v-icon x-small>mdi-archive-outline</v-icon>
                {{ textContent['reports'] }}
            </button>
        </li>
        <li class="list__item">
            <button class="user__logout" @click.prevent="logoutHandle">
                <v-icon x-small>mdi-power</v-icon>
                {{ textContent['logout'] }}
            </button>
        </li>
        <li v-if="isDebugging" class="list__item">
            <button class="user__logout" @click.prevent="requestHandle">
                test request
            </button>
        </li>
    </ul>
</template>

<script>
import { mapActions } from 'vuex'
import { actionTypes } from '@/store/modules/auth'
import { actionTypes as actionTypesModals } from '@/store/modules/modals'

import popUpTypes from '@/types/pop-up-types'
import languageMixin from '@/mixins/language.mixin'
import debugging from '@/mixins/debugging.mixin'

const { reportMain, userProfileEdit, requestTester } = popUpTypes
export default {
    name: 'UserActionList',
    mixins: [languageMixin, debugging],
    methods: {
        ...mapActions('auth', {
            logout: actionTypes.logout,
        }),
        ...mapActions('modals', { onOpen: actionTypesModals.openPopUp }),
        logoutHandle: function () {
            this.logout().then(() => {
                this.$router.replace({ name: 'Login' })
            })
        },
        reportHandle: function () {
            this.onOpen(reportMain)
        },
        requestHandle: function () {
            this.onOpen(requestTester)
        },
        profileHandle: function () {
            this.onOpen(userProfileEdit)
        },
    },
}
</script>

<style lang="scss" scoped>
.list {
    width: 130px;
    position: relative;
    padding: 10px 0;
    background: $color-primary-light;
    font-size: 12px;

    &__item {
        width: 100%;
        padding: 5px 15px;
        cursor: pointer;
        transition: background-color 150ms linear;
        border-bottom: 1px solid #e3e3e3;

        &:last-child {
            border: none;
        }

        &:hover {
            background: #e3e3e3;
        }
    }
}
</style>
