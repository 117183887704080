<template>
    <div class="user">
        <h3 class="user__title">{{ userData['nickName'] }}</h3>
        <div class="user__register">
            {{ textContent.registered }}
            <span class="user__data user__data--date">
                <DateCell :value="userData['created_at']" />
            </span>
        </div>
        <div class="user__last-login">
            {{ textContent.lastLogin }}
            <span class="user__data">
                <DateCell :value="userData['last_login']" />
            </span>
        </div>

        <div class="user__role">
            {{ textContent.role }}
            <span class="user__data">{{ userData.role.name }}</span>
        </div>

        <div class="user__actions">
            <v-btn
                small
                class="ma-2"
                outlined
                color="red"
                @click.prevent="deleteUserHandle"
            >
                <i class="icon-cross"></i>
                <span> {{ textContent.delete }}</span>
            </v-btn>
            <v-btn
                small
                class="ma-2"
                outlined
                color="primary"
                @click.prevent="editUserHandle"
            >
                <i class="icon-edit"></i>
                <span> {{ textContent.edit }}</span>
            </v-btn>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getterTypes as getterTypesConfiguration } from '@/store/modules/configuration'
import languageMixin from '@/mixins/language.mixin'
import DateCell from '@/components/common/cells/date-cell.vue'

export default {
    name: 'UserCard',
    components: { DateCell },
    mixins: [languageMixin],
    props: {
        userData: {
            type: Object,
            required: true,
            default: () => {},
        },
    },
    emits: ['edit-user', 'delete-user'],
    computed: {
        ...mapGetters('configuration', {
            getLanguage: getterTypesConfiguration.getLanguage,
        }),
    },
    methods: {
        deleteUserHandle: function () {
            this.$emit('delete-user', this.userData)
        },
        editUserHandle: function () {
            this.$emit('edit-user', this.userData)
        },
    },
}
</script>

<style scoped lang="scss">
.user {
    background: $color-primary-light;
    color: $color-secondary-dark;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 14px;
    padding: 15px 15px;

    &__title {
        font-size: 16px;
        font-weight: 500;
        color: $color-primary-dark;
    }

    &__register {
    }

    &__last-login {
        margin-bottom: 10px;
    }

    &__role {
        margin-bottom: 15px;
    }

    &__data {
        color: $color-primary-dark;

        &--date {
            display: inline-block;
            direction: ltr;
        }
    }

    &__actions {
        width: 100%;
        max-width: 350px;
        display: flex;
        justify-content: space-between;
    }
}
</style>
