<template>
    <div v-if="ntf.wig_id" class="extra">
        <p @click="() => openWig(ntf.wig_id)">
            {{ textContent['wig'].toLowerCase() }}
        </p>
        <p @click="() => openClient(ntf.client_id)">
            {{ textContent['client'].toLowerCase() }}:
            {{ getClientWithId(ntf.client_id) }}
        </p>
    </div>
</template>

<script>
import { find } from 'lodash'
import { mapActions, mapGetters } from 'vuex'
import {
    actionTypes as actionTypesClients,
    getterTypes,
} from '@/store/modules/clients'
import { actionTypes as actionTypesOrders } from '@/store/modules/orders'
import languageMixin from '@/mixins/language.mixin'

export default {
    name: 'NotificationTemplateNewOrder',
    mixins: [languageMixin],
    props: {
        ntf: {
            type: Object,
            default: () => {},
        },
    },
    computed: {
        ...mapGetters('clients', { clients: getterTypes.getAllClients }),
    },
    methods: {
        ...mapActions('clients', {
            loadAllClients: actionTypesClients.getAllClients,
            getClient: actionTypesClients.getClient,
            deleteClient: actionTypesClients.deleteClient,
        }),
        ...mapActions('orders', { getOrder: actionTypesOrders.loadOrder }),
        textContent: function () {
            return this.getTextForSelected(this.lang, 'Notification') || ''
        },
        getClientWithId: function (id) {
            if (!id) return ''
            return find(this.clients, { id })['full_name']
        },
        openWig: function (id) {
            this.getOrder(id)
        },
        openClient: function (id) {
            this.getClient(id)
        },
    },
}
</script>

<style scoped lang="scss">
.extra {
    padding-bottom: 10px;

    p {
        font-size: 1.1em;
        cursor: pointer;
        border-radius: 5px;
        border: 1px solid #37bc9b;
        padding: 3px 5px;
        width: fit-content;
        font-weight: 600;
        color: #37bc9b;
        margin: 0 0 5px;
        transition: all 150ms ease-in-out;

        &:last-child {
            margin-bottom: 0;
        }

        &:hover {
            color: #fff;
            background: #37bc9b;
            transform: translateY(-1px);
        }
    }
}
</style>
