<template>
    <v-snackbar v-model="isActive" :timeout="timeoutFor" right :color="color">
        <div class="snackbar">
            <div class="snackbar__body">
                <i class="snackbar__icon icon-checkmark"></i>
                <div class="snackbar__message">
                    <h4 class="snackbar__desc">{{ options.text }}</h4>
                    <h3 class="snackbar__title">{{ textContent[message] }}</h3>
                </div>
            </div>
            <v-btn color="#fff" text @click="closeHandle">
                {{ textContent['close'] }}
            </v-btn>
            <div class="snackbar__progress">
                <div
                    class="snackbar__progress-line"
                    :class="{
                        [`snackbar__progress-line--is-active`]: isActive,
                    }"
                ></div>
            </div>
        </div>
    </v-snackbar>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { getterTypes } from '@/store/modules/modal-notifications'
import { mutationTypes } from '@/store/modules/modal-notifications'
import languageMixin from '@/mixins/language.mixin'

export default {
    name: 'NotificationSuccess',
    mixins: [languageMixin],
    data() {
        return {
            color: '',
            message: '',
            timeout: 4000,
        }
    },
    computed: {
        ...mapGetters('modalNotifications', {
            options: getterTypes.getSuccessStatus,
        }),
        timeoutFor: function () {
            return this.timeout
        },
        isActive: {
            get: function () {
                return this.options.isActive
            },
            set: function () {
                this.close()
            },
        },
    },
    watch: {
        isActive: function () {
            this.getType()
        },
    },
    methods: {
        ...mapMutations('modalNotifications', { close: mutationTypes.close }),
        getType: function () {
            switch (this.options.type) {
                case 'success':
                    this.color = '#5dc7ad'
                    this.message = 'success'
                    this.timeout = 3000
                    break
                case 'warning':
                    this.color = 'rgba(199,154,0, 0.95)'
                    this.message = 'warning'
                    this.timeout = 5000
                    break
                case 'error':
                    this.color = 'rgba(217,0,26,0.69)'
                    this.message = 'Error'
                    this.timeout = 50000
                    break
                default:
                    return console.log('Wrong type of type notification')
            }
        },
        closeHandle: function () {
            this.close()
        },
    },
}
</script>

<style scoped lang="scss">
.snackbar {
    color: $color-primary-light;
    display: flex;
    justify-content: space-between;

    &__body {
        display: flex;
        align-items: center;
    }

    &__icon {
        font-size: 25px;
        line-height: 25px;
        margin-right: 15px;
    }

    &__message {
    }

    &__desc,
    &__title {
        font-weight: 400;
    }

    &__desc {
    }

    &__title {
        font-size: 14px;
    }

    &__progress {
        position: absolute;
        bottom: 3px;
        left: 0;
        width: 100%;
        background: rgba(255, 255, 255, 0.3);
        height: 5px;
    }

    &__progress-line {
        position: absolute;
        top: 0;
        left: 0;
        width: 0%;
        height: 100%;
        background: rgba(255, 255, 255, 0.7);
        transition: width linear 4000ms;

        &--is-active {
            width: 100%;
        }
    }
}
</style>
