<template>
    <div
        class="orders-page-payments"
        data-test-id="wig-page--payments--wrapper"
    >
        <div class="orders-page-payments__total">
            <p>
                {{ $t('pages.wigPage.ordersPagePayments.header') }}
                <v-btn
                    x-small
                    color="#3bafda"
                    misc
                    dark
                    data-test-id="wig-page--payments--add-note-btn"
                    @click="editTotalPrice"
                >
                    <v-icon small> mdi mdi-pencil </v-icon>
                </v-btn>
            </p>
            <p data-test-id="wig-page--payments--total-price">
                {{ totalPrice }}
            </p>
        </div>

        <div class="orders-page-payments__total">
            <p>{{ $t('pages.wigPage.ordersPagePayments.paidToDate') }}</p>
            <div class="orders-page-payments__total-body">
                <p data-test-id="wig-page--payments--total-deposit">
                    {{ totalDeposit }}
                </p>
                <div class="orders-page-payments__total-status">
                    <v-progress-circular
                        :value="status"
                        :rotate="270"
                        color="#3CBD51"
                        :width="5"
                        :size="24"
                    >
                    </v-progress-circular>
                    <p>{{ status }}%</p>
                </div>
            </div>
        </div>

        <div class="orders-page-payments__header">
            <h3 class="orders-page-payments__title">
                {{ $t('pages.wigPage.ordersPagePayments.paymentsNotes') }}
            </h3>
        </div>
        <div class="orders-page-payments__content">
            <div
                v-if="checkNotes()"
                class="orders-page-payments__payments-notes"
                data-test-id="wig-page--payments--notes"
            >
                {{ this.order.info }}
            </div>
            <p v-else class="orders-page-payments__empty">
                {{ $t('pages.wigPage.ordersPagePayments.noPaymentNotes') }}
            </p>
        </div>

        <div
            v-if="!readonly && getAllowed(['admin', 'manager'])"
            class="orders-page-payments__btns"
        >
            <v-btn
                width="49%"
                min-width="80"
                color="#967adc"
                dark
                data-test-id="wig-page--payments--add-payment-btn"
                class="orders-page-payments__btn"
                @click="addPayment"
            >
                <v-icon dense> mdi mdi-currency-usd </v-icon>
                {{
                    $t('pages.wigPage.ordersPagePayments.actions.addPayment')
                }}</v-btn
            >
            <v-btn
                width="49%"
                min-width="80"
                color="#3bafda"
                dark
                data-test-id="wig-page--payments--add-note-btn"
                class="orders-page-payments__btn"
                @click="addNotes"
            >
                <v-icon dense> mdi-note-plus-outline </v-icon>
                {{
                    $t('pages.wigPage.ordersPagePayments.actions.addNote')
                }}</v-btn
            >
        </div>

        <div class="orders-page-payments__header">
            <h3 class="orders-page-payments__title">
                {{ $t('pages.wigPage.ordersPagePayments.paymentsHistory') }}
            </h3>
        </div>
        <div class="orders-page-payments__content">
            <template v-if="payments.length !== 0">
                <OrderDepositHistory />
            </template>
            <template v-else>
                <p class="orders-page-payments__empty">
                    {{ $t('pages.wigPage.ordersPagePayments.noDepositYet') }}
                </p>
            </template>
        </div>
    </div>
</template>

<script>
import OrderDepositHistory from '@/components/orders/order-deposit-history.vue'
import { mapActions, mapGetters } from 'vuex'
import { getterTypes } from '@/store/modules/payments'
import { getterTypes as gettersTypesOrders } from '@/store/modules/orders'
import { actionTypes } from '@/store/modules/modals'
import popUpTypes from '@/types/pop-up-types'
const { orderDeposit, orderPaymentsNotes, orderPaymentsTotalPrice } = popUpTypes

export default {
    name: 'WigPagePayments',
    components: { OrderDepositHistory },
    props: {
        totalPrice: { type: Number, default: 0 },
    },
    computed: {
        ...mapGetters('orders', { order: gettersTypesOrders.getOrder }),
        ...mapGetters('payments', { getPayments: getterTypes.getPayments }),
        payments: function () {
            return (
                this.getPayments.filter(
                    (payment) => payment.terminated !== 1
                ) || []
            )
        },
        totalDeposit: function () {
            return this.payments.reduce((acc, payment) => {
                return acc + +payment.amount
            }, 0)
        },
        status: function () {
            return !this.totalPrice
                ? 0
                : Math.floor((this.totalDeposit / this.totalPrice) * 100)
        },
        readonly: function () {
            const state = this.order?.state?.toLowerCase() || ''
            return state === 'archived'
        },
    },
    methods: {
        ...mapActions('modals', { onOpen: actionTypes.openPopUp }),
        addPayment: function () {
            this.onOpen(orderDeposit)
        },
        addNotes: function () {
            this.onOpen(orderPaymentsNotes)
        },
        editTotalPrice: function () {
            this.onOpen(orderPaymentsTotalPrice)
        },
        checkNotes: function () {
            return this.order.info !== ''
        },
    },
}
</script>

<style lang="scss">
.orders-page-payments {
    margin: 16px 0 8px;
    background: rgba(255, 255, 255, 0.75);
    border-radius: 8px;
    padding: 8px 16px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);

    &__status {
        position: absolute;
        top: 0;
        right: 0;

        @include for-hebrew {
            right: auto;
            left: 0;
        }
    }

    &__title {
        padding: 8px 0 0;
        font-weight: 700;
        font-size: 14px;
    }

    &__content {
        position: relative;
        padding: 8px 0;
        word-wrap: break-word;
    }

    &__foot {
        border-top: 1px solid #d0d0d0;
        display: flex;
        flex-direction: column;
    }

    &__total {
        font-size: 16px;
        font-weight: 700;
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding: 8px 0;

        p {
            margin-bottom: 0 !important;

            button {
                margin-left: 8px;

                @include for-hebrew {
                    margin-left: 0;
                    margin-right: 8px;
                }
            }
        }

        &--deposit {
            font-weight: 400;
            border-top: 1px solid #d0d0d0;
            border-bottom: none;
        }
        &-body {
            display: flex;
            gap: 10px;
        }
        &-status {
            font-weight: 500;
            color: #878787;
            display: flex;
            gap: 5px;
            p {
                margin-left: 5px;
            }
        }
    }

    &__status {
        font-size: 14px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    &__btns {
        padding-top: 8px;
        display: flex;
        justify-content: space-between;
        width: 100%;
    }
    &__btn {
        text-transform: unset !important;
    }

    &__payments-notes {
        font-size: 14px;
    }
    &__empty {
        font-size: 14px;
        color: #878787;
    }
}
</style>
