import { getterTypesJewishCalendar } from '@/store/modules/jewish-calendar/types'

export default {
    [getterTypesJewishCalendar.getJewishDates]: (state) => (lang) => {
        let dates = []
        if (Object.keys(state[lang]).length === 0) return []
        Object.keys(state[lang]).forEach((key) => {
            dates = [...dates, ...state[lang][key]]
        })
        return dates
    },
}
