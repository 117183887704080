<template>
    <div class="qr-code">
        <div v-if="!isLoaded" class="qr-code__loader">
            <v-progress-circular
                indeterminate
                :size="75"
                width="5"
                :value="100"
                color="primary"
            ></v-progress-circular>
        </div>
        <div
            class="qr-code__scanner"
            :class="{ ['qr-code__scanner--is-visible']: isLoaded }"
        >
            <qrcode-stream
                :camera="camera"
                @init="onInit"
                @decode="onDecode"
            ></qrcode-stream>
        </div>
    </div>
</template>

<script>
import { QrcodeStream } from 'vue-qrcode-reader'

export default {
    name: 'QrCode',
    components: {
        QrcodeStream,
    },
    props: {
        camera: {
            type: String,
            default: 'off',
        },
    },
    emits: ['onDetect', 'on-detect'],
    data() {
        return {
            detected: '',
            isLoaded: false,
        }
    },
    watch: {
        camera: function (value) {
            if (value === 'off') this.isLoaded = false
        },
    },
    methods: {
        onDecode: function (e) {
            this.$emit('on-detect', e)
        },
        async onInit(promise) {
            // show loading indicator
            try {
                const { capabilities } = await promise
                if (Object.keys(capabilities).length) {
                    this.isLoaded = true
                }

                // successfully initialized
            } catch (error) {
                if (error.name === 'NotAllowedError') {
                    // user denied camera access permisson
                } else if (error.name === 'NotFoundError') {
                    // no suitable camera device installed
                } else if (error.name === 'NotSupportedError') {
                    // page is not served over HTTPS (or localhost)
                } else if (error.name === 'NotReadableError') {
                    // maybe camera is already in use
                } else if (error.name === 'OverconstrainedError') {
                    // did you requested the front camera although there is none?
                } else if (error.name === 'StreamApiNotSupportedError') {
                    // browser seems to be lacking features
                }
            } finally {
                // hide loading indicator
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.qr-code {
    width: 100%;
    aspect-ratio: 1 /1;
    background: #fff;
    position: relative;
    overflow: hidden;

    &__scanner {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 140%;
        opacity: 0;
        transition: opacity 150ms 150ms ease-in-out;

        &--is-visible {
            opacity: unset;
        }
    }

    &__loader {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
</style>
