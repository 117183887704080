<template>
    <div class="additional-properties-list">
        <ul class="additional-properties-list__list">
            <li
                v-for="(day, index) in propertiesByDate"
                :key="index"
                class="additional-properties-list__row"
            >
                <WigPageAdditionalPropertiesByDateItem :day="day" />
            </li>
        </ul>
    </div>
</template>

<script>
import WigPageAdditionalPropertiesByDateItem from '@/features/wig-page/components/wig-page-additional-properties-by-date-item.vue'
import prepareAdditionalPropertiesByDateUtils from '@/features/wig-page/utils/prepare-additinal-properties-by-date.utils'

export default {
    name: 'WigPageAdditionalPropertiesByDateList',
    components: { WigPageAdditionalPropertiesByDateItem },
    props: {
        properties: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        propertiesByDate: function () {
            return prepareAdditionalPropertiesByDateUtils(this.properties)
        },
    },
}
</script>

<style lang="scss" scoped>
.additional-properties-list {
    padding: 24px 8px 16px;

    &__list {
        list-style: none;
        padding: 0;
        margin: 0;
    }

    &__row {
        & + & {
            margin-top: 20px;
        }
    }
}
</style>
