<template>
    <section class="users">
        <ModalDeleteUser
            :credentials="selectedUser"
            :is-active="modalDeleteUserIsActive"
            @close-modal="modalDeleteUserIsActive = false"
        />
        <ModalEditUser
            :credentials="selectedUser"
            :is-active="modalEditUserIsActive"
            @close-modal="modalEditUserIsActive = false"
        />
        <SubHeader title="Users" :crumbs="textContent.crumbs" />
        <div class="users__actions">
            <ModalAddUser />
            <div v-if="isUserFetching" class="users__loader-circle">
                <v-progress-circular
                    indeterminate
                    color="primary"
                ></v-progress-circular>
            </div>
        </div>
        <transition-group name="list" tag="ul" class="users__body users-list">
            <li v-for="user in users" :key="user.id" class="users-list__item">
                <UserCard
                    :user-data="user"
                    @delete-user="deleteUserHandle"
                    @edit-user="editUserHandle"
                />
            </li>
        </transition-group>
    </section>
</template>

<script>
import SubHeader from '@/components/common/sub-header'
import UserCard from '@/components/users-list/user-card'
import ModalAddUser from '@/components/modals/user-modal-add'
import ModalDeleteUser from '@/components/modals/user-modal-delete'
import ModalEditUser from '@/components/modals/user-modal-edit'

import languageMixin from '@/mixins/language.mixin'

import { mapActions, mapGetters } from 'vuex'
import { actionTypes, getterTypes } from '@/store/modules/users'
import { getterTypes as getterTypesConfiguration } from '@/store/modules/configuration'
import { actionTypes as actionTypesConfirmer } from '@/store/modules/confirmer'
import { actionTypes as actionTypesModalNotifications } from '@/store/modules/modal-notifications'

export default {
    name: 'Users',
    components: {
        SubHeader,
        UserCard,
        ModalAddUser,
        ModalDeleteUser,
        ModalEditUser,
    },
    mixins: [languageMixin],
    data() {
        return {
            modalDeleteUserIsActive: false,
            modalEditUserIsActive: false,
            selectedUser: {
                name: '',
                id: 0,
                password: '',
            },
            content: {
                en: {
                    crumbs: [
                        { title: 'Home', path: '/' },
                        { title: 'Users', path: '/users-list' },
                    ],
                },
                he: {
                    crumbs: [
                        { title: 'בית', path: '/' },
                        { title: 'מִשׁתַמֵשׁ', path: '/users-list' },
                    ],
                },
            },
        }
    },
    computed: {
        ...mapGetters('users', {
            users: getterTypes.getAllUsers,
            isUserFetching: getterTypes.isFetching,
        }),
        ...mapGetters('configuration', {
            getLanguage: getterTypesConfiguration.getLanguage,
        }),
        crumbs: function () {
            return this.getLanguage === 'Hebrew'
                ? this.content.he.crumbs
                : this.content.en.crumbs
        },
    },
    mounted() {
        if (!this.users || this.users.length === 0) {
            this.loadAllUsers()
        }
    },
    methods: {
        ...mapActions('users', {
            loadAllUsers: actionTypes.loadAllUsers,
            deleteUser: actionTypes.deleteUser,
        }),
        ...mapActions('confirmer', {
            confirmHandle: actionTypesConfirmer.confirmHandle,
        }),
        ...mapActions('modalNotifications', {
            success: actionTypesModalNotifications.success,
        }),
        deleteUserHandle: async function ({ id }) {
            try {
                await this.confirmHandle(this.textContent.deleteMessage)
                await this.deleteUser(id)
                this.success('User delete')
            } catch (e) {
                console.error(e)
            }
        },
        editUserHandle: function (user) {
            this.modalEditUserIsActive = true
            this.selectedUser = user
        },
    },
}
</script>

<style scoped lang="scss">
.users {
    &__actions {
        margin-bottom: 15px;
        display: flex;
        gap: 16px;
    }

    &__body {
    }
}

.users-list {
    display: grid;
    grid-gap: 20px;
    grid-template-columns: 1fr;
    transition: all 1s;

    @include tablet-up {
        grid-template-columns: repeat(2, 1fr);
    }

    @include desktop-up {
        grid-template-columns: repeat(4, 1fr);
    }

    &__item {
        position: relative;
    }
}

.list-enter, .list-leave-to
  /* .list-complete-leave-active до версии 2.1.8 */ {
    opacity: 0;
    transform: translateY(30px);
}

.list-leave-active {
    position: absolute;
}
</style>
