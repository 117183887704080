<template>
    <v-expansion-panel>
        <v-expansion-panel-header color="#f7f7f9">
            <h4 class="order-property__sub-title">
                6. {{ propertiesContent['length'] }}
            </h4>
        </v-expansion-panel-header>
        <v-expansion-panel-content color="#f7f7f9">
            <div class="order-property__input-wrapper">
                <v-text-field
                    v-model="properties.fields['lengthFromNape']"
                    light
                    outlined
                    dense
                    type="text"
                    name="length-from-nape"
                    :label="propertiesContent['length-from-nape']"
                    :error-messages="properties.errors['lengthFromNape']"
                    @input="properties.errors['lengthFromNape'] = ''"
                >
                </v-text-field>
                <v-text-field
                    v-model="properties.fields['lengthFromTop']"
                    light
                    outlined
                    dense
                    type="text"
                    name="length-from-top"
                    :label="propertiesContent['length-from-top']"
                    :error-messages="properties.errors['lengthFromTop']"
                    @input="properties.errors['lengthFromTop'] = ''"
                >
                </v-text-field>
                <v-text-field
                    v-model="properties.fields['sideLength']"
                    light
                    outlined
                    dense
                    type="text"
                    name="side-length"
                    :label="propertiesContent['side-length']"
                    :error-messages="properties.errors['sideLength']"
                    @input="properties.errors['sideLength'] = ''"
                >
                </v-text-field>
                <v-text-field
                    v-model="properties.fields['lengthComments']"
                    light
                    outlined
                    dense
                    type="text"
                    name="length-comments"
                    :label="propertiesContent['length-comments']"
                    :error-messages="properties.errors['lengthComments']"
                    @input="properties.errors['lengthComments'] = ''"
                >
                </v-text-field>
            </div>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
import languagePropertiesMixin from '@/mixins/language-properties.mixin'

export default {
    name: 'OrderFormPropertyLength',
    mixins: [languagePropertiesMixin],
    props: {
        dataProperties: {
            type: Object,
            default: () => ({
                fields: {
                    ['lengthFromNape']: '',
                    ['lengthFromTop']: '',
                    ['sideLength']: '',
                    ['lengthComments']: '',
                },
                errors: {},
            }),
        },
    },
    data() {
        return {}
    },
    computed: {
        properties: function () {
            return this.dataProperties
        },
    },
    methods: {},
}
</script>

<style lang="scss" scoped>
.order-property {
    &__sub-title {
        color: $color-primary-dark;
    }

    &__input-wrapper {
        padding: 15px 15px 0;
    }

    &__inner {
        border: 1px solid #626161;
        padding: 15px;
        margin-bottom: 15px;
    }
}
</style>
