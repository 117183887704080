import { meetingListMutationTypes } from './types'

export default {
    [meetingListMutationTypes.SET_MEETINGS](state, meetings) {
        state.meetings = meetings
    },
    [meetingListMutationTypes.SET_TOTAL](state, total) {
        state.total = total
    },
    [meetingListMutationTypes.SET_FETCHING](state, isFetching) {
        state.isFetching = isFetching
    },
    [meetingListMutationTypes.SET_PAGE_COUNT](state, pageCount) {
        state.pageCount = pageCount
    },
    [meetingListMutationTypes.SET_PAGE](state, page) {
        state.page = page
    },
    [meetingListMutationTypes.SET_SELECTED_ITEM](state, selectedItem) {
        state.selectedItem = selectedItem
    },
    [meetingListMutationTypes.SET_SEARCH_QUERY](state, searchQuery) {
        state.searchQuery = searchQuery
    },
    [meetingListMutationTypes.SET_CALENDARS](state, calendars) {
        state.calendars = calendars
    },
}
