<template>
    <v-dialog v-model="isActive" width="400" @click:outside="closeHandle">
        <div class="payments-notes-modal">
            <div class="payments-notes-modal__head">
                <h3>
                    {{ $t('pages.wigPage.mainActions.changeStateToAnyByList') }}
                </h3>
                <v-btn
                    class="payments-notes-modal__close-btn"
                    icon
                    color="#55595c"
                    data-test-id="wig-page--payments--add-note-pop-up--close-btn"
                    @click="closeHandle"
                >
                    <v-icon>mdi-close-circle-outline</v-icon>
                </v-btn>
            </div>
            <div class="payments-notes-modal__body">
                <v-autocomplete
                    v-model="selectedState"
                    outlined
                    value="value"
                    te
                    :items="formattedWithKeyLabelStates"
                    dense
                >
                </v-autocomplete>
            </div>
            <div class="payments-notes-modal__foot">
                <v-btn
                    color="#3bafda"
                    dark
                    small
                    data-test-id="wig-page--payments--add-note-pop-up--submit-btn"
                    @click="submit"
                >
                    {{ $t('pages.wigPage.slideOuts.update') }}
                </v-btn>
            </div>
        </div>
    </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { actionTypes } from '@/store/modules/orders'
import { actionTypes as actionTypesModalNotifications } from '@/store/modules/modal-notifications'

import modalsMixin from '@/mixins/modals.mixin'

import popUpTypes from '@/types/pop-up-types'
import { getterTypes } from '@/store/modules/orders'
import languageMixin from '@/mixins/language.mixin'
import prepareBeforeTranslation from '@/helpers/prepareBeforeTranslation'

const { orderChangeStateToAny } = popUpTypes

export default {
    name: 'OrderModalChangeStateToAny',
    mixins: [modalsMixin, languageMixin],
    data() {
        return {
            selfName: orderChangeStateToAny,
            commonStates: [
                'Draft',
                'Cancelled Wigs',
                'Storage',
                'Delivered Wigs',
                'Archived',
            ],
            selectedState: '',
        }
    },
    computed: {
        ...mapGetters('orders', { order: getterTypes.getOrder }),
        states: function () {
            return [
                ...this.commonStates,
                this.order?.is_repair === 1
                    ? 'Repair Wigs'
                    : 'Wigs in Production',
                this.order?.is_repair === 1
                    ? 'Ready Repair Wigs'
                    : 'Ready New Wig',
            ]
        },
        selectedId: function () {
            console.log(this.order)
            return this.order.id
        },
        formattedWithKeyLabelStates: function () {
            return this.states.map((state) => {
                return {
                    value: state,
                    text: this.$t(
                        `shared.wigStates.${prepareBeforeTranslation(state)}`
                    ),
                }
            })
        },
    },
    methods: {
        ...mapActions('orders', {
            updateNotes: actionTypes.updateOrder,
            updateState: actionTypes.updateState,
        }),
        ...mapActions('modalNotifications', {
            success: actionTypesModalNotifications.success,
        }),
        submit: async function () {
            try {
                const payload = {
                    id: this.order.id,
                    state: this.selectedState,
                }
                await this.updateState(payload)
                this.closeHandle()
            } catch (e) {
                console.error(e)
            }
        },
    },
}
</script>

<style scoped lang="scss">
.payments-notes-modal {
    min-height: 100px;
    background: #fff;
    padding: 16px;
    width: 100%;

    &__head {
        margin-bottom: 8px;
        position: relative;
    }

    &__body {
        margin-bottom: 8px;
    }

    &__foot {
    }

    &__close-btn {
        position: absolute;
        top: -8px;
        right: -8px;

        @include for-hebrew {
            right: auto;
            left: -8px;
        }
    }
}
</style>
