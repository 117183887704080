<template>
    <v-dialog v-model="isActive" fullscreen persistent eager>
        <div class="calendar-layout">
            <div class="calendar-layout__actions">
                <v-btn
                    icon
                    data-test-id="calendar--main--close-btn"
                    @click="closeHandle"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <h4 class="calendar-layout__title">Calendar</h4>
            </div>
            <div class="calendar-layout__body">
                <Calendar />
            </div>
        </div>
    </v-dialog>
</template>

<script>
import Calendar from '@/views/calendar'
import { mapActions, mapGetters } from 'vuex'
import { actionTypes, getterTypes } from '@/store/modules/modals'
import popUpTypes from '@/types/pop-up-types'

const { calendarContext } = popUpTypes

export default {
    name: 'CalendarSubLayout',
    components: { Calendar },
    data() {
        return {
            dialog: true,
        }
    },
    computed: {
        ...mapGetters('modals', { checkIsActive: getterTypes.isActive }),
        isActive: function () {
            return this.checkIsActive(calendarContext)
        },
    },
    methods: {
        ...mapActions('modals', { onClose: actionTypes.closePopUp }),
        closeHandle: function () {
            this.onClose(calendarContext)
        },
    },
}
</script>

<style lang="scss" scoped>
.calendar-layout {
    position: absolute;
    bottom: 0;
    background: #fff;
    width: 100%;
    height: calc(100% - 40px);
    padding: 75px 15px 20px;

    &__actions {
        width: 100%;
        position: absolute;
        height: 65px;
        top: 0;
        left: 0;
        background: #fff;
        z-index: 2;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
        display: flex;
        align-items: center;
        padding: 0 15px;
    }

    &__body {
        position: relative;
        z-index: 1;
        height: fit-content;
    }

    &__title {
        margin-left: 10px;
    }
}
</style>
