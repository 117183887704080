const clientNameTrim = (client) => {
    let clientName = client
    let clientPhone = client.match(/\(.{0,20}\)$/)
    clientName = clientName.replace(/\(.{0,20}\)$/gi, '')

    if (!clientPhone) {
        clientPhone = client.match(/^\(.{0,20}\)/)
        clientName = clientName.replace(/^\(.{0,20}\)/i, '')
    }

    if (!clientPhone) {
        clientPhone = client.match(/\+[0-9]{1,4}\s[0-9]{3,10}$/)
        clientName = clientName.replace(/\+[0-9]{1,4}\s[0-9]{3,10}$/i, '')
    }

    if (!clientPhone) {
        clientPhone = client.match(/0[0-9]{9}$/)
        clientName = clientName.replace(/0[0-9]{9}$/i, '')
    }

    if (!clientPhone)
        return {
            clientPhone: '',
            clientName: client,
        }

    clientPhone =
        clientPhone[0] === '()'
            ? ''
            : clientPhone[0]
                  .split('')
                  .splice(1, clientPhone[0].length - 2)
                  .join('')
    return { clientName, clientPhone }
}

export default clientNameTrim
