<template>
    <v-dialog v-model="isActive" width="500" @click:outside="closeHandle">
        <div class="order-webcam">
            <div class="order-webcam__header">Webcam photo</div>
            <div class="order-webcam__body">
                <div class="order-webcam__camera-container">
                    <WebCam
                        ref="webcam"
                        :device-id="deviceId"
                        width="100%"
                        height="100%"
                        @started="onStarted"
                        @stopped="onStopped"
                        @error="onError"
                        @cameras="onCameras"
                        @camera-change="onCameraChange"
                    />
                </div>
                <div class="order-webcam__actions">
                    <v-btn dark color="#967adc" small @click="onCapture"
                        >Take Snapshot
                    </v-btn>
                </div>
                <div class="order-webcam__cameras">
                    <v-select
                        v-model="camera"
                        :items="devices"
                        label="Select Device"
                        dense
                        item-text="label"
                        item-value="deviceId"
                    ></v-select>
                </div>
            </div>
            <div class="order-webcam__footer">
                <v-btn dark color="#55595c" @click="closeHandle"> Close</v-btn>
            </div>
        </div>
    </v-dialog>
</template>

<script>
import { WebCam } from 'vue-web-cam'

export default {
    name: 'OrderModalWebcam',
    components: {
        // eslint-disable-next-line vue/no-unused-components
        WebCam,
    },
    props: {
        isActiveData: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['close-handle', 'on-capture'],
    data() {
        return {
            key: 'value',
            img: null,
            camera: null,
            deviceId: null,
            devices: [],
        }
    },
    computed: {
        isActive: {
            get: function () {
                return this.isActiveData
            },
            set: function () {
                this.closeHandle()
            },
        },
        device: function () {
            return this.devices.find((n) => n.deviceId === this.deviceId)
        },
    },
    watch: {
        camera: function (id) {
            this.deviceId = id
        },
        devices: function () {
            const [first] = this.devices
            if (first) {
                this.camera = first.deviceId
                this.deviceId = first.deviceId
            }
        },
    },
    methods: {
        closeHandle: function () {
            this.$emit('close-handle')
        },
        onCapture() {
            const img = this.$refs.webcam.capture()
            this.$emit('on-capture', img)
        },
        onStarted(stream) {
            console.log('On Started Event', stream)
        },
        onStopped(stream) {
            console.log('On Stopped Event', stream)
        },
        onStop() {
            this.$refs.webcam.stop()
        },
        onStart() {
            this.$refs.webcam.start()
        },
        onError(error) {
            console.log('On Error Event', error)
        },
        onCameras(cameras) {
            this.devices = cameras
            console.log('On Cameras Event', cameras)
        },
        onCameraChange(deviceId) {
            this.deviceId = deviceId
            this.camera = deviceId
            console.log('On Camera Change Event', deviceId)
        },
    },
}
</script>

<style lang="scss" scoped>
.order-webcam {
    background: $color-primary-light;

    &__header {
        padding: 15px;
        border-bottom: 1px solid #eceeef;
        margin-bottom: 15px;
    }

    &__body {
        padding: 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__camera-container {
        border: 1px solid $color-primary-dark;
        width: 350px;
        max-height: 450px;
        overflow: hidden;
        margin-bottom: 15px;
    }

    &__actions {
        display: flex;
        justify-content: center;
    }

    &__footer {
        padding: 15px;
        display: flex;
        justify-content: flex-end;
        border-top: 1px solid #eceeef;
    }

    &__cameras {
        margin-top: 15px;
    }
}
</style>
