import api from '@/api/wig-changes'

const state = () => ({
    changes: {},
})

export const getterTypes = {
    getWigChanges: '[changes] Get all changes',
}

export const actionTypes = {
    loadWigChanges: '[changes] Load all changes',
}

export const mutationsTypes = {
    addWigChanges: '[changes] Add changes',
}

const getters = {
    [getterTypes.getWigChanges]: ({ changes }) => changes,
}

const mutations = {
    [mutationsTypes.addWigChanges](state, data) {
        state.changes = data
    },
}

const actions = {
    [actionTypes.loadWigChanges]({ commit }, changeId) {
        api.wigChanges
            .getWigChanges(changeId)
            .then(({ data }) => {
                // console.log(data)
                commit(mutationsTypes.addWigChanges, data)
            })
            .catch()
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
