<template>
    <div class="sub-header">
        <h2 class="sub-header__title">{{ title }}</h2>
        <div class="sub-header__bread-crumbs">
            <BreadCrumbs :crumbs="crumbs" />
        </div>
    </div>
</template>

<script>
import BreadCrumbs from '@/components/common/bread-crumbs'

export default {
    name: 'SubHeader',
    components: {
        BreadCrumbs,
    },
    props: {
        title: {
            type: String,
            default: '',
            required: true,
        },
        crumbs: {
            type: Array,
            default: () => [],
            required: true,
        },
    },
}
</script>

<style lang="scss" scoped>
.sub-header {
    display: flex;
    align-items: center;
    margin-bottom: 25px;

    @media print {
        display: none;
    }

    &__title {
        padding-right: 25px;
        border-right: 1px solid $color-for-nav-link;
        margin-right: 25px;
        font-size: 20px;

        @include for-hebrew {
            border-right: none;
            border-left: 1px solid $color-for-nav-link;
            padding-right: 0px;
            padding-left: 25px;
            margin-right: 0px;
            margin-left: 25px;
        }

        @include tablet-up {
            font-size: 24px;
        }
    }
}
</style>
