import api from '@/api/jewish-calendar'
import {
    actionTypesJewishCalendar,
    mutationTypesJewishCalendar,
} from '@/store/modules/jewish-calendar/types'

export default {
    [actionTypesJewishCalendar.loadAll]({ commit, state }, date) {
        const range = `${date.year}-${date.month}`
        const cond = state?.en?.[range] && state?.en?.[range]?.length !== 0

        if (cond) return null
        // eslint-disable-next-line no-async-promise-executor
        return new Promise(async (res, rej) => {
            api.getDate(date)
                .then(({ items }) => {
                    res(items)
                    commit(mutationTypesJewishCalendar.addJewishDates, {
                        items,
                        range,
                        lang: date.lang,
                    })
                })
                .catch((er) => {
                    rej(er)
                })
        })
    },
}
