import { ordersV2MutationTypes } from '@/store/modules/orders_v2/types'
import { ordersV2ActionTypes } from '@/store/modules/orders_v2/types'

import api from '@/api/orders'

export default {
    [ordersV2ActionTypes.FETCH_ORDERS]: async ({ commit, state }) => {
        commit(ordersV2MutationTypes.SET_LOADING, true)

        const params = {
            itemsPerPage: state.itemsPerPage,
            currentPage: state.currentPage,
            state: state.ordersState,
            search: state.search,
            special: state.special,
            managers: state.managers,
            usaToolsIsEnabled: state.usaToolsIsEnabled,
            sort: state.sort,
        }

        try {
            const response = await api.getAllV2(params)
            commit(ordersV2MutationTypes.SET_ORDERS, response.wigs)
            commit(
                ordersV2MutationTypes.SET_TOTAL_ITEMS,
                response.pagination.total
            )
        } catch (error) {
            console.error(error)
        } finally {
            commit(ordersV2MutationTypes.SET_LOADING, false)
        }
    },
    [ordersV2ActionTypes.SET_CURRENT_PAGE]: ({ commit, dispatch }, page) => {
        commit(ordersV2MutationTypes.SET_CURRENT_PAGE, page)
        dispatch(ordersV2ActionTypes.FETCH_ORDERS)
    },
    [ordersV2ActionTypes.SET_USA_MANAGERS]: (
        { commit, dispatch },
        managers
    ) => {
        commit(ordersV2MutationTypes.SET_USA_MANAGERS, managers)
        dispatch(ordersV2ActionTypes.FETCH_ORDERS)
    },
    [ordersV2ActionTypes.SET_SEARCH_QUERY]: ({ commit, dispatch }, query) => {
        commit(ordersV2MutationTypes.SET_CURRENT_PAGE, 1)
        commit(ordersV2MutationTypes.SET_SEARCH_QUERY, query)
        dispatch(ordersV2ActionTypes.FETCH_ORDERS)
    },
    [ordersV2ActionTypes.SET_SORT]: ({ commit, dispatch }, sort) => {
        commit(ordersV2MutationTypes.SET_SORT, sort)
        dispatch(ordersV2ActionTypes.FETCH_ORDERS)
    },
    [ordersV2ActionTypes.SET_SPECIAL]: ({ commit, dispatch }, special) => {
        commit(ordersV2MutationTypes.SET_SPECIAL, special)
        commit(ordersV2MutationTypes.SET_CURRENT_PAGE, 1)
        dispatch(ordersV2ActionTypes.FETCH_ORDERS)
    },
    [ordersV2ActionTypes.SET_ORDERS_STATE]: ({ commit, dispatch }, state) => {
        commit(ordersV2MutationTypes.SET_ORDERS_STATE, state)
        commit(ordersV2MutationTypes.SET_CURRENT_PAGE, 1)
        dispatch(ordersV2ActionTypes.FETCH_ORDERS)
    },
    [ordersV2ActionTypes.SET_USA_TOOLS]: ({ commit, dispatch }, value) => {
        commit(ordersV2MutationTypes.SET_USA_TOOLS, value)
        commit(ordersV2MutationTypes.SET_CURRENT_PAGE, 1)
        dispatch(ordersV2ActionTypes.FETCH_ORDERS)
    },
}
