<template>
    <v-dialog v-model="isActive" width="700">
        <div class="additional-properties">
            <div class="additional-properties__head">
                <h3 class="additional-properties__title">
                    {{ textContent['update-repair-properties'] }}
                </h3>
                <v-btn icon color="#55595c" @click="closeHandle">
                    <v-icon>mdi-close-circle-outline</v-icon>
                </v-btn>
            </div>
            <div class="additional-properties__body">
                <OrdersAdditionalProperties
                    :on-dispatch="onDispatch"
                    @dispatch-cancel-handle="
                        () => {
                            onDispatch = 0
                            closeHandle()
                        }
                    "
                />
            </div>
            <div class="additional-properties__foot">
                <v-btn small dark color="#967adc" @click="updateHandle">
                    {{ textContent['update'] }}
                </v-btn>
                <v-btn small dark color="#55595c" @click="closeHandle">
                    {{ textContent['close'] }}
                </v-btn>
            </div>
        </div>
    </v-dialog>
</template>

<script>
import languageMixin from '@/mixins/language.mixin'
import modalsMixin from '@/mixins/modals.mixin'
import OrdersAdditionalProperties from '@/components/orders/orders-additional-properties'

import popUpTypes from '@/types/pop-up-types'
import { mapGetters } from 'vuex'
import { getterTypes as getterTypesOrders } from '@/store/modules/orders'

const { orderAdditionalPropertiesUpdate } = popUpTypes

export default {
    name: 'ModalAddUser',
    components: { OrdersAdditionalProperties },

    mixins: [modalsMixin, languageMixin],
    data() {
        return {
            onDispatch: 0,
            selfName: orderAdditionalPropertiesUpdate,
        }
    },
    computed: {
        ...mapGetters('orders', { order: getterTypesOrders.getOrder }),
    },
    methods: {
        updateHandle: function () {
            this.onDispatch = this.order.id
        },
    },
}
</script>

<style scoped lang="scss">
.additional-properties {
    background: #fff;
    padding: 5px 15px 20px;

    &__head {
        display: flex;
        justify-content: space-between;
        padding: 15px 10px;
    }

    &__actions {
    }

    &__foot {
        padding: 25px 10px 0;
        display: flex;
        justify-content: space-between;
    }
}
</style>
