import axios from 'axios'
import { getStorage } from '@/helpers/Local'

let startTime = 0
axios.interceptors.request.use((config) => {
    const token = getStorage('access_token')
    config.headers.Authorization = token ? `Bearer ${token}` : ''
    startTime = new Date()
    return config
})

axios.interceptors.response.use(
    (response) => {
        // console.log(`[${response.config.url}]`, new Date() - startTime, 'ms')
        return response
    },
    (error) => {
        const url = error.response.config.url
        const status = error.response.status
        if (status === 401 && url !== '/base/api/login/')
            document.location.reload()

        return Promise.reject(error)
    }
)

export default axios
