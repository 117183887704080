import { mutationTypesJewishCalendar } from '@/store/modules/jewish-calendar/types'

export default {
    [mutationTypesJewishCalendar.addJewishDates](
        state,
        { items, range, lang }
    ) {
        const type = lang === 'he' ? 'he' : 'en'
        const dates = items
            .filter((item) => item.date.length <= 10)
            .map((item) => ({
                ['start_at']: item.date,
                text: item.title,
                link: item?.link,
                color: '#787878',
                category: 'holidays',
            }))
        state[type] = {
            ...state[type],
            [range]: dates,
        }
    },
}
