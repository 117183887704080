<template>
    <div class="reports-payment">
        <div class="reports-payment__top">
            <div class="reports-payment__select">
                <v-text-field
                    v-model="requestData.from"
                    dense
                    outlined
                    type="date"
                    name="from"
                    :label="textContent['from']"
                    hide-details
                ></v-text-field>
            </div>
            <div class="reports-payment__select">
                <v-text-field
                    v-model="requestData.to"
                    dense
                    outlined
                    type="date"
                    name="to"
                    :label="textContent['to']"
                    hide-details
                ></v-text-field>
            </div>
            <v-btn
                class="reports-payment__btn"
                dark
                color="#967adc"
                @click="datePaymentsHandle"
            >
                {{ $t('pages.report.reload') }}
            </v-btn>
            <v-btn
                class="reports-payment__btn-pdf"
                outlined
                dark
                color="#967adc"
                @click="generatePdf"
                >pdf
            </v-btn>
        </div>
        <template>
            <ReportsPaymentTable />
        </template>
    </div>
</template>

<script>
import ReportsPaymentTable from '@/components/reports/reports-payment-table'
import languageMixin from '@/mixins/language.mixin'
import languageOrderExtraMixin from '@/mixins/language-order-extra.mixin'
import { mapActions } from 'vuex'
import { actionTypes } from '@/store/modules/report'
import regularToSnakeCase from '@/helpers/regular-to-snake-case'
import { zonedTimeToUtc } from 'date-fns-tz'
import { add } from 'date-fns'

export default {
    name: 'ReportsPayment',
    components: { ReportsPaymentTable },
    mixins: [languageMixin, languageOrderExtraMixin],
    data: () => ({
        employees: false,
        requestData: {
            from: '',
            to: '',
        },
    }),
    mounted() {
        this.presentDate()
        this.datePaymentsHandle()
        if (this.employees) {
            this.dateEmployeesHandle()
        }
    },
    methods: {
        ...mapActions('report', {
            getPayments: actionTypes.getPayments,
            getPaymentsPdf: actionTypes.getPaymentsByPdf,
            getEmployees: actionTypes.getEmployees,
            getEmployeesPdf: actionTypes.getEmployeesByPdf,
        }),
        addZero: function (data) {
            return data < 10 ? '0' + data : data
        },
        presentDate: function () {
            const date = new Date()
            const year = date.getFullYear()
            const month = this.addZero(date.getMonth() + 1)
            const day = this.addZero(date.getDate())

            this.requestData.from = `${year}-${month}-${day}`
            this.requestData.to = `${year}-${month}-${day}`
        },
        getStatus: function (status) {
            return this.extraContent[regularToSnakeCase(status)]
        },
        datePaymentsHandle: async function () {
            // TODO: date and time refactor
            const format = (date) => {
                return `${date.toISOString().substring(0, 10)} ${date
                    .toISOString()
                    .substring(11, 19)}`
            }

            const fromWithHebrewOffset = zonedTimeToUtc(
                this.requestData.from,
                'Asia/Jerusalem'
            )

            const toWithHebrewOffset = add(
                zonedTimeToUtc(this.requestData.to, 'Asia/Jerusalem'),
                {
                    hours: 24,
                }
            )

            await this.getPayments({
                from: format(fromWithHebrewOffset),
                to: format(toWithHebrewOffset),
                format: 'json',
            })
        },
        generatePdf: async function () {
            const payload = {
                from: this.requestData.from,
                to: this.requestData.to,
                format: 'pdf',
            }

            const handle = this.employees
                ? this.getEmployeesPdf
                : this.getPaymentsPdf

            await handle(payload)
        },
    },
}
</script>

<style lang="scss" scoped>
.reports-payment {
    &__top {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 16px;
    }

    &__switch {
        display: flex;
        align-items: center;
    }

    &__text {
        margin-right: 7px;
        font-size: 16px;

        @include for-hebrew {
            margin-right: 0;
            margin-left: 7px;
        }
    }

    &__select {
        height: 57px;
        max-width: 200px;
        width: 100%;

        & + & {
            margin: 0 10px;
        }
    }

    &__name {
        color: #939393;
    }

    &__btn {
        max-width: 130px;
        height: 40px;
    }

    &__btn-pdf {
        margin-left: 15px;

        @include for-hebrew {
            margin-left: 0;
            margin-right: 15px;
        }
    }

    td {
        text-transform: capitalize;
    }
}
</style>
