<template>
    <ul class="deposit" data-test-id="wig-page--payments--history-table">
        <li
            v-for="({ amount, source, comment, createdAt }, i) in payments"
            :key="i"
            class="deposit__item"
        >
            <p class="deposit__date">
                <DateCell :value="createdAt"></DateCell>
            </p>
            <div class="deposit__payment-block">
                <p class="deposit__amount">{{ amount }}</p>
                <p class="deposit__extra">{{ text(source) }}, {{ comment }}</p>
            </div>
        </li>
    </ul>
</template>

<script>
import { mapGetters } from 'vuex'
import { getterTypes } from '@/store/modules/payments'
import languageMixin from '@/mixins/language.mixin'
import { getFullDayFirstFull } from '@/helpers/getDateInFormat'
import regularToSnakeCase from '@/helpers/regular-to-snake-case'
import DateCell from '@/components/common/cells/date-cell.vue'
import { format } from 'date-fns'
import { FULL_DATE_FORMAT } from '@/consts'

export default {
    name: 'OrderDepositHistory',
    components: { DateCell },
    mixins: [languageMixin],
    computed: {
        ...mapGetters('payments', { getPayments: getterTypes.getPayments }),
        payments: function () {
            return this.getPayments
                .filter((payment) => payment.terminated !== 1)
                .map((payment) => {
                    return {
                        ...payment,
                        createdAt: payment.created_at,
                    }
                })
        },
        textContent: function () {
            return (
                this.getTextForSelected(this.lang, 'OrderModalDepositUpdate') ||
                ''
            )
        },
    },
    methods: {
        text: function (text) {
            regularToSnakeCase()
            return this.textContent[regularToSnakeCase(text)] || `*${text}`
        },
        dateFormat: function (date) {
            return date ? getFullDayFirstFull(date) : ''
        },
        createdAtDateFormat: function (date) {
            return format(new Date(date), FULL_DATE_FORMAT)
        },
    },
}
</script>

<style scoped lang="scss">
.deposit {
    padding: 0 0 5px;
    &__item {
        display: flex;
        color: #878787;
        font-size: 12px;

        & + & {
            margin-top: 4px;
        }

        p {
            margin-bottom: 0 !important;
        }
    }
    &__payment-block {
        display: flex;
        gap: 5px;
    }
    &__date {
        min-width: 120px;
        span {
            color: #878787 !important;
        }
    }

    &__amount {
        min-width: 38px;
        margin-right: 4px;
        font-weight: 700;
        color: #000;
    }
}
</style>
