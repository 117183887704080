<template>
    <div>
        <VueHtml2pdf
            ref="html2Pdf"
            :show-layout="false"
            :float-layout="true"
            :enable-download="false"
            :preview-modal="true"
            :paginate-elements-by-height="1100"
            :pdf-quality="1"
            :manual-pagination="true"
            pdf-format="a4"
            pdf-orientation="portrait"
            pdf-content-width="800px"
            :html-to-pdf-options="htmlToPdfOptions"
            @progress="progress($event)"
        >
            <template #pdf-content>
                <section class="pdf-item">
                    <OrdersContextInfoForPrint />
                </section>
            </template>
        </VueHtml2pdf>
        <div @click="generateReport">
            <slot name="activator">Отправить</slot>
        </div>
    </div>
</template>

<script>
import VueHtml2pdf from 'vue-html2pdf'
import OrdersContextInfoForPrint from '@/features/wig-page/components/wigs-details-print-page.vue'
import { mapMutations } from 'vuex'
import { mutationTypes } from '@/store/modules/modal-loader-progress'

export default {
    name: 'OrdersPrintInfo',
    components: {
        VueHtml2pdf,
        OrdersContextInfoForPrint,
    },
    data() {
        return {
            htmlToPdfOptions: {
                margin: 0.15,
                filename: `wig.pdf`,

                image: {
                    type: 'png',
                    quality: 1,
                },

                enableLinks: false,

                html2canvas: {
                    scale: 1,
                    useCORS: true,
                    removeContainer: false,
                },

                jsPDF: {
                    unit: 'in',
                    format: 'a4',
                    orientation: 'portrait',
                },
            },
        }
    },
    methods: {
        ...mapMutations('modalLoaderProgress', {
            setProgress: mutationTypes.setProgress,
            startPDF: mutationTypes.startLoading,
            finishPDF: mutationTypes.endLoading,
        }),
        progress: function (e) {
            if (e === 100) this.finishPDF()
        },
        generateReport() {
            this.startPDF()
            setTimeout(() => {
                this.$refs.html2Pdf.generatePdf()
            }, 1000)
        },
    },
}
</script>

<style lang="scss">
.orders-context-info {
    padding: 20px 30px;
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;

    &__column {
        width: 45%;
    }

    &__title {
        font-size: 16px;
    }

    &__desc {
        font-size: 14px;

        &--details {
            color: #cc3c3c;
        }
    }

    &__icon-btn {
        cursor: pointer;
    }
}
</style>
