import { render, staticRenderFns } from "./order-modal-additional-properties-update.vue?vue&type=template&id=7d79cb74&scoped=true"
import script from "./order-modal-additional-properties-update.vue?vue&type=script&lang=js"
export * from "./order-modal-additional-properties-update.vue?vue&type=script&lang=js"
import style0 from "./order-modal-additional-properties-update.vue?vue&type=style&index=0&id=7d79cb74&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7d79cb74",
  null
  
)

export default component.exports