import { clientsV2GetterTypes } from './types'

const getters = {
    [clientsV2GetterTypes.GET_ITEMS]: (state) => state.items,
    [clientsV2GetterTypes.GET_TOTAL_ITEMS]: (state) => state.total,
    [clientsV2GetterTypes.GET_ITEMS_PER_PAGE]: (state) => state.itemsPerPage,
    [clientsV2GetterTypes.GET_TOTAL_PAGES]: (state) =>
        Math.ceil(state.total / state.itemsPerPage),
    [clientsV2GetterTypes.GET_CURRENT_PAGE]: (state) => state.currentPage,
    [clientsV2GetterTypes.GET_CLIENTS_STATUS]: (state) => state.status,
    [clientsV2GetterTypes.GET_LOADING]: (state) => state.loading,
    [clientsV2GetterTypes.GET_ERROR]: (state) => state.error,
}

export default getters
