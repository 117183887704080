<template>
    <div class="order-properties">
        <v-expansion-panels v-model="mainPanelIsActive" dark :disabled="false">
            <v-expansion-panel>
                <v-expansion-panel-header color="#967adc">
                    <h3 class="order-properties__title">
                        {{ propertiesContent['properties'] }}
                    </h3>
                </v-expansion-panel-header>
                <v-expansion-panel-content color="#fff">
                    <v-expansion-panels v-model="panel" multiple light>
                        <v-expansion-panel
                            v-for="(item, i) in template"
                            :key="i"
                        >
                            <v-expansion-panel-header color="#f7f7f9">
                                <h4 class="order-property__sub-title">
                                    {{ i + 1 }}. {{ template[i].header }}
                                </h4>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content color="#f7f7f9">
                                <div class="order-property__input-wrapper">
                                    <v-text-field
                                        v-model="properties.fields[item.field]"
                                        light
                                        outlined
                                        dense
                                        type="text"
                                        :name="item.field"
                                        :label="template[i].header"
                                        :error-messages="
                                            properties.errors[item.field]
                                        "
                                        @input="
                                            properties.errors[item.field] = ''
                                        "
                                    >
                                    </v-text-field>
                                </div>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
import { actionTypes } from '@/store/modules/language-pack'
import languagePropertiesMixin from '@/mixins/language-properties.mixin'

export default {
    name: 'OrderFormPropertiesForRepair',
    mixins: [languagePropertiesMixin],
    props: {
        // eslint-disable-next-line vue/require-prop-types
        mainPanel: {
            default: '',
        },
        dataProperties: {
            type: Object,
            default: () => ({
                fields: {
                    ['wigType']: '',
                    ['capSize']: '',
                    ['topType']: '',
                    ['front']: '',
                    ['baseColor']: '',
                    ['length']: '',
                    ['hairTextureOnWefts']: '',
                    ['wigFullness']: '',
                    ['weight']: '',
                },
            }),
        },
    },
    emits: ['panel-handler'],
    data() {
        return {
            errors: {},
            panel: [],
        }
    },
    computed: {
        template: function () {
            return [
                {
                    header: this.propertiesContent['wig-type'],
                    field: 'wigType',
                },
                {
                    header: this.propertiesContent['cap-size'],
                    field: 'capSize',
                },
                {
                    header: this.propertiesContent['top-type'],
                    field: 'topType',
                },
                { header: this.propertiesContent['front'], field: 'front' },
                {
                    header: this.propertiesContent['base-color'],
                    field: 'baseColor',
                },
                { header: this.propertiesContent['length'], field: 'length' },
                {
                    header: this.propertiesContent['hair-texture-on-wefts'],
                    field: 'hairTextureOnWefts',
                },
                {
                    header: this.propertiesContent['wig-fullness'],
                    field: 'wigFullness',
                },
                { header: this.propertiesContent['weight'], field: 'weight' },
            ]
        },
        properties: function () {
            return { ...this.dataProperties, errors: this.errors }
        },
        mainPanelIsActive: {
            get: function () {
                return this.mainPanel
            },
            set: function (newValue) {
                this.$emit('panel-handler', newValue)
            },
        },
    },
    methods: {
        ...mapActions('languagePack', {
            loadLanguage: actionTypes.getLanguagePack,
        }),
    },
}
</script>

<style scoped lang="scss">
.order-property {
    &__sub-title {
        color: $color-primary-dark;
    }

    &__input-wrapper {
        padding: 15px 15px 0;
    }
}
</style>
