export const meetingListActionTypes = {
    FETCH_MEETINGS: 'meetingsList/fetchMeetings',
    SET_PAGE: 'meetingsList/setPage',
    SET_SEARCH_QUERY: 'meetingsList/setSearchQuery',
    SET_CALENDARS: 'meetingsList/setCalendars',
}

export const meetingListMutationTypes = {
    SET_MEETINGS: 'meetingsList/setMeetings',
    SET_TOTAL: 'meetingsList/setTotal',
    SET_FETCHING: 'meetingsList/setFetching',
    SET_PAGE_COUNT: 'meetingsList/setPageCount',
    SET_PAGE: 'meetingsList/setPage',
    SET_SELECTED_ITEM: 'meetingsList/setSelectedItem',
    SET_SEARCH_QUERY: 'meetingsList/setSearchQuery',
    SET_CALENDARS: 'meetingsList/setCalendars',
}

export const meetingListGetterTypes = {
    GET_ALL_MEETINGS: 'meetingsList/getAllMeetings',
    GET_IS_FETCHING: 'meetingsList/getIsFetching',
    GET_PAGE_COUNT: 'meetingsList/getPageCount',
    GET_SELECTED_ITEM: 'meetingsList/getSelectedItem',
    GET_TOTAL: 'meetingsList/getTotal',
}
