import calendarRangeHandler from '@/helpers/calendarRangeHandler'

const rangeForRequest = (date) => {
    const reformatDate = date.split('-')
    const selectedDate = new Date(reformatDate[0], reformatDate[1] - 1)
    const selectedYear = selectedDate.getFullYear()
    let selectedMonth = selectedDate.getMonth() + 1
    if (selectedMonth.toString().length === 1)
        selectedMonth = `0${selectedMonth}`
    const quantityOfDays = new Date(selectedYear, selectedMonth, 0).getDate()

    const start = `${selectedYear}-${selectedMonth}-01`
    const end = `${selectedYear}-${selectedMonth}-${quantityOfDays}`
    return `${start}&date_to=${end}`
}

const getRange = (data) => {
    const date = new Date(data)
    const currentYear = date.getFullYear()
    const currentMonth = date.getMonth() + 1
    return `${currentYear}-${currentMonth}`
}

const getRangeForJewishCalendar = (range) => {
    const { dateFrom, dateTo } = calendarRangeHandler(range)
    const reformat = (range) => {
        const date = new Date(range)
        return { year: date.getFullYear(), month: date.getMonth() + 1 }
    }

    return [reformat(dateFrom), reformat(range), reformat(dateTo)]
}

export default rangeForRequest
export { getRange, getRangeForJewishCalendar }
