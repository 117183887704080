import axios from '@/api/axios'

export default {
    user: {
        getAll: () => axios.get(`/base/api/users/200`).then((res) => res.data),
        getUser: (id) =>
            axios.get(`/base/api/user/${id}`).then((res) => res.data),
        addUser: (credentials) =>
            axios
                .post('/base/api/user/', { ...credentials })
                .then((res) => res.data),
        deleteUser: (id) =>
            axios.delete(`/base/api/user/${id}`).then((res) => res.data),
        changeRole: ({ id, newRole, newColor }) =>
            axios.put(`/base/api/user/${id}/updateRole`, {
                ['role_id']: newRole,
                ['hex_color']:newColor
            }),
        changePassword: ({ id, password }) =>
            axios.put(`/base/api/user/${id}/updatePassword`, { password }),
        changeName: ({ id, nickName }) =>
            axios.put(`/base/api/user/${id}/updateName`, { nickName }),
        report: (params) => axios.get(`/base/api/report/employees?${params}`),
        reportByPayments: (params) =>
            axios.get(`/base/api/report/payments?${params}`),
        changeData: (credentials) =>
            axios.put('/base/api/user/updateData', credentials),
        getUsersByRole: (params) =>
            axios.get(`/base/api/getUsersByRole?role=${params}`),

    },
}
