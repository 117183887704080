export default {
    returnLink: 'Back to wigs table',
    header: 'General information',
    items: {
        client: 'Client',
        barcode: 'Barcode',
        additionalWigID: 'Additional wig ID',
        details: 'Details',
        state: 'State',
        status: 'Status',
        storageNotes: 'Storage notes',
        completeDate: 'Complete date',
        meetingManager: 'Meeting manager',
        designer: 'Designer',
        manager: 'Manager',
        createdBy: 'Created By',
        createdAt: 'Created At',
        updatedAt: 'Updated At',
    },
    extraActions: {
        changeClient: 'Change client',
        changeManager: 'Change manager',
        scheduleAppointment: 'Schedule an appointment',
        scheduleAppointmentForUSA: 'Schedule an appointment for USA',
        showScheduledAppointment: 'Show scheduled appointment',
        rescheduleForRework: 'Reschedule for rework',
    },
}
