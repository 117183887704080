<template>
    <v-dialog v-model="isActive" width="530" @click:outside="cancelHandle">
        <div class="client-context" style="--bg: #fff">
            <div class="client-context__head">
                <h3 class="client-context__title">
                    {{ textContent['View-client'] }} -
                    <span v-if="getClient['full_name']">{{
                        getClient['full_name']
                    }}</span>
                    <span v-if="getClient.isDeleted === 1"> [Deleted] </span>
                </h3>
                <v-btn
                    icon
                    color="#55595c"
                    data-test-id="clients--client-pop-up--close-btn"
                    @click="cancelHandle"
                >
                    <v-icon>mdi-close-circle-outline</v-icon>
                </v-btn>
            </div>
            <div class="client-context__body">
                <div class="tabs">
                    <v-tabs v-model="tabs" color="#3bafda" centered>
                        <v-tab
                            v-for="({ title, icon }, index) in tabsText"
                            :key="index"
                        >
                            <p class="tabs__title">
                                <v-icon class="tabs__icon">{{ icon }}</v-icon>
                                <span class="tabs__title-text">{{
                                    title
                                }}</span>
                            </p>
                        </v-tab>
                    </v-tabs>

                    <v-tabs-items v-model="tabs">
                        <v-tab-item>
                            <div class="tabs__body client-info">
                                <h3 class="tabs__sub-title">Info</h3>
                                <div
                                    v-for="(
                                        { title, value }, index
                                    ) in info.items"
                                    :key="index"
                                    class="client-info__column"
                                >
                                    <p class="client-info__title">
                                        {{ title }}
                                    </p>

                                    <p
                                        v-if="info.items[0].value"
                                        class="client-info__value"
                                    >
                                        {{ value }}
                                    </p>
                                </div>
                            </div>
                        </v-tab-item>
                        <v-tab-item>
                            <div class="tabs__body">
                                <h3 class="tabs__sub-title">
                                    {{ textContent['Wigs-list'] }}
                                </h3>
                                <template v-if="getClient['wigs']">
                                    <ClientWigsList :wigs="getClient['wigs']" />
                                </template>
                            </div>
                        </v-tab-item>
                        <v-tab-item>
                            <div class="tabs__body">
                                <h3 class="tabs__sub-title">
                                    {{ textContent['Calendar'] }}
                                </h3>
                                <template v-if="getClient['meetings']">
                                    <ClientMeetingsList
                                        :meetings="getClient['meetings']"
                                    />
                                </template>
                            </div>
                        </v-tab-item>
                    </v-tabs-items>
                </div>
            </div>
            <v-divider></v-divider>
            <div class="client-context__footer">
                <template v-if="getClient.isDeleted === 0">
                    <v-btn
                        dark
                        small
                        color="#3bafda"
                        @click="() => addOrder(true)"
                    >
                        <v-icon class="btn-icon">mdi-wrench</v-icon>
                        <span class="btn-text">{{
                            textContent['add-repair']
                        }}</span>
                    </v-btn>
                    <v-btn
                        dark
                        small
                        color="#3bafda"
                        @click="() => addOrder(false)"
                    >
                        <v-icon class="btn-icon">mdi-pencil-outline</v-icon>
                        <span class="btn-text">{{
                            textContent['add-order']
                        }}</span>
                    </v-btn>
                    <v-btn dark small color="#967adc" @click="moveToCalendar">
                        <v-icon class="btn-icon">mdi-calendar-week</v-icon>
                        <span class="btn-text">{{
                            textContent['Calendar']
                        }}</span>
                    </v-btn>
                    <v-btn
                        :disabled="!getAllowed(permission.edit)"
                        :dark="getAllowed(permission.edit)"
                        small
                        color="#3bafda"
                        @click="editHandle"
                    >
                        <v-icon class="btn-icon">mdi-pencil-outline</v-icon>
                        <span class="btn-text">{{ textContent.edit }}</span>
                    </v-btn>
                    <v-btn
                        :disabled="!getAllowed(permission.delete)"
                        :dark="getAllowed(permission.delete)"
                        small
                        color="#da4453"
                        @click="deleteHandle"
                    >
                        <v-icon class="btn-icon"> mdi-trash-can-outline</v-icon>
                        <span class="btn-text">{{
                            textContent['Delete']
                        }}</span>
                    </v-btn>
                </template>
                <template v-else>
                    <v-btn
                        :disabled="!getAllowed(permission.delete)"
                        :dark="getAllowed(permission.delete)"
                        small
                        color="#4caf50"
                        @click="restoreHandle"
                    >
                        <v-icon class="btn-icon"> mdi-restore</v-icon>
                        <span class="btn-text">Restore</span>
                    </v-btn>
                </template>
            </div>
        </div>
    </v-dialog>
</template>

<script>
import ClientWigsList from '@/components/clients/client-wigs-list'
import ClientMeetingsList from '@/components/clients/client-meetings-list'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import {
    actionTypes as actionTypesClients,
    getterTypes,
} from '@/store/modules/clients'
import { gettersTypes as getterTypesForAuth } from '@/store/modules/auth'
import {
    getterTypes as getterTypesModals,
    actionTypes as actionTypesModals,
} from '@/store/modules/modals'
import { actionTypes as actionTypesConfirmer } from '@/store/modules/confirmer'
import { mutationTypes as mutationTypesClients } from '@/store/modules/clients'

import popUpTypes from '@/types/pop-up-types'
import { actionTypes as actionTypesModalNotifications } from '@/store/modules/modal-notifications'
import phoneNumberView from '@/helpers/phone-number-view'
import languageMixin from '@/mixins/language.mixin'
import { getFullDayFirstFull } from '@/helpers/getDateInFormat'
import formatDate from '@/helpers/format-date'

const { clientUpdate, clientContext, calendarContext, orderAdd } = popUpTypes

export default {
    name: 'ClientModalContext',
    components: {
        ClientWigsList,
        ClientMeetingsList,
    },
    mixins: [languageMixin],
    props: {
        id: {
            type: Number,
            default: 0,
        },
    },
    emits: ['close-modal', 'edit-handle', 'delete-handle', 'add-order-handle'],
    data() {
        return {
            dialog: true,
            tabs: 0,
            permission: {
                edit: ['ADMIN', 'MANAGER', 'USA_MANAGER'],
                delete: ['ADMIN'],
            },
        }
    },
    computed: {
        ...mapGetters('auth', { getAllowed: getterTypesForAuth.getAllowed }),
        ...mapGetters('clients', {
            getClient: getterTypes.getClient,
            getAllClients: getterTypes.getAllClients,
        }),
        ...mapGetters('modals', { checkIsActive: getterTypesModals.isActive }),
        isActive: function () {
            return this.checkIsActive(clientContext)
        },
        tabsText: function () {
            return [
                { title: this.textContent['Info'], icon: 'mdi-information' },
                {
                    title: this.textContent['Wigs-list'],
                    icon: 'mdi-format-list-bulleted',
                },
                {
                    title: this.textContent['Calendar'],
                    icon: 'mdi-calendar-month-outline',
                },
            ]
        },
        info: function () {
            return {
                items: [
                    {
                        title: this.textContent['Name'],
                        value: this.getClient['full_name'],
                    },
                    {
                        title: this.textContent['Email'],
                        value: this.getClient['email'],
                    },
                    {
                        title: this.textContent['Phone'],
                        value: phoneNumberView(this.getClient['phone']),
                    },
                    {
                        title: this.textContent['additional-phone'],
                        value: this.getClient['additionalPhone'],
                    },
                    {
                        title: this.textContent['Address'],
                        value: this.getClient['address'],
                    },
                    {
                        title: this.textContent['created'],
                        value: formatDate(this.getClient['created_at']),
                    },
                    {
                        title: this.textContent['updated'],
                        value: formatDate(this.getClient['updated_at']),
                    },
                ],
            }
        },
        modalIsActive: function () {
            return this.isActive
        },
    },
    methods: {
        ...mapActions('confirmer', {
            confirmHandle: actionTypesConfirmer.confirmHandle,
        }),
        ...mapActions('modals', {
            onClose: actionTypesModals.closePopUp,
            onOpen: actionTypesModals.openPopUp,
        }),
        ...mapActions('clients', {
            deleteClient: actionTypesClients.deleteClient,
            restoreClient: actionTypesClients.restoreClient,
        }),
        ...mapActions('modalNotifications', {
            success: actionTypesModalNotifications.success,
        }),
        ...mapMutations('clients', {
            onClear: mutationTypesClients.clearSelected,
        }),
        cancelHandle: function () {
            this.onClose(clientContext)
            this.onClear()
            this.tabs = 0
        },
        editHandle: function () {
            this.onOpen(clientUpdate)
        },
        deleteHandle: async function () {
            try {
                await this.confirmHandle(
                    this.textContent['message-delete-client']
                )
                await this.deleteClient(this.getClient.id)
                this.success('Client has been delete')
                this.onClose(clientContext)
            } catch (e) {
                console.log(e)
            }
        },
        restoreHandle: async function () {
            try {
                await this.confirmHandle(
                    this.textContent['message-restore-client']
                )
                await this.restoreClient(this.getClient.id)
                this.success('Client has been restored')
                this.onClose(clientContext)
            } catch (e) {
                console.log(e)
            }
        },
        addOrder: function (isRepair) {
            const payload = { isRepair, clientId: this.getClient.id }
            this.onOpen({ name: orderAdd, payload })
        },
        moveToCalendar: function () {
            const { id, meetings } = this.getClient
            const selectDate = () =>
                meetings.reduce((ak, meetings) => {
                    const date = +new Date(meetings.start_at) - Date.now()
                    const akDate = +new Date(ak.start_at) - Date.now()

                    return date >= akDate && date > 0 ? meetings : ak
                }).start_at

            let payload =
                meetings.length > 0
                    ? { action: 'to-date', selectDate: selectDate() }
                    : { action: 'for-client', clientId: id }

            this.onOpen({ name: calendarContext, payload })
        },
    },
}
</script>

<style scoped lang="scss">
.client-context {
    background: var(--bg);
    padding: 10px;

    &__head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 7px;
        padding-bottom: 7px;
        border-bottom: 1px solid #eceeef;
    }

    &__title {
        color: #525252;
        font-weight: 400;
        font-size: 16px;
        line-height: 16px;

        span {
            font-weight: 600;
        }
    }

    &__footer {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        flex-wrap: wrap;
        margin: 10px 0 0;

        button {
            margin-bottom: 10px;

            &:nth-child(1) {
                order: 1;
                @include tablet-up {
                    order: unset;
                }
            }

            @include tablet-up {
                margin-bottom: 0;
            }
        }

        @include tablet-up {
            flex-direction: row;
        }

        .btn-icon {
            font-size: 13px;
            //color: var(--bg);
        }

        .btn-text {
            //color: var(--bg);
            text-transform: none;
        }
    }
}

.tabs {
    &__body {
        width: 100%;
        padding: 15px 10px;
        background: #f7f7f9;
        min-height: 100px;
    }

    &__sub-title {
        width: 100%;
        text-align: center;
        margin: 5px auto;

        @include tablet-up {
            display: none;
        }
    }

    &__title-text {
        display: none;

        @include tablet-up {
            display: inline;
        }
    }

    &__icon {
        font-size: 17px;
    }
}

.client-info {
    display: flex;
    flex-wrap: wrap;

    &__column {
        font-size: 14px;
        width: 100%;
        margin-bottom: 15px;

        @include tablet-up {
            width: 50%;
        }
    }

    &__title {
        margin-bottom: 2px;
    }

    &__value {
        direction: ltr;
        text-align: left;

        @include for-hebrew {
            text-align: right;
        }
    }
}
</style>
