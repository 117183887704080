import properties from '@/assets/data/properties.json'

const state = () => ({
    orders: [],
    selectedOrder: {},
    setters: [],
    properties: properties.items,
    loading: false,
    oldClients: [],
    isSubmitting: false,
    currentPage: 1,
    itemsPerPage: 20,
    search: '',
    total: 0,
    selectedUsaManagers: [],
    usaToolsIsEnabled: false,
    sort: '',
    special: 0,
    managers: [],
    ordersState: '',
})

import getters from './getters'
import mutations from './mutations'
import actions from './actions'

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
