<template>
    <div class="selector">
        <button
            v-if="ifManagerSelected()"
            class="selector_close"
            @click="clearManagers"
        >
            x
        </button>
        <v-select
            v-model="selectedManagers"
            :items="usersByRole"
            :label="
                $t(`pages.calendar.chooseManager.chooseManager`).toUpperCase()
            "
            multiple
            solo
            class="selector_dropdown"
            item-text="nickName"
            return-object
            @input="selectedManagersHandler"
        >
            <template #selection="{ item }">
                <v-item-group>
                    <v-item>
                        <div
                            :style="{ backgroundColor: item.hex_color }"
                            class="selector_first-letter"
                        >
                            {{ getFirstLetter(item.nickName) }}
                        </div>
                    </v-item>
                </v-item-group>
            </template>
            <template #item="{ item }">
                <v-list-item-content>
                    <v-list-item-title>
                        <span class="selector_managers">
                            <div
                                :style="{ backgroundColor: item.hex_color }"
                                class="selector_first-letter"
                            >
                                {{ getFirstLetter(item.nickName) }}
                            </div>
                            <span>{{ item.nickName }}</span>
                        </span>
                    </v-list-item-title>
                </v-list-item-content>
            </template>
        </v-select>
    </div>
</template>

<script>
export default {
    name: 'CalendarManagerSelector',
    props: {
        typeOfCalendar: {
            type: String,
            default: '',
        },
        usersByRole: {
            type: Array,
            default: () => [],
        },
    },
    emits: ['managers-handler'],
    data() {
        return {
            selectedManagers: [],
        }
    },
    methods: {
        getFirstLetter: function (name) {
            return name.charAt(0).toUpperCase()
        },
        selectedManagersHandler: function () {
            this.$emit('managers-handler', this.selectedManagers)
        },
        ifManagerSelected: function () {
            return this.selectedManagers.length > 0
        },
        clearManagers: function () {
            this.selectedManagers = []
            this.selectedManagersHandler()
        },
    },
}
</script>

<style lang="scss" scoped>
.selector {
    max-width: 300px;
    width: 100%;
    font-size: 14px;
    display: flex;
    gap: 10px;
    align-items: baseline;
    justify-content: flex-end !important;
    &_dropdown {
        max-width: 250px;
    }
    &_close {
        color: #fff;
        background: rgba($color: red, $alpha: 0.5);
        padding: 10px 15px;
        border-radius: 4px;
        cursor: pointer;
    }
    &_close:hover {
        background: rgba($color: red, $alpha: 0.8);
    }
    &_container {
        width: 100%;
    }
    &_first-letter {
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 700;
        margin: 0 5px;
        border-radius: 100%;
        width: 35px;
        height: 35px;
        color: #fff;
    }
    &_managers {
        display: flex;
        align-items: center;
        span {
            font-size: 14px;
            line-height: 14px;
        }
    }
}
</style>
