<template>
    <v-dialog v-model="modalIsActive" width="500" @click:outside="cancelHandle">
        <div class="add-user__body">
            <h3 class="add-user__title">Confirmation ?</h3>

            <v-divider></v-divider>

            <div class="add-user__actions">
                <v-btn
                    min-width="85"
                    small
                    color="#3bafda"
                    dark
                    @click="deleteUserHandle"
                >
                    Yes
                </v-btn>
                <v-btn
                    min-width="85"
                    small
                    color="#da4453"
                    dark
                    @click="cancelHandle"
                >
                    Cancel
                </v-btn>
            </div>
        </div>
    </v-dialog>
</template>

<script>
import { mapActions } from 'vuex'
import { actionTypes } from '@/store/modules/users'
import { actionTypes as actionTypesModalNotifications } from '@/store/modules/modal-notifications'

export default {
    name: 'ModalDeleteUser',
    props: {
        isActive: {
            type: Boolean,
            default: false,
            required: true,
        },
        credentials: {
            type: Object,
            required: true,
        },
    },
    emits: ['close-modal'],
    data() {
        return {}
    },
    computed: {
        modalIsActive: function () {
            return this.isActive
        },
    },
    methods: {
        ...mapActions('users', { deleteUser: actionTypes.deleteUser }),
        ...mapActions('modalNotifications', {
            success: actionTypesModalNotifications.success,
        }),
        deleteUserHandle: function () {
            this.deleteUser(this.credentials.id).then(() => {
                this.cancelHandle()
                this.success('User delete')
            })
        },
        cancelHandle: function () {
            this.$emit('close-modal')
        },
    },
}
</script>

<style scoped lang="scss">
.add-user {
    &__title {
        text-align: center;
        margin-bottom: 15px;
    }

    &__body {
        height: fit-content;
        background: $color-primary-light;
        padding: 30px 15px 0;
    }

    &__actions {
        padding: 10px 0;
        display: flex;
        justify-content: space-between;
    }
}
</style>
