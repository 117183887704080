<template>
    <v-dialog v-model="isActive" width="500" @click:outside="cancelHandle">
        <div class="add-meeting">
            <div class="add-meeting__head">
                <h3 class="add-meeting__title">
                    {{ textContent['meeting-schedule'] }}
                </h3>
            </div>
            <div class="add-meeting__body">
                <div class="tabs">
                    <v-tabs
                        v-model="tabs"
                        color="#3bafda"
                        centered
                        @change="tabOnChange"
                    >
                        <v-tab
                            v-for="({ title, icon }, index) in tabsText"
                            :key="index"
                        >
                            <p class="tabs__title">
                                <v-icon class="tabs__icon">{{ icon }}</v-icon>
                                <span>{{ title }}</span>
                            </p>
                        </v-tab>
                    </v-tabs>

                    <v-tabs-items v-model="tabs">
                        <v-tab-item>
                            <div class="tabs__body">
                                <div
                                    class="tabs__input-wrapper tabs__input-wrapper--client"
                                >
                                    <ClientFormSelect
                                        :selected-id="fields['client_id']"
                                        @select-handle="selectedClientHandle"
                                    />
                                </div>
                                <div class="tabs__input-wrapper">
                                    <v-text-field
                                        v-model="fields['date']"
                                        dense
                                        outlined
                                        type="date"
                                        name="date"
                                        :label="textContent['date']"
                                        :error-messages="errors['date']"
                                        @input="errors['date'] = ''"
                                    ></v-text-field>
                                </div>
                                <div class="tabs__input-wrapper">
                                    <v-autocomplete
                                        v-model="fields['time']"
                                        outlined
                                        :items="time"
                                        :label="textContent['time']"
                                        dense
                                        :error-messages="errors['time']"
                                        @input="errors['time'] = ''"
                                    >
                                    </v-autocomplete>
                                </div>
                                <div
                                    v-if="isnUsaManager"
                                    class="tabs__input-wrapper"
                                >
                                    <v-autocomplete
                                        v-model="fields['calendar']"
                                        outlined
                                        :items="types"
                                        :label="textContent['calendar']"
                                        dense
                                        :error-messages="errors['calendar']"
                                        @input="errors['calendar'] = ''"
                                    >
                                    </v-autocomplete>
                                </div>
                                <div class="tabs__input-wrapper">
                                    <v-text-field
                                        v-model="fields['subject']"
                                        dense
                                        outlined
                                        type="text"
                                        name="subject"
                                        :label="textContent['subject']"
                                        :error-messages="errors['subject']"
                                        @input="errors['subject'] = ''"
                                    ></v-text-field>
                                </div>
                            </div>
                        </v-tab-item>
                        <v-tab-item>
                            <div class="tabs__body">
                                <div
                                    class="tabs__input-wrapper tabs__input-wrapper--client"
                                >
                                    <OrderFormSelect
                                        :selected-id="fields['wig_id']"
                                        @select-handle="selectedWigHandle"
                                    />
                                </div>
                                <div class="tabs__input-wrapper">
                                    <v-text-field
                                        v-model="fields['date']"
                                        dense
                                        outlined
                                        type="date"
                                        name="date"
                                        :label="textContent['date']"
                                        :error-messages="errors['date']"
                                        @input="errors['date'] = ''"
                                    ></v-text-field>
                                </div>
                                <div class="tabs__input-wrapper">
                                    <v-autocomplete
                                        v-model="fields['time']"
                                        outlined
                                        :items="time"
                                        :label="textContent['time']"
                                        dense
                                        :error-messages="errors['time']"
                                        @input="errors['time'] = ''"
                                    >
                                    </v-autocomplete>
                                </div>
                                <div
                                    v-if="checkIsPickNew() && isnUsaManager"
                                    class="tabs__input-wrapper"
                                >
                                    <v-autocomplete
                                        v-model="fields['manager_id']"
                                        outlined
                                        :items="meetingManagers"
                                        item-text="nickName"
                                        item-value="id"
                                        :label="
                                            $t(
                                                `pages.calendar.chooseManager.designer`
                                            )
                                        "
                                        dense
                                        :error-messages="errors['manager_id']"
                                        @input="errors['manager_id'] = ''"
                                    >
                                    </v-autocomplete>
                                </div>
                                <div
                                    v-if="isnUsaManager"
                                    class="tabs__input-wrapper"
                                >
                                    <v-autocomplete
                                        v-model="fields['calendar']"
                                        outlined
                                        :items="types"
                                        :label="textContent['calendar']"
                                        dense
                                        :error-messages="errors['calendar']"
                                        @input="errors['calendar'] = ''"
                                    >
                                    </v-autocomplete>
                                </div>
                                <div class="tabs__input-wrapper">
                                    <v-text-field
                                        v-model="fields['subject']"
                                        dense
                                        outlined
                                        type="text"
                                        name="subject"
                                        :label="textContent['subject']"
                                        :error-messages="errors['subject']"
                                        @input="errors['subject'] = ''"
                                    ></v-text-field>
                                </div>
                            </div>
                        </v-tab-item>
                    </v-tabs-items>
                </div>
            </div>
            <div class="add-meeting__foot">
                <v-btn color="#55595c" dark small @click="cancelHandle">
                    {{ textContent['close'] }}
                </v-btn>
                <v-btn color="#967adc" dark small @click="onAdd">
                    {{ textContent['schedule'] }}
                </v-btn>
            </div>
        </div>
    </v-dialog>
</template>

<script>
import ClientFormSelect from '@/components/forms/client-form-select'
import OrderFormSelect from '@/components/forms/order-form-select'
import { mapGetters } from 'vuex'
import { getterTypes as getterTypesClients } from '@/store/modules/clients'
import { find } from 'lodash/collection'
import languageMixin from '@/mixins/language.mixin'

export default {
    name: 'CalendarModalAdd',
    components: {
        ClientFormSelect,
        OrderFormSelect,
    },
    mixins: [languageMixin],
    props: {
        fieldsData: {
            type: Object,
            default: () => ({
                fields: {},
                errors: {},
            }),
        },
        isActiveData: {
            type: Boolean,
            default: false,
        },
        meetingManagers: {
            type: Array,
            default: () => [],
        },
    },
    emits: ['close-handle', 'on-add'],
    data() {
        return {
            tabs: '',
            clientFullName: '',
            managerItems: [],
        }
    },
    computed: {
        ...mapGetters('clients', {
            getAllClients: getterTypesClients.getAllClients,
        }),
        tabsText: function () {
            return [
                { title: this.textContent['for-client'], icon: 'mdi-account' },
                { title: this.textContent['for-order'], icon: 'mdi-cart' },
            ]
        },
        types: function () {
            return [
                { text: this.textContent['orders'], value: 'ORDERS' },
                {
                    text: this.textContent['pick-up-new'],
                    value: 'PICK_UP_NEW',
                },
                { text: this.textContent['repairs'], value: 'REPAIRS' },
                {
                    text: this.textContent['pick-up-repairs'],
                    value: 'PICK_UP_REPAIRS',
                },
                { text: this.textContent['us-agent'], value: 'US_AGENT' },
            ]
        },
        isActive: {
            get: function () {
                return this.isActiveData
            },
            set: function () {
                this.cancelHandle()
            },
        },
        fields: function () {
            return this.fieldsData.fields
        },
        errors: function () {
            return this.fieldsData.errors
        },
        time: function () {
            let time = []
            let date = new Date()
            let timeLine = 12
            date.setHours(9)
            date.setMinutes(0)
            const halfHour = 1800000

            if (this.checkIsPickUp()) {
                timeLine = 17
            }

            for (let i = 0; i < timeLine; i++) {
                const h = date.getHours()
                const m =
                    date.getMinutes().toString().length === 1
                        ? date.getMinutes() + '0'
                        : date.getMinutes()
                time = [...time, `${h}:${m}`]
                date = new Date(+date + halfHour)
            }
            return time
        },
        isnUsaManager: function () {
            return !this.getAllowed(['usa_manager'])
        },
    },
    watch: {
        fieldsData: function () {
            if (this.fieldsData?.fields?.wig_id) {
                this.tabs = 1
            }
        },
    },
    mounted() {
        if (this.fieldsData?.fields?.wig_id) {
            this.tabs = 1
            this.fields['client_id'] = this.fields['wig_id']
        }
    },
    updated() {
        if (this.fieldsData?.fields?.wig_id) {
            this.tabs = 1
        }
    },
    methods: {
        cancelHandle: function () {
            this.clientFullName = ''
            this.$emit('close-handle')
        },
        selectedClientHandle: function ({ name, id, fullName }) {
            this.fields['client_id'] = id
            this.fields.client = fullName
            this.clientFullName = name
        },
        selectedWigHandle: function ({ id, clientId }) {
            this.fields['wig_id'] = id
            this.fields['client_id'] = clientId
            this.clientFullName = find(this.getAllClients, {
                id: clientId,
            })?.full_name
            this.fields.client = this.clientFullName
        },
        onAdd: function () {
            this.$emit('on-add')
        },
        tabOnChange: function (tab) {
            if (tab === 0) return (this.fields['wig_id'] = '')
        },
        checkIsPickUp: function () {
            return (
                this.fields.calendar === 'PICK_UP_NEW' ||
                this.fields.calendar === 'PICK_UP_REPAIRS'
            )
        },
        checkIsPickNew: function () {
            return this.fields.calendar === 'PICK_UP_NEW'
        },
    },
}
</script>

<style scoped lang="scss">
.add-meeting {
    background: $color-primary-light;

    &__head {
        padding: 10px;
        border-bottom: 1px solid #eceeef;
    }

    &__title {
        font-weight: 500;
    }

    &__body {
        padding: 0 10px;
    }

    &__foot {
        padding: 10px;
        display: flex;
        justify-content: space-between;
    }
}

.tabs {
    &__title {
    }

    &__input-wrapper--client {
        margin-bottom: 25px;
    }

    &__icon {
    }

    &__body {
    }
}
</style>
