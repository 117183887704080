<template>
    <section class="orders">
        <OrderModalBarcodeScanner
            :is-active-data="modals.barcode.isActive"
            @close-handle="closeBarcode"
            @barcode-detect-handle="detectHandle"
        />
        <SubHeader
            :title="textContent['title']"
            :crumbs="textContent['crumbs']"
        />
        <div class="orders__body">
            <div class="orders__search">
                <OrdersSearch
                    :is-disabled="switchSpecialFilter"
                    :search-data="search"
                    @search-handle="searchHandle"
                    @filter-handle="filterHandle"
                    @open-barcode="openBarcode"
                />
            </div>
            <div class="orders__content content">
                <div class="content__head">
                    <h3 class="content__title">{{ textContent.list }}</h3>

                    <div class="content__actions">
                        <div
                            v-if="!getAllowed(['usa_manager'])"
                            class="content__actions-btn content__actions-btn--usa-set"
                            :class="{ ['is-active']: usaToolsIsEnabled }"
                        >
                            <v-btn
                                :dark="!usaToolsIsEnabled"
                                color="rgb(176, 84, 84)"
                                :x-small="isMobile"
                                :small="!isMobile"
                                icon
                                @click="usaToolsSetHandle"
                            >
                                <v-icon>mdi-tune-vertical-variant</v-icon>
                            </v-btn>
                        </div>
                        <div
                            v-if="!getAllowed(['usa_manager'])"
                            class="content__actions-btn"
                        >
                            <v-btn
                                :outlined="usaToolsIsEnabled"
                                :dark="!usaToolsIsEnabled"
                                color="rgb(176, 84, 84)"
                                :x-small="isMobile"
                                :small="!isMobile"
                                @click="usaToolsHandle"
                            >
                                <span class="content__btn-text">
                                    {{ $t('pages.wigs.actions.usaToolsBtn') }}
                                </span>
                            </v-btn>
                        </div>
                        <div class="content__actions-btn">
                            <v-btn
                                :outlined="switchSpecialFilter"
                                :dark="!switchSpecialFilter"
                                color="#3bafda"
                                :x-small="isMobile"
                                :small="!isMobile"
                                @click="specialFilterHandle"
                            >
                                <span class="content__btn-text">
                                    {{ textContent['special-filter'] }}
                                </span>
                            </v-btn>
                        </div>
                        <div class="content__actions-btn">
                            <v-btn
                                v-if="
                                    getAllowed([
                                        'admin',
                                        'manager',
                                        'usa_manager',
                                    ])
                                "
                                color="#967adc"
                                :x-small="isMobile"
                                :small="!isMobile"
                                dark
                                @click="() => openAdd(true)"
                            >
                                <v-icon x-small>mdi-wrench</v-icon>
                                <span class="content__btn-text">
                                    {{ textContent['add-repair'] }}
                                </span>
                            </v-btn>
                        </div>
                        <div class="content__actions-btn">
                            <v-btn
                                v-if="
                                    getAllowed([
                                        'admin',
                                        'manager',
                                        'usa_manager',
                                    ])
                                "
                                color="#967adc"
                                :x-small="isMobile"
                                :small="!isMobile"
                                dark
                                data-test-id="wigs--main-actions--add-new-wig-btn"
                                @click="() => openAdd(false)"
                            >
                                <v-icon x-small>mdi-plus</v-icon>
                                <span class="content__btn-text">
                                    {{ textContent['add-order'] }}
                                </span>
                            </v-btn>
                        </div>
                    </div>
                </div>
                <OrdersTable
                    :orders="orders"
                    :page="getCurrentPage"
                    :item-per-page="itemsPerPage"
                    :total="getTotal"
                    @select-order-handle="selectOrderHandle"
                    @resize="extraScrollHandle"
                    @change-page-handle="changePageHandle"
                    @sort-handle="sortHandle"
                />
            </div>
        </div>
    </section>
</template>

<script>
import SubHeader from '@/components/common/sub-header'
import OrdersSearch from '@/components/orders/orders-search'
import OrdersTable from '@/components/orders/orders-table'
import OrderModalBarcodeScanner from '@/components/modals/order-modal-barcode-scanner'

import { mapActions, mapGetters, mapMutations } from 'vuex'
import { actionTypes, getterTypes, mutationTypes } from '@/store/modules/orders'
import {
    ordersV2ActionTypes,
    ordersV2GetterTypes,
} from '@/store/modules/orders_v2/types'
import { actionTypes as actionTypesModals } from '@/store/modules/modals'

import { filter } from 'lodash'

import popUpTypes from '@/types/pop-up-types'
import languageMixin from '@/mixins/language.mixin'

const { orderAdd, orderUsaTools } = popUpTypes

export default {
    name: 'Orders',
    components: {
        SubHeader,
        OrdersSearch,
        OrdersTable,
        OrderModalBarcodeScanner,
    },
    mixins: [languageMixin],
    data() {
        return {
            switchSpecialFilter: false,
            selectedId: '',
            search: '',
            stateOfOrder: '',
            newState: '',
            propertiesForUpdate: {},
            isRepair: false,
            usaToolsIsEnabled: false,
            modals: {
                barcode: {
                    isActive: false,
                },
                assignCreator: {
                    isActive: false,
                },
            },
        }
    },
    computed: {
        ...mapGetters('ordersV2', {
            getAllV2: ordersV2GetterTypes.GET_ORDERS,
            getTotal: ordersV2GetterTypes.GET_TOTAL,
            getCurrentPage: ordersV2GetterTypes.GET_CURRENT_PAGE,
            itemsPerPage: ordersV2GetterTypes.GET_ITEMS_PER_PAGE,
            selectedUsaManagers: ordersV2GetterTypes.GET_USA_MANAGERS,
        }),
        ...mapGetters('orders', {
            getAll: getterTypes.getAll,
            selectedOrder: getterTypes.getOrder,
        }),
        idIsAlready: function () {
            return this.$route?.params?.id || undefined
        },
        isMobile: function () {
            return this.$resize && !this.$mq.above(1023)
        },
        orders: function () {
            return filter(this.filteredOrders, (order) => {
                const search = this.search.toLowerCase().toString()
                if (search.length === 0) return true
                const client =
                    order['client'] && order['client'].toLowerCase().toString()
                const prevClient =
                    order['previous_client'] &&
                    order['previous_client'].toLowerCase().toString()
                const barcode =
                    order['barcode'] &&
                    order['barcode'].toLowerCase().toString()
                const uniqId =
                    order['user_generated_id'] &&
                    order['user_generated_id'].toLowerCase().toString()
                const creator =
                    order['manager_nickname'] &&
                    order['manager_nickname'].toLowerCase().toString()
                return (
                    (barcode && barcode.includes(search)) ||
                    (client && client.includes(search)) ||
                    (prevClient && prevClient.includes(search)) ||
                    (uniqId && uniqId.includes(search)) ||
                    (creator && creator.includes(search))
                )
            })
        },
        stateOfOrderWithSpecial: function () {
            return this.switchSpecialFilter ? 'special' : this.stateOfOrder
        },
        filteredOrders: function () {
            return this.getAllV2
        },
    },
    watch: {
        selectedUsaManagers: function () {
            this.getOrders()
        },
    },
    mounted() {
        this.loadAllV2()
        if (this.idIsAlready) {
            this.selectOrderHandle(this.idIsAlready)
        }
    },
    methods: {
        ...mapActions('orders', {
            loadAll: actionTypes.loadAll,
            loadOrder: actionTypes.loadOrder,
        }),
        ...mapActions('ordersV2', {
            loadAllV2: ordersV2ActionTypes.FETCH_ORDERS,
            setCurrentPage: ordersV2ActionTypes.SET_CURRENT_PAGE,
            setUsaManagers: ordersV2ActionTypes.SET_USA_MANAGERS,
            setSearch: ordersV2ActionTypes.SET_SEARCH_QUERY,
            setOrdersState: ordersV2ActionTypes.SET_ORDERS_STATE,
            setSort: ordersV2ActionTypes.SET_SORT,
            setSpecial: ordersV2ActionTypes.SET_SPECIAL,
            setUsaToolsIsEnabled: ordersV2ActionTypes.SET_USA_TOOLS,
        }),
        ...mapActions('modals', {
            onOpen: actionTypesModals.openPopUp,
        }),
        ...mapMutations('orders', {
            addSetter: mutationTypes.addSetter,
            removeSetter: mutationTypes.removeSetter,
        }),
        specialFilterHandle: function () {
            if (this.usaToolsIsEnabled) return
            this.switchSpecialFilter = !this.switchSpecialFilter

            if (this.switchSpecialFilter) {
                this.setSpecial(1)
                return
            }

            this.setSpecial(0)
        },
        filterHandle: function (con) {
            if (con === 'All states') {
                this.setOrdersState('')
                return
            }

            this.setOrdersState(con)
        },
        searchHandle: function (search) {
            this.setSearch(search)
        },
        detectHandle: function (con) {
            this.search = con
        },
        openBarcode: function () {
            this.modals.barcode.isActive = true
        },
        closeBarcode: function () {
            this.modals.barcode.isActive = false
        },
        openAdd: function (isRepair) {
            const payload = { isRepair }
            this.onOpen({ name: orderAdd, payload })
        },
        selectOrderHandle: function (id) {
            this.selectedId = id
            Object.keys(this.propertiesForUpdate).forEach(
                (key) => (this.propertiesForUpdate[key] = '')
            )
            this.loadOrder(this.selectedId).then(() => {
                this.propertiesForUpdate = {
                    fields: this.selectedOrder.properties,
                }
            })
        },
        extraScrollHandle: function () {},
        usaToolsHandle: function () {
            this.usaToolsIsEnabled = !this.usaToolsIsEnabled

            this.setUsaToolsIsEnabled(this.usaToolsIsEnabled)

            if (!this.usaToolsIsEnabled) {
                this.setUsaManagers([])
            }
        },
        usaToolsSetHandle: function () {
            this.onOpen(orderUsaTools)
        },
        changePageHandle: function (page) {
            this.setCurrentPage(page)
        },
        isOrderAllStates: function () {
            return this.stateOfOrder === 'All states'
        },
        getOrders: async function () {
            if (this.isOrderAllStates()) {
                await this.setOrdersState('')
                return
            }

            await this.loadAllV2()
        },
        isStateIncluded: function () {
            const states = [
                'Draft',
                'Wigs in Production',
                'Rework',
                'Ready New Wig',
                'Ready Repair Wigs',
                'Repair Wigs',
                'Cancelled Wigs',
                'Wigs in Stock',
                'Delivered Wigs',
                'Storage',
                'Archived',
            ]
            return states.includes(this.stateOfOrderWithSpecial)
        },
        isSpecial: function () {
            return this.stateOfOrderWithSpecial === 'special'
        },
        sortHandle: function (payload) {
            this.setSort(payload)
        },
    },
}
</script>

<style lang="scss" scoped>
.orders {
    &__body {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @include tablet-large-up {
            flex-direction: row;
        }
    }

    &__search,
    &__content {
        background: $color-primary-light;
        box-shadow: 0 2px 1px rgb(0 0 0 / 5%);
    }

    &__search {
        @include tablet-large-up {
            max-width: 200px;
        }

        height: fit-content;
    }

    &__content {
        background: $color-primary-light;
        box-shadow: 0 2px 1px rgb(0 0 0 / 5%);
        flex-shrink: 0;
        flex-grow: 1;
        padding: 15px;
        overflow: auto;
        min-height: 100px;

        @include tablet-large-up {
            margin-right: 15px;
            max-width: calc(100% - 226px);
            padding: 15px 8px;
        }

        @include desktop-up {
            max-width: calc(100% - 245px);
            padding: 15px 15px;
        }
    }
}

.content {
    &__head {
        display: flex;
        justify-content: space-between;
    }

    &__actions {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        @include tablet-up {
            flex-wrap: nowrap;
            justify-content: flex-end;
        }
    }

    &__actions-btn {
        z-index: 2;
        //background: #fff;

        &:first-child {
            margin-right: 250px;
            margin-bottom: 10px;

            @include tablet-up {
                margin-right: 0;
                margin-bottom: 0;
            }
        }

        & + & {
            @include tablet-up {
                margin-left: 15px;

                @include for-hebrew {
                    margin-right: 15px;
                    margin-left: 0;
                }
            }
        }

        &--usa-set {
            position: relative;
            transform: translateX(45px);
            z-index: 1;
            transition: all 250ms ease-in-out;
            opacity: 0;

            &.is-active {
                transform: translateX(10px);
                opacity: unset;
            }
        }
    }

    &__title {
        display: none;

        @include tablet-large-up {
            display: block;
        }
    }

    &__btn-text {
    }
}
</style>
