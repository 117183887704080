<template>
    <div class="languages">
        <v-menu v-model="menu" offset-y nudge-bottom="33">
            <template #activator="{ on, attrs }">
                <div class="languages__selected" v-bind="attrs" v-on="on">
                    <img :src="flag(selected)" alt="flag" />
                    <p>{{ selected }}</p>
                </div>
            </template>
            <ul class="list">
                <li
                    v-for="(language, i) in languages"
                    :key="i"
                    class="list__item"
                    @click="() => onChange(language)"
                >
                    <div class="languages__selected">
                        <img :src="flag(language)" alt="flag" />
                        <p>{{ language }}</p>
                    </div>
                </li>
            </ul>
        </v-menu>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import { getterTypes, mutationTypes } from '@/store/modules/configuration'

export default {
    name: 'HeaderLanguages',
    data() {
        return {
            menu: false,
            languages: ['English', 'Hebrew'],
        }
    },
    computed: {
        ...mapGetters('configuration', { selected: getterTypes.getLanguage }),
    },
    methods: {
        ...mapMutations('configuration', {
            changeLanguage: mutationTypes.changeLanguage,
        }),
        flag: function (country) {
            let img = ''
            switch (country) {
                case 'English':
                    img = 'en.svg'
                    break
                case 'Hebrew':
                    img = 'he.svg'
                    break
                default:
                    console.log('Country is wrong')
            }
            return require(`@/assets/common/${img}`)
        },
        onChange: function (language) {
            this.changeLanguage(language)
            location.reload()
        },
    },
}
</script>

<style lang="scss" scoped>
.languages {
    margin-right: 30px;
    height: fit-content;

    @include for-hebrew {
        margin-right: 0;
        margin-left: 30px;
    }

    &__selected {
        display: flex;
        align-items: center;

        img {
            height: fit-content;
            width: 20px;
            margin-right: 10px;
            max-height: 20px;

            @include for-hebrew {
                margin-right: 0;
                margin-left: 10px;
            }
        }

        p {
            margin: 0;
        }
    }
}

.list {
    margin: 0;
    padding: 5px 0;
    background: $color-primary-light;

    &__item {
        font-size: 12px;
        width: 100%;
        padding: 5px 15px;
        cursor: pointer;
        transition: background-color 150ms linear;
        border-bottom: 1px solid #e3e3e3;

        &:last-child {
            border: none;
        }

        &:hover {
            background: #e3e3e3;
        }
    }
}
</style>
