import WigPageAdditionalPropertiesUpdateSlideOut from '@/features/wig-page/components/wig-page-additional-properties-update-slide-out.vue'
import store from '@/store'
import { actionTypesSlideOuts } from '@/store/modules/slide-outs/types'
import WigPageAdditionalPropertiesEditSlideOut from '@/features/wig-page/components/wig-page-additional-properties-edit-slide-out.vue'

export const slideOutsMapper = {
    ADDITIONAL_PROPERTIES_UPDATE: WigPageAdditionalPropertiesUpdateSlideOut,
    ADDITIONAL_PROPERTIES_EDIT: WigPageAdditionalPropertiesEditSlideOut,
}

const openSlideOut = (slideOutComponent, props) => {
    const payload = { slideOutComponent, props }
    store.dispatch(
        `slideOuts/${actionTypesSlideOuts.SET_SELECTED_SLIDE_OUT}`,
        payload
    )
}

export default openSlideOut
