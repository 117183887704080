<template>
    <v-dialog v-model="isActive" width="400" @click:outside="closeHandle">
        TEST
        <div class="properties-update">
            <template v-if="isRepair">
                <OrderFormPropertiesForRepair :data-properties="properties" />
            </template>
            <template v-else>
                <OrderFormProperties
                    :main-panel="mainPanel"
                    :data-properties="properties"
                    disabled
                    @panel-handle="(value) => (mainPanel = value)"
                />
            </template>

            <div class="properties-update__actions">
                <v-btn color="#55595c" dark small @click="closeHandle">
                    {{ textContent['close'] }}
                </v-btn>
                <v-btn color="#967adc" dark small @click="updateHandle">
                    {{ textContent['update'] }}
                </v-btn>
            </div>
        </div>
    </v-dialog>
</template>

<script>
import OrderFormProperties from '@/components/forms/order-form-properties'
import OrderFormPropertiesForRepair from '@/components/forms/order-form-properties-for-repair'
import { actionTypes, getterTypes } from '@/store/modules/orders'
import { mapActions, mapGetters } from 'vuex'
import { actionTypes as actionTypesModalNotifications } from '@/store/modules/modal-notifications'

import modalsMixin from '@/mixins/modals.mixin'

import popUpTypes from '@/types/pop-up-types'
import languageMixin from '@/mixins/language.mixin'

const { orderPropertiesUpdate } = popUpTypes

export default {
    name: 'OrderModalPropertiesUpdate',
    components: { OrderFormProperties, OrderFormPropertiesForRepair },
    mixins: [modalsMixin, languageMixin],
    props: {
        // eslint-disable-next-line vue/require-prop-types
        selectedId: {
            default: 0,
        },
    },
    emits: ['close-handle'],
    data() {
        return {
            selfName: orderPropertiesUpdate,
            mainPanel: 0,
            errors: {},
            fields: {
                ['wigType']: '',
                ['capSize']: '',
                ['capAdjustment']: '',
                ['topType']: '',
                ['topTypeComments']: '',
                ['front']: '',
                ['frontComments']: '',
                ['baseColor']: '',
                ['skinColor']: '',
                ['highlightsColor']: '',
                ['highlightsColorSelect']: '',
                ['typeOfHighlights']: '',
                ['skinTopHighlights']: '',
                ['frontColor']: '',
                ['highlightsColoring']: '',
                ['highlightsColoringSelect']: '',
                ['colorComments']: '',
                ['lengthFromNape']: '',
                ['lengthFromTop']: '',
                ['sideLength']: '',
                ['lengthComments']: '',
                ['hairTextureOnWefts']: '',
                ['hairTextureComments']: '',
                ['wigFullness']: '',
                ['wigFullnessAdditionalAmount']: '',
                ['wigFullnessComments']: '',
                ['weight']: '',
            },
        }
    },
    computed: {
        ...mapGetters('orders', { getOrder: getterTypes.getOrder }),
        isRepair: function () {
            return this.getOrder['is_repair']
        },
        properties: function () {
            return { fields: this.fields, errors: this.errors }
        },
        isProduction: function () {
            return (
                this.getOrder.state &&
                (this.getOrder.state.toLowerCase() === 'wigs in production' ||
                    this.getOrder.state.toLowerCase() === 'rework')
            )
        },
    },
    watch: {
        getOrder: function () {
            this.selectHandle()
        },
    },
    methods: {
        ...mapActions('orders', {
            updateProperties: actionTypes.updateProperties,
        }),
        ...mapActions('modalNotifications', {
            success: actionTypesModalNotifications.success,
        }),
        selectHandle: function () {
            const properties = this.getOrder.properties || {}
            Object.keys(this.fields).forEach((field) => {
                this.fields[field] =
                    properties && properties[field] ? properties[field] : ''
            })
        },
        updateHandle: function () {
            let properties = {}

            Object.keys(this.properties.fields).forEach((key) => {
                if (!this.properties.fields[key]) return
                properties = {
                    ...properties,
                    [key]: this.properties.fields[key],
                }
            })

            const res = {
                id: this.getOrder.id,
                properties: { ...properties },
            }
            this.updateProperties(res)
                .then(() => {
                    this.success('Order has been updated')
                    this.closeHandle()
                })
                .catch(() => {
                    //TODO add logic for render errors
                    // this.errors = er
                })
        },
    },
}
</script>

<style scoped lang="scss">
.properties-update {
    padding: 15px 10px 10px;
    background: white;

    &__actions {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
    }
}
</style>
