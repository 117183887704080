<template>
    <div class="stages">
        <div class="body">
            <ul class="categories-list">
                <li
                    v-for="({ title, items }, i) in itemsForRender"
                    :key="i"
                    class="category"
                >
                    <h4>{{ title }}</h4>
                    <ul class="status-list">
                        <li
                            v-for="(
                                // eslint-disable-next-line
                                { tittle, id, done, done_by, done_at },
                                j
                            ) in items"
                            :key="j"
                            class="status-list__item"
                        >
                            <div class="status-list__main">
                                <v-checkbox
                                    v-model="selectedItems"
                                    class="status-list__checkbox"
                                    :label="tittle"
                                    :value="id"
                                    :success="done"
                                    :readonly="
                                        (!isProduction &&
                                            isWorkerPreventMode(id)) ||
                                        readonly
                                    "
                                    hide-details
                                    dense
                                    @change="changeHandle"
                                >
                                </v-checkbox>
                            </div>
                            <template v-if="done && done_by">
                                <div class="status-list__extra">
                                    <div class="status-list__date">
                                        {{ dateFormat(done_at) }}
                                    </div>
                                    <div class="status-list__user">
                                        {{ done_by }}
                                    </div>
                                </div>
                            </template>
                        </li>
                    </ul>
                </li>
            </ul>

            <v-btn
                v-if="isProduction && !readonly"
                color="#967adc"
                dark
                small
                @click="updateStatusHandle"
            >
                {{ textContent['update'] }}
            </v-btn>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { actionTypes, getterTypes } from '@/store/modules/order-repairs-stages'
import { getterTypes as getterTypesOrders } from '@/store/modules/orders'
import languageMixin from '@/mixins/language.mixin'
import formatDate from '@/helpers/format-date'

export default {
    name: 'OrdersStatusRepairStages',
    mixins: [languageMixin],
    props: {
        id: {
            type: Number,
            default: 0,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['close-handle'],
    data() {
        return {
            defaultSate: [],
            stage: {
                title: '',
                comment: '',
                category: '',
            },
            selectedItems: [],
        }
    },
    computed: {
        ...mapGetters('orderRepairsStages', {
            isSubmitting: getterTypes.getIsSubmitting,
            additionalProperties: getterTypes.getAdditionalProperties,
        }),
        ...mapGetters('orders', { order: getterTypesOrders.getOrder }),
        itemsData: function () {
            return this.additionalProperties
        },
        categories: function () {
            return Object.keys(this.itemsForRender).map((title) => title)
        },
        itemsForRender: function () {
            let categories = {}
            this.itemsData.forEach((item) => {
                const category = item?.category || 'Common'
                categories[category] = {
                    title: category,
                    items: [...(categories?.[category]?.items || []), item],
                }
            })

            return categories
        },
        isProduction: function () {
            return (
                (this.order.state &&
                    this.order.state.toLowerCase() === 'repair wigs') ||
                this.order.state.toLowerCase() === 'rework'
            )
        },
    },
    watch: {
        itemsData: function (items) {
            this.selectedItems = items
                .map((i) => (i.done ? i.id : null))
                .filter((i) => i)
        },
    },
    mounted() {
        this.defaultSate = this.itemsData

        this.selectedItems = this.itemsData
            .map((i) => (i.done ? i.id : null))
            .filter((i) => i)
    },
    methods: {
        ...mapActions('orderRepairsStages', {
            onDone: actionTypes.onDone,
            onUndone: actionTypes.onUndone,
        }),
        updateStatusHandle: function () {
            let forUnDone = []
            let forDone = []

            this.itemsData.forEach((items) => {
                if (items.done && !this.selectedItems.includes(items.id))
                    forUnDone = [...forUnDone, items.id]
                if (!items.done && this.selectedItems.includes(items.id))
                    forDone = [...forDone, items.id]
            })

            if (forDone.length !== 0) {
                this.onDone({ wigID: this.id, propertyIDs: forDone })
            }

            if (forUnDone.length !== 0) {
                this.onUndone({ wigID: this.id, propertyIDs: forUnDone })
            }
        },
        changeHandle: function () {},
        dateFormat: function (date) {
            return formatDate(date)
        },
        isWorkerPreventMode: function (value) {
            const userHasAdminRole = this.getAllowed(['admin'])
            return this.selectedItems.includes(value) && !userHasAdminRole
        },
    },
}
</script>

<style lang="scss">
.categories-list {
    margin: 0 !important;
    padding: 0 !important;
}
.stages {
    padding: 16px 16px 0 0;

    &__action {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
    }

    &__text-fields {
        flex-grow: 1;
        margin-right: 15px;
    }

    &__input {
        flex-grow: 1;
    }

    &__button {
        margin-left: 10px;
        margin-bottom: 31px;
    }

    &__title {
        font-weight: 400;
        display: flex;
        align-items: center;

        span {
            margin-left: 5px;
            margin-top: 4px;
        }
    }
}

.status-list {
    margin-bottom: 20px;
    padding: 0 0 0 8px !important;

    &__item {
        min-height: 44px;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px dotted #888888;
        padding: 3px 0;
        align-items: center;

        label {
            font-weight: 700;
        }
    }

    &__main {
        max-width: 80%;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        height: 100%;
    }

    &__comment {
        padding-left: 31px;
        margin-bottom: 0 !important;
        font-size: 13px;
        opacity: 0.75;
        min-height: 33px;
    }

    &__extra {
        font-size: 13px;
        opacity: 0.75;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &__date {
        margin-bottom: 5px;
    }
}
</style>
