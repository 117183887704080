import { replace } from 'lodash'

export default (key) => {
    let res = ''
    const actionType = key.includes('main.') ? 'main.' : ''

    switch (actionType) {
        case 'main.':
            res = replace(key, 'main.', '')
            break
        default:
            res = key
    }

    return res
}
