<template>
    <div class="category">
        <div class="category__wrapper">
            <div class="category__head-line">
                <div>
                    <div class="category__title-container">
                        <template v-if="categoryEditModeIsActive">
                            <textarea
                                ref="categoryTextArea"
                                v-model="categoryTextArea"
                                class="category__category-text-area"
                                rows="2"
                                style="resize: none"
                            >
                            </textarea>
                        </template>
                        <template v-else>
                            <h3 class="category__title">
                                {{ category.category }}
                            </h3>
                        </template>
                        <template v-if="categoryEditModeIsActive">
                            <v-btn
                                icon
                                color="blue"
                                outlined
                                @click="() => categorySaveHandle(category)"
                            >
                                <v-icon small color="#fff">
                                    mdi-check-bold
                                </v-icon>
                            </v-btn>
                        </template>
                        <template v-else>
                            <v-btn
                                icon
                                color="blue"
                                outlined
                                @click="() => categoryEditHandle()"
                            >
                                <v-icon x-small color="#fff">
                                    mdi-pencil-outline
                                </v-icon>
                            </v-btn>
                        </template>
                    </div>
                </div>
            </div>
            <ul class="category__properties">
                <li
                    v-for="(property, index) in properties"
                    :key="index"
                    class="category__property"
                >
                    <div class="category__property-title">
                        <template v-if="editedPropertyId === property.id">
                            <textarea
                                ref="propertyTextArea"
                                v-model="propertyTextArea"
                                class="category__property-text-area"
                                rows="4"
                                style="resize: none"
                            >
                            </textarea>
                        </template>
                        <template v-else>
                            {{ property.title }}
                        </template>
                    </div>
                    <div class="category__property-btns">
                        <template v-if="editedPropertyId === property.id">
                            <v-btn
                                icon
                                color="blue"
                                outlined
                                @click="() => propertySaveHandle(property)"
                            >
                                <v-icon small color="#3788ff">
                                    mdi-check-bold
                                </v-icon>
                            </v-btn>
                        </template>
                        <template v-else>
                            <v-btn
                                icon
                                color="blue"
                                outlined
                                @click="() => propertyEditHandle(property.id)"
                            >
                                <v-icon x-small color="#3788ff">
                                    mdi-pencil-outline
                                </v-icon>
                            </v-btn>
                        </template>
                        <v-btn
                            icon
                            color="pink"
                            outlined
                            @click="() => propertyRemoveHandle(property.id)"
                        >
                            <v-icon x-small color="#d95a5a">
                                mdi-close-circle
                            </v-icon>
                        </v-btn>
                    </div>
                </li>
            </ul>
        </div>
        <div class="category__add-new">
            <slot></slot>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getterTypes as getterTypesOrders } from '@/store/modules/orders'

export default {
    name: 'OrdersAdditionalPropertiesCategory',
    props: {
        category: {
            type: Object,
            default: () => ({}),
        },
    },
    emits: [
        'property-remove-handle',
        'property-edit-handle',
        'category-edit-handle',
    ],
    data() {
        return {
            categoryEditModeIsActive: false,
            editedPropertyId: null,
            propertiesModeIsActiveMap: {},
            categoryTextArea: '',
            propertyTextArea: '',
        }
    },
    computed: {
        ...mapGetters('orders', { selectedWig: getterTypesOrders.getOrder }),
        properties: function () {
            return this.category.properties.filter(({ title }) => title)
        },
    },
    watch: {
        properties: function () {
            this.category.properties.forEach((property) => {
                this.propertiesModeIsActiveMap[property.id] = false
            })
        },
    },
    methods: {
        categoryEditHandle: async function () {
            this.categoryEditModeIsActive = true
            await this.$nextTick()
            this.$refs.categoryTextArea.focus()
            this.categoryTextArea = this.category.category
        },
        propertyRemoveHandle: function (id) {
            this.$emit('property-remove-handle', id)
        },
        propertyEditHandle: async function (id) {
            // Change selected row to textarea
            this.editedPropertyId = id
            this.propertiesModeIsActiveMap = {
                ...this.propertiesModeIsActiveMap,
                [id]: true,
            }

            this.properties.forEach((property) => {
                if (property.id === id) {
                    this.propertyTextArea = property.title
                }
            })

            await this.$nextTick()
            this.$refs.propertyTextArea[0].focus()
        },
        propertySaveHandle: function (property) {
            this.$emit('property-edit-handle', {
                propertyId: property.id,
                updatedTitle: this.propertyTextArea,
            })
            this.editedPropertyId = null
            this.propertyTextArea = ''
        },
        categorySaveHandle: function () {
            this.$emit('category-edit-handle', {
                updatedTitle: this.categoryTextArea,
                categoryId: this.category.category_id,
            })
            this.categoryTextArea = ''
            return (this.categoryEditModeIsActive = false)
        },
    },
}
</script>

<style lang="scss" scoped>
.category {
    &__wrapper {
        padding: 0 5px;
        position: relative;

        &:before {
            position: absolute;
            content: '';
            display: block;
            height: calc(100% - 77px);
            width: 2px;
            top: 10px;
            left: 0;
            //background: rgb(150, 122, 220);
        }
    }

    &__title-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 12px;
        padding: 4px;
        margin-bottom: 4px;
        button {
            background: #3788ff;
            border-radius: 100%;
            width: 30px;
            height: 30px;
            box-shadow: 0 2px 2px rgba(0, 0, 0, 0.3);
        }
    }

    &__property {
        position: relative;
        margin-bottom: 4px;
        cursor: pointer;
        opacity: 0.8;
        transition: all 150ms linear;
        border-bottom: 1px dotted #ddd;
        padding: 5px 17px;
        font-size: 13px;
        font-weight: 400;

        &:hover {
            opacity: unset;

            .category__remove-btn {
                opacity: unset;
            }
        }

        &:before {
            position: absolute;
            content: '';
            display: block;
            height: 2px;
            width: 30px;
            top: 18px;
            left: -35px;
            //background: rgb(150, 122, 220);
        }
        &-btns {
            position: absolute;
            top: -2px;
            right: 4px;
            display: flex;
            gap: 8px;
            @include for-hebrew {
                right: auto;
                left: 4px;
            }

            button {
                border-radius: 100%;
                width: 30px;
                height: 30px;
            }
        }
    }

    &__title {
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 0;
    }

    &__property-title {
        font-size: 13px;
        font-weight: 400;
        margin-bottom: 0;
        padding-right: 55px;

        @include for-hebrew {
            padding-right: 0;
            padding-left: 55px;
        }
    }

    &__category-text-area {
        resize: none;
        width: 100%;
        outline: none;
        font-style: italic;
        overflow-y: hidden;
        background: rgba(0, 0, 0, 0.05);
        caret-shape: underscore;
        margin: 0 -8px;
        padding: 0 8px;
    }

    &__property-text-area {
        resize: none;
        width: 100%;
        outline: none;
        font-style: italic;
        overflow-y: hidden;
        background: rgba(0, 0, 0, 0.05);
        caret-shape: underscore;
        margin: 0 -8px;
        padding: 0 8px;
    }

    &__comment {
        font-size: 13px;
    }
}
</style>
