import { render, staticRenderFns } from "./bread-crumbs.vue?vue&type=template&id=18a2c7f0&scoped=true"
import script from "./bread-crumbs.vue?vue&type=script&lang=js"
export * from "./bread-crumbs.vue?vue&type=script&lang=js"
import style0 from "./bread-crumbs.vue?vue&type=style&index=0&id=18a2c7f0&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "18a2c7f0",
  null
  
)

export default component.exports