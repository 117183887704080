<template>
    <div class="search">
        <p class="search__title">Orders</p>
        <div class="search__input-wrapper">
            <v-autocomplete
                v-model="order"
                :value="value"
                :items="orders"
                dense
                outlined
                label="Orders"
                item-color="#967adc"
                hide-details
                @change="selectHandle"
            >
            </v-autocomplete>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { find } from 'lodash'
import { actionTypes, getterTypes } from '@/store/modules/orders'

export default {
    name: 'OrderFormSelect',
    props: {
        // eslint-disable-next-line vue/require-default-prop
        selectedId: null,
    },
    emits: ['select-handle'],
    data() {
        return {
            order: {},
            value: {},
        }
    },
    computed: {
        ...mapGetters('orders', {
            getAll: getterTypes.getAll,
        }),
        orders: function () {
            return this.getAll.map((order) => ({
                text: order['client'],
                value: {
                    client: order.client,
                    id: order.id,
                },
            }))
        },
    },
    watch: {
        selectedId: function () {
            this.selectOrder()
        },
    },
    mounted() {
        this.loadAll().then(() => {
            this.selectOrder()
        })
    },
    methods: {
        ...mapActions('orders', {
            loadAll: actionTypes.loadAll,
        }),
        selectHandle: function () {
            const { client_id } = find(this.getAll, { id: this.order.id })
            this.$emit('select-handle', {
                client: this.order.client,
                id: this.order.id,
                clientId: client_id,
            })
        },
        selectOrder: function () {
            if (!this.selectedId) {
                this.order = {
                    id: '',
                    client: '',
                }
            } else {
                const id = this.selectedId
                const order = find(this.getAll, { id })

                this.order = {
                    id,
                    client: order.client,
                }
                this.selectHandle()
            }
        },
    },
}
</script>
<style scoped lang="scss">
.search {
    &__title {
        margin-bottom: 15px;
    }

    &__input-wrapper {
        margin-bottom: 10px;
    }
}
</style>
