<template>
    <div class="dev-page">
        <div class="dev-page__wrapper">
            <OrdersReworkTab :rework-properties="getProperties" />
            <hr />
            <br />
            <!--            <OrdersReworkProperties-->
            <!--                :rework-properties="getProperties"-->
            <!--                @edit-handle="editHandle"-->
            <!--            />-->
        </div>
    </div>
</template>

<script>
import OrdersReworkTab from '@/components/orders/orders-rework-tab.vue'
import { mapActions, mapGetters } from 'vuex'
import {
    actionTypesOrdersReworkProperties,
    getterTypesOrdersReworkProperties,
} from '@/store/modules/orders-rework-properties/types'
import shortid from 'shortid'

export default {
    name: 'DevPage',
    components: { OrdersReworkTab },

    data() {
        return {
            onDispatch: 0,
        }
    },
    computed: {
        ...mapGetters('ordersReworkProperties', {
            getProperties: getterTypesOrdersReworkProperties.getProperties,
        }),
    },
    methods: {
        ...mapActions('ordersReworkProperties', {
            postProperties: actionTypesOrdersReworkProperties.createProperties,
            deleteProperties:
                actionTypesOrdersReworkProperties.removeProperties,
            checkProperties: actionTypesOrdersReworkProperties.checkProperties,
            uncheckProperties:
                actionTypesOrdersReworkProperties.uncheckProperties,
        }),
        updateHandle: function (payload) {
            let { forDone, forUnDone } = payload
            if (forDone?.length !== 0) this.checkProperties(forDone)
            if (forUnDone?.length !== 0) this.uncheckProperties(forUnDone)
        },
        editHandle: function (payload) {
            let { propertiesForRemoving, propertiesForAdding } = payload
            if (
                propertiesForRemoving.length === 0 &&
                propertiesForAdding.length === 0
            )
                return

            propertiesForAdding = propertiesForAdding.map((property) => {
                return {
                    tittle: property.title,
                    category: property.category,
                    comment: property.comment,
                    // add extra content
                    id: shortid(),
                    done: false,
                    done_at: null,
                    done_by: null,
                }
            })

            if (propertiesForAdding.length !== 0)
                this.postProperties(propertiesForAdding)

            if (propertiesForRemoving.length !== 0)
                this.deleteProperties(propertiesForRemoving)
        },
    },
}
</script>

<style lang="scss" scoped>
.dev-page {
    &__wrapper {
        width: 900px;
        min-height: 800px;
        max-height: calc(100vh - 200px);
        border: 1px solid rgba(0, 0, 0, 0.5);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        overflow: auto;
    }
}
</style>
