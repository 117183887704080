import { actionTypes as actionTypesModalsNotification } from '@/store/modules/modal-notifications'

const state = () => ({
    modals: [],
    resolves: [],
    isLoading: false,
})

export const getterTypes = {
    isActive: '[modals] check if this pop-up is active',
    payload: '[modals] Get extra data',
    getModals: '[modals] Panel is active',
    getLoadingStatus: '[modals][loading] get loading status',
}

export const mutationTypes = {
    openPopUp: '[modals] open selected pop-up',
    openPopUpWithPayload: '[modals] open selected pop-up with payload',
    closePopUp: '[modals] close selected pop-up',
    goTo: '[modals] Go to selected modal mutation',
    startLoading: '[modals][mutation] start of loading',
    endLoading: '[modals][mutation] end of loading',
    setPayload: '[modals][mutation] set payload for selected',
    addResFunction: '[modals][mutation] added resolve function',
}

export const actionTypes = {
    openPopUp: '[modals] Handle open selected pop-up',
    closePopUp: '[modals] Handle close selected pop-up',
    goTo: '[modals] Go to selected modal action',
}

const getters = {
    [getterTypes.isActive]:
        ({ modals }) =>
        (popUp) => {
            if (!popUp) return false
            return modals[modals.length - 1]?.name === popUp
        },
    [getterTypes.payload]:
        ({ modals }) =>
        (popUp) => {
            if (!popUp) return false
            const res = modals.filter((item) => item.name === popUp)[0]?.payload
            return res
        },
    [getterTypes.getModals]: ({ modals }) => {
        return modals
    },
    [getterTypes.getLoadingStatus]: ({ isLoading }) => isLoading,
}

const mutations = {
    [mutationTypes.openPopUp](state, popUp) {
        state.modals = [...state.modals, { name: popUp }]
    },
    [mutationTypes.openPopUpWithPayload](state, popUp) {
        const { name, payload } = popUp
        state.modals = [...state.modals, { name, payload }]
    },
    [mutationTypes.closePopUp](state, popUp) {
        state.modals = state.modals.filter((item) => item.name !== popUp)
    },
    [mutationTypes.goTo](state, modal) {
        state.modals = state.modals.splice(0, modal)
    },
    [mutationTypes.startLoading](state) {
        state.isLoading = true
    },
    [mutationTypes.endLoading](state) {
        state.isLoading = false
    },
    [mutationTypes.setPayload](state, { popUp, payload }) {
        state.modals = state.modals.map((modal) => {
            return modal.name === popUp ? { ...modal, payload } : modal
        })
    },
    [mutationTypes.addResFunction](state, { name, res }) {
        state.resolves = [...state.resolves, { name, res }]
    },
}

const actions = {
    [actionTypes.openPopUp]({ commit, state, dispatch }, popUp) {
        if (typeof popUp === 'string') {
            if (state.modals.map((item) => item.name).includes(popUp)) {
                dispatch(
                    `modalNotifications/${actionTypesModalsNotification.warning}`,
                    'This element has been opened',
                    { root: true }
                )
                return null
            }
            commit(mutationTypes.openPopUp, popUp)
            return new Promise((res) => {
                commit(mutationTypes.addResFunction, { name: popUp, res })
            })
        } else {
            const { name } = popUp
            if (state.modals.map((item) => item.name).includes(name)) {
                dispatch(
                    `modalNotifications/${actionTypesModalsNotification.warning}`,
                    'This element has been opened',
                    { root: true }
                )
                return null
            }
            commit(mutationTypes.openPopUpWithPayload, popUp)
            return new Promise((res) => {
                commit(mutationTypes.addResFunction, { name: popUp.name, res })
            })
        }
    },
    [actionTypes.closePopUp]({ commit, state }, popUp) {
        state.resolves = state.resolves
            .map((item, index) => {
                if (item.name === popUp) {
                    item.res(state.modals[index]?.payload || {})
                    return undefined
                }
                return item
            })
            .filter((i) => i)
        commit(mutationTypes.closePopUp, popUp)
    },
    [actionTypes.goTo]({ commit }, modal) {
        commit(mutationTypes.goTo, modal)
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
