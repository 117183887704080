const wigColorsMapper = {
    ['wigs in production']: '#f6bb42',
    ['repair wigs']: '#ffed01',
    ['ready repair wigs']: '#7decff',
    ['ready new wig']: '#6af789',
    ['cancelled wigs']: '#fd5959',
    ['storage']: '#57a3ff',
    ['delivered wigs']: '#b09789',
    ['wigs in stock']: '#e971ff',
    ['rework']: '#ff7200',
    ['archived']: '#c5c5c5',
    ['draft']: '#fff',
}

const getWigColorByState = (state) => {
    return wigColorsMapper[state.toLowerCase()] || wigColorsMapper['draft']
}

export default getWigColorByState
