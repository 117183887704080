<template>
    <ul class="bread-crumbs">
        <li
            v-for="({ title, path }, index) in crumbs"
            :key="index"
            class="bread-crumbs__item"
        >
            <router-link class="bread-crumbs__link" :to="path">
                {{ title }}
            </router-link>
        </li>
    </ul>
</template>

<script>
export default {
    name: 'BreadCrumbs',
    props: {
        crumbs: {
            type: Array,
            default: function () {
                return []
            },
        },
    },
}
</script>

<style scoped lang="scss">
.bread-crumbs {
    display: flex;

    &__item {
        & + & {
            margin-left: 7px;

            &:before {
                margin-right: 5px;
                content: '/';
            }
        }
    }

    &__link {
        text-decoration: none;
        color: #3fb1db;
        transition: color 150ms ease-in-out;

        &:hover {
            color: #2494be;
        }

        &.router-link-exact-active {
            cursor: text;
            color: $color-primary-dark;
        }
    }
}
</style>
