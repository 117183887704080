const serializeMeetingData = (response) => {
    try {
        return {
            id: response.id,
            subject: response.subject,
            manager: response.manager,
            startAt: response.start_at,
            calendarType: response.calendar,
            previousDates: response.old_dates,
            client: {
                id: response.client.id,
                phone: response.client.phone,
                fullName: `${response.client.name} ${response.client.surname}`,
            },
            meetingManager:
                response.meeting_manager &&
                Object.keys(response.meeting_manager).length !== 0
                    ? {
                          id: response.meeting_manager.id,
                          nickName: response.meeting_manager.nickName,
                          hex_color: response.meeting_manager.hex_color,
                          shortNickName: response.meeting_manager.shortNickName,
                      }
                    : null,
            wig: {
                id: response?.wig?.id || null,
                isReady: response?.wig?.isReady || false,
            },
        }
    } catch (e) {
        console.log(e)
    }
}

export default serializeMeetingData
