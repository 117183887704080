import Vue from 'vue'
import App from './App.vue'
import vuetify from '@/plugins/vuetify'
import VueHtmlToPaper from 'vue-html-to-paper'
import webcam from '@/plugins/vue-webcam'
import mediaQueries from '@/plugins/vue-media-queries'
import store from '@/store'
import router from './router'
import { i18n } from '@/plugins/i18n'
import { VueMaskDirective } from 'v-mask'
import permissions from '@/mixins/permissions'
import PortalVue from 'portal-vue'

Vue.directive('mask', VueMaskDirective)

Vue.mixin(permissions)

const options = {
    styles: ['/styles/print-page.css'],
}
Vue.use(VueHtmlToPaper, options)

Vue.use(PortalVue)

Vue.config.productionTip = false

new Vue({
    i18n,
    store,
    vuetify,
    router,
    webcam,
    mediaQueries,
    render: (h) => h(App),
}).$mount('#app')
