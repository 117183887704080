<template>
    <v-menu
        v-model="isOpen"
        :close-on-content-click="false"
        offset-y
        nudge-bottom="30"
    >
        <template #activator="{ on, attrs }">
            <v-badge bordered offset-y="10" offset-x="10">
                <template #badge>{{ quantity.unRead }}</template>
                <v-icon v-bind="attrs" v-on="on">mdi-bell</v-icon>
            </v-badge>
        </template>
        <UserMessages :messages="notifications" />
    </v-menu>
</template>

<script>
import { mapGetters } from 'vuex'
import { gettersTypes } from '@/store/modules/auth'
import UserMessages from '@/components/users-list/user-messages'

export default {
    name: 'NotificationHeaderList',
    components: { UserMessages },
    data() {
        return {
            isOpen: false,
        }
    },
    computed: {
        ...mapGetters('auth', {
            notifications: gettersTypes.getNotification,
        }),
        quantity: function () {
            return {
                all: this.notifications.length,
                unRead: this.notifications.reduce((count, ntf) => {
                    return !ntf.read ? count + 1 : count
                }, 0),
            }
        },
    },
}
</script>

<style lang="scss" scoped></style>
