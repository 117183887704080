import store from '@/store'
import { gettersTypes } from '@/store/modules/auth'

const getAllowedByRole = (allowedRoles) => {
    if (!allowedRoles || allowedRoles.length === 0) {
        return false
    }

    const getAllowed = store.getters[`auth/${gettersTypes.getAllowed}`]
    return getAllowed(allowedRoles)
}

export default getAllowedByRole
