import api from '@/api/report'

const state = () => ({
    employees: [],
    payments: [],
})

export const getterTypes = {
    getAllPayments: 'getAllPayments',
    getAllEmployees: 'getAllEmployees',
}

export const actionTypes = {
    getPayments: ' getPayments',
    getPaymentsByPdf: '[report] get payment by pdf format',
    getEmployees: ' getEmployees',
    getEmployeesByPdf: '[report] get employees by pdf format',
}

const mutations = {
    setPayments(state, data) {
        state.payments = data.map((item) => {
            return {
                ...item,
                amount: +item.total,
                remainder: item['total_cost']
                    ? `${item.totally}/${item['total_cost']}`
                    : '',
                paid: item.totally >= item['total_cost'],
                status: (item.totally / item['total_cost']) * 100,
            }
        })
    },
    setEmployees(state, data) {
        state.employees = data
    },
}

const getters = {
    [getterTypes.getAllPayments]: ({ payments }) => payments,
    [getterTypes.getAllEmployees]: ({ employees }) => employees,
}

const actions = {
    [actionTypes.getPayments]({ commit }, data) {
        return new Promise((res, rej) => {
            api.getPayments(data)
                .then((payments) => {
                    commit('setPayments', payments.data)
                    res(payments)
                })
                .catch((error) => rej(error))
        })
    },
    // eslint-disable-next-line no-unused-vars
    [actionTypes.getPaymentsByPdf]({ commit }, data) {
        return new Promise((res, rej) => {
            api.getPaymentsPdf(data)
                .then((payments) => {
                    res(payments)
                })
                .catch((error) => rej(error))
        })
    },
    [actionTypes.getEmployees]({ commit }, data) {
        return new Promise((res, rej) => {
            api.getEmployees(data)
                .then((employees) => {
                    commit('setEmployees', employees.data)
                    res(employees)
                })
                .catch((error) => rej(error))
        })
    },
}

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
}
