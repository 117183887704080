<template>
    <v-dialog
        v-model="modalIsActive"
        attach="body"
        z-index="9999"
        overlay-color="#000"
        :overlay-opacity="0.8"
        width="400"
    >
        <div v-if="modalIsActive" class="client-delete__body">
            <h3 class="client-delete__title">{{ getMessage }}</h3>
            <v-divider></v-divider>
            <v-text-field
                ref="input"
                v-model="value"
                dense
                outlined
                type="text"
                name="value"
            ></v-text-field>
            <v-divider></v-divider>
            <div class="client-delete__actions">
                <v-btn
                    min-width="85"
                    class="client-delete__btn"
                    small
                    color="#3bafda"
                    dark
                    @click="agreeHandle"
                >
                    {{ textContent['btn_yes'] }}
                </v-btn>
                <v-btn
                    min-width="85"
                    class="client-delete__btn"
                    small
                    color="#da4453"
                    dark
                    @click="cancelHandle"
                >
                    {{ textContent['btn_no'] }}
                </v-btn>
            </div>
        </div>
    </v-dialog>
</template>

<script>
import confirmMixin from '@/mixins/confirm.mixin'
import languageMixin from '@/mixins/language.mixin'

export default {
    name: 'Confirmer',
    mixins: [confirmMixin, languageMixin],
    data() {
        return {
            value: null,
        }
    },
    watch: {
        modalIsActive: function (newValue) {
            if (!newValue) return null
            this.focus()
        },
    },
    mounted() {
        this.focus()
    },
    methods: {
        agreeHandle: function () {
            this.onAgree(this.value)
            this.value = null
        },
        focus: async function () {
            this.$nextTick(() => {
                setTimeout(() => {
                    if (!this.$refs.input) return null
                    this.$refs.input.focus()
                })
            })
        },
    },
}
</script>

<style scoped lang="scss">
.client-delete {
    &__title {
        text-align: center;
        margin-bottom: 15px;
    }

    &__body {
        height: fit-content;
        background: $color-primary-light;
        padding: 30px 15px 0;
    }

    &__actions {
        padding: 10px 0;
        display: flex;
        justify-content: space-between;
    }

    &__btn {
        min-width: 85px;
    }
}
</style>
