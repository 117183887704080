<template>
    <v-dialog v-model="isActive" width="500" @click:outside="closeHandle">
        <div class="usa-tools">
            <div class="usa-tools__wrapper">
                <div class="usa-tools__head">
                    <h3 class="usa-tools__title">USA specific tools</h3>
                </div>
                <div class="usa-tools__body">
                    <v-autocomplete
                        v-model="selectedManagers"
                        outlined
                        clearable
                        :items="users"
                        :loading="isUserFetching"
                        placeholder="USA"
                        dense
                        hide-details
                        multiple
                        @change="() => {}"
                    >
                    </v-autocomplete>
                </div>
                <div class="usa-tools__foot">
                    <v-btn color="#3bafda" dark small @click="closeHandle">
                        <span>Show {{ getAllOrdersV2.length }} results</span>
                    </v-btn>
                </div>
            </div>
        </div>
    </v-dialog>
</template>

<script>
import modalsMixin from '@/mixins/modals.mixin'

import popUpTypes from '@/types/pop-up-types'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import {
    actionTypes as actionTypesUsers,
    getterTypes,
} from '@/store/modules/users'
import { getterTypes as getterTypesOrders } from '@/store/modules/orders'
import { mutationTypes } from '@/store/modules/orders'

const { orderUsaTools } = popUpTypes
import { excludedUsaManagersForUsaTools } from '@/assets/data/usa-manager.config.json'
import {
    ordersV2ActionTypes,
    ordersV2GetterTypes,
} from '@/store/modules/orders_v2/types'

export default {
    name: 'OrderModalUSATools',
    mixins: [modalsMixin],
    data() {
        return {
            selectedManagers: [],
            selfName: orderUsaTools,
        }
    },
    computed: {
        ...mapGetters('users', {
            getAllUsers: getterTypes.getAllUsers,
            isUserFetching: getterTypes.isFetching,
        }),
        ...mapGetters('orders', { getAllOrders: getterTypesOrders.getAll }),
        ...mapGetters('ordersV2', {
            getAllOrdersV2: ordersV2GetterTypes.GET_ORDERS,
        }),
        users: function () {
            return this.getAllUsers
                .filter(
                    (user) =>
                        user.role.name === 'USA_MANAGER' &&
                        !excludedUsaManagersForUsaTools.includes(user?.nickName)
                )
                .map(({ nickName, id }) => ({
                    text: nickName,
                    value: id,
                }))
        },
    },
    watch: {
        isActive: function (value) {
            if (value) {
                if (!this.getAllUsers || this.getAllUsers.length === 0) {
                    this.loadAllUsers()
                }
            }
        },
        selectedManagers: function (managers) {
            this.setUsaManagers(managers)
        },
    },
    methods: {
        ...mapActions('users', { loadAllUsers: actionTypesUsers.loadAllUsers }),
        ...mapActions('ordersV2', {
            setUsaManagers: ordersV2ActionTypes.SET_USA_MANAGERS,
        }),
        ...mapMutations('orders', {
            addSetter: mutationTypes.addSetter,
            removeSetter: mutationTypes.removeSetter,
        }),
    },
}
</script>

<style lang="scss" scoped>
.usa-tools {
    background: #fff;
    border-radius: 15px;
    overflow: hidden;

    &__wrapper {
    }

    &__head {
        padding: 16px 16px 0;
        position: relative;
    }

    &__title {
        font-size: 13px;
    }

    &__body {
        padding: 0 16px;
        margin-bottom: 12px;
    }

    &__foot {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 0 12px;
        gap: 15px;
    }
}
</style>
