import api from '@/api/order-repairs-stages'
import { uniqBy } from 'lodash'
import { actionTypes as actionTypesModalsNotification } from '@/store/modules/modal-notifications'
import {
    actionTypes as actionTypesFromOrders,
    actionTypes as actionTypesOrders,
} from '@/store/modules/orders'

const state = () => ({
    isSubmitting: false,
    additionalProperties: [],
})

export const getterTypes = {
    getIsSubmitting: '[order-repairs-stage][getter] Get state of submitting',
    getAdditionalProperties:
        '[order-repairs-stage][getter] Get all additional properties',
}

export const mutationsTypes = {
    startSubmit: '[order-repairs-stage][mutation] Start submit',
    endSubmit: '[order-repairs-stage][mutation] End submit',
    putLoadedProperties:
        '[order-repairs-stage][mutation] Put loaded properties',
    addNewProperties:
        '[order-repairs-stage][mutation] Add new additional properties',
    onDone: '[order-repairs-stage][mutation] Done selected property',
    onUndone: '[order-repairs-stage][mutation] Undone selected property',
    onDelete: '[order-repairs-stage][mutation] OnDelete selected property',
    onEdit: '[order-repairs-stage][mutation] Edit existing property',
    onEditCategory: '[order-repairs-stage][mutation] Edit existing category',
}

export const actionTypes = {
    putLoadedProperties: '[order-repairs-stage][action] Put loaded properties',
    addNewProperties:
        '[order-repairs-stage][action] Add new additional properties',
    onDone: '[order-repairs-stage][action] Done selected property',
    onUndone: '[order-repairs-stage][action] Undone selected property',
    onDelete: '[order-repairs-stage][action] Delete selected property',
    onEdit: '[order-repairs-stage][action] Edit existing property',
    onEditCategory: '[order-repairs-stage][action] Edit existing category',
}

const getters = {
    [getterTypes.getIsSubmitting]: ({ isSubmitting }) => isSubmitting,
    [getterTypes.getAdditionalProperties]: ({ additionalProperties }) =>
        uniqBy(additionalProperties, 'id'),
}

const mutations = {
    [mutationsTypes.startSubmit](state) {
        state.isSubmitting = true
    },
    [mutationsTypes.endSubmit](state) {
        state.isSubmitting = false
    },
    [mutationsTypes.putLoadedProperties](state, payload) {
        state.additionalProperties = payload || []
    },
    [mutationsTypes.addNewProperties](state, properties) {
        properties = properties.map((property) => {
            return {
                comment: property.comment,
                done: false,
                done_at: null,
                done_by: '',
                created_at: property.created_at,
                id: property.id,
                tittle: property.tittle,
                category: property.category,
            }
        })

        state.additionalProperties = [
            ...(state.additionalProperties || []),
            ...properties,
        ]
    },
    [mutationsTypes.onDone](state, properties) {
        properties.forEach(({ id, done_at, done_by }) => {
            state.additionalProperties = state.additionalProperties.map(
                (property) =>
                    property.id === id
                        ? { ...property, done: true, done_at, done_by }
                        : property
            )
        })
    },
    [mutationsTypes.onUndone](state, properties) {
        properties.forEach((id) => {
            state.additionalProperties = state.additionalProperties.map(
                (property) =>
                    property.id === id
                        ? { ...property, done: false, doneStage: '' }
                        : property
            )
        })
    },
    [mutationsTypes.onDelete](state, id) {
        state.additionalProperties = state.additionalProperties.filter(
            (property) => property.id !== id
        )
    },
    [mutationsTypes.onEdit](state, updatedProperty) {
        state.additionalProperties = state.additionalProperties.map(
            (property) => {
                if (property.id === updatedProperty.id) {
                    return { ...property, tittle: updatedProperty.tittle }
                }

                return property
            }
        )
    },
    [mutationsTypes.onEditCategory](state, updatedCategory) {
        state.additionalProperties = state.additionalProperties.map(
            (property) => {
                if (property.category_id === updatedCategory.id) {
                    return { ...property, category: updatedCategory.name }
                }

                return property
            }
        )
    },
}

const actions = {
    [actionTypes.putLoadedProperties]({ commit }, payload) {
        commit(mutationsTypes.putLoadedProperties, payload)
    },
    [actionTypes.addNewProperties]({ commit, dispatch }, payload) {
        commit(mutationsTypes.startSubmit)
        return new Promise((res, rej) => {
            const propertiesWithType = payload.newProperties.map((property) => {
                return { ...property, type: 'repair' }
            })
            api.putNewStage({
                wigID: payload.wigID,
                properties: propertiesWithType,
            })
                .then(({ data }) => {
                    setTimeout(() => {
                        commit(mutationsTypes.addNewProperties, data)
                        res(data)
                        commit(mutationsTypes.endSubmit)
                        dispatch(
                            `orders/${actionTypesOrders.reloadSelectedOrder}`,
                            undefined,
                            { root: true }
                        )
                    }, 350)
                })
                .catch((er) => {
                    console.error(er)
                    rej(er)
                })
        })
    },
    [actionTypes.onDone]({ commit }, payload) {
        return new Promise((res, rej) => {
            api.doneStage(payload)
                .then(({ data }) => {
                    setTimeout(() => {
                        res(data)
                        commit(mutationsTypes.onDone, data)
                    }, 0)
                })
                .catch((er) => {
                    rej(er)
                })
        })
    },
    [actionTypes.onUndone]({ commit }, payload) {
        return new Promise((res, rej) => {
            api.unDoneStage(payload)
                .then(({ data }) => {
                    setTimeout(() => {
                        res(data)
                        commit(mutationsTypes.onUndone, payload.ids)
                    }, 0)
                })
                .catch((er) => {
                    rej(er)
                })
        })
    },
    [actionTypes.onDelete]({ commit, dispatch }, payload) {
        return new Promise((res, rej) => {
            api.deleteStage(payload)
                .then(({ data }) => {
                    setTimeout(() => {
                        res(data)
                        commit(mutationsTypes.onDelete, payload.property_id)
                    }, 0)
                })
                .catch((er) => {
                    rej(er)
                    console.log({ er })
                    dispatch(
                        `modalNotifications/${actionTypesModalsNotification.error}`,
                        er?.response?.data?.message || 'Something went wrong',
                        { root: true }
                    )
                })
        })
    },
    async [actionTypes.onEdit]({ commit, dispatch }, payload) {
        try {
            const updatedProperty = await api.editStage({
                wigId: payload.wigID,
                propertyId: payload.payload.propertyId,
                updatedTitle: payload.payload.updatedTitle,
            })
            commit(mutationsTypes.onEdit, updatedProperty)
            dispatch(
                `orders/${actionTypesFromOrders.reloadSelectedOrder}`,
                undefined,
                { root: true }
            )
            return updatedProperty
        } catch (e) {
            console.log(e)
        }
    },
    async [actionTypes.onEditCategory]({ commit, dispatch }, payload) {
        try {
            const updatedCategory = await api.editCategory(payload)
            commit(mutationsTypes.onEditCategory, updatedCategory)
            dispatch(
                `orders/${actionTypesFromOrders.reloadSelectedOrder}`,
                undefined,
                { root: true }
            )
        } catch (e) {
            console.log(e)
        }
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
