export default (str) => {
    try {
        if (typeof JSON.parse(str) === 'number') {
            return false
        }

        JSON.parse(str)
    } catch (e) {
        return false
    }
    return true
}
