import { actionTypes } from '@/store/modules/modals'
import popUpTypes from '@/types/pop-up-types'
import getAllowedByRole from '@/helpers/getAllowedByRole'
const { orderAssignCreator } = popUpTypes

const changeManagerHandle = (wig, store) => {
    store.dispatch(`modals/${actionTypes.openPopUp}`, {
        name: orderAssignCreator,
    })
}
const changeManagerValidator = (wig) => {
    if (!getAllowedByRole(['admin', 'manager'])) {
        return false
    }

    return wig.state?.toLowerCase() !== 'archived'
}

export const changeManagerDateActions = [
    {
        titleKey: 'changeManager',
        handle: changeManagerHandle,
        validator: changeManagerValidator,
    },
]
