import { getStorage } from '@/helpers/Local'

const getSelectedLanguage = () => {
    const language = getStorage('language') || ''
    return language === 'Hebrew'
        ? { lang: 'he', dir: 'rtl' }
        : { lang: 'en', dir: 'ltr' }
}

export default getSelectedLanguage
