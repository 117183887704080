const prepareGeneral = (data) => {
    return {
        ['client_id']: data['client_id'],
        ['totalPrice']: 0,
        ['barcode']: data['barcode'] || '',
        ['info']: '',
        ['is_paid']: true,
        ['details']: '',
        ['user_generated_id']: data['user_generated_id'] || '',
        ['is_reword']: false,
        ['visible']: true,
        ['shippingInfo']: '',
        ['is_repair']: false,
        ['state']: 'Draft',
    }
}

const prepareProperties = (data) => ({ ...data })

export { prepareGeneral, prepareProperties }
