<template>
    <v-expansion-panel :disabled="disableProperties">
        <v-expansion-panel-header color="#f7f7f9">
            <h4
                :class="{
                    'order-property__sub-title_grey': disableProperties,
                    'order-property__sub-title': !disableProperties,
                }"
            >
                4. {{ propertiesContent['front'] }}
            </h4>
        </v-expansion-panel-header>
        <v-expansion-panel-content color="#f7f7f9">
            <div
                id="order-property--front--front-select"
                class="order-property__input-wrapper"
            >
                <v-autocomplete
                    v-model="mainValue"
                    outlined
                    :items="items"
                    :label="propertiesContent['front']"
                    dense
                    attach="#order-property--front--front-select"
                    :error-messages="properties.errors['front']"
                    @input="properties.errors['front'] = ''"
                    @change="
                        () => {
                            clearSub()
                            changeHandle()
                        }
                    "
                >
                </v-autocomplete>
                <template v-if="mainValue">
                    <v-autocomplete
                        v-model="subValue"
                        outlined
                        :items="subItems[mainValue]"
                        :label="label(mainValue)"
                        dense
                        attach="#order-property--front--front-select"
                        :error-messages="properties.errors['front']"
                        @input="properties.errors['front'] = ''"
                        @change="changeHandle"
                    >
                    </v-autocomplete>
                </template>
                <v-text-field
                    v-model="properties.fields['frontComments']"
                    light
                    outlined
                    dense
                    type="text"
                    name="front-comments"
                    :label="propertiesContent['front-comments']"
                    :error-messages="properties.errors['frontComments']"
                    @input="properties.errors['frontComments'] = ''"
                >
                </v-text-field>
            </div>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
import { mapGetters } from 'vuex'
import { getterTypes } from '@/store/modules/orders'
import isJson from '@/helpers/isJson'
import languagePropertiesMixin from '@/mixins/language-properties.mixin'
import regularToSnakeCase from '@/helpers/regular-to-snake-case'

export default {
    name: 'OrderFormPropertyFront',
    mixins: [languagePropertiesMixin],
    props: {
        dataProperties: {
            type: Object,
            default: () => ({
                fields: {
                    ['front']: '',
                    ['frontComments']: '',
                },
                errors: {},
            }),
        },
        disableProperties: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            mainValue: null,
            subValue: null,
        }
    },
    computed: {
        ...mapGetters('orders', { getOrder: getterTypes.getOrder }),
        items: function () {
            return [
                { text: this.propertiesContent['none'], value: null },
                {
                    text: this.propertiesContent['closed-lace'],
                    value: 'Closed Lace',
                },
                {
                    text: this.propertiesContent['open-lace'],
                    value: 'Open Lace',
                },
                { text: this.propertiesContent['hand-tie'], value: 'Hand Tie' },
            ]
        },
        subItems: function () {
            return {
                ['Closed Lace']: [
                    { text: this.propertiesContent['none'], value: null },
                    { text: this.propertiesContent['thin'], value: 'Thin' },
                    {
                        text: this.propertiesContent['regular'],
                        value: 'Regular',
                    },
                    { text: this.propertiesContent['height'], value: 'Height' },
                    {
                        text: this.propertiesContent['deep-closed'],
                        value: 'Deep closed',
                    },
                ],
                ['Open Lace']: [
                    { text: this.propertiesContent['none'], value: null },
                    { text: this.propertiesContent['flat'], value: 'Flat' },
                    {
                        text: this.propertiesContent['with-heights'],
                        value: 'With Heights',
                    },
                    { text: this.propertiesContent['height'], value: 'Height' },
                    {
                        text: this.propertiesContent['deep-closed'],
                        value: 'Deep Closed',
                    },
                ],
                ['Hand Tie']: [
                    { text: this.propertiesContent['none'], value: null },
                    { text: this.propertiesContent['bangs'], value: 'Bangs' },
                    {
                        text: this.propertiesContent['lace-top'],
                        value: 'Lace Type',
                    },
                ],
            }
        },
        properties: function () {
            return this.dataProperties
        },
        resValue: function () {
            // TODO: nee to rewrite this logic at all
            const arr = [this.mainValue, this.subValue].filter((i) => i)
            return arr.length !== 0 ? JSON.stringify(arr) : null
        },
    },
    watch: {
        disableProperties: function (disabled) {
            if (disabled) {
                this.clearFull()
            }
        },
        getOrder: function () {
            this.init()
        },
    },
    mounted() {
        setTimeout(() => {
            this.init()
        })
    },
    methods: {
        label: function (label) {
            return this.propertiesContent[regularToSnakeCase(label)]
        },
        changeHandle: function () {
            this.properties.fields['front'] = this.resValue
        },
        init: function () {
            const front = this.properties.fields['front']
            if (!front || !isJson(front)) return null
            const value = JSON.parse(front)
            this.mainValue = value[0]
            this.subValue = value[1]
        },
        clearSub: function () {
            this.subValue = null
        },
        clearFull: function () {
            this.mainValue = null
            this.subValue = null
            this.properties.fields['front'] = ''
            this.properties.fields['frontComments'] = ''
        },
    },
}
</script>

<style lang="scss" scoped>
.order-property {
    &__sub-title {
        color: $color-primary-dark;
    }
    &__sub-title_grey {
        color: rgb(175, 175, 175);
    }
    &__input-wrapper {
        padding: 15px 15px 0;
    }
}
</style>
