import { i18n } from '@/plugins/i18n'
const buildChangeManagerLogs = (changeManagerData = []) => {
    return changeManagerData.map((item) => {
        return {
            user: item.user,
            prevManager: item.previous_manager.nickName,
            newManager: item.creator.nickName,
            date: item.created_at,
        }
    })
}

const buildChangeClientLogs = (changeClientData = []) => {
    return changeClientData.map((item) => {
        return {
            user: item.user,
            prevClient: `${item.previous_client.name} ${item.previous_client.surname}`,
            newClient: `${item.new_client.name} ${item.new_client.surname}`,
            date: item.created_at,
        }
    })
}

const buildChangeStatusLogs = (changeStatusData = []) => {
    return changeStatusData.map((item) => {
        return {
            doneBy: item.user,
            action: item.state,
            doneAt: item.created_at,
        }
    })
}

const buildSourceHistoryLogs = (sourceHistoryData = []) => {
    return sourceHistoryData.map((item) => {
        return {
            current: false,
            source: i18n.t(
                `pages.wigPage.logs.tables.sourceHistory.sourceType.${item.source}`
            ),
            sourceWig: item.sourceWig
                ? {
                      href: `/orders/${item.sourceWig.id}`,
                      label: item.sourceWig.client,
                      target: '_blank',
                  }
                : null,
            createdWig: {
                href: `/orders/${item.createdWig.id}`,
                label: item.createdWig.client,
                target: '_blank',
            },
            doneBy: item.doneBy,
            doneAt: item.doneAt,
            currentWigID: item.createdWig.id,
        }
    })
}

export const buildLogsFromWigData = (wig) => {
    const general = {
        headers: [{ value: 'text' }, { value: 'value' }],
        items: [
            {
                text: i18n.t('pages.wigPage.logs.headers.createdBy'),
                value: wig.created_by,
            },
            {
                text: i18n.t('pages.wigPage.logs.headers.createdAt'),
                value: wig?.created_at && wig.created_at,
            },
            {
                text: i18n.t('pages.wigPage.logs.headers.updatedAt'),
                value: wig?.updated_at && wig.updated_at,
            },
        ],
    }

    const clientChangeLogs = {
        headers: [
            { text: 'doneBy', value: 'user' },
            { text: 'prevClient', value: 'prevClient' },
            { text: 'newClient', value: 'newClient' },
            { text: 'doneAt', value: 'date' },
        ],
        items: buildChangeClientLogs(wig.clientChangeLogs),
    }
    const managerChangeLogs = {
        headers: [
            { text: 'doneBy', value: 'user' },
            { text: 'prevManager', value: 'prevManager' },
            { text: 'newManager', value: 'newManager' },
            { text: 'doneAt', value: 'date' },
        ],
        items: buildChangeManagerLogs(wig.managerChangeLogs),
    }

    const statusChangeLogs = {
        headers: [
            { text: 'doneBy', value: 'doneBy' },
            { text: 'action', value: 'action' },
            { text: 'doneAt', value: 'doneAt' },
        ],
        items: buildChangeStatusLogs(wig?.stateLog),
    }

    const sourceHistoryLogs = {
        headers: [
            { text: 'source', value: 'source', width: 30 },
            { text: 'sourceWig', value: 'sourceWig', width: 30 },
            { text: 'createdWig', value: 'createdWig' },
            { text: 'doneBy', value: 'doneBy' },
            { text: 'doneAt', value: 'doneAt' },
        ],
        items: buildSourceHistoryLogs(wig.sourceHistoryLogs),
    }

    return {
        general,
        clientChangeLogs,
        managerChangeLogs,
        statusChangeLogs,
        sourceHistoryLogs,
    }
}
