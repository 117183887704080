<template>
    <v-dialog v-model="isActive" width="500" @click:outside="cancelHandle">
        <div class="client-add">
            <div v-if="isBusy" class="client-add__overlay-blocker">
                <v-progress-circular
                    indeterminate
                    :size="75"
                    width="5"
                    :value="100"
                    color="#967adc"
                ></v-progress-circular>
            </div>
            <div class="client-add__header">
                <h4 class="client-add__title">{{ title }}</h4>
                <v-btn icon color="#55595c" @click="cancelHandle">
                    <v-icon>mdi-close-circle-outline</v-icon>
                </v-btn>
            </div>
            <v-divider></v-divider>
            <div class="client-add__body">
                <ClientFormAdd
                    :is-active="isActive"
                    :data="{ fields: client, errors: errors }"
                />
                <div class="client-add__actions">
                    <v-btn small color="#55595c" dark @click="cancelHandle">
                        {{ textContent.close }}
                    </v-btn>
                    <v-btn
                        small
                        color="#967adc"
                        dark
                        data-test-id="clients--add-client-pop-up--confirm-btn"
                        @click="submitHandle"
                    >
                        {{ id ? textContent.edit : textContent.add }}
                    </v-btn>
                </div>
            </div>
        </div>
    </v-dialog>
</template>

<script>
import ClientFormAdd from '@/components/forms/client-form-add'

import { mapActions, mapGetters } from 'vuex'
import { actionTypes } from '@/store/modules/clients'
import { getterTypes as getterTypesClients } from '@/store/modules/clients'
import { getterTypes as getterTypesCountries } from '@/store/modules/countries'
import { actionTypes as actionTypesModalNotifications } from '@/store/modules/modal-notifications'
import { actionTypes as actionTypeCountries } from '@/store/modules/countries'
import {
    getterTypes as getterTypesModals,
    actionTypes as actionTypesModals,
} from '@/store/modules/modals'

import popUpTypes from '@/types/pop-up-types'
import { actionTypes as actionTypesConfirmer } from '@/store/modules/confirmer'
import parseFromHeToInternPhoneFormat from '@/helpers/parse-from-he-to-intern-phone-format'
import languageMixin from '@/mixins/language.mixin'
import { removeSpaces } from '@/helpers/textTrimHandle'
import { ordersV2ActionTypes } from '@/store/modules/orders_v2/types'

const { clientUpdate, calendarContext, clientContext } = popUpTypes

export default {
    name: 'ClientModalAddChange',
    components: {
        ClientFormAdd,
    },
    mixins: [languageMixin],
    emits: ['close-modal'],
    data() {
        return {
            isBusy: false,
            myInputModel: '',
            client: {
                name: '',
                surname: '',
                email: '',
                phone: '',
                additionalPhone: '',
                country: '',
                city: '',
            },
            errors: {
                name: '',
                surname: '',
                email: '',
                phone: '',
                additionalPhone: '',
                country: '',
                city: '',
            },
        }
    },
    computed: {
        ...mapGetters('clients', { getClient: getterTypesClients.getClient }),
        ...mapGetters('countries', {
            countries: getterTypesCountries.getCountries,
            cities: getterTypesCountries.getCities,
        }),
        ...mapGetters('modals', {
            checkIsActive: getterTypesModals.isActive,
            payload: getterTypesModals.payload,
        }),
        id: function () {
            return this.getClient.id
        },
        isActive: function () {
            return this.checkIsActive(clientUpdate)
        },
        title: function () {
            return this.id
                ? this.textContent['edit-client']
                : this.textContent['add-a-new-client']
        },
        modalPayload: function () {
            return this.payload(clientUpdate)
        },
    },
    watch: {
        isActive: function () {
            if (!this.isActive) return null
            this.changeData()
            this.selectCountyHandle()
        },
    },
    mounted() {
        this.getCountries()
        this.changeData()
    },
    methods: {
        ...mapActions('clients', {
            addClient: actionTypes.addClient,
            updateClient: actionTypes.updateClient,
            checkPhoneISAlready: actionTypes.checkClientIsAlready,
        }),
        ...mapActions('modalNotifications', {
            success: actionTypesModalNotifications.success,
        }),
        ...mapActions('countries', {
            getCountries: actionTypeCountries.getCountries,
            getCities: actionTypeCountries.getCities,
        }),
        ...mapActions('modals', {
            onClose: actionTypesModals.closePopUp,
            onOpen: actionTypesModals.openPopUp,
        }),
        ...mapActions('confirmer', {
            confirmHandle: actionTypesConfirmer.confirmHandle,
        }),

        submitHandle: async function () {
            this.isBusy = true
            this.processValidation()

            if (Object.values(this.errors).filter((i) => i)[0]) {
                this.isBusy = false
                return null
            }
            const user = false

            this.client = {
                ...this.client,
                phone: removeSpaces(this.client.phone),
            }

            let clientData = this.getAllowed(['usa_manager'])
                ? { ...this.client, phone: '1-000-000-0000' }
                : this.client

            if (this.id) {
                this.updateClient({
                    client: {
                        ...this.client,
                        visible: true,
                    },
                    id: this.id,
                })
                    .then(() => {
                        this.success('Client has been updated')
                        this.cancelHandle()
                    })
                    .catch((errors) => {
                        this.errors = { ...this.errors, ...errors }
                    })
                    .finally(() => {
                        this.isBusy = false
                    })
            } else if (user) {
                try {
                    await this.confirmHandle(
                        this.textContent['message-phone-already-exists']
                    )
                    this.addClient({
                        client: {
                            ...clientData,
                        },
                    })
                        .then(() => {
                            this.success('Client has been added')
                            this.cancelHandle()
                        })
                        .catch((errors) => {
                            this.errors = { ...this.errors, ...errors }
                        })
                } catch {
                    return null
                } finally {
                    this.isBusy = false
                }
            } else {
                this.addClient({
                    client: {
                        ...clientData,
                    },
                    options: {
                        withoutOpenClientCard:
                            this.modalPayload?.withoutOpenClientCard,
                    },
                })
                    .then((client) => {
                        this.success('Client has been added')

                        if (this.modalPayload?.resolve) {
                            this.modalPayload?.resolve(client)
                        }

                        if (this.modalPayload?.withoutOpenClientCard) {
                            this.cancelHandle()
                            return
                        }

                        this.cancelHandle()

                        if (!this.modalPayload?.withoutOpenClientCard)
                            this.onOpen(clientContext)

                        if (!this.getAllowed(['usa_manager'])) {
                            const payload = {
                                action: 'for-client',
                                clientId: client.id,
                            }
                            this.onOpen({ name: calendarContext, payload })
                        }
                    })
                    .catch((errors) => {
                        this.errors = { ...this.errors, ...errors }
                    })
                    .finally(() => {
                        this.isBusy = false
                    })
            }
        },
        selectCountyHandle: function () {
            if (this.id) {
                this.errors['country'] = ''
                this.getCities(this.client.country.toLowerCase())
            } else {
                this.getCountries().then(() => {
                    this.client.country = 'Israel'
                    this.getCities(this.client.country.toLowerCase()).then(
                        () => {
                            this.client.city = 'Jerusalem'
                        }
                    )
                })
            }
        },
        cancelHandle: function () {
            this.onClose(clientUpdate)
        },
        changeData: function () {
            Object.keys(this.client).forEach((item) => {
                this.client[item] = ''
                this.errors[item] = ''
            })

            if (this.id) {
                this.client = {
                    ...this.client,
                    ...this.getClient,
                    phone: parseFromHeToInternPhoneFormat(this.getClient.phone),
                }
            }
        },
        processValidation: function () {
            const phone = this.client?.phone.replace(/[+]|[\s]/g, '')
            const phonePattern = /^(972)(\d){9}$/
            const phoneIsValid =
                phonePattern.test(phone) ||
                this.getAllowed(['usa_manager']) ||
                this.client.country !== 'Israel'

            if (phoneIsValid) return

            this.errors = {
                ...this.errors,
                phone: "Typed phone wasn't validated",
            }
        },
    },
}
</script>

<style scoped lang="scss">
.client-add {
    height: fit-content;
    background: $color-primary-light;
    padding: 15px 15px 0;
    position: relative;

    &__overlay-blocker {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.55);
        z-index: 3;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
        position: relative;
        z-index: 1;
    }

    &__title {
        color: #525252;
        font-weight: 400;
        font-size: 16px;
    }

    &__body {
        margin-top: 10px;
        position: relative;
        z-index: 1;
    }

    &__actions {
        width: 100%;
        padding: 10px 15px 20px;
        display: flex;
        justify-content: space-between;

        button + button {
            margin-left: 15px;

            @include for-hebrew {
                margin: 0 15px 0 0;
            }
        }
    }
}
</style>
