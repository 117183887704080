export default {
    header: 'Price to be paid',
    paidToDate: 'Paid to date',
    paymentsHistory: 'Payments history',
    paymentsNotes: 'Payments notes',
    totalDeposit: 'Total deposit',
    noDepositYet: 'No deposit has been made',
    noPaymentNotes: 'Payment note will be here',
    updatePaymentsNotes: 'Add/Edit Payment Notes',
    actions: {
        addPayment: 'Add Payment',
        addNote: 'Add/Edit notes',
    },
}
