<template>
    <v-dialog v-model="isActive" width="500" @click:outside="closeHandle">
        <div class="storage">
            <div class="storage__header">
                <h3 class="storage__title">Sent a wig</h3>
            </div>
            <div class="storage__body">
                <div class="storage__input-wrapper">
                    <v-textarea
                        v-model="comment"
                        outlined
                        name="storage-comment"
                        label="Storage comment"
                        rows="2"
                        maxlentght="50"
                        :error-messages="errors"
                        @input="errors = ''"
                    >
                    </v-textarea>
                </div>
            </div>
            <div class="storage__footer">
                <v-btn color="#967adc" dark small @click="storageCommentHandle">
                    Save
                </v-btn>
                <v-btn color="#3bafda" dark small @click="skipHandle">
                    Skip
                </v-btn>
            </div>
        </div>
    </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { actionTypes, getterTypes } from '@/store/modules/orders'
import { actionTypes as actionTypesModalNotifications } from '@/store/modules/modal-notifications'

import modalsMixin from '@/mixins/modals.mixin'

import popUpTypes from '@/types/pop-up-types'

const { orderStorage } = popUpTypes
export default {
    name: 'OrderModalStorage',
    mixins: [modalsMixin],
    data() {
        return {
            selfName: orderStorage,
            comment: '',
            errors: '',
            files: [],
        }
    },
    computed: {
        ...mapGetters('orders', { order: getterTypes.getOrder }),
        orderId: function () {
            return this.order.id
        },
        selectedId: function () {
            return this.order.id
        },
    },
    watch: {
        orderId: function () {
            if (!this.order) return null
            this.comment = this.order.shippingInfo
        },
    },
    mounted() {
        if (!this.order) return null
        this.comment = this.order.shippingInfo
    },
    methods: {
        ...mapActions('orders', {
            onUpdate: actionTypes.updateOrder,
            updateState: actionTypes.updateState,
        }),
        ...mapActions('modalNotifications', {
            success: actionTypesModalNotifications.success,
        }),
        updateStateHandle: function () {
            const res = { id: this.selectedId, state: 'Storage' }
            this.updateState(res).then(() => {
                this.closeHandle()
                this.success('State has been updated')
            })
        },
        storageCommentHandle: function () {
            let res = { ['shippingInfo']: this.comment }

            const json = JSON.stringify(res)
            const form = new FormData()
            this.files.forEach((file) => {
                form.append('files[]', file)
            })
            form.append('data', json)

            const data = { id: this.selectedId, form }

            this.onUpdate(data)
                .then(() => {
                    this.success('Comment of storage has been added')
                    this.closeHandle()
                    this.updateStateHandle()
                })
                .catch((error) => {
                    this.errors = error
                })
        },
        skipHandle: function () {
            this.closeHandle()
            this.updateStateHandle()
        },
    },
}
</script>

<style scoped lang="scss">
.storage {
    background: $color-primary-light;

    &__header {
        padding: 10px;
    }

    &__body {
        padding: 0 10px;
    }

    &__footer {
        display: flex;
        justify-content: space-between;
        padding: 0 10px 15px;
    }
}
</style>
