<template>
    <div class="tab-container">
        <slot />
    </div>
</template>

<script>
export default {
    name: 'WigPageTabContainer',
}
</script>

<style lang="scss" scoped>
.tab-container {
    padding: 16px;

    @include tablet-large-up {
        height: var(--vr-body-height);
        overflow: auto;
        padding: 0 8px 0 16px;
        margin-right: 8px;
    }
}
</style>
