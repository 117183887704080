<template>
    <v-expansion-panel>
        <v-expansion-panel-header color="#f7f7f9">
            <h4 class="order-property__sub-title">
                8. {{ propertiesContent['wig-fullness'] }}
            </h4>
        </v-expansion-panel-header>
        <v-expansion-panel-content color="#f7f7f9">
            <div
                id="order-property--wig-fullness--wig-fullness-select"
                class="order-property__input-wrapper"
            >
                <v-autocomplete
                    v-model="properties.fields['wigFullness']"
                    outlined
                    :items="items"
                    dense
                    attach="#order-property--wig-fullness--wig-fullness-select"
                    :error-messages="properties.errors['wigFullness']"
                    @input="properties.errors['wigFullness'] = ''"
                >
                </v-autocomplete>

                <v-text-field
                    v-model="properties.fields['wigFullnessAdditionalAmount']"
                    light
                    outlined
                    dense
                    type="text"
                    name="wig-fullness-additional-amount"
                    :label="propertiesContent['wig-fullness-additional-amount']"
                    :error-messages="
                        properties.errors['wigFullnessAdditionalAmount']
                    "
                    @input="
                        properties.errors['wigFullnessAdditionalAmount'] = ''
                    "
                >
                </v-text-field>
                <v-text-field
                    v-model="properties.fields['wigFullnessComments']"
                    light
                    outlined
                    dense
                    type="text"
                    name="wig-fullness-comments"
                    :label="propertiesContent['wig-fullness-comments']"
                    :error-messages="properties.errors['wigFullnessComments']"
                    @input="properties.errors['wigFullnessComments'] = ''"
                >
                </v-text-field>
            </div>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
import languagePropertiesMixin from '@/mixins/language-properties.mixin'

export default {
    name: 'OrderFormPropertyWigFullness',
    mixins: [languagePropertiesMixin],
    props: {
        dataProperties: {
            type: Object,
            default: () => ({
                fields: {
                    ['wigFullness']: '',
                    ['wigFullnessAdditionalAmount']: '',
                    ['wigFullnessComments']: '',
                },
                errors: {},
            }),
        },
    },
    computed: {
        items: function () {
            return [
                { text: this.propertiesContent['none'], value: '' },
                { text: this.propertiesContent['standard'], value: 'Standard' },
                { text: this.propertiesContent['thin'], value: 'Thin' },
                {
                    text: this.propertiesContent['full-additional-charge'],
                    value: 'Full (additional charge)',
                },
            ]
        },
        properties: function () {
            return this.dataProperties
        },
    },
    methods: {},
}
</script>

<style lang="scss" scoped>
.order-property {
    &__sub-title {
        color: $color-primary-dark;
    }

    &__input-wrapper {
        padding: 15px 15px 0;
    }

    &__inner {
        border: 1px solid #626161;
        padding: 15px;
        margin-bottom: 15px;
    }
}
</style>
