<template>
    <v-dialog v-model="isActive" width="500" @click:outside="closeHandle">
        <div class="report">
            <div class="report__head">
                Report
                <v-btn
                    class="report__close"
                    icon
                    color="#55595c"
                    @click="closeHandle"
                >
                    <v-icon>mdi-close-circle-outline</v-icon>
                </v-btn>
            </div>
            <div class="report__body">
                <div class="report__select-wrapper">
                    <v-text-field
                        v-model="requestData.from"
                        dense
                        outlined
                        type="date"
                        name="from"
                        label="From"
                        :error-messages="errors.from"
                        @input="errors.from = ''"
                    ></v-text-field>
                </div>
                <div class="report__select-wrapper">
                    <v-text-field
                        v-model="requestData.to"
                        dense
                        outlined
                        type="date"
                        name="to"
                        label="To"
                        :error-messages="errors.to"
                        @input="errors.to = ''"
                    ></v-text-field>
                </div>
                <div class="report__select-wrapper">
                    <v-select
                        v-model="requestData.type"
                        outlined
                        dense
                        :items="types"
                        label="Type"
                    ></v-select>
                </div>
                <div
                    v-if="requestData.type === 'wig'"
                    class="report__select-wrapper"
                >
                    <v-select
                        v-model="requestData.user_id"
                        outlined
                        dense
                        :items="users"
                        label="Users"
                    ></v-select>
                </div>
                <div class="report__select-wrapper"></div>
            </div>
            <div class="report__action">
                <v-btn color="#0065c5" dark small @click="downloadHandle">
                    <v-icon small>mdi-download-outline</v-icon>
                    Generate
                </v-btn>
            </div>
        </div>
    </v-dialog>
</template>

<script>
import modalsMixin from '@/mixins/modals.mixin'

import popUpTypes from '@/types/pop-up-types'
import { mapActions, mapGetters } from 'vuex'
import { actionTypes, getterTypes } from '@/store/modules/users'

const { reportMain } = popUpTypes
export default {
    name: 'ReportModalMain',
    mixins: [modalsMixin],
    data() {
        return {
            selfName: reportMain,
            types: ['wig', 'payment'],
            errors: {
                from: '',
                to: '',
                type: 'wig',
            },
            requestData: {
                from: '',
                to: '',
                type: 'wig',
                user_id: 'none',
            },
        }
    },
    computed: {
        ...mapGetters('users', { getUsers: getterTypes.getAllUsers }),
        users: function () {
            return [
                { text: 'All', value: 'none' },
                ...this.getUsers.map(({ nickName, id }) => {
                    return {
                        text: nickName,
                        value: id,
                    }
                }),
            ]
        },
    },
    methods: {
        ...mapActions('users', {
            getReport: actionTypes.getReport,
            getReportByPayment: actionTypes.getReportByPayments,
        }),
        downloadHandle: async function () {
            const params = new URLSearchParams()
            let errors = []
            if (!this.requestData.from) {
                this.errors.from = 'Please enter this field'
                errors = [...errors, 'from']
            }

            if (!this.requestData.to) {
                errors = [...errors, 'to']
                this.errors.to = 'Please enter this field'
            }

            if (errors.length > 0) return null

            let report

            switch (this.requestData.type) {
                case 'wig':
                    params.append('from', this.requestData.from)
                    params.append('to', this.requestData.to)
                    if (
                        this.requestData.user_id &&
                        this.requestData.user_id !== 'none'
                    ) {
                        params.append('user_id', this.requestData.user_id)
                    }
                    params.append('format', 'pdf')
                    report = await this.getReport(params.toString())
                    window.open(report, '_blank')
                    return null
                case 'payment':
                    params.append('from', this.requestData.from)
                    params.append('to', this.requestData.to)
                    params.append('format', 'pdf')

                    report = await this.getReportByPayment(params.toString())
                    window.open(report, '_blank')
                    return
                default:
                    console.log('Wrong type of report')
            }
            //
            // const report = await this.getReport(params.toString())
            // window.open(report, '_blank')
        },
    },
}
</script>

<style lang="scss">
.report {
    background: #fff;
    position: relative;
    padding: 10px 0;

    &__head {
        font-weight: 600;
        padding: 0 15px 5px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.2);
        margin-bottom: 15px;
    }

    &__body {
        padding: 10px 15px 10px;
        min-height: 50px;
        justify-content: space-between;
        display: flex;
        flex-wrap: wrap;
    }

    &__select-wrapper {
        width: 45%;
    }

    &__action {
        padding: 0 15px;
    }

    &__close {
        position: absolute !important;
        top: 5px;
        right: 4px;
    }
}
</style>
