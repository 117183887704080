<template>
    <div :class="styles['login-form']">
        <div :class="styles['header']">
            <div :class="styles['header__text']">Login</div>
        </div>
        <div class="login-error" :class="{ ['is-active']: error }">
            <v-alert dense outlined type="error">
                Check your username and password.
            </v-alert>
        </div>
        <div :class="styles['input-wrapper']">
            <v-text-field
                v-model="login"
                label="Login"
                outlined
                :rules="[valid.login]"
                required
                :error="error"
                @input="() => (error = false)"
            ></v-text-field>
        </div>
        <div :class="styles['input-wrapper']">
            <v-text-field
                v-model="password"
                label="Password"
                outlined
                :rules="[valid.password]"
                required
                :error="error"
                :append-icon="passwordIsShow ? 'mdi-eye' : 'mdi-eye-off'"
                :type="passwordIsShow ? 'text' : 'password'"
                hint="At least 8 characters"
                @click:append="passwordIsShow = !passwordIsShow"
                @input="() => (error = false)"
            ></v-text-field>
        </div>
        <div :class="styles['input-action']" class="login__input-action">
            <v-btn
                color="#3bafda"
                width="100%"
                :disabled="isSubmitting"
                @click="getLogin"
            >
                <div class="btn-content">
                    <i class="icon icon-unlock"></i>
                    <span>Login</span>
                </div>
            </v-btn>
        </div>
    </div>
</template>

<script>
import styles from './login-form.module.scss'
import { mapGetters, mapActions } from 'vuex'
import { actionTypes } from '@/store/modules/auth'

export default {
    name: 'LoginForm',

    data() {
        return {
            user: '',
            dialog: false,
            styles,
            login: '',
            password: '',
            passwordIsShow: false,
            error: false,
            valid: {
                login: () => true || 'login',
                password: () => true || 'Min 4 characters',
            },
        }
    },
    computed: {
        ...mapGetters('auth', { isSubmitting: 'isSubmitting' }),
    },
    methods: {
        ...mapActions('auth', { toLogin: actionTypes.login }),
        getLogin: function () {
            this.toLogin({ login: this.login, password: this.password })
                .then(() => {
                    this.$router.replace({ name: 'Home' })
                })
                .catch(() => {
                    this.error = true
                })
        },
    },
}
</script>

<style lang="scss" scoped>
.btn-content {
    color: $color-primary-light;
    font-weight: 500;
    text-transform: none;
    font-size: 16px;
}

.login-error {
    height: 0;
    overflow: hidden;
    transition: height 250ms ease-in-out;

    &.is-active {
        height: 58px;
    }
}
</style>
