import { mapActions, mapGetters } from 'vuex'
import { actionTypes, getterTypes } from '@/store/modules/confirmer'

export default {
    computed: {
        ...mapGetters('confirmer', {
            modalIsActive: getterTypes.getStatus,
            getMessage: getterTypes.getMessage,
        }),
    },
    methods: {
        ...mapActions('confirmer', {
            onAgree: actionTypes.onAgree,
            onCancel: actionTypes.onCancel,
        }),
        agreeHandle: function () {
            this.onAgree()
        },
        cancelHandle: function () {
            this.onCancel()
        },
    },
}
