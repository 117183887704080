<template>
    <div class="logo">
        <div class="logo__img-bx">
            <img
                src="@/assets/common/logo-light-sm.png"
                alt="logo"
                class="logo__img"
            />
        </div>
        <div class="logo__title">YAFI KAMINER</div>
    </div>
</template>

<script>
export default {
    name: 'LogoPrimary',
}
</script>

<style lang="scss" scoped>
.logo {
    display: flex;
    padding: 15px 12px;
    align-items: center;
    width: fit-content;
    min-width: 220px;

    &__img {
        width: 100%;
    }

    &__img-bx {
        width: 36px;
        height: 36px;
        margin-right: 13px;
    }

    &__title {
        color: $color-for-logo-primary;
        font-weight: 500;
        font-size: 20px;
        padding-top: 2px;
    }
}

.rtl {
    .logo {
        &__img-bx {
            margin-right: 0;
            margin-left: 13px;
        }
    }
}
</style>
