import { parseISO, addMonths, format, addDays, subDays } from 'date-fns'

const calendarRangeHandler = (range) => {
    range = range.replace(/^([0-9]{4})(-)([0-9]{1})$/, '$1$20$3')

    const prevMonth = format(subDays(parseISO(range), 5), 'yyyy-MM-dd')
    const nextMonth = format(
        addDays(addMonths(parseISO(range), 1), 5),
        'yyyy-MM-dd'
    )
    let arr = [prevMonth, range, nextMonth]

    const dateFrom = arr[0]
    const dateTo = arr[arr.length - 1]

    return { dateFrom, dateTo }
}

export default calendarRangeHandler
