export default {
    properties: 'Properties',
    none: 'none',
    full: 'Full',
    fall: 'Fall',
    kipaFall: 'Kipa Fall',
    handMade: 'Hand Made',
    wigType: 'Wig Type',
    capSize: 'Cap Size',
    capAdjustment: 'Cap Adjustment',
    topType: 'Top Type',
    regularSkin: 'Regular Skin',
    laceTop: 'Lace Top',
    ribben: 'Ribben',
    topTypeComments: 'Lace Top Comment',
    laceTopComment: 'Lace Top Comment',
    ribbenComment: 'Ribben Comment',
    weftTopInCircle: 'Weft Top In Circle',
    skinHeights: 'Skin Heights',
    regular: 'Regular',
    american: 'American',
    fine: 'Fine',
    curly: 'Curly',
    body: 'Body',
    straight: 'Straight',
    wavy: 'Wavy',
    weftTopLikeFall: 'Weft Top Like Fall',
    flat: 'flat',
    withHeights: 'with Heights',
    front: 'Front',
    frontComments: 'Front comment',
    closedLace: 'Closed Lace',
    thin: 'Thin',
    height: 'Height',
    deepClosed: 'Deep Closed',
    openLace: 'Open Lace',
    handTie: 'Hand Tie',
    bangs: 'Bangs',
    laceType: 'Lace Type',
    baseColor: 'Base Color',
    skinColor: 'Skin Color',
    highlightsColor: 'Highlights Color',
    highlightsColorSelect: 'Highlights Color Select',
    highlightsColoringSelect: 'Highlights Coloring Select',
    highlightsColoring: 'Highlights Coloring',
    honey: 'Honey',
    ash: 'Ash',
    typeOfHighlights: 'Type Of Highlights',
    halfBlonde: 'Half Blonde',
    gentleMixed: 'Gentle Mixed',
    specialYafiOrder: "Special Yafi's order",
    specialYafiOrders: "Special Yafi's order",
    skinTopHighlights: 'Skin Top Highlights',
    non: 'None',
    onTheBottom: 'On The Bottom',
    fromTheRoot: 'From The Root',
    blended: 'Blended',
    streaky: 'Streaky',
    frontColor: 'Front Color',
    withHighlights: 'With Highlights',
    nonHighlights: 'Non highlights',
    colorComments: 'Color Comment',
    length: 'Length',
    lengthFromNape: 'Length From Nape',
    lengthFromTop: 'Length From Top',
    sideLength: 'Side Length',
    lengthComments: 'Length Comment',
    hairTextureComments: 'Hair Texture Comments',
    hairTextureOnWefts: 'Hair Texture On Wefts',
    straightWithBody: 'Straight With Body',
    veryLightWave: 'Very Light Wave',
    lightWave: 'Light Wave',
    lightWavePlus: 'Light Wave Plus',
    lightWithStrongWave: 'Light Wave Strong Wave',
    strongWave: 'Strong Wave',
    hairTextureComment: 'Hair Texture Comment',
    wigFullness: 'Wig Fullness',
    standard: 'Standard',
    fullAdditionalCharge: 'Full (additional charge)',
    wigFullnessAdditionalAmount: 'Wig Fullness Additional Amount',
    wigFullnessComments: 'Wig Fullness Comment',
    weight: 'Weight',
    lengthUsed: 'Length Used',
    complete_data: 'Complete date',
    createdAt: 'Created At',
}
