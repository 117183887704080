<template>
    <v-dialog v-model="isActive" max-width="500" @click:outside="closeHandle">
        <div class="common-modal">
            <div class="common-modal__head">
                <div class="common-modal__close-btn">
                    <v-btn icon color="#55595c" @click="closeHandle">
                        <v-icon>mdi-close-circle-outline</v-icon>
                    </v-btn>
                </div>
            </div>
            <div class="common-modal__body">
                <template v-if="componentIsReady">
                    <component :is="component"></component>
                </template>
            </div>
        </div>
    </v-dialog>
</template>

<script>
import popUpTypes from '@/types/pop-up-types'
import modalsMixin from '@/mixins/modals.mixin'

const { commonModal } = popUpTypes
export default {
    name: 'CommonModal',
    mixins: [modalsMixin],
    emits: ['close-handle'],
    data() {
        return {
            selfName: commonModal,
        }
    },
    computed: {
        componentIsReady: function () {
            return !!this.extraData?.component
        },
        component: function () {
            return this.extraData.component
        },
    },
    methods: {},
}
</script>

<style lang="scss">
.common-modal {
    background: #fff;

    &__head {
        width: 100%;
        position: relative;
        height: 16px;
    }
    &__body {
        padding: 16px;
    }
    &__close-btn {
        position: absolute;
        right: 4px;
        top: 4px;
    }
}
</style>
