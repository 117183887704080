/*
const additionalProperties = [
    {
        id: 5,
        tittle: 'test stage 1',
        comment: 'comment',
        category: null,
        done: true,
        done_at: '2022-06-10 12:10:00',
        done_by: 'Admin',
    },
    {
        id: 6,
        tittle: 'test stage 2',
        comment: 'comment',
        category: null,
        done: true,
        done_at: '2022-06-10 12:27:38',
        done_by: 'Admin',
    },
    {
        id: 7,
        tittle: 'test stage 3',
        comment: 'comment',
        category: null,
        done: true,
        done_at: '2022-06-10 20:54:57',
        done_by: 'Admin',
    },
    {
        id: 8,
        tittle: 'test stage 4',
        comment: 'test stage 4 comment',
        category: null,
        done: true,
        done_at: '2022-06-10 15:52:23',
        done_by: 'Admin',
    },
    {
        id: 9,
        tittle: 'test stage 5',
        comment: 'test commenrt',
        category: '2. Supper',
        done: false,
        done_at: null,
        done_by: 'Admin',
    },
]
*/

export default (properties) => {
    let res = {}

    if (!properties) return {}
    properties.forEach((property) => {
        const category = property.category || 'Common'

        const newItem = {
            [property.tittle]: {
                title: '',
                value: property.tittle,
                done: property.done,
                isAdditional: true,
            },
        }

        res = { ...res, [category]: { ...res[category], ...newItem } }
    })
    return res
}
