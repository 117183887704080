import { format } from 'date-fns'
import { FULL_DATE_FORMAT } from '@/consts'


const formatDate = (date, UTC = true, formatType = FULL_DATE_FORMAT ) => {
    try {
        if (!date) return 'N/A'

        if (typeof date === 'number') {
            UTC = false
            return `${format(new Date(date), formatType)}`
        }
        return `${format(new Date(date + (UTC ? 'Z' : '')), formatType)}`
    } catch (e) {
        console.error(e)
        console.log(date)
        return date
    }
}

export default formatDate
