<template>
    <div class="calendar-event-expanded-card">
        <div class="calendar-event-expanded-card__body">
            <p class="calendar-event-expanded-card__time">
                {{ getTime(eventDetails.startAt) }}
            </p>
            <p class="calendar-event-expanded-card__client">
                {{ eventDetails.client.fullName }}
            </p>

            <p
                v-if="shouldShowManager"
                class="calendar-event-expanded-card__manager"
            >
                {{ eventDetails.manager }}
            </p>

            <p v-else class="calendar-event-expanded-card__phone">
                {{ toHebrewPhoneFormat(eventDetails.client.phoneNumber) }}
            </p>

            <p class="calendar-event-expanded-card__desc">
                {{ eventDetails.subject }}
            </p>
        </div>
        <div class="calendar-event-expanded-card__actions">
            <v-btn dark x-small dense color="#3bafda" @click="updateHandle">
                <v-icon x-small>mdi-square-edit-outline</v-icon>
            </v-btn>

            <v-btn
                v-if="eventDetails.wigId || eventDetails.wig?.id"
                dark
                x-small
                dense
                color="#37bc9b"
                @click="goToWigs"
            >
                <v-icon x-small>mdi-archive-arrow-up-outline</v-icon>
            </v-btn>
            <v-btn dark x-small dense color="#f6bb42" @click="goToClient">
                <v-icon x-small>mdi-account-arrow-right</v-icon>
            </v-btn>
            <v-btn dark x-small dense color="#da4453" @click="deleteHandle">
                <v-icon x-small>mdi-delete-forever</v-icon>
            </v-btn>
        </div>
        <div
            v-if="
                eventDetails.previousDates &&
                getUniqueDatesByDate(
                    eventDetails.previousDates,
                    eventDetails.startAt
                )?.length > 0
            "
            class="calendar-event-expanded-card__old-list"
        >
            <CalendarOldDatesList
                :old-dates="
                    getUniqueDatesByDate(
                        eventDetails.previousDates,
                        eventDetails.startAt
                    )
                "
            />
        </div>
    </div>
</template>

<script>
import CalendarOldDatesList from '@/components/calendar/calendar-old-dates-list.vue'
import parseFromInternToHePhoneFormat from '@/helpers/parse-from-intern-to-he-phone-format'
import getUniqueDatesByDate from '@/components/calendar/get-unique-dates-by-date.utils'
import { mapActions } from 'vuex'
import { actionTypes as actionTypesClients } from '@/store/modules/clients'
import wigTransitions from '@/helpers/transitions/wig-transitions'
import { format, parseISO } from 'date-fns'
import { FULL_DATE_FORMAT, TIME_DATE_FORMAT } from '@/consts'

export default {
    name: 'CalendarEventExpandedCard',
    components: { CalendarOldDatesList },
    props: {
        isActive: {
            type: Boolean,
            default: false,
        },
        eventDetails: {
            type: Object,
            default: () => ({}),
        },
    },
    emits: ['update-handle', 'delete-handle'],
    data() {
        return {
            isLoading: true,
        }
    },
    computed: {
        shouldShowManager: function () {
            return (
                this.eventDetails &&
                this.eventDetails.calendar &&
                this.eventDetails.calendar.toLowerCase() === 'us_agent'
            )
        },
    },
    methods: {
        ...mapActions('clients', { getClient: actionTypesClients.getClient }),
        getUniqueDatesByDate,
        toHebrewPhoneFormat: function (phone) {
            return parseFromInternToHePhoneFormat(phone)
        },
        updateHandle: function () {
            this.$emit('update-handle', this.eventDetails)
        },
        goToWigs: function () {
            wigTransitions.moveToWigPageInNewTab(
                this.eventDetails.wigId || this.eventDetails.wig?.id
            )
        },
        goToClient: function () {
            this.getClient(this.eventDetails.client.id)
        },
        deleteHandle: function () {
            this.$emit('delete-handle')
        },
        getTime: function (date) {
            try {
                return format(new Date(date), TIME_DATE_FORMAT)
            } catch (e) {
                return date
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.calendar-event-expanded-card {
    padding: 5px;
    border-radius: 5px;
    background: $color-primary-light;
    width: 100%;
    font-size: 13px;

    p {
        margin: 0;
    }

    &__loader {
        width: fit-content;
        margin: 0 auto 16px;
    }

    &__head {
        display: flex;
        justify-content: flex-end;
    }

    &__time {
        font-weight: 700;
        font-size: 1.1em;
        margin-bottom: 5px;
    }

    &__manager {
        font-weight: 700;
        font-size: 1.1em;
        margin-bottom: 5px;
    }

    &__body {
        padding: 0 0 5px;
        margin-bottom: 5px;
        border-bottom: 1px solid #eceeef;
    }

    &__actions {
        display: flex;
        justify-content: space-evenly;
        padding-bottom: 5px;
    }

    &__old-list {
        margin-top: 5px;
        padding-top: 5px;
        border-top: 1px solid #eceeef;
    }
}
</style>
