const getColorScheme = (calendar) => {
    let color = ''
    let iconColor = ''

    switch (calendar?.toLowerCase() || '') {
        case 'orders':
            color = '#967adc'
            iconColor = '#967adc'
            break
        case 'pick-up new wigs':
            color = '#37bc9b'
            iconColor = '#690E00'
            break
        case 'pick-up repairs':
        case 'repairs':
            color = '#f6bb42'
            iconColor = '#82261A'
            break
        case 'us_agent':
            color = '#b05454'
            iconColor = ''
            break
        default:
            color = 'primary'
            iconColor = 'primary'
            break
    }

    return { color, iconColor }
}

export default getColorScheme
