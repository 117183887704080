<template>
    <v-dialog v-model="modalIsActive" width="500" @click:outside="cancelHandle">
        <div class="edit-user__body">
            <div class="edit-user__head">
                <h3 class="edit-user__title">
                    {{ textContent.edit }} <span>{{ credentials.login }}</span>
                </h3>
                <v-btn icon color="#55595c" @click="cancelHandle">
                    <v-icon>mdi-close-circle-outline</v-icon>
                </v-btn>
            </div>
            <v-divider></v-divider>
            <div class="tabs">
                <v-tabs v-model="tabs" centered>
                    <v-tab
                        v-for="({ title, icon }, index) in textContent.tabsText"
                        :key="index"
                    >
                        <p class="tabs__title">
                            <i :class="icon"></i>
                            <span>{{ title }}</span>
                        </p>
                    </v-tab>
                </v-tabs>

                <v-tabs-items v-model="tabs" right>
                    <v-tab-item>
                        <div class="tabs__body">
                            <v-select
                                v-model="role.id"
                                :items="getRoles"
                                :label="textContent['role']"
                                type="name"
                                dense
                                outlined
                            ></v-select>
                            <template v-if="isManagerPlus()">
                                <p>
                                    {{ $t('pages.users.color') }}
                                </p>
                                <ModalColorList
                                    @color-selected="handleColorSelected"
                                />
                            </template>
                        </div>
                        <v-divider></v-divider>
                        <div class="tabs__actions">
                            <v-btn
                                color="#967adc"
                                dark
                                @click="changeRoleHandle"
                            >
                                {{ textContent.edit }}
                            </v-btn>
                        </div>
                    </v-tab-item>
                    <v-tab-item>
                        <div class="tabs__body">
                            <v-text-field
                                v-model="nickName.value"
                                outlined
                                dense
                                name="login-for-new"
                                :label="textContent['new-nickname']"
                                hint="At least 8 characters"
                                :error-messages="nickName.error"
                                @input="nickName.error = ''"
                            ></v-text-field>
                            <v-text-field
                                v-model="password.new.value"
                                outlined
                                dense
                                :append-icon="
                                    password.new.icon
                                        ? 'mdi-eye'
                                        : 'mdi-eye-off'
                                "
                                :type="password.new.icon ? 'text' : 'password'"
                                name="login-for-new"
                                :label="textContent['new-password']"
                                hint="At least 8 characters"
                                :error-messages="password.new.error"
                                @click:append="
                                    password.new.icon = !password.new.icon
                                "
                                @input="password.new.error = ''"
                            ></v-text-field>
                        </div>
                        <div class="tabs__actions">
                            <v-btn
                                color="#967adc"
                                dark
                                @click="changePasswordHandle"
                            >
                                {{ textContent.edit }}
                            </v-btn>
                        </div>
                    </v-tab-item>
                    <v-tab-item></v-tab-item>
                </v-tabs-items>
            </div>
        </div>
    </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { actionTypes } from '@/store/modules/users'
import { actionTypes as actionTypesModalNotifications } from '@/store/modules/modal-notifications'
import { getterTypes } from '@/store/modules/roles'

import languageMixin from '@/mixins/language.mixin'
import ModalColorList from '@/components/modals/user-modal-add-color'

export default {
    name: 'ModalEditUser',
    components: {
        ModalColorList,
    },
    mixins: [languageMixin],
    props: {
        isActive: {
            type: Boolean,
            default: false,
            required: true,
        },
        credentials: {
            type: Object,
            required: true,
        },
    },
    emits: ['close-modal'],
    data() {
        return {
            tabs: null,
            nickName: {
                value: '',
                error: '',
            },
            password: {
                new: {
                    value: '',
                    icon: false,
                    error: '',
                },
            },
            role: {
                id: null,
            },
            selectedColor: null,
        }
    },
    computed: {
        id: function () {
            return this.credentials.id
        },
        ...mapGetters('roles', { getRoles: getterTypes.getAllRoles }),
        modalIsActive: function () {
            return this.isActive
        },
    },
    watch: {
        id: function () {
            this.role = { id: this.credentials.role.id }
        },
    },
    methods: {
        ...mapActions('users', {
            changeRole: actionTypes.changeRole,
            changeCredentials: actionTypes.changeCredentials,
        }),
        ...mapActions('modalNotifications', {
            success: actionTypesModalNotifications.success,
        }),
        changeRoleHandle: function () {
            let credentials = {
                id: this.credentials.id,
                newRole: this.role.id,
                newColor: null,
            }
            if (this.isManagerPlus()) {
                const hexColor = this.selectedColor
                credentials.newColor = hexColor
            }
            this.changeRole(credentials).then(() => {
                this.cancelHandle()
                this.success('Role is changed')
            })
        },
        changePasswordHandle: function () {
            const credentials = {
                id: this.credentials.id,
                nickName: this.nickName.value,
                password: this.password.new.value,
            }
            this.changeCredentials(credentials)
                .then((data) => {
                    this.cancelHandle()
                    this.showMessage(data)
                })
                .catch(({ password }) => {
                    this.password.new.error = password
                })
        },
        showMessage: function (data) {
            let message = ''

            const type = data
                .map((item) =>
                    item.data.includes('Password')
                        ? 'password'
                        : item.data.includes('Name')
                        ? 'name'
                        : null
                )
                .join('')

            switch (type) {
                case 'password':
                    message = 'Password is changed'
                    break
                case 'name':
                    message = 'Name is changed'
                    break
                case 'passwordname':
                    message = 'Password and Name are changed'
                    break
                case 'namepassword':
                    message = 'Password and Name are changed'
                    break
                default:
                    console.log('Error')
            }
            this.success(message)
        },
        cancelHandle: function () {
            this.$emit('close-modal')
        },
        isManagerPlus: function () {
            return this.role.id === 8
        },
        handleColorSelected(color) {
            this.selectedColor = color
        },
    },
}
</script>

<style scoped lang="scss">
.edit-user {
    &__title {
        font-weight: 400;
        margin-bottom: 15px;

        span {
            font-weight: 700;
        }
    }

    &__body {
        height: fit-content;
        background: $color-primary-light;
        padding: 20px 15px 20px;
        p {
            color: gray;
            margin-bottom: 0;
            font-size: 12px;
        }
    }

    &__head {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__actions {
        padding: 10px 0;
        display: flex;
        justify-content: space-between;
    }
}

.tabs {
    margin: 20px 0 0;

    &__title {
        font-size: 13px;
        text-transform: none;
        display: flex;

        @include for-hebrew {
        }

        span {
            display: none;

            @include tablet-up {
                display: inline;
            }
        }
    }

    &__body {
        margin: 20px 0 0;
    }

    &__actions {
        display: flex;
        justify-content: flex-end;
    }
}
</style>
