<template>
    <v-expansion-panel>
        <v-expansion-panel-header color="#f7f7f9">
            <h4 class="order-property__sub-title">
                7. {{ propertiesContent['hair-texture-on-wefts'] }}
            </h4>
        </v-expansion-panel-header>
        <v-expansion-panel-content color="#f7f7f9">
            <div
                id="order-property--hair-texture--hair-texture-select"
                class="order-property__input-wrapper"
            >
                <v-autocomplete
                    v-model="properties.fields['hairTextureOnWefts']"
                    outlined
                    :items="items"
                    :label="propertiesContent['hair-texture-on-wefts']"
                    dense
                    attach="#order-property--hair-texture--hair-texture-select"
                    :error-messages="properties.errors['hairTextureOnWefts']"
                    @input="properties.errors['hairTextureOnWefts'] = ''"
                >
                </v-autocomplete>

                <v-text-field
                    v-model="properties.fields['hairTextureComments']"
                    light
                    outlined
                    dense
                    type="text"
                    name="hair-texture-comments"
                    :label="propertiesContent['hair-texture-comment']"
                    :error-messages="properties.errors['hairTextureComments']"
                    @input="properties.errors['hairTextureComments'] = ''"
                >
                </v-text-field>
            </div>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
import languagePropertiesMixin from '@/mixins/language-properties.mixin'

export default {
    name: 'OrderFormPropertyHairTexture',
    mixins: [languagePropertiesMixin],
    props: {
        dataProperties: {
            type: Object,
            default: () => ({
                fields: {
                    ['hairTextureOnWefts']: '',
                    ['hairTextureComments']: '',
                },
                errors: {},
            }),
        },
    },
    computed: {
        items: function () {
            return [
                { text: this.propertiesContent['none'], value: 'None' },
                { text: this.propertiesContent['straight'], value: 'Straight' },
                {
                    text: this.propertiesContent['straight-with-body'],
                    value: 'Straight With Body',
                },
                {
                    text: this.propertiesContent['very-light-wave'],
                    value: 'Very Light Wave',
                },
                {
                    text: this.propertiesContent['light-wave'],
                    value: 'Light Wave',
                },
                {
                    text: this.propertiesContent['light-wave-plus'],
                    value: 'Light Wave+',
                },
                {
                    text: this.propertiesContent['light-with-strong-wave'],
                    value: 'Light With Strong Wave',
                },
                {
                    text: this.propertiesContent['strong-wave'],
                    value: 'Strong Wave',
                },
                { text: this.propertiesContent['curly'], value: 'Curly' },
            ]
        },
        properties: function () {
            return this.dataProperties
        },
    },
    methods: {},
}
</script>

<style lang="scss" scoped>
.order-property {
    &__sub-title {
        color: $color-primary-dark;
    }

    &__input-wrapper {
        padding: 15px 15px 0;
    }

    &__inner {
        border: 1px solid #626161;
        padding: 15px;
        margin-bottom: 15px;
    }
}
</style>
