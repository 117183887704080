import axios from '@/api/axios'

export default {
    wigChanges: {
        getWigChanges: (changeId) =>
            axios
                .get(`/base/api/wig-changes/${changeId}`)
                .then((res) => res.data),
    },
}
