<template>
    <v-dialog v-model="dialog" width="500">
        <template #activator="{ attrs }">
            <v-btn
                color="primary"
                dark
                v-bind="attrs"
                @click.stop="dialog = true"
            >
                <i class="icon-plus"></i>
                <span> {{ textContent.btn_add_new_user }} </span>
            </v-btn>
        </template>
        <div class="add-user">
            <div class="add-user__head">
                <h3 class="add-user__title">Add a new user</h3>
                <v-btn icon color="#55595c" @click="dialog = false">
                    <v-icon>mdi-close-circle-outline</v-icon>
                </v-btn>
            </div>
            <div class="add-user__body">
                <v-text-field
                    v-model="login"
                    dense
                    outlined
                    type="text"
                    name="login-for-new"
                    label="Login"
                    :error-messages="errors['login']"
                    @input="errors['login'] = ''"
                ></v-text-field>

                <v-text-field
                    v-model="nickName"
                    dense
                    outlined
                    type="text"
                    name="Email-for-new"
                    label="Nickname"
                    :error-messages="errors['nickName']"
                    @input="errors['nickName'] = ''"
                ></v-text-field>

                <v-text-field
                    v-model="password"
                    outlined
                    dense
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show1 ? 'text' : 'password'"
                    name="password-for-new"
                    label="Password"
                    hint="At least 8 characters"
                    :error-messages="errors['password']"
                    @input="errors['password'] = ''"
                    @click:append="show1 = !show1"
                ></v-text-field>

                <v-select
                    v-model="roleId"
                    :items="getRoles"
                    label="Role"
                    dense
                    outlined
                    :error-messages="errors['role_id']"
                    @change="errors['role_id'] = ''"
                ></v-select>

                <div v-if="isManagerPlus()" class="add-user__color">
                    <p>{{ $t('pages.users.color') }}</p>
                    <ModalColorList @color-selected="handleColorSelected" />
                </div>

                <v-divider></v-divider>

                <div class="add-user__actions">
                    <v-btn small color="grey" dark @click="dialog = false">
                        Close
                    </v-btn>
                    <v-btn small color="primary" dark @click="addUserHandle">
                        Add user
                    </v-btn>
                </div>
            </div>
        </div>
    </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { actionTypes } from '@/store/modules/users'
import { getterTypes } from '@/store/modules/roles'
import { actionTypes as actionTypesModalNotifications } from '@/store/modules/modal-notifications'

import languageMixin from '@/mixins/language.mixin'
import ModalColorList from '@/components/modals/user-modal-add-color'

export default {
    name: 'ModalAddUser',
    components: {
        ModalColorList,
    },
    mixins: [languageMixin],

    data() {
        return {
            dialog: false,
            show1: false,
            login: '',
            nickName: '',
            password: '',
            roleId: 1,
            selectedColor: null,
            errors: {
                login: '',
                nickName: '',
                password: '',
                ['role_id']: '',
            },
        }
    },
    computed: {
        ...mapGetters('roles', { getRoles: getterTypes.getAllRoles }),
        iconType: function () {
            return this.isRtl ? 'prepend-inner-icon' : 'append-icon'
        },
    },
    methods: {
        ...mapActions('users', { addUser: actionTypes.addNewUser }),
        ...mapActions('modalNotifications', {
            success: actionTypesModalNotifications.success,
        }),
        addUserHandle: function () {
            let newUser = {
                login: this.login,
                nickName: this.nickName,
                password: this.password,
                ['role_id']: this.roleId,
            }
            if (this.isManagerPlus()) {
                const hexColor = this.selectedColor
                newUser = {
                    ...newUser,
                    hex_color: hexColor,
                }
            }
            this.addUser(newUser)
                .then(() => {
                    this.dialog = false
                    this.success('User created')
                })
                .catch((errors) => {
                    this.errors = { ...this.errors, ...errors }
                })
        },
        isManagerPlus: function () {
            return this.roleId === 8
        },
        handleColorSelected(color) {
            this.selectedColor = color
        },
    },
}
</script>

<style scoped lang="scss">
.add-user {
    background: $color-primary-light;

    &__head {
        margin-bottom: 15px;
        padding: 20px 15px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__title {
    }

    &__body {
        height: fit-content;
        padding: 0 15px 0;
    }

    &__actions {
        padding: 10px 0;
        display: flex;
        justify-content: flex-end;

        button + button {
            margin-left: 15px;
        }
    }
    &__color {
        p {
            color: gray;
            margin-bottom: 0;
            font-size: 12px;
        }
    }
}
</style>
