import pages from './pages'
import shared from './shared'

export default {
    pages,
    shared,
    message: {
        hello: 'test',
    },
}
