<template>
    <v-dialog v-model="isActive" width="500" @click:outside="closeHandle">
        <div class="profile">
            <div class="profile__head">
                <h3 class="profile__title">{{ textContent['edit'] }}</h3>
                <v-btn icon color="#55595c" @click="closeHandle">
                    <v-icon>mdi-close-circle-outline</v-icon>
                </v-btn>
            </div>
            <div class="profile__body">
                <div class="profile__input-wrapper">
                    <v-text-field
                        v-model="nickName"
                        dense
                        outlined
                        type="text"
                        name="barcode-for-new"
                        :label="textContent['new-nickname']"
                    >
                    </v-text-field>
                </div>
                <div class="profile__input-wrapper">
                    <v-text-field
                        v-model="password"
                        dense
                        outlined
                        name="barcode-for-new"
                        :append-icon="
                            passwordIsShow ? 'mdi-eye' : 'mdi-eye-off'
                        "
                        :type="passwordIsShow ? 'text' : 'password'"
                        :label="textContent['new-password']"
                        @click:append="passwordIsShow = !passwordIsShow"
                    >
                    </v-text-field>
                </div>
            </div>
            <div class="profile__foot">
                <div class="profile__actions">
                    <v-btn color="#55595c" dark small @click="closeHandle">
                        {{ textContent['close'] }}
                    </v-btn>
                    <v-btn color="#967adc" dark small @click="onUpdate">
                        {{ textContent['update'] }}
                    </v-btn>
                </div>
            </div>
        </div>
    </v-dialog>
</template>

<script>
import languageMixin from '@/mixins/language.mixin'
import modalsMixin from '@/mixins/modals.mixin'
import popUpTypes from '@/types/pop-up-types'
import { mapActions, mapGetters } from 'vuex'
import {
    actionTypes as actionTypesAuth,
    gettersTypes,
} from '@/store/modules/auth'
import { actionTypes } from '@/store/modules/users'
import { actionTypes as actionTypesModalNotifications } from '@/store/modules/modal-notifications'

const { userProfileEdit } = popUpTypes

export default {
    name: 'UsersModalEditProfile',
    mixins: [modalsMixin, languageMixin],
    data() {
        return {
            selfName: userProfileEdit,
            nickName: '',
            password: '',
            passwordIsShow: false,
        }
    },
    computed: {
        ...mapGetters('auth', {
            userName: gettersTypes.getName,
            userId: gettersTypes.getId,
        }),
        textContent: function () {
            return this.getTextForSelected(this.lang, 'ModalEditUser') || ''
        },
    },
    watch: {
        userName(newName) {
            if (!newName) return null
            this.nickName = newName
        },
    },
    mounted() {
        this.nickName = this.userName
    },
    methods: {
        ...mapActions('users', {
            changeCredentials: actionTypes.changeCredentials,
        }),
        ...mapActions('modalNotifications', {
            success: actionTypesModalNotifications.success,
        }),
        ...mapActions('auth', {
            getCurrentUser: actionTypesAuth.getCurrentUser,
        }),
        onUpdate() {
            const credentials = {
                id: this.userId,
                nickName: this.nickName,
                password: this.password,
            }
            this.changeCredentials(credentials)
                .then(() => {
                    this.closeHandle()
                    this.success('Your credentials has been updated')
                    this.getCurrentUser()
                })
                .catch(() => {})
        },
    },
}
</script>

<style lang="scss">
.profile {
    background: #fff;

    &__head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5px 15px 10px;
    }

    &__body {
        padding: 0 15px;
    }

    &__foot {
        padding: 0 15px 10px;
    }

    &__actions {
        display: flex;
        justify-content: space-between;
    }
}
</style>
