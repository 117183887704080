import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import '@fortawesome/fontawesome-free/css/all.css'

Vue.use(Vuetify)

const opts = {
    icons: {
        iconfont: 'mdiSvg' || 'fa',
    },
    customVariables: ['~@/styles/variables.scss'],
    treeShake: true,
}

export default new Vuetify(opts)
