const state = () => ({
    success: {
        isActive: false,
        text: '',
        type: '',
    },
    error: {
        isActive: false,
        text: '',
    },
})

export const getterTypes = {
    getSuccessStatus: '[modal-notification] get success status',
    getErrorStatus: '[modal-notification] get error status',
}

export const mutationTypes = {
    success: '[modal-notification] get success is active',
    warning: '[modal-notification] get warning is active',
    error: '[modal-notification] get error is active',
    close: '[modal-notification] close all',
}

export const actionTypes = {
    success: '[modal-notification] successfully',
    warning: '[modal-notification] get warning',
    error: '[modal-notification] not successfully',
}

const getters = {
    [getterTypes.getSuccessStatus]: (state) => state.success,
    [getterTypes.getErrorStatus]: (state) => state.error,
}

const mutations = {
    [mutationTypes.success](state, text) {
        state.success.isActive = true
        state.success.text = text
        state.success.type = 'success'
    },
    [mutationTypes.warning](state, text) {
        state.success.isActive = true
        state.success.text = text
        state.success.type = 'warning'
    },
    [mutationTypes.error](state, text) {
        state.success.isActive = true
        state.success.text = text || ''
        state.success.type = 'error'
    },
    [mutationTypes.close](state) {
        state.success.isActive = false
        state.error.isActive = false
        state.success.text = ''
    },
}

const actions = {
    [actionTypes.success]({ commit }, text) {
        commit(mutationTypes.success, text)
    },
    [actionTypes.warning]({ commit }, text) {
        commit(mutationTypes.warning, text)
    },
    [actionTypes.error]({ commit }, text) {
        commit(mutationTypes.error, text)
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
