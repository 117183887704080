<template>
    <div class="orders-gallery">
        <OrderModalWebcam
            :is-active-data="modals.webcam.isActive"
            @close-handle="webcamModalClose"
            @on-capture="onCaptureHandle"
        />
        <div
            v-if="
                getAllowed(['ADMIN', 'manager', 'usa_manager']) &&
                state !== 'Archived'
            "
            class="orders-gallery__header"
        >
            <div class="orders-gallery__actions">
                <input
                    ref="input"
                    class="orders-gallery__file-input"
                    type="file"
                    multiple
                    @input="changeFilesHandle"
                />
                <v-btn
                    color="#967adc"
                    dark
                    :x-small="!isMobile"
                    :small="isMobile"
                    @click="openUpload"
                >
                    {{ textContent['upload-photo'] }}
                </v-btn>
                <v-btn
                    color="#967adc"
                    dark
                    :x-small="!isMobile"
                    :small="isMobile"
                    @click="webcamModalOpen"
                >
                    {{ textContent['take-a-photo'] }}
                </v-btn>
            </div>
        </div>
        <ul class="orders-gallery__thumbs-list">
            <li
                v-for="{ url, id, created_at, created_by } in files"
                :key="id"
                class="orders-gallery__item"
            >
                <a target="_blank" :href="url">
                    <div class="orders-gallery__img-bx">
                        <img :src="url" class="orders-gallery__img" :alt="id" />
                    </div>

                    <ul class="orders-gallery__img-info">
                        <li>
                            <p>
                                <DateCell :value="created_at" />
                            </p>
                        </li>
                        <li>
                            <p>{{ created_by || 'N/A' }}</p>
                        </li>
                    </ul>
                </a>
                <v-btn
                    v-if="
                        getAllowed([
                            'ADMIN',
                            roleUsaManagerAfterStartProduction,
                            'manager',
                        ]) && state !== 'Archived'
                    "
                    class="orders-gallery__btn-remove"
                    color="#da4453"
                    dark
                    x-small
                    @click="() => removeHandle(id)"
                >
                    <v-icon x-small dark>mdi-close</v-icon>
                </v-btn>
            </li>
        </ul>
    </div>
</template>

<script>
import OrderModalWebcam from '@/components/modals/order-modal-webcam'

import { mapActions } from 'vuex'
import { actionTypes } from '@/store/modules/orders'
import { actionTypes as actionTypesModalNotifications } from '@/store/modules/modal-notifications'
import { actionTypes as actionTypesConfirmer } from '@/store/modules/confirmer'
import languageMixin from '@/mixins/language.mixin'
import convertBase64ToFile from '@/helpers/convert-base64-to-file'
import formatDate from '@/helpers/format-date'
import DateCell from '@/components/common/cells/date-cell.vue'

export default {
    name: 'OrdersGallery',
    components: {
        DateCell,
        OrderModalWebcam,
    },
    mixins: [languageMixin],
    props: {
        files: {
            type: Array,
            default: () => [],
        },
        state: {
            type: String,
            default: 'Draft',
        },
    },
    data() {
        return {
            newFiles: [],
            modals: {
                webcam: {
                    isActive: false,
                },
            },
        }
    },
    computed: {
        isMobile: function () {
            return this.$resize && !this.$mq.above(1023)
        },
        roleUsaManagerAfterStartProduction: function () {
            return this.state.toLowerCase() === 'draft' ? 'usa_manager' : ''
        },
    },
    methods: {
        ...mapActions('orders', {
            addNewPhoto: actionTypes.addNewPhoto,
            deletePhoto: actionTypes.deletePhoto,
        }),
        ...mapActions('modalNotifications', {
            success: actionTypesModalNotifications.success,
        }),
        ...mapActions('confirmer', {
            confrimHandle: actionTypesConfirmer.confirmHandle,
        }),
        webcamModalOpen: function () {
            this.modals.webcam.isActive = true
        },
        webcamModalClose: function () {
            this.modals.webcam.isActive = false
        },
        removeHandle: async function (id) {
            this.confrimHandle(this.textContent['photo-confirm-deleted']).then(
                () => {
                    this.deletePhoto(id).then(() => {
                        this.success(this.textContent['photo-success-deleted'])
                    })
                }
            )
        },
        changeFilesHandle: function (e) {
            const files = e?.target?.files

            if (!files) return
            Object.values(files).forEach((item) => {
                this.addNewPhoto(item).then(() => {
                    this.success(this.textContent['photo-success-added'])
                })
            })
        },
        openUpload: function () {
            this.$refs.input.value = ''
            this.$refs.input.click()
        },
        onCaptureHandle: function (file) {
            this.webcamModalClose()
            this.addNewPhoto(convertBase64ToFile(file)).then(() => {
                this.success(this.textContent['photo-success-added'])
            })
        },
        dateInFormat: function (date) {
            return formatDate(date)
        },
    },
}
</script>

<style lang="scss" scoped>
.orders-gallery {
    padding: 16px;
    border-radius: 4px;
    background: #f7f7f9;
    max-width: 100%;
    margin-top: 16px;
    position: relative;

    &:before {
        content: '';
        position: absolute;
        top: -16px;
        left: 0;
        height: 1px;
        width: 100%;
        background: linear-gradient(to right, transparent 50%, #fff 50%),
            linear-gradient(to right, #b2b2b2, #b2b2b2);
        background-size: 4px 2px, 100% 2px;
    }

    &__header {
    }

    &__actions {
        display: flex;
        justify-content: space-between;

        button + button {
            margin-left: 10px;
        }

        margin-bottom: 25px;
    }

    &__thumbs-list {
        display: grid;
        grid-template-columns: 1fr;
        gap: 16px;
        padding: 0;

        @include mobile-sm-up {
            grid-template-columns: repeat(2, 1fr);
        }

        @include tablet-up {
            grid-template-columns: repeat(2, 1fr);
        }

        @include desktop-xl-up {
            grid-template-columns: repeat(3, 1fr);
        }
        flex-wrap: wrap;
    }

    &__item {
        position: relative;
        min-width: fit-content;
        height: 100%;
        display: flex;
        align-items: center;
    }

    &__img-bx {
        width: 100%;
        overflow: hidden;
        border-radius: 5px;
        cursor: pointer;
    }

    &__img {
        width: 100%;
    }

    &__img-info {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        background: #fff;
        overflow: hidden;
        border-radius: 5px;
        padding: 4px 0;

        li {
            display: flex;
            justify-content: space-between;
            font-size: 11px;
            color: #000;
            flex-direction: column;
            padding: 0 8px;
            margin: 0;
        }

        p {
            padding: 0;
            margin: 0;
        }
    }

    &__btn-remove {
        position: absolute;
        right: 0;
        top: 5px;

        @include tablet-up {
            right: -10px;
        }
    }

    &__file-input {
        display: none;
    }
}
</style>
