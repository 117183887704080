import axios from '@/api/axios'

export default {
    dashboard: {
        dashboard: (credentials) =>
            axios
                .get('/base/api/dashboard/index', {
                    ...credentials,
                })
                .then((res) => res.data),
    },
}
