import { mapGetters } from 'vuex'
import { getterTypes } from '@/store/modules/configuration'
import { getterTypes as getterTypesLanguagePack } from '@/store/modules/language-pack'

export default {
    data() {
        return {
            contentType: this.$options.name,
        }
    },
    computed: {
        ...mapGetters('configuration', { lang: getterTypes.getLanguageShort }),
        ...mapGetters('languagePack', {
            getTextForSelected: getterTypesLanguagePack.getContent,
        }),
        textContent: function () {
            return this.getTextForSelected(this.lang, this.contentType) || ''
        },
        isRtl: function () {
            return this.lang === 'he'
        },
    },
}
