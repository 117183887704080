export const getterTypesJewishCalendar = {
    getJewishDates: '[jewish-calendar] Get all date for jewish calendar',
}

export const mutationTypesJewishCalendar = {
    addJewishDates: '[jewish-calendar] Add all Jewish dates',
}

export const actionTypesJewishCalendar = {
    loadAll: '[jewish-calendar] Load all jewish dates',
}
