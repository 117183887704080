import VueI18n from 'vue-i18n'
import localization from '../localization'
import Vue from 'vue'

Vue.use(VueI18n)
export const i18n = new VueI18n({
    locale: 'en',
    messages: localization,
    missing: (locale, key, vm) => {
        // Customize the message here
        return `Translation not found for key '${key}' in locale '${locale}'`
    },
})
