import api from '@/api/logs-v2'
export const getUserEnvMetric = async (userName) => {
    try {
        if (!userName) return
        const windowSize = window?.screen?.width || 0
        const system = navigator.userAgent

        const metric = { windowSize, system }
        const body = {
            title: 'main metric',
            desc: JSON.stringify(metric),
            user: userName,
        }
        // await api.logsV2.addLog(body)
    } catch (e) {
        console.warn('Server not defined')
    }
}
