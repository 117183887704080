const state = () => ({
    isActive: false,
})

export const getterTypes = {
    getIsActive: '[modal-loader-progress] Get state about active',
}

export const mutationTypes = {
    startLoading: '[modal-loader-progress] Start loading',
    setProgress: '[modal-loader-progress] Change state of progress',
    endLoading: '[modal-loader-progress] Finish loading',
}

const getters = {
    [getterTypes.getIsActive]: ({ isActive }) => isActive,
}

const mutations = {
    [mutationTypes.startLoading](state) {
        state.isActive = true
    },
    [mutationTypes.endLoading](state) {
        state.isActive = false
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
}
