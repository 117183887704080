import isJson from '@/helpers/isJson'

export const propertiesTemplates = {
    baseColor: [
        {
            label: 'צבע בסיס',
            key: 'baseColor',
        },
        {
            label: 'צבע סקין',
            key: 'skinColor',
        },
        {
            label: 'צבע גוונים',
            key: 'highlightsColoringSelect',
        },
        {
            label: 'צבע גוונים',
            key: 'highlightsColoring',
        },
        {
            label: 'סוג גוונים',
            key: 'typeOfHighlights',
        },
        {
            label: 'גוונים בסקין',
            key: 'skinTopHighlights',
        },
        {
            label: 'צבע פרונט',
            key: 'frontColor',
        },
        {
            label: 'עם גוונים',
            key: 'withHighlights',
        },
        {
            label: 'צבע גוונים',
            key: 'highlightsColor',
        },
        {
            label: 'צבע גוונים',
            key: 'highlightsColorSelect',
        },
        {
            label: 'הערות צבע',
            key: 'colorComments',
        },
    ],
    length: [
        {
            label: 'אורך',
            key: 'length',
        },
        {
            label: 'אורך מהעורף',
            key: 'lengthFromNape',
        },
        {
            label: 'אורך מהכיפה',
            key: 'lengthFromTop',
        },
        {
            label: 'אורך בצדדים',
            key: 'sideLength',
        },
        {
            label: 'הערות לאורך',
            key: 'lengthComments',
        },
    ],
    heirTexture: [
        {
            label: 'סוג שיער טרסים',
            key: 'hairTextureOnWefts',
        },
        {
            label: 'הערות סוג שיער',
            key: 'hairTextureComments',
        },
    ],
    wigFullness: [
        {
            label: 'מלאות הפאה',
            key: 'wigFullness',
        },
        {
            label: 'תוספת סך',
            key: 'wigFullnessAdditionalAmount',
        },
        {
            label: 'הערות למלאות הפאה',
            key: 'wigFullnessComments',
        },
    ],
    front: [
        {
            label: 'לייס סגור',
            key: 'Closed Lace',
        },
        {
            label: 'לייס פתוח',
            key: 'Open Lace',
        },
        {
            label: 'עבודת יד',
            key: 'Hand Tie',
        },
        {
            label: 'הערות לפרונט',
            key: 'frontComments',
        },
    ],
    capSize: [
        {
            label: 'גודל רשת',
            key: 'capSize',
        },
        {
            label: 'התאמת רשת',
            key: 'capAdjustment',
        },
    ],
    topTape: [
        {
            label: 'סקין רגיל',
            key: 'Regular Skin',
        },
        {
            label: 'לייס טופ',
            key: 'Lace Top',
        },
        {
            label: 'ריבן',
            key: 'Ribben',
        },
        {
            label: 'שבלול',
            key: 'Weft Top In Circle',
        },
        {
            label: 'גובה הסקין',
            key: 'Skin Heights',
        },
        {
            label: 'הערות סוג כיפה',
            key: 'topTypeComments',
        },
    ],
}

export const provideTopTapeProperty = (wigProperties) => {
    let res = wigProperties

    Object.keys(wigProperties).forEach((key) => {
        if (isJson(wigProperties[key])) {
            const [subKey, value] = JSON.parse(wigProperties[key])
            res = { ...res, [subKey]: value }
        }
    })

    return res
}

const preparePropertiesListByTemplate = (wigProperties, template) => {
    wigProperties = provideTopTapeProperty(wigProperties)
    return propertiesTemplates[template]
        .map((field) => {
            return {
                label: field.label,
                value: wigProperties[field.key],
                key: field.key,
            }
        })
        .filter(({ value, key }) => {
            if (!wigProperties.topType) {
                return !!value
            }
            const [property] = JSON.parse(wigProperties.topType)
            return (property === 'Ribben' && key === 'Ribben') || !!value
        })
}

export default preparePropertiesListByTemplate
