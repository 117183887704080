<template>
    <div class="event-name">
        <div class="event-name__time-container">
            <p v-if="!isHebrew" class="event-name__time">{{ time }}</p>

            <div
                v-if="checkDisplayManager(!isMonth)"
                class="event-name__manager"
                :style="{
                    backgroundColor: event.text.meetingManager.hex_color,
                }"
            >
                {{ getFirstLetter(event.text.meetingManager.nickName) }}
            </div>
        </div>

        <a
            v-if="event.text.link"
            :href="event.text.link"
            target="_blank"
            class="event-name__text is-hebrew"
        >
            {{ isHebrew ? event.text.text : event.text.client.fullName }}
        </a>

        <div
            v-else
            class="event-name__text"
            :class="{ ['is-hebrew']: isHebrew }"
        >
            <p>
                {{ isHebrew ? event.text.text : event.text.client.fullName }}
            </p>
            <p v-if="isDay || isWeek">
                <template v-if="isDay">
                    <p class="event-name__phone">
                        {{ toHebrewPhoneFormat(event) }}<br />
                    </p>
                </template>
                {{ event.text.subject }}
            </p>
        </div>

        <div v-if="event.text.wigIsReady" class="event-name__done-icon">
            <v-icon :color="event.text.iconColor" small
                >mdi-check-outline
            </v-icon>
        </div>

        <div
            v-if="event?.text?.calendar?.toLowerCase() === 'us_agent'"
            class="event-name__done-icon event-name__done-icon--append"
        >
            <v-icon v-if="event.isRepairWig" color="white" small>
                mdi-wrench-outline
            </v-icon>

            <v-icon v-else-if="event.isNewWig" color="white" small>
                mdi-new-box
            </v-icon>
        </div>

        <div
            v-if="
                event?.text?.calendar?.toLowerCase() === 'us_agent' &&
                event.manager
            "
            class="event-name__usa-manager-nickname"
        >
            {{ event.manager }}
        </div>

        <div
            v-if="checkDisplayManager(isMonth)"
            class="event-name__manager"
            :style="{ backgroundColor: event.text.meetingManager.hex_color }"
        >
            {{ getFirstLetter(event.text.meetingManager.nickName) }}
        </div>
    </div>
</template>

<script>
import parseFromInternToHePhoneFormat from '@/helpers/parse-from-intern-to-he-phone-format'

export default {
    name: 'CalendarEventName',
    props: {
        event: {
            type: Object,
            default: () => {},
        },
    },
    computed: {
        isDay: function () {
            return this.event.typeOfCalendar === 'day'
        },
        isWeek: function () {
            return this.event.typeOfCalendar === 'week'
        },
        isMonth: function () {
            return this.event.typeOfCalendar === 'month'
        },
        isHebrew: function () {
            return this.hour === '00'
        },
        hour() {
            return this.event.hour.toString().length === 1
                ? `0${this.event.hour}`
                : this.event.hour
        },
        minute() {
            return this.event.minute.toString().length === 1
                ? `${this.event.minute}0`
                : this.event.minute
        },
        time() {
            return `${this.hour}:${this.minute}`
        },
    },
    methods: {
        toHebrewPhoneFormat(event) {
            const phone = event.text?.client?.phoneNumber
            if (!phone) return
            return parseFromInternToHePhoneFormat(phone)
        },
        checkPickUp: function () {
            return (
                this.event?.text?.calendar?.toLowerCase() === 'pick-up new wigs'
            )
        },
        getFirstLetter(managerName) {
            if (!managerName || typeof managerName !== 'string') return ''
            return managerName.charAt(0).toUpperCase()
        },
        checkDisplayManager: function (isMonth) {
            return (
                this.checkPickUp() && this.event.text.meetingManager && isMonth
            )
        },
    },
}
</script>

<style lang="scss" scoped>
.event-name {
    padding: 0 5px;
    display: flex;
    align-items: center;
    height: 100%;
    width: 100%;
    overflow: hidden;
    gap: 10px;
    &__manager {
        border-radius: 100%;
        width: 16px;
        height: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    &__time-container {
        display: flex;
        flex-direction: column;
    }
    &__time {
        height: 100%;
        margin-bottom: 0;
    }

    &__text {
        color: $color-primary-light;
        text-decoration: none;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        &.is-hebrew {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            text-align: center;
            font-size: 1.2em;
            font-weight: 700;
        }

        p {
            padding: 0;
            margin: 0;
        }
    }

    &__phone {
        direction: ltr;
    }

    &__done-icon {
        margin: 0 8px;
    }
}
</style>
