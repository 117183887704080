<template>
    <div class="color-list">
        <div
            v-for="color in colors"
            :key="color"
            class="color-list__item"
            :style="{
                backgroundColor: color,
                borderColor: selectedColor === color ? 'black' : 'transparent',
            }"
            @click="handleColorClick(color)"
        ></div>
    </div>
</template>

<script>
export default {
    name: 'ModalColorList',
    emits: ['color-selected'],
    data() {
        return {
            colors: ['#ff0000', '#ff9f05', '#00C2FF', '#e802b2', '#8F00FF'],
            selectedColor: null,
        }
    },
    methods: {
        handleColorClick(color) {
            this.selectedColor = color
            this.$emit('color-selected', color)
        },
    },
}
</script>

<style lang="scss">
.color-list {
    display: flex;
    gap: 10px;
    margin-bottom: 10px;
    padding: 10px 0;
    &__item {
        width: 25px;
        height: 25px;
        border-radius: 50%;
        border: 2px solid transparent;
        transition: 0.2s ease-in-out;
    }
    &__item:hover {
        cursor: pointer;
        opacity: 0.5;
    }
}
</style>
