<template>
    <div class="orders-rework-tab">
        <div
            v-if="workflowActionsMenuIsActive"
            class="orders-rework-tab__actions"
        >
            <div class="orders-rework-tab__actions-btn">
                <v-menu offset-y>
                    <template #activator="{ on, attrs }">
                        <v-btn
                            :x-small="!isMobile"
                            :small="isMobile"
                            color="primary"
                            dark
                            v-bind="attrs"
                            v-on="on"
                        >
                            {{ $t('pages.wigPage.workflowActionsMenu.header') }}
                        </v-btn>
                    </template>
                    <ul class="orders-rework-tab__actions-menu">
                        <li
                            v-if="!getAllowed(['usa_manager'])"
                            class="orders-rework-tab__actions-menu-item"
                            @click="openAdditionalPropertiesSlideOutUpdate"
                        >
                            {{ $t('pages.wigPage.workflowActionsMenu.update') }}
                        </li>
                        <li
                            v-if="
                                getAllowed(['admin', 'manager']) ||
                                (getAllowed(['usa_manager']) &&
                                    getWig.state === 'Draft')
                            "
                            class="orders-rework-tab__actions-menu-item"
                            @click="openAdditionalPropertiesSlideOutEdit"
                        >
                            {{ $t('pages.wigPage.workflowActionsMenu.edit') }}
                        </li>
                    </ul>
                </v-menu>
            </div>
        </div>
        <div class="orders-rework-tab__body">
            <template v-if="propertiesToUpdate.length > 0">
                <WigPageAdditionalPropertiesByDateList
                    :properties="propertiesToUpdate"
                />
            </template>
            <template v-else>
                <NoRecordsYet />
            </template>
        </div>
        <Transition name="slide-fade" appear mode="out-in">
            <template v-if="activePopUp === 'SlideOut'">
                <SlideOut @on-close="() => openInnerPopUp(null)">
                    <template #default="{ onClose }">
                        <OrdersReworkProperties
                            :rework-properties="propertiesToUpdate"
                            @edit-handle="editHandle"
                            @edit-property-by-id="editPropertyById"
                            @close-handle="onClose"
                        />
                    </template>
                </SlideOut>
            </template>
        </Transition>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import {
    actionTypesOrdersReworkProperties,
    getterTypesOrdersReworkProperties,
} from '@/store/modules/orders-rework-properties/types'
import OrdersReworkProperties from '@/components/orders/orders-rework-properties.vue'
import { getterTypes } from '@/store/modules/orders'
import {
    getterTypes as getterTypesOrdersRepairProperties,
    actionTypes as actionTypesRepairProperties,
} from '@/store/modules/order-repairs-stages'
import SlideOut from '@/components/common/slide-out.vue'
import NoRecordsYet from '@/components/common/no-records-yes.vue'
import loadingDecorator from '@/store/utils/loadingDecorator'
import { mutationTypesSlideOuts } from '@/store/modules/slide-outs/types'
import openSlideOut, {
    slideOutsMapper,
} from '@/store/utils/open-slide-out.utils'
import WigPageAdditionalPropertiesByDateList from '@/features/wig-page/components/wig-page-additional-properties-by-date-list.vue'

export default {
    name: 'OrdersReworkTab',
    components: {
        WigPageAdditionalPropertiesByDateList,
        NoRecordsYet,
        SlideOut,
        OrdersReworkProperties,
    },
    props: {
        isReworkProperties: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            test: 'DATA',
            properties: [],
            activePopUp: null,
        }
    },
    computed: {
        ...mapGetters('ordersReworkProperties', {
            reworkProperties: getterTypesOrdersReworkProperties.getProperties,
        }),
        ...mapGetters('orderRepairsStages', {
            repairProperties:
                getterTypesOrdersRepairProperties.getAdditionalProperties,
        }),
        ...mapGetters('orders', { getWig: getterTypes.getOrder }),
        propertiesToUpdate: function () {
            return this.isReworkProperties
                ? this.reworkProperties
                : this.repairProperties
        },
        workflowActionsMenuIsActive: function () {
            if (this.getAllowed(['usa_manager'])) {
                return this.getWig.state === 'Draft'
            }

            const state = this.getWig?.state?.toLowerCase() || ''
            return state !== 'archived'
        },
    },
    watch: {
        activePopUp: function (value) {
            if (value) {
                document
                    .querySelector('html')
                    .classList.add('overflow-y-hidden-v2')
            } else {
                document
                    .querySelector('html')
                    .classList.remove('overflow-y-hidden-v2')
            }
        },
    },
    methods: {
        ...mapMutations('slideOuts', {
            setSelectedSlideOut: mutationTypesSlideOuts.SET_SELECTED_SLIDE_OUT,
        }),
        ...mapActions('ordersReworkProperties', {
            postProperties: actionTypesOrdersReworkProperties.createProperties,
            deleteProperties:
                actionTypesOrdersReworkProperties.removeProperties,
            checkProperties: actionTypesOrdersReworkProperties.checkProperties,
            uncheckProperties:
                actionTypesOrdersReworkProperties.uncheckProperties,
        }),
        ...mapActions('orderRepairsStages', {
            postPropertiesRepairs: actionTypesRepairProperties.addNewProperties,
            checkPropertiesRepairs: actionTypesRepairProperties.onDone,
            unCheckPropertiesRepairs: actionTypesRepairProperties.onUndone,
        }),
        updateHandle: function (payload) {
            const checkProperties = this.isReworkProperties
                ? this.checkProperties
                : this.checkPropertiesRepairs
            const uncheckProperties = this.isReworkProperties
                ? this.uncheckProperties
                : this.unCheckPropertiesRepairs

            let { forDone, forUnDone } = payload

            if (forDone?.length !== 0)
                loadingDecorator(() =>
                    checkProperties({ wigID: this.getWig.id, ids: forDone })
                )

            if (forUnDone?.length !== 0)
                uncheckProperties({
                    wigID: this.getWig.id,
                    ids: forUnDone,
                })
        },
        editPropertyById: function (propertyId) {
            console.log(propertyId)
        },
        editHandle: function (payload) {
            const postProperties = this.isReworkProperties
                ? this.postProperties
                : this.postPropertiesRepairs

            let { propertiesForRemoving, propertiesForAdding } = payload
            if (
                propertiesForRemoving.length === 0 &&
                propertiesForAdding.length === 0
            )
                return

            propertiesForAdding = propertiesForAdding.map((property) => {
                return {
                    tittle: property.title,
                    category: property.category,
                    comment: property.comment,
                }
            })

            if (propertiesForAdding.length !== 0)
                postProperties({
                    wigID: this.getWig.id,
                    newProperties: propertiesForAdding,
                })

            if (propertiesForRemoving.length !== 0)
                this.deleteProperties({
                    wigID: this.getWig.id,
                    idsForRemoving: propertiesForRemoving,
                })
        },
        openInnerPopUp: function (type) {
            if (type === this.activePopUp) return (this.activePopUp = null)
            this.activePopUp = type
        },
        openAdditionalPropertiesSlideOutUpdate: function () {
            if (this.isReworkProperties) {
                return openSlideOut(
                    slideOutsMapper.ADDITIONAL_PROPERTIES_UPDATE,
                    {
                        isReworkProperties: true,
                    }
                )
            }

            openSlideOut(slideOutsMapper.ADDITIONAL_PROPERTIES_UPDATE, {
                isReworkProperties: false,
            })
        },
        openAdditionalPropertiesSlideOutEdit: function () {
            return openSlideOut(slideOutsMapper.ADDITIONAL_PROPERTIES_EDIT, {
                isReworkProperties: this.isReworkProperties,
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.orders-rework-tab {
    position: relative;
    display: flex;
    flex-direction: column;
    padding-top: 16px;

    &::-webkit-scrollbar {
        width: 0;
    }

    &__pop-up {
        position: absolute;
        z-index: 2;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
        padding: 16px;
        background: #fff;
        opacity: unset;
        transform: translateY(0);
    }

    &__body {
        position: relative;
        z-index: 1;
        margin-top: -16px;

        @include for-hebrew {
            padding-right: 0;
            padding-left: 15px;
        }
    }

    &__actions {
        width: 100%;
        z-index: 100;
        line-height: 0;
        display: flex;
        justify-content: flex-end;
        padding: 0 16px;
        position: sticky;
        top: 16px;

        @include for-hebrew {
            right: auto;
            left: 16px;
        }
    }

    &__actions-btn {
    }

    &__actions-menu {
        background: #fff;
        padding: 0 !important;
    }

    &__actions-menu-item {
        padding: 8px 16px;
        cursor: pointer;

        &:hover {
            background: #967adc;
            color: #fff;
        }

        & + & {
            border-top: 1px dotted #c7c7c7;
        }
    }
}

.slide-fade-enter-active {
    transition: all 450ms ease-out;
}

.slide-fade-leave-active {
    transition: all 450ms cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
    opacity: 0;
}
</style>
