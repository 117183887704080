export default {
    employees: 'עובדות',
    payments: 'תשלומים',
    remainder: 'שולם/מתוך',
    totally: 'סך הכל',
    kind: 'תיקון',
    rowsPerPage: 'שורות בעמוד',
    action: 'הפעולה',
    all: 'הכל',
    any: 'כולם',
    reload: 'טען תוצאות',
    noDataAvailable: 'אין מידע זמין',
    source: 'שיטת תשלום',
    status: 'סטטוס',
    client: 'לקוחה',
    created: 'נוצר ב-',
    employee: 'עובדת',
    card: 'כרטיס אשראי',
    cash: 'מזומן',
    payoutCheck: 'שיק',
    wireTransfer: 'העברה בנקאית',
    reports: 'דו"חות',
    UPDATE_WIG_STATE: 'שינוי סטטוס',
    UPDATE_WIG_STATUS: 'עדכון שלב יצור',
    CREATE_WIG: 'יצירת כרטיס פאה',
    //states
    archived: 'ארכיון',
    allStates: 'כל הסטטוסים',
    draft: 'פאות בהמתנה',
    wigsInProduction: 'פאות בייצור',
    readyNewWig: 'פאות חדשות מוכנות',
    repairWigs: 'פאות תיקונים בעבודה',
    readyRepairWigs: 'פאות מתיקון מוכנות',
    cancelledWigs: 'פאות שבוטלו',
    wigsInStock: 'פאות במלאי',
    storage: 'פאות באחסון',
    deliveredWigs: 'פאות שנלקחו',
    rework: 'עבודה חוזרת',
    wigBuilding: 'בניית פאה',
    sawingWeft: 'ייצור טרסים',
    sawingWeftOnCap: 'הרכבה על הרשת',
    skinMatching: 'התאמת סקין',
    skinsConnect: 'התקנת סקין/לייסטופ',
    frontHandTie: 'עבודת יד - קלאסית',
    firstHairline: 'עבודת יד - עומק לפני טשטוש',
    firstHairlineTracing: 'עבודת יד - עומק לפני טשטוש',
    secondHairline: 'עבודת יד - טשטוש אחרי עומק',
    secondHairlineFinishes: 'עבודת יד - טשטוש אחרי עומק',
    underLaceMaterialSawing: 'תפירת בד מתחת ללייס',
    underLaceMaterialClosing: 'סגירת בד מתחת ללייס',
    highlightsIncludeWashing: 'גוונים',
    specialShampoo: 'חומר',
    washing: 'חפיפה וכיווץ',
    cutAndSet: 'עיצוב מראש',
    productionStart: 'התחלת ייצור',
    productionComplete: 'הייצור הושלם',
    repairComplete: 'התיקון הושלם',
    delivery: 'מסירה',
    newOrder: 'חדשה הזמנה',
    newRepair: 'תיקון חדש',
}
