/**
 * Get an array of unique dates based on the month and day portion of date strings.
 *
 * @param {string[]} dates - An array of date strings.
 * @param {string} selectedDate - A reference date string.
 * @returns {string[]} - An array of unique dates.
 */
const getUniqueDatesByDate = (dates, selectedDate) => {
    const currentDateObj = new Date(selectedDate)
    const currentDateString = `${currentDateObj.getMonth()}-${currentDateObj.getDate()}-${currentDateObj.getHours()}-${currentDateObj.getMinutes()}`
    const uniqueDateIds = new Set()
    uniqueDateIds.add(currentDateString)

    const res = dates.reduce(
        (acc, date) => {
            const currentDateObj = new Date(date)
            const dateId = `${currentDateObj.getMonth()}-${currentDateObj.getDate()}-${currentDateObj.getHours()}-${currentDateObj.getMinutes()}`

            if (!acc.uniqueDateIds.has(dateId)) {
                acc.uniqueDateIds.add(dateId)
                acc.uniqueDates.push(date)
            }

            return acc
        },
        { uniqueDateIds, uniqueDates: [] }
    ).uniqueDates

    return res
}

export default getUniqueDatesByDate
