<template>
    <div class="orders-context-info">
        <div class="orders-context-info__column">
            <h3 class="orders-context-info__title">
                {{ textContent['barcode'] }}
            </h3>
            <p class="orders-context-info__desc">{{ info.barcode }}</p>
        </div>
        <div class="orders-context-info__column">
            <h3 class="orders-context-info__title">
                {{ textContent['status'] }}
                <v-btn
                    v-if="
                        !isRepair &&
                        getAllowed([
                            'admin',
                            'manager',
                            'worker',
                            roleUsaManagerAfterStartProduction,
                        ])
                    "
                    x-small
                    color="#f6bb42"
                    dark
                    @click="openStatusUpdate"
                >
                    <v-icon small> mdi-pencil-outline</v-icon>
                </v-btn>
                <v-btn
                    v-if="
                        isRepair &&
                        getAllowed([
                            'admin',
                            'manager',
                            'worker',
                            roleUsaManagerAfterStartProduction,
                        ])
                    "
                    x-small
                    color="#967adc"
                    dark
                    @click="openStatusUpdate"
                >
                    <v-icon small> mdi-pencil-outline</v-icon>
                </v-btn>
            </h3>
            <p class="orders-context-info__desc">
                {{ status }}
            </p>
        </div>
        <div class="orders-context-info__column">
            <h3 class="orders-context-info__title">
                {{ textContent['deposit'] }}
                <v-btn
                    v-if="
                        getAllowed(['admin', 'manager']) &&
                        info.state !== 'Archived'
                    "
                    x-small
                    dark
                    color="#4caf50"
                    @click="openDepositUpdate"
                >
                    <v-icon x-small dark>mdi-plus</v-icon>
                </v-btn>
            </h3>
            <p class="orders-context-info__desc">{{ sumOfPayments }}</p>
        </div>
        <div class="orders-context-info__column">
            <h3 class="orders-context-info__title">
                {{ textContent['client'] }}
                <v-btn
                    v-if="
                        getAllowed([
                            'ADMIN',
                            'manager',
                            roleUsaManagerAfterStartProduction,
                        ]) && info.state !== 'Archived'
                    "
                    x-small
                    color="#f6bb42"
                    dark
                    @click="clientUpdateHandle"
                >
                    {{ textContent['change'] }}
                </v-btn>
            </h3>
            <p
                class="orders-context-info__desc"
                @click="() => goToClient(info.client_id)"
            >
                {{ info.client }}
            </p>
        </div>

        <div class="orders-context-info__column">
            <h3 class="orders-context-info__title">
                {{ textContent['deposit-history'] }}
            </h3>
            <p class="orders-context-info__desc">
                <OrderDepositHistory />
            </p>
        </div>
        <div class="orders-context-info__column">
            <h3 class="orders-context-info__title">
                {{ textContent['creator'] }}
                <v-btn
                    v-if="
                        getAllowed(['ADMIN', 'manager']) &&
                        info.state !== 'Archived'
                    "
                    x-small
                    dark
                    color="#967adc"
                    @click="openAssignCreator"
                >
                    {{ textContent['assign'] }}
                </v-btn>
            </h3>
            <p class="orders-context-info__desc">{{ info.creator }}</p>
        </div>
        <div class="orders-context-info__column">
            <h3 class="orders-context-info__title">
                {{ textContent['info'] }}
            </h3>
            <p class="orders-context-info__desc">{{ info.info }}</p>
        </div>
        <div class="orders-context-info__column">
            <h3 class="orders-context-info__title">
                {{ textContent['details'] }}
            </h3>
            <p
                class="orders-context-info__desc orders-context-info__desc--details"
            >
                {{ info.details }}
            </p>
        </div>
        <div class="orders-context-info__column">
            <h3 class="orders-context-info__title">
                {{ textContent['total-price'] }}
            </h3>
            <p class="orders-context-info__desc">{{ info.totalPrice }}</p>
        </div>
        <div class="orders-context-info__column">
            <h3 class="orders-context-info__title">
                {{
                    info.is_repair === 1
                        ? propertiesContent['repair-properties']
                        : propertiesContent['properties']
                }}
                <v-btn
                    v-if="
                        getAllowed([
                            'ADMIN',
                            'manager',
                            roleUsaManagerAfterStartProduction,
                        ]) && info.state !== 'Archived'
                    "
                    x-small
                    color="#967adc"
                    dark
                    @click="openPropertiesUpdate"
                >
                    <v-icon small> mdi-pencil-outline</v-icon>
                </v-btn>
            </h3>
            <OrdersPropertiesList
                :data-properties="properties"
                :is-repair="info.is_repair === 1"
            />
        </div>
        <div class="orders-context-info__column">
            <h3 class="orders-context-info__title">
                {{ textContent['created'] }}
            </h3>
            <p
                class="orders-context-info__desc orders-context-info__desc--date"
            >
                {{ dateFormatHandle(info['created_at']) }}
            </p>
        </div>
        <div class="orders-context-info__column">
            <h3 class="orders-context-info__title">
                {{ textContent['updated'] }}
            </h3>
            <p
                class="orders-context-info__desc orders-context-info__desc--date"
            >
                {{ dateFormatHandle(info['updated_at']) }}
            </p>
        </div>
        <div class="orders-context-info__column">
            <h3 class="orders-context-info__title">
                {{ textContent['complete-date'] }}
                <v-icon
                    v-if="
                        getAllowed(['ADMIN', 'manager']) &&
                        info.state !== 'Archived'
                    "
                    class="orders-context-info__icon-btn"
                    color="#3bafda"
                    @click="moveToMeeting"
                >
                    mdi-calendar-month-outline
                </v-icon>
                <v-icon
                    v-else-if="
                        getAllowed(['usa_manager']) && !info['complete_data']
                    "
                    class="orders-context-info__icon-btn"
                    color="rgb(176, 84, 84)"
                    @click="createMeetingForUsa"
                >
                    mdi-calendar-month-outline
                </v-icon>
                <v-icon
                    v-if="
                        getAllowed(['ADMIN', 'manager']) &&
                        info.state === 'Rework'
                    "
                    class="orders-context-info__icon-btn"
                    color="#4caf50"
                    @click="goToMeeting"
                >
                    mdi-transfer-right
                </v-icon>
            </h3>
            <p
                v-if="info.state !== 'Archived'"
                class="orders-context-info__desc orders-context-info__desc--date"
            >
                {{ dateFormatHandle(info['complete_data'], true) }}
            </p>
        </div>
        <div class="orders-context-info__column">
            <h3 class="orders-context-info__title">
                {{ textContent['storage-info'] }}
            </h3>
            <p class="orders-context-info__desc">{{ info.shippingInfo }}</p>
        </div>
    </div>
</template>

<script>
import OrdersPropertiesList from '@/components/orders/orders-properties-list'
import OrderDepositHistory from '@/components/orders/order-deposit-history'

import { mapActions, mapGetters } from 'vuex'
import { getterTypes } from '@/store/modules/payments'
import { actionTypes } from '@/store/modules/clients'
import { actionTypes as actionTypesModals } from '@/store/modules/modals'

import popUpTypes from '@/types/pop-up-types'
import { gettersTypes as getterTypesForAuth } from '@/store/modules/auth'
import languageMixin from '@/mixins/language.mixin'
import regularToSnakeCase from '@/helpers/regular-to-snake-case'
import languageOrderExtraMixin from '@/mixins/language-order-extra.mixin'
import languagePropertiesMixin from '@/mixins/language-properties.mixin'
import {
    getFullDayFirstFull,
    getFullDayFirstFullStraight,
} from '@/helpers/getDateInFormat'

const {
    orderPropertiesUpdate,
    orderStatusUpdate,
    orderDeposit,
    orderAssignCreator,
    calendarContext,
    orderClientUpdate,
    calendarAddForUsa,
    orderAdditionalPropertiesUpdate,
} = popUpTypes

export default {
    name: 'OrdersContextInfo',
    components: {
        OrdersPropertiesList,
        OrderDepositHistory,
    },
    mixins: [languageMixin, languageOrderExtraMixin, languagePropertiesMixin],
    props: {
        info: {
            type: Object,
            default: () => ({
                barcode: '',
                status: '',
                deposit: '',
                client: '',
                depositHistory: '',
                creator: '',
                info: '',
                details: '',
                totalPrice: '',
                properties: '',
                created: '',
                updated: '',
                completeDate: '',
                storageInfo: '',
                storageComment: '',
            }),
        },
    },
    computed: {
        ...mapGetters('payments', {
            payments: getterTypes.getPayments,
            sumOfPayments: getterTypes.getSum,
        }),
        ...mapGetters('auth', { getAllowed: getterTypesForAuth.getAllowed }),
        roleUsaManagerAfterStartProduction: function () {
            return this.info?.state?.toLowerCase() === 'draft'
                ? 'usa_manager'
                : ''
        },
        isProduction: function () {
            return (
                this.info.state &&
                (this.info.state.toLowerCase() === 'wigs in production' ||
                    this.info.state.toLowerCase() === 'rework')
            )
        },
        isRepair: function () {
            return this.info.is_repair === 1
        },
        status: function () {
            if (!this.getAllowed(['admin', 'manager', 'worker']))
                return this.extraContent[regularToSnakeCase(this.info.state)]
            const status = this.isProduction
                ? this.info.currentStatus
                : this.info.state
            return this.extraContent[regularToSnakeCase(status)]
        },
        properties: function () {
            if (!this.info.properties) return {}
            const res = {}
            Object.keys(this.info.properties).forEach((key) => {
                if (this.info.properties[key])
                    res[key] = this.info.properties[key]
            })
            return res
        },
        isnUsaManager: function () {
            return this.getAllowed(['usa_manager'])
        },
    },
    methods: {
        ...mapActions('clients', { getClient: actionTypes.getClient }),
        ...mapActions('modals', { onOpen: actionTypesModals.openPopUp }),
        openPropertiesUpdate: function () {
            if (this.info.is_repair === 1) {
                this.onOpen(orderAdditionalPropertiesUpdate)
            } else {
                this.onOpen(orderPropertiesUpdate)
            }
        },
        openStatusUpdate: function () {
            this.onOpen(orderStatusUpdate)
        },
        openDepositUpdate: function () {
            this.onOpen(orderDeposit)
        },
        openAssignCreator: function () {
            this.onOpen(orderAssignCreator)
        },
        createMeetingForUsa: function () {
            const payload = {
                ['wig_id']: this.info.id,
                ['client_id']: this.info.client_id,
            }
            this.onOpen({ name: calendarAddForUsa, payload })
        },
        moveToMeeting: function () {
            const payload = this.info['complete_data']
                ? {
                      action: 'to-date',
                      selectDate: this.info['complete_data'],
                      type: this.isnUsaManager
                          ? 'usa_agent'
                          : !this.info['is_repair']
                          ? 'pick_up_new'
                          : 'pick_up_repairs',
                  }
                : {
                      action: 'for-wig',
                      wigId: this.info.id,
                      type: this.isnUsaManager
                          ? 'usa_agent'
                          : !this.info['is_repair']
                          ? 'pick_up_new'
                          : 'pick_up_repairs',
                  }
            this.onOpen({ name: calendarContext, payload })
        },
        goToMeeting: function () {
            const payload = {
                action: 'for-wig',
                wigId: this.info.id,
                type: !this.info['is_repair']
                    ? 'pick_up_new'
                    : 'pick_up_repairs',
            }
            this.onOpen({ name: calendarContext, payload })
        },
        goToClient: function (id) {
            this.getClient(id)
        },
        clientUpdateHandle: function () {
            this.onOpen(orderClientUpdate)
        },
        dateFormatHandle: function (date, straight = false) {
            if (straight) return date ? getFullDayFirstFullStraight(date) : ''
            return date ? getFullDayFirstFull(date) : ''
        },
    },
}
</script>

<style scoped lang="scss">
.orders-context-info {
    padding: 20px 0;
    display: flex;
    flex-direction: column;
    width: 100%;

    @include tablet-up {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    &__column {
        width: 100%;
        @include tablet-up {
            width: 45%;
        }
    }

    &__title {
        font-size: 16px;
    }

    &__desc {
        font-size: 14px;

        &--details {
            color: #cc3c3c;
        }

        &--date {
            @include for-hebrew {
                direction: ltr;
                text-align: right;
            }
        }
    }

    &__icon-btn {
        cursor: pointer;
    }
}
</style>
