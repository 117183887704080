import { ordersV2MutationTypes } from '@/store/modules/orders_v2/types'

const mutations = {
    [ordersV2MutationTypes.SET_ORDERS](state, orders) {
        state.orders = orders
    },
    [ordersV2MutationTypes.SET_TOTAL_ITEMS](state, total) {
        state.total = total
    },
    [ordersV2MutationTypes.SET_CURRENT_PAGE](state, page) {
        state.currentPage = page
    },
    [ordersV2MutationTypes.SET_LOADING](state, loading) {
        state.loading = loading
    },
    [ordersV2MutationTypes.SET_USA_MANAGERS](state, managers) {
        state.managers = managers
    },
    [ordersV2MutationTypes.SET_SPECIAL](state, special) {
        state.special = special
    },
    [ordersV2MutationTypes.SET_SORT](state, sort) {
        state.sort = sort
    },
    [ordersV2MutationTypes.SET_ORDERS_STATE](state, newState) {
        state.ordersState = newState
    },
    [ordersV2MutationTypes.SET_SEARCH_QUERY](state, search) {
        state.search = search
    },
    [ordersV2MutationTypes.SET_USA_TOOLS](state, value) {
        state.usaToolsIsEnabled = value
    },
}

export default mutations
