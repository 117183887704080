export const getterTypesOrdersReworkProperties = {
    getProperties: '[orders-rework-properties/getter] get rework properties',
}

export const mutationTypesOrdersReworkProperties = {
    initProperties: '[orders-rework-properties/mutation] init properties',
    createProperties: '[orders-rework-properties/mutation] create properties',
    deleteProperties: '[orders-rework-properties/mutation] delete properties',
    checkProperties: '[orders-rework-properties/mutation] check properties',
    uncheckProperties: '[orders-rework-properties/mutation] uncheck properties',
}

export const actionTypesOrdersReworkProperties = {
    createProperties: '[orders-rework-properties/action] create properties',
    removeProperties: '[orders-rework-properties/action] remove properties',
    updateProperties: '[orders-rework-properties/action] update properties',
    checkProperties: '[orders-rework-properties/action] check properties',
    uncheckProperties: '[orders-rework-properties/action] uncheck properties',
}
