import api from '@/api/order-repairs-stages'
import {
    actionTypesOrdersReworkProperties,
    mutationTypesOrdersReworkProperties,
} from '@/store/modules/orders-rework-properties/types'
import { actionTypes as actionTypesFromOrders } from '@/store/modules/orders'

export default {
    [actionTypesOrdersReworkProperties.createProperties]: async (
        { commit, dispatch },
        { wigID, newProperties }
    ) => {
        const reformatedProperties = newProperties.map((properties) => ({
            ...properties,
            type: 'rework',
        }))

        try {
            const { data: properties } = await api.putNewStage({
                wigID,
                properties: reformatedProperties,
            })
            commit(
                mutationTypesOrdersReworkProperties.createProperties,
                properties
            )
            dispatch(
                `orders/${actionTypesFromOrders.reloadSelectedOrder}`,
                undefined,
                { root: true }
            )
        } catch (err) {
            console.error(err)
        }
    },
    [actionTypesOrdersReworkProperties.removeProperties]: async (
        { commit, dispatch },
        { wigID, idsForRemoving }
    ) => {
        try {
            await Promise.all(
                idsForRemoving.map((propertyID) => {
                    return api.deleteStage({
                        id: wigID,
                        property_id: propertyID,
                    })
                })
            )
            commit(
                mutationTypesOrdersReworkProperties.deleteProperties,
                idsForRemoving
            )
            dispatch(
                `orders/${actionTypesFromOrders.reloadSelectedOrder}`,
                undefined,
                { root: true }
            )
        } catch (e) {
            console.log(e)
        }
    },
    [actionTypesOrdersReworkProperties.checkProperties]: async (
        { commit },
        { wigID, ids }
    ) => {
        try {
            const propertiesData = (await api.doneStage({ wigID, ids: ids }))
                .data
            commit(mutationTypesOrdersReworkProperties.checkProperties, {
                ids,
                propertiesData,
            })
        } catch (e) {
            console.error(e)
        }
    },
    [actionTypesOrdersReworkProperties.uncheckProperties]: async (
        { commit },
        { wigID, ids }
    ) => {
        try {
            await api.unDoneStage({ wigID, ids: ids })
            commit(mutationTypesOrdersReworkProperties.uncheckProperties, ids)
        } catch (e) {
            console.error(e)
        }
    },
}
