import { mapGetters } from 'vuex'
import { gettersTypes as getterTypesForAuth } from '@/store/modules/auth'

export default {
    computed: {
        ...mapGetters('auth', { getAllowed: getterTypesForAuth.getAllowed }),
        isMobile: function () {
            return this.$resize && !this.$mq.above(1023)
        },
    },
}
