<template>
    <v-dialog v-model="isActive" width="400" @click:outside="closeHandle">
        <div class="client-select">
            <div class="client-select__head">
                <v-btn icon color="#55595c" @click="closeHandle">
                    <v-icon>mdi-close-circle-outline</v-icon>
                </v-btn>
            </div>
            <div class="client-select__body">
                <ClientFormSelect @select-handle="onSelect"></ClientFormSelect>
            </div>
            <div class="client-select__foot">
                <div class="client-select__btn">
                    <v-btn
                        min-width="50"
                        color="#967adc"
                        x-small
                        dark
                        data-test-id="shared--confirmer--submit-btn"
                        @click="onConfirm"
                    >
                        {{ textContent['confirm'] }}
                    </v-btn>
                </div>
                <div class="client-select__btn">
                    <v-btn color="#55595c" x-small dark @click="closeHandle">
                        {{ textContent['close'] }}
                    </v-btn>
                </div>
            </div>
        </div>
    </v-dialog>
</template>

<script>
import ClientFormSelect from '@/components/forms/client-form-select'
import modalsMixin from '@/mixins/modals.mixin'
import popUpTypes from '@/types/pop-up-types'
import languageMixin from '@/mixins/language.mixin'
const { clientSelect } = popUpTypes

export default {
    name: 'ClientModalSelect',
    components: { ClientFormSelect },
    mixins: [modalsMixin, languageMixin],
    emits: ['close-modal'],
    data() {
        return { selfName: clientSelect, selectedId: null }
    },
    methods: {
        onSelect: function ({ id }) {
            this.selectedId = id
        },
        onConfirm: function () {
            if (!this.selectedId) {
                alert('ples selec id')
                return
            }
            this.setPayload({ clientId: this.selectedId })
            this.closeHandle()
        },
    },
}
</script>

<style lang="scss" scoped>
.client-select {
    background: #fff;
    padding: 0 15px;
    position: relative;

    &__head {
        padding: 5px 0;

        button {
            position: absolute;
            right: 10px;
            top: 5px;

            @include for-hebrew {
                right: auto;
                left: 10px;
            }
        }
    }

    &__foot {
        display: flex;
        justify-content: space-between;
        padding-bottom: 10px;
    }
}
</style>
