import prepareBeforeTranslation from '@/helpers/prepareBeforeTranslation'
import { i18n } from '@/plugins/i18n'

const getWigStatusByState = (wig) => {
    const state = wig?.state?.toLowerCase() || ''
    const status = wig?.status || ''
    const isRepair = wig.is_repair === 1

    if (
        state !== 'rework' &&
        state !== 'wigs in production' &&
        state !== 'repair wigs'
    ) {
        return ''
    }

    if (state === 'rework') {
        return i18n.t(`shared.wigStates.rework`)
    }

    if (state === 'repair wigs') {
        return i18n.t(`shared.wigStates.repair-wigs`)
    }

    if (status && !isRepair) {
        return i18n.t(`shared.wigStates.${prepareBeforeTranslation(status)}`)
    }

    return status
}

export default getWigStatusByState
