import axios from 'axios'

export default {
    countries: {
        getCountries: () =>
            axios
                .get('https://countriesnow.space/api/v0.1/countries/codes')
                .then((res) => res.data),
        getCities: (country) =>
            axios
                .post('https://countriesnow.space/api/v0.1/countries/cities', {
                    country: country,
                })
                .then((res) => res.data),
        getFlags: () =>
            axios
                .get(
                    'https://countriesnow.space/api/v0.1/countries/flag/unicode'
                )
                .then((res) => res.data.data),
    },
}
