export const getterTypesSlideOuts = {
    GET_SELECTED_SLIDE_OUT: '[slide-outs/getter] get selected component',
    GET_PROPS: '[slide-outs/getter] get props',
}

export const mutationTypesSlideOuts = {
    SET_SELECTED_SLIDE_OUT: '[slide-outs/mutation] set selected component',
}

export const actionTypesSlideOuts = {
    SET_SELECTED_SLIDE_OUT: '[slide-outs/action] set selected component',
}
