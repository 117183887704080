<template>
    <div class="search">
        <p class="search__title">{{ textContent['client'] }}</p>
        <div class="search__input-wrapper">
            <v-autocomplete
                v-model="client"
                :items="clients"
                dense
                outlined
                :label="textContent['client']"
                item-color="#967adc"
                hide-details
                data-test-id="wigs--add-new-wig-form--client-autocomplete"
                hide-no-data
                :loading="clientsIsFetching"
                @change="selectHandle"
                @update:search-input="handleInput"
            >
                <template #append-outer>
                    <v-icon color="#37bc9b" @click="addNewClient"
                        >mdi-plus-thick</v-icon
                    >
                </template>
            </v-autocomplete>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
    actionTypes as actionTypesClients,
    getterTypes as getterTypesClients,
} from '@/store/modules/clients'
import { find } from 'lodash'
import languageMixin from '@/mixins/language.mixin'
import popUpTypes from '@/types/pop-up-types'
import { actionTypes as actionTypesModals } from '@/store/modules/modals'
import {
    clientsV2ActionTypes,
    clientsV2GetterTypes,
} from '@/store/modules/clients_v2/types'

const { clientUpdate } = popUpTypes

export default {
    name: 'ClientFormSelect',
    mixins: [languageMixin],
    props: {
        // eslint-disable-next-line vue/require-default-prop
        selectedId: null,
    },
    emits: ['select-handle'],
    data() {
        return {
            client: {},
            value: {},
            test: '',
            search: '',
            searchQueryTimeout: null,
        }
    },
    computed: {
        ...mapGetters('clientsV2', {
            getAllClientsV2: clientsV2GetterTypes.GET_ITEMS,
            clientsIsFetching: clientsV2GetterTypes.GET_LOADING,
        }),
        ...mapGetters('clients', {
            getAllClients: getterTypesClients.getAllClients,
        }),
        clients: function () {
            return this.getAllClientsV2
                .filter((client) => client.isDeleted === 0)
                .map((client) => ({
                    text: `${client['full_name']} ${client['phone']}`,
                    value: {
                        id: client.id,
                        name: `${client['full_name']} ${client['phone']}`,
                        fullName: client['full_name'],
                    },
                }))
        },
    },
    watch: {
        selectedId: function (id) {
            this.selectClient(id)
        },
        search: function (val) {
            if (val && val !== this.client?.name) {
                clearTimeout(this.searchQueryTimeout)

                this.searchQueryTimeout = setTimeout(() => {
                    this.setSearchQuery(val)
                }, 500)
                return
            }

            if (val === '') {
                this.loadAllClients()
            }
        },
    },
    mounted() {
        if (this.getAllClientsV2.length === 0) {
            this.loadAllClients()
        }

        this.selectClient(this.selectedId)
    },
    methods: {
        ...mapActions('clients', {
            loadAllClients: actionTypesClients.getAllClients,
        }),
        ...mapActions('clientsV2', {
            loadAllClients: clientsV2ActionTypes.FETCH_ITEMS,
            setSearchQuery: clientsV2ActionTypes.SET_SEARCH_QUERY,
        }),
        ...mapActions('modals', { onOpen: actionTypesModals.openPopUp }),
        selectHandle: function () {
            this.$emit('select-handle', {
                name: this.client.name,
                id: this.client.id,
                fullName: this.client.fullName,
            })
        },
        selectClient: async function (id) {
            if (!id) {
                this.client = {}
                return
            }

            if (this.getAllClientsV2.length === 0) {
                await this.loadAllClients()
            }

            const client = find(this.getAllClientsV2, { id })
            this.client = {
                id,
                name: `${client.full_name} ${client.phone}`,
                fullName: client.full_name,
            }
            this.selectHandle()
        },
        addNewClient: async function () {
            try {
                let resolve = null
                let newClient = new Promise((res) => {
                    resolve = res
                })
                await this.onOpen({
                    name: clientUpdate,
                    payload: {
                        withoutOpenClientCard: true,
                        resolve,
                    },
                })

                await this.loadAllClients()
                const clientID = await newClient.then((client) => client.id)
                await this.selectClient(clientID)
            } catch (e) {
                console.error(e)
            }
        },
        handleInput: function (value) {
            this.search = value
        },
    },
}
</script>
<style scoped lang="scss">
.search {
    &__title {
        margin-bottom: 15px;
    }

    &__input-wrapper {
        margin-bottom: 10px;
    }
}
</style>
