<template>
    <div>
        <ul class="print-properties-list">
            <li
                v-for="(field, index) in propertiesList"
                :key="index"
                class="print-properties-list__item"
            >
                <span class="print-properties-list__label">
                    <span v-if="index === 0 && cellTitle">{{ cellTitle }}</span>
                    {{ field.label }}:
                </span>
                {{ translated(field.value) }}
            </li>
        </ul>
    </div>
</template>

<script>
import preparePropertiesListByTemplate from '@/features/wig-page/utils/wigs-details-print-page-proprties-list-templates'
import languagePropertiesMixin from '@/mixins/language-properties.mixin'
import regularToSnakeCase from '@/helpers/regular-to-snake-case'

export default {
    name: 'WigsDetailsPrintPageBaseColorCell',
    mixins: [languagePropertiesMixin],
    props: {
        wigProperties: {
            type: Object,
            default: () => ({}),
        },
        template: {
            type: String,
            default: 'none',
        },
        additionalTitle: {
            type: String,
            default: '',
        },
    },
    computed: {
        propertiesList: function () {
            return preparePropertiesListByTemplate(
                this.wigProperties,
                this.template
            )
        },
        cellTitle: function () {
            return this.additionalTitle
        },
    },
    methods: {
        translated: function (key) {
            const translated = this.propertiesContent[regularToSnakeCase(key)]
            return translated || key
        },
    },
}
</script>

<style lang="scss">
.print-properties-list {
    margin: 0 8px !important;

    &__item {
        list-style-type: disc;
        direction: rtl;

        &:empty {
            display: none;
        }
    }

    &__label {
        font-weight: 700;
    }
}
</style>
