<template>
    <v-dialog
        v-model="isActive"
        width="700"
        persistent
        @click:outside.prevent="closeHandle"
    >
        <div class="associate">
            <div class="associate__head">
                <h3>{{ $t('pages.wigPage.associatePopup.associate') }}</h3>
                <v-btn icon color="#55595c" @click="closeHandle">
                    <v-icon>mdi-close-circle-outline</v-icon>
                </v-btn>
            </div>
            <div class="associate__search">
                <div class="search__input-wrapper">
                    <v-text-field
                        v-model="search"
                        :placeholder="$t('pages.wigPage.associatePopup.search')"
                        outlined
                        dense
                    >
                        <template #append>
                            <v-progress-circular
                                v-if="isBusy"
                                size="24"
                                color="info"
                                indeterminate
                            ></v-progress-circular>
                            <v-icon v-else @click="searchHandle">
                                mdi-magnify
                            </v-icon>
                        </template>
                    </v-text-field>
                </div>
            </div>
            <ul class="associate-list">
                <li
                    v-for="(result, index) in searchResults"
                    :key="index"
                    class="associate-list__item"
                    @click="handleClick(result)"
                >
                    <div class="associate-list__wrapper">
                        <div class="associate-list__content">
                            <div>
                                {{
                                    $t(
                                        'pages.wigPage.generalList.items.client'
                                    )
                                }}:
                            </div>
                            <h3>{{ result.client }}</h3>
                        </div>

                        <div class="associate-list__content">
                            <div>
                                {{
                                    $t(
                                        'pages.wigPage.generalList.items.barcode'
                                    )
                                }}:
                            </div>
                            <p>{{ result.barcode }}</p>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </v-dialog>
</template>

<script>
import modalsMixin from '@/mixins/modals.mixin'
import popUpTypes from '@/types/pop-up-types'
import { mapActions, mapGetters } from 'vuex'
import {
    actionTypes,
    actionTypes as actionTypesOrders,
    getterTypes as getterTypesOrders,
} from '@/store/modules/orders'
const { associate } = popUpTypes

export default {
    name: 'UsersModalEditProfile',
    mixins: [modalsMixin],

    data() {
        return {
            selfName: associate,
            search: '',
            isBusy: false,
            searchResults: [],
        }
    },
    computed: {
        ...mapGetters('orders', {
            order: getterTypesOrders.getOrder,
        }),
    },
    watch: {
        isActive(newValue) {
            if (newValue) {
                this.search = ''
                this.searchHandle()
            }
        },
    },
    methods: {
        ...mapActions('orders', {
            filterByBarcodeOrClient: actionTypesOrders.filterByBarcodeOrClient,
            associateWig: actionTypesOrders.associateWig,
            getWigsByState: actionTypes.getWigsByState,
        }),
        searchHandle: async function () {
            try {
                this.isBusy = true
                if (!this.search) {
                    this.searchResults = await this.getWigsByState(
                        'Wigs in Stock'
                    )
                    return
                }
                this.searchResults = await this.filterByBarcodeOrClient(
                    this.search
                )
            } catch (e) {
                console.log(e)
            } finally {
                this.isBusy = false
            }
        },
        handleClick: async function (item) {
            if (!this.order) return
            try {
                await this.associateWig({ wig: this.order.id, wig_id: item.id })
            } catch (e) {
                console.error(e)
            } finally {
                this.closeHandle()
            }
        },
    },
}
</script>

<style lang="scss">
.associate {
    background: #fff;

    &__head {
        padding: 20px 15px;
        border-bottom: 1px solid #eceeef;
        display: flex;
        justify-content: space-between;
        align-items: center;
        h3 {
            font-weight: 400;
        }
    }
    &__search {
        padding: 20px 15px;
    }
    ul {
        padding: 0 !important;
    }
    &-list {
        display: flex;
        flex-direction: column;

        &__item {
            border-bottom: 1px solid #eceeef;
            padding: 8px 15px !important;
            cursor: pointer;
            transition: 0.5s;
            &:hover {
                background: #eceeef;
            }
        }
        &__wrapper {
            display: flex;
            flex-direction: column;
            gap: 16px;
            @include tablet-up {
                flex-direction: row;
                align-items: center;
            }
        }
        &__content {
            display: flex;
            align-items: center;
            gap: 8px;
            h3 {
                font-size: 18px;
            }
            p {
                margin: 0 !important;
            }
        }
    }
}
</style>
