import { groupBy, sortBy } from 'lodash'
import { format, parseISO, parse } from 'date-fns'

const groupByInArray = (data, identity) => {
    const groupedData = groupBy(data, identity)
    return Object.keys(groupedData).map((key) => {
        return { title: key, items: groupedData[key] }
    })
}

const sortByDate = (data) => {
    return sortBy(data, (item) => {
        return parse(item.title, 'dd.MM.yyyy', new Date()).getTime()
    }).reverse()
}

const prepareAdditionalPropertiesByDateUtils = (data) => {
    data = data.map((item) => ({
        ...item,
        createdAt: format(parseISO(item?.created_at, 1), 'dd.MM.yyyy'),
    }))

    const additionalPropertiesByDate = groupByInArray(data, 'createdAt')

    const additionalPropertiesByDateByCategory = additionalPropertiesByDate.map(
        (item) => {
            return {
                ...item,
                items: groupByInArray(item.items, 'category'),
            }
        }
    )

    return sortByDate(additionalPropertiesByDateByCategory)
}

export default prepareAdditionalPropertiesByDateUtils
