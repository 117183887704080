<template>
    <div class="day">
        <h3 class="day__title">
            <v-icon color="#6f53b9" small>mdi-calendar-range</v-icon>
            <span>
                {{ day.title }}
            </span>
        </h3>
        <ul class="day__categories">
            <li
                v-for="(category, index) in day.items"
                :key="index"
                class="category"
            >
                <h4 class="category__title">{{ category.title }}</h4>
                <ul class="category__properties">
                    <li
                        v-for="(property, indexJ) in category.items"
                        :key="indexJ"
                    >
                        <div class="property">
                            <v-checkbox
                                class="status-list__checkbox"
                                :label="property.tittle"
                                :value="property.id"
                                :success="property.done"
                                :off-icon="
                                    property.done
                                        ? '$checkboxOn'
                                        : '$checkboxOff'
                                "
                                readonly
                                hide-details
                                dense
                            >
                            </v-checkbox>
                            <div class="property__info">
                                <div class="property__done-at">
                                    <DateCell :value="property.done_at" />
                                </div>
                                <div class="property__done-by">
                                    {{ property.done_by }}
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </li>
        </ul>
    </div>
</template>

<script>
import DateCell from '@/components/common/cells/date-cell.vue'

export default {
    name: 'WigPageAdditionalPropertiesByDateItem',
    components: { DateCell },
    props: {
        day: {
            type: Object,
            default: () => {},
        },
    },
}
</script>

<style lang="scss">
.day {
    background: #f4f4f4;
    border-radius: 16px;
    padding: 8px 16px;

    &__title {
        font-size: 16px;
        font-weight: 700;
        color: #6f53b9;
        width: fit-content;
    }

    &__categories {
        list-style: none;
        padding: 0 !important;
        margin: 0;
    }
}

.category {
    margin-bottom: 16px;

    &__title {
        color: #5d5d5d;
        margin-left: 48px;

        @include for-hebrew {
            margin-right: 48px;
            margin-left: 0;
        }
    }

    &__properties {
        list-style: none;
        padding: 0 !important;
        margin: 0;
    }
}

.property {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px dotted #bebebe;
    align-items: center;
    min-height: 45px;

    label {
        @include for-hebrew {
            text-align: right !important;
        }
    }

    &__info {
        margin-left: 8px;
        font-size: 12px;
        min-width: 107px;

        @include for-hebrew {
            margin-right: 8px;
            margin-left: 0;
        }
    }

    &__done-by {
    }

    &__done-at {
    }
}
</style>
