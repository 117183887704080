const en = {
    'united states': 'USA',
    'united kingdom': 'England',
    switzerland: 'Swiss',
}

const he = {
    israel: 'ישראל',
    'united states': 'ארהב',
    canada: 'קנדה',
    'united kingdom': 'אנגליה',
    switzerland: 'שוויץ',
    belgium: 'בלגיה',
    germany: 'גרמניה',
    france: 'צרפת',
}

const countryNameConvert = (country, isRtl) => {
    const converted = isRtl
        ? he[country.toLowerCase()]
        : en[country.toLowerCase()]
    return converted || country
}

export default countryNameConvert
