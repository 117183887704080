import actions from './actions'
import mutations from './mutations'
import getters from './getters'

export const state = {
    meetings: [],
    page: 1,
    pageCount: 0,
    total: 0,
    itemPerPage: 10,
    isFetching: false,
    searchQuery: '',
    calendars: [],
    selectedItem: {},
}

export default {
    namespaced: true,
    state,
    actions,
    mutations,
    getters,
}
