<template>
    <div class="no-records-yet">
        <div class="no-records-yet__icon">
            <v-icon x-large color="#6a4bc0">mdi-pound</v-icon>
        </div>
        <div class="no-records-yet__desc">
            {{ $t('shared.noRecordsYet.noRecordsYet') }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'NoRecordsYet',
}
</script>

<style lang="scss">
.no-records-yet {
    padding: 75px 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    &__icon {
        margin-bottom: 8px;
    }
    &__desc {
        font-size: 18px;
    }
}
</style>
