<template>
    <span class="warning-cell">
        {{ value }}
    </span>
</template>

<script>
export default {
    name: 'WarningCell',
    props: {
        value: {
            type: String,
            default: '',
        },
    },
}
</script>

<style lang="scss" scoped>
.warning-cell {
    color: #cc3c3c;
}
</style>
