export const clientsV2GetterTypes = {
    GET_ITEMS: 'clientsV2/GET_ITEMS',
    GET_TOTAL_ITEMS: 'clientsV2/GET_TOTAL_ITEMS',
    GET_TOTAL_PAGES: 'clientsV2/GET_TOTAL_PAGES',
    GET_ITEMS_PER_PAGE: 'clientsV2/GET_ITEMS_PER_PAGE',
    GET_CURRENT_PAGE: 'clientsV2/GET_CURRENT_PAGE',
    GET_CLIENTS_STATUS: 'clientsV2/GET_CLIENTS_STATUS',
    GET_LOADING: 'clientsV2/GET_LOADING',
    GET_ERROR: 'clientsV2/GET_ERROR',
}

export const clientsV2MutationTypes = {
    SET_ITEMS: 'clientsV2/SET_ITEMS',
    SET_TOTAL_ITEMS: 'clientsV2/SET_TOTAL_ITEMS',
    SET_CURRENT_PAGE: 'clientsV2/SET_CURRENT_PAGE',
    SET_SEARCH_QUERY: 'clientsV2/SET_SEARCH_QUERY',
    SET_SORT_OPTIONS: 'clientsV2/SET_SORT_OPTIONS',
    SET_CLIENTS_STATUS: 'clientsV2/SET_CLIENTS_STATUS',
    SET_LOADING: 'clientsV2/SET_LOADING',
    SET_ERROR: 'clientsV2/SET_ERROR',
}

export const clientsV2ActionTypes = {
    FETCH_ITEMS: 'clientsV2/FETCH_ITEMS',
    SET_CURRENT_PAGE: 'clientsV2/SET_CURRENT_PAGE',
    SET_SEARCH_QUERY: 'clientsV2/SET_SEARCH_QUERY',
    SET_SORT_OPTIONS: 'clientsV2/SET_SORT_OPTIONS',
    SET_CLIENTS_STATUS: 'clientsV2/SET_CLIENTS_STATUS',
}

export const clientsStatusTypes = {
    ACTIVE: 'active',
    DELETED: 'deleted',
    BOTH: 'both',
}
