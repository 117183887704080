<template>
    <div class="quagga">
        <div ref="quaggaContainer" class="quagga__container"></div>

        <div class="quagga__devices-list">
            <v-select
                v-model="device"
                dense
                :items="devices"
                item-text="title"
                item-value="id"
                label="Devices"
                @change="devicesChangeHandle"
            ></v-select>
        </div>
    </div>
</template>

<script>
import Quagga from 'quagga'
import { getStorage, setStorage } from '@/helpers/Local'

export default {
    name: 'QuaggaBarcodeScanner',
    props: {
        isActive: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['on-detected'],
    data() {
        return {
            quagga: Quagga,
            device: {},
            devices: [],
            codes: '',
        }
    },
    watch: {
        isActive: function (newValue) {
            if (!newValue) {
                this.quagga.stop()
                return null
            }
            this.start()
        },
    },
    mounted() {
        this.listOfDevices()
        this.start()
    },
    methods: {
        devicesChangeHandle: function () {
            this.quagga.stop()
            setStorage('device-id', this.device)
            this.start()
        },
        start: function () {
            this.quagga.init(
                {
                    inputStream: {
                        name: 'Live',
                        type: 'LiveStream',
                        target: this.$refs.quaggaContainer,
                        constraints: {
                            width: 375,
                            height: 375,
                            facingMode: 'environment',
                            deviceId: getStorage('device-id') || this.device,
                        },
                    },
                    decoder: {
                        readers: [
                            'code_128_reader',
                            'ean_reader',
                            'ean_8_reader',
                        ],
                        debug: {
                            showCanvas: true,
                            showPatches: true,
                            showFoundPatches: true,
                            showSkeleton: true,
                            showLabels: true,
                            showPatchLabels: true,
                            showRemainingPatchLabels: true,
                            boxFromPatches: {
                                showTransformed: true,
                                showTransformedBox: true,
                                showBB: true,
                            },
                        },
                    },
                },
                (err) => {
                    if (err) {
                        console.log(err)
                        return
                    }
                    this.quagga.start()
                    this.quagga.onProcessed(this.onProcessed)
                    this.quagga.onDetected(this.onDetected)
                    console.log('Initialization finished. Ready to start')
                }
            )
        },
        onProcessed: function (result) {
            let drawingCtx = Quagga.canvas.ctx.overlay,
                drawingCanvas = Quagga.canvas.dom.overlay

            if (result) {
                if (result.boxes) {
                    drawingCtx.clearRect(
                        0,
                        0,
                        parseInt(drawingCanvas.getAttribute('width')),
                        parseInt(drawingCanvas.getAttribute('height'))
                    )
                    result.boxes
                        .filter(function (box) {
                            return box !== result.box
                        })
                        .forEach(function (box) {
                            Quagga.ImageDebug.drawPath(
                                box,
                                { x: 0, y: 1 },
                                drawingCtx,
                                { color: 'green', lineWidth: 2 }
                            )
                        })
                }

                if (result.box) {
                    Quagga.ImageDebug.drawPath(
                        result.box,
                        { x: 0, y: 1 },
                        drawingCtx,
                        { color: '#00F', lineWidth: 2 }
                    )
                }

                if (result.codeResult && result.codeResult.code) {
                    Quagga.ImageDebug.drawPath(
                        result.line,
                        { x: 'x', y: 'y' },
                        drawingCtx,
                        { color: 'red', lineWidth: 3 }
                    )
                }
            }
        },
        onDetected: function (result) {
            this.codes = result.codeResult.code
            this.quagga.stop()
            this.$emit('on-detected', { barcode: this.codes })
        },
        listOfDevices: function () {
            if (
                !navigator.mediaDevices ||
                !navigator.mediaDevices.enumerateDevices
            ) {
                console.log('enumerateDevices() not supported.')
                return
            }

            return navigator.mediaDevices
                .enumerateDevices()
                .then((devices) => {
                    devices.forEach((device) => {
                        if (device.kind !== 'videoinput') return null
                        this.devices = [
                            ...this.devices,
                            {
                                title: device.label,
                                id: device.deviceId,
                            },
                        ]
                    })
                })
                .then(() => {
                    this.device = this.devices[0]
                })
                .catch(function (err) {
                    console.log(err.name + ': ' + err.message)
                })
        },
    },
}
</script>

<style lang="scss">
.quagga {
    width: 100%;

    &__container {
        width: 100%;
        min-height: 375px;
        margin-bottom: 25px;
    }

    &__devices-list {
        padding: 0 15px;
    }
}

canvas.drawing,
canvas.drawingBuffer {
    position: absolute;
    left: 0;
    top: 0;
}
</style>
