<template>
    <v-expansion-panel>
        <v-expansion-panel-header
            color="#f7f7f9"
            data-test-id="wigs--new-wig-properties--cap-size--cap-size-drop-down-btn"
        >
            <h4 class="order-property__sub-title">
                2. {{ propertiesContent['cap-size'] }}
            </h4>
        </v-expansion-panel-header>
        <v-expansion-panel-content color="#f7f7f9">
            <div
                id="order-property--cap-size--cap-size-select"
                class="order-property__input-wrapper"
            >
                <v-autocomplete
                    v-model="properties.fields['capSize']"
                    outlined
                    :items="items"
                    :label="propertiesContent['cap-size']"
                    dense
                    attach="#order-property--cap-size--cap-size-select"
                    :error-messages="properties.errors['capSize']"
                    data-test-id="wigs--new-wig-properties--cap-size--cap-size-select"
                    @input="properties.errors['capSize'] = ''"
                >
                </v-autocomplete>

                <v-text-field
                    v-model="properties.fields['capAdjustment']"
                    light
                    outlined
                    dense
                    type="text"
                    name="capAdjustment"
                    :label="propertiesContent['cap-adjustment']"
                    :error-messages="properties.errors['capAdjustment']"
                    data-test-id="wigs--new-wig-properties--cap-size--cap-size-comment-text-input"
                    @input="properties.errors['capAdjustment'] = ''"
                >
                </v-text-field>
            </div>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
import languagePropertiesMixin from '@/mixins/language-properties.mixin'

export default {
    name: 'OrderFormPropertyCapSize',
    mixins: [languagePropertiesMixin],
    props: {
        dataProperties: {
            type: Object,
            default: () => ({
                fields: {
                    ['capSize']: '',
                    ['capAdjustment']: '',
                },
                errors: {
                    ['capSize']: '',
                    ['capAdjustment']: '',
                },
            }),
        },
    },
    data() {
        return {
            items: [
                { text: 'XXS', value: 'XXS' },
                { text: 'XS', value: 'XS' },
                { text: 'S', value: 'S' },
                { text: 'M', value: 'M' },
                { text: 'L', value: 'L' },
                { text: 'XL', value: 'XL' },
                { text: 'XXL', value: 'XXL' },
            ],
        }
    },
    computed: {
        properties: function () {
            return this.dataProperties
        },
    },
}
</script>

<style lang="scss" scoped>
.order-property {
    &__sub-title {
        color: $color-primary-dark;
    }

    &__input-wrapper {
        padding: 15px 15px 0;
    }
}
</style>
