<template>
    <section class="report">
        <SubHeader
            :title="$t('pages.report.reports')"
            :crumbs="textContent['crumbs']"
        />
        <div class="report__body">
            <v-tabs v-model="tab" align-with-title dense>
                <v-tab>{{ $t('pages.report.payments') }}</v-tab>
                <v-tab>{{ $t('pages.report.employees') }}</v-tab>
            </v-tabs>

            <div class="report__tabs">
                <v-tabs-items v-model="tab">
                    <v-tab-item>
                        <div class="report__tab">
                            <ReportsPayment />
                        </div>
                    </v-tab-item>
                    <v-tab-item>
                        <div class="report__tab">
                            <ReportsUsers />
                        </div>
                    </v-tab-item>
                </v-tabs-items>
            </div>
        </div>
    </section>
</template>

<script>
import languageMixin from '@/mixins/language.mixin'
import languageOrderExtraMixin from '@/mixins/language-order-extra.mixin'
import SubHeader from '@/components/common/sub-header'
import ReportsPayment from '@/components/reports/reports-payment'
import ReportsUsers from '@/components/reports/reports-users'
import { actionTypes, getterTypes } from '../store/modules/users'
import { mapActions, mapGetters } from 'vuex'

export default {
    name: 'Reports',
    components: { SubHeader, ReportsPayment, ReportsUsers },
    mixins: [languageMixin, languageOrderExtraMixin],
    data: () => ({
        crumbs: [{ title: 'Home' }],
        tab: [],
    }),
    computed: {
        ...mapGetters('users', {
            users: getterTypes.getAllUsers,
        }),
    },
    mounted() {
        if (!this.users || this.users.length === 0) {
            this.loadAllUsers()
        }
    },
    methods: {
        ...mapActions('users', {
            loadAllUsers: actionTypes.loadAllUsers,
        }),
    },
}
</script>

<style lang="scss" scoped>
.report {
    min-height: 100%;

    &__body {
        width: 100%;
    }

    &__tabs {
        width: 100%;
    }

    &__tab {
        width: 100%;
        padding: 16px 0 0;
    }
}
</style>
