import { mapActions, mapGetters, mapMutations } from 'vuex'
import { actionTypes, getterTypes, mutationTypes } from '@/store/modules/modals'

const modalsMixin = {
    computed: {
        ...mapGetters('modals', {
            checkIsActive: getterTypes.isActive,
            payload: getterTypes.payload,
        }),
        isActive: function () {
            return this.checkIsActive(this.selfName)
        },
        extraData: function () {
            return this.payload(this.selfName)
        },
    },
    methods: {
        ...mapActions('modals', {
            onClose: actionTypes.closePopUp,
            onOpen: actionTypes.openPopUp,
        }),
        ...mapMutations('modals', {
            setPayloadHandle: mutationTypes.setPayload,
        }),
        setPayload: function (payload) {
            try {
                this.setPayloadHandle({ popUp: this.selfName, payload })
            } catch (e) {
                console.log(e)
            }
        },
        closeHandle: function () {
            this.onClose(this.selfName)
        },
    },
}

export default modalsMixin
