<template>
    <v-dialog v-model="isActive" width="400" @click:outside="closeHandle">
        <QrCodeModalScan
            :is-active="qrCodeIsActive"
            @on-detect="detectHandle"
            @on-close="closeBarcode"
        />
        <div class="order-general">
            <div class="order-general__header">
                <div class="order-general__title">
                    {{ textContent['edit'] }}
                </div>
                <v-btn icon color="#55595c" @click="closeHandle">
                    <v-icon>mdi-close-circle-outline</v-icon>
                </v-btn>
            </div>
            <div class="order-general__body">
                <div class="order-general__input-wrapper">
                    <v-text-field
                        v-model="dataGeneral.fields.barcode"
                        dense
                        outlined
                        type="text"
                        name="barcode-for-new"
                        :label="textContent['barcode']"
                        :error-messages="dataGeneral.errors['barcode']"
                        @input="dataGeneral.errors['barcode'] = ''"
                    >
                        <template #append>
                            <button class="icon-container" @click="onScan">
                                <v-icon>fas fa-barcode</v-icon>
                            </button>
                        </template>
                    </v-text-field>
                </div>
                <div class="order-general__input-wrapper">
                    <v-text-field
                        v-model="dataGeneral.fields.user_generated_id"
                        dense
                        outlined
                        type="text"
                        name="uniqID-for-new"
                        :label="textContent['uniq-id']"
                        :error-messages="
                            dataGeneral.errors['user_generated_id']
                        "
                        @input="dataGeneral.errors['user_generated_id'] = ''"
                    >
                    </v-text-field>
                </div>
                <div class="order-general__input-wrapper">
                    <v-textarea
                        v-model="dataGeneral.fields.details"
                        outlined
                        name="details"
                        :label="textContent['details']"
                        rows="2"
                    >
                    </v-textarea>
                </div>
                <div class="order-general__input-wrapper">
                    <v-text-field
                        v-model="dataGeneral.fields.totalPrice"
                        dense
                        outlined
                        type="number"
                        name="price-for-new"
                        :label="textContent['price']"
                        :error-messages="dataGeneral.errors['totalPrice']"
                        @input="dataGeneral.errors['totalPrice'] = ''"
                    >
                        <template #append>
                            <span class="icon-container">
                                <v-icon>fas fa-shekel-sign</v-icon>
                            </span>
                        </template>
                    </v-text-field>
                </div>
                <div class="order-general__input-wrapper">
                    <v-text-field
                        v-model="dataGeneral.fields.shippingInfo"
                        dense
                        outlined
                        type="text"
                        name="barcode-for-new"
                        :label="textContent['storage-info']"
                        :placeholder="textContent['city-street']"
                        :error-messages="dataGeneral.errors['shippingInfo']"
                        @input="dataGeneral.errors['shippingInfo'] = ''"
                    >
                        <template #append>
                            <button class="icon-container">
                                <v-icon>mdi-map-marker-outline</v-icon>
                            </button>
                        </template>
                    </v-text-field>
                </div>
                <div class="order-general__input-wrapper">
                    <v-file-input
                        v-model="files"
                        :label="textContent['photo']"
                        outlined
                        dense
                        multiple
                    ></v-file-input>
                </div>
            </div>
            <div class="order-general__footer">
                <div class="order-general__actions">
                    <v-btn color="#55595c" dark small @click="closeHandle">
                        {{ textContent['close'] }}
                    </v-btn>
                    <v-btn color="#967adc" dark small @click="updateHandle">
                        {{ textContent['update'] }}
                    </v-btn>
                </div>
            </div>
        </div>
    </v-dialog>
</template>

<script>
import QrCodeModalScan from '@/components/modals/qr-code-modal-scan'
import { mapActions, mapGetters } from 'vuex'
import { actionTypes, getterTypes } from '@/store/modules/orders'
import { actionTypes as actionTypesModalNotifications } from '@/store/modules/modal-notifications'

import modalsMixin from '@/mixins/modals.mixin'

import popUpTypes from '@/types/pop-up-types'
import languageMixin from '@/mixins/language.mixin'
import { ordersV2ActionTypes } from '@/store/modules/orders_v2/types'

const { orderGeneralUpdate } = popUpTypes

export default {
    name: 'OrderModalGeneralUpdate',
    components: { QrCodeModalScan },
    mixins: [modalsMixin, languageMixin],
    emits: ['close-handle'],
    data() {
        return {
            selfName: orderGeneralUpdate,
            files: [],
            dataGeneral: {
                fields: {},
                errors: {},
            },
            qrCodeIsActive: false,
        }
    },
    computed: {
        ...mapGetters('orders', { selectedOrder: getterTypes.getOrder }),
    },
    watch: {
        isActive: function (value) {
            if (!value) this.files = []
            this.dataGeneral.fields = this.selectedOrder
        },
    },
    methods: {
        ...mapActions('orders', { onUpdate: actionTypes.updateOrder }),
        ...mapActions('modalNotifications', {
            success: actionTypesModalNotifications.success,
        }),
        ...mapActions('ordersV2', {
            loadAllV2: ordersV2ActionTypes.FETCH_ORDERS,
        }),
        updateHandle: function () {
            let res = {
                details: null,
                info: null,
                totalPrice: 0,
                ['user_generated_id']: null,
                barcode: null,
            }

            Object.keys(this.dataGeneral.fields).forEach((field) => {
                if (!this.dataGeneral.fields[field]) return
                res = { ...res, [field]: this.dataGeneral.fields[field] }
            })

            const json = JSON.stringify(res)
            const form = new FormData()
            this.files.forEach((file) => {
                form.append('files[]', file)
            })
            form.append('data', json)

            const data = { id: this.dataGeneral.fields.id, form }

            console.log(data)

            this.onUpdate(data).then(() => {
                this.success('Order has been updated')
                this.closeHandle()
            })

            this.loadAllV2()
        },
        onScan: function () {
            this.qrCodeIsActive = true
        },
        detectHandle: function (code) {
            this.dataGeneral.fields.barcode = code
        },
        closeBarcode: function () {
            this.qrCodeIsActive = false
        },
    },
}
</script>

<style scoped lang="scss">
.order-general {
    background: $color-primary-light;
    padding: 15px 10px;

    &__header {
        margin-bottom: 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__actions {
        display: flex;
        justify-content: space-between;
    }
}
</style>
