<template>
    <v-dialog v-model="isActive" width="500" @click:outside="closeHandle">
        <div class="requester">
            <v-text-field
                v-model="body"
                label="request"
                placeholder="Placeholder"
                outlined
            ></v-text-field>
            <v-btn color="#0065c5" dark small @click="onSubmit"> Send</v-btn>
        </div>
    </v-dialog>
</template>

<script>
import modalsMixin from '@/mixins/modals.mixin'

import popUpTypes from '@/types/pop-up-types'
import axios from '@/api/axios'

const { requestTester } = popUpTypes
export default {
    name: 'ExtraRequestTester',
    mixins: [modalsMixin],
    data() {
        return {
            selfName: requestTester,
            body: '',
        }
    },
    computed: {},
    methods: {
        onSubmit: async function () {
            await axios.get(`/base/api/${this.body}`)
        },
    },
}
</script>

<style lang="scss">
.requester {
    background: #fff;
    padding: 15px;
}
</style>
