<template>
    <div class="reports-table">
        <div class="reports-table__actions">
            <div class="reports-table__filter">
                <!-- eslint-disable vue/no-deprecated-v-bind-sync -->
                <v-autocomplete
                    v-model="filters.client.value"
                    :search-input.sync="search"
                    :loading="clientsIsFetching"
                    :items="filtersItems.clients"
                    dense
                    :label="$t('pages.report.client')"
                    hide-details
                    hide-no-data
                    placeholder="Client"
                ></v-autocomplete>
            </div>
            <div class="reports-table__filter">
                <v-autocomplete
                    v-model="filters.user.value"
                    :items="filtersItems.users"
                    dense
                    :label="$t('pages.report.employee')"
                    hide-details
                    placeholder="Employee"
                ></v-autocomplete>
            </div>
        </div>
        <div class="reports-table__table">
            <v-data-table
                :headers="headers"
                :items="filteredEmployees"
                :items-per-page="-1"
                :footer-props="{
                    'items-per-page-text': $t('pages.report.rowsPerPage'),
                }"
                :no-data-text="$t('pages.report.noDataAvailable')"
                class="elevation-1"
                dense
                @click:row="clickHandle"
            >
                <!--    eslint-disable-next-line -->
                <template #item.isRepairWig="{ item }">
                    <template v-if="item.isRepairWig">
                        <v-icon small color="red">mdi-wrench</v-icon>
                    </template>
                </template>

                <!--    eslint-disable-next-line -->
                <template #item.activity_title="{ item }">
                    <div class="reports-table__action">
                        <p class="reports-table__action-title">
                            {{ $t(`pages.report.${item.activity_title}`) }}
                        </p>
                        <p class="reports-table__action-desc">
                            {{
                                getActivityDescriptionByTranslateHandle(
                                    item.activity_description
                                )
                            }}
                        </p>
                    </div>
                </template>
                <!--    eslint-disable-next-line -->
                <template #item.created_at="{ item }">
                    <span style="direction: ltr; display: inline-block">
                        <DateCell :value="item.created_at" />
                    </span>
                </template>
                <!--    eslint-disable-next-line -->
                <template #item.totally="{ item }">
                    <div
                        v-if="
                            (item.totally || item.totally === 0) &&
                            item.total_cost
                        "
                        class="reports-table__status"
                    >
                        <div class="reports-table__status-circle">
                            <v-progress-circular
                                :rotate="270"
                                :size="45"
                                :width="6"
                                :value="(item.totally / item.total_cost) * 100"
                                :color="
                                    getColor(
                                        (item.totally / item.total_cost) * 100
                                    )
                                "
                            >
                            </v-progress-circular>
                            <div class="value">
                                {{
                                    Math.ceil(
                                        (item.totally / item.total_cost) * 100
                                    )
                                }}%
                            </div>
                        </div>
                        <div
                            v-if="item.total_cost - item.totally !== 0"
                            class="reports-table__status-debt"
                        >
                            <p>debt</p>
                            {{ item.total_cost - item.totally }}
                        </div>
                        <div v-else class="reports-table__status-check">
                            <v-icon color="#3cc516">
                                mdi-check-decagram-outline
                            </v-icon>
                        </div>
                    </div>
                </template>
            </v-data-table>
        </div>
    </div>
</template>

<script>
import languageMixin from '@/mixins/language.mixin'
import languageOrderExtraMixin from '@/mixins/language-order-extra.mixin'
import { mapActions, mapGetters } from 'vuex'
import { getterTypes } from '@/store/modules/report'
import { getterTypes as getterTypesUsers } from '@/store/modules/users'
import { getFullDayFirstFull } from '@/helpers/getDateInFormat'
import { actionTypes as actionTypesOrders } from '@/store/modules/orders'
import DateCell from '@/components/common/cells/date-cell.vue'
import wigTransitions from '@/helpers/transitions/wig-transitions'
import {
    clientsV2ActionTypes,
    clientsV2GetterTypes,
} from '@/store/modules/clients_v2/types'

export default {
    name: 'ReportsUsersTable',
    components: { DateCell },
    mixins: [languageMixin, languageOrderExtraMixin],
    data() {
        return {
            key: '',
            search: '555',
            filters: {
                client: {
                    items: [
                        {
                            text: this.$t('pages.report.any'),
                            value: '',
                        },
                        {
                            text: 'Client 001',
                            value: 'client-001',
                        },
                        {
                            text: 'Client 002',
                            value: 'client-002',
                        },
                    ],
                    value: '',
                },
                user: {
                    items: [
                        {
                            text: this.$t('pages.report.any'),
                            value: '',
                        },
                    ],
                    value: '',
                },
            },
        }
    },
    computed: {
        ...mapGetters('clientsV2', {
            getAllClients: clientsV2GetterTypes.GET_ITEMS,
            clientsIsFetching: clientsV2GetterTypes.GET_LOADING,
        }),
        ...mapGetters('report', {
            getAllEmployees: getterTypes.getAllEmployees,
        }),
        ...mapGetters('users', {
            getAllUsers: getterTypesUsers.getAllUsers,
        }),
        textPayment: function () {
            return (
                this.getTextForSelected(this.lang, 'OrderModalDepositUpdate') ||
                ''
            )
        },
        items: function () {
            return []
        },
        headers: function () {
            return [
                //TODO: add translate
                {
                    text: this.$t('pages.report.kind'),
                    value: 'isRepairWig',
                    sortable: false,
                },
                { text: this.textContent['created'], value: 'created_at' },
                {
                    text: this.textContent['barcode'],
                    value: 'barcode',
                    sortable: false,
                },
                {
                    text: this.$t('pages.report.employee'),
                    value: 'employees_name',
                    sortable: false,
                },
                {
                    text: this.textContent['client'],
                    value: 'client',
                    sortable: false,
                },
                {
                    text: this.$t('pages.report.action'),
                    value: 'activity_title',
                    sortable: false,
                    width: 300,
                },
                {
                    text: this.$t('pages.report.status'),
                    value: 'totally',
                    sortable: false,
                    width: 175,
                },
            ]
        },
        filteredEmployees: function () {
            return this.getAllEmployees
                .filter((item) => {
                    return (
                        (!this.filters.client.value
                            ? true
                            : this.filters.client.value.toLowerCase() ===
                              item?.client?.toLowerCase()) &&
                        (!this.filters.user.value
                            ? true
                            : this.filters.user.value.toLowerCase() ===
                              item?.employees_name?.toLowerCase())
                    )
                })
                .map((item) => {
                    return {
                        ...item,
                        isRepairWig: item?.is_repair_wig === 1,
                    }
                })
        },
        filtersItems: function () {
            const clients = this.getAllClients.map(({ full_name }) => ({
                text: full_name,
                value: full_name,
            }))
            const users = this.getAllUsers.map(({ nickName }) => ({
                text: nickName,
                value: nickName,
            }))
            return {
                clients: [
                    { text: this.$t('pages.report.any'), value: '' },
                    ...clients,
                ],
                users: [
                    { text: this.$t('pages.report.any'), value: '' },
                    ...users,
                ],
            }
        },
    },
    watch: {
        search: function (val) {
            if (val && val !== 'Any') {
                clearTimeout(this.searchQueryTimeout)

                this.searchQueryTimeout = setTimeout(() => {
                    this.setSearchQuery(val)
                }, 500)
                return
            }

            if (val === '') {
                this.fetchClients()
            }
        },
    },
    mounted() {
        this.setSearchQuery('')
    },
    methods: {
        ...mapActions('clientsV2', {
            fetchClients: clientsV2ActionTypes.FETCH_ITEMS,
            setSearchQuery: clientsV2ActionTypes.SET_SEARCH_QUERY,
        }),
        ...mapActions('orders', {
            getOrder: actionTypesOrders.loadOrder,
        }),
        dateFormat: function (date) {
            return getFullDayFirstFull(date)
        },
        clickHandle: function (e) {
            if (e.wig_id) {
                wigTransitions.moveToWigPageInNewTab(e.wig_id)
            }
        },
        getColor: function (value) {
            return value >= 100 ? 'green' : value >= 50 ? 'orange' : 'red'
        },
        toCamelCase: function (str) {
            return str
                .toLowerCase()
                .replace(/[_\s]+(.)?/g, (_, chr) =>
                    chr ? chr.toUpperCase() : ''
                )
        },
        containsHebrew: function (str) {
            const regex = /[\u0590-\u05FF]/
            return regex.test(str)
        },
        getActivityDescriptionByTranslateHandle: function (str) {
            if (this.containsHebrew(str)) {
                return str
            }

            const translatedKey = `pages.report.${this.toCamelCase(str)}`
            const translatedText = this.$t(translatedKey)

            if (translatedText.includes('Translation not found for key')) {
                return str
            }

            return translatedText
        },
    },
}
</script>

<style lang="scss" scoped>
.reports-table {
    &__totally-row {
        font-weight: 700;
        font-size: 1.1em;
    }
    &__totally {
        width: 100%;
        text-align: right;
    }

    &__actions {
        display: flex;
        justify-content: flex-start;
        margin-bottom: 16px;
    }

    &__filter {
        max-width: 200px;

        & + & {
            margin-left: 15px;
        }
    }

    &__action {
        border: 2px solid rgb(150, 122, 220);
        border-radius: 16px;
        padding: 4px 10px;
        width: 100%;
        margin: 3px 0;
    }

    &__action-title {
        font-size: 0.95em;
    }

    &__action-desc {
        padding-left: 5px;
        font-size: 0.8em;
    }
    &__status {
        display: flex;
        gap: 0 10px;
        justify-content: center;
        align-items: center;
    }

    &__status-circle {
        min-width: 35px;
        position: relative;

        .value {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            line-height: 0.8;
            font-size: 0.75em;
            font-weight: 700;
            margin-top: 1px;
        }
    }

    &__status-debt {
        text-align: right;
        min-width: 50%;
        border-bottom: 1px solid rgba(0, 0, 0, 0.35);
        p {
            font-size: 0.9em;
            color: rgba(101, 101, 101, 0.85);
        }
    }

    &__status-check {
        margin-left: 15px;
    }
}
</style>
