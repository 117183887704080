export const ordersV2GetterTypes = {
    GET_ORDERS: 'ordersV2/GET_ORDERS',
    GET_TOTAL: 'ordersV2/GET_TOTAL',
    GET_ITEMS_PER_PAGE: 'ordersV2/GET_ITEMS_PER_PAGE',
    GET_TOTAL_PAGES: 'ordersV2/GET_TOTAL_PAGES',
    GET_CURRENT_PAGE: 'ordersV2/GET_CURRENT_PAGE',
    GET_LOADING: 'ordersV2/GET_LOADING',
    GET_USA_MANAGERS: 'ordersV2/GET_USA_MANAGERS',
}

export const ordersV2MutationTypes = {
    SET_ORDERS: 'ordersV2/SET_ORDERS',
    SET_TOTAL_ITEMS: 'ordersV2/SET_TOTAL_ITEMS',
    SET_CURRENT_PAGE: 'ordersV2/SET_CURRENT_PAGE',
    SET_LOADING: 'ordersV2/SET_LOADING',
    SET_USA_MANAGERS: 'ordersV2/SET_USA_MANAGERS',
    SET_SEARCH_QUERY: 'ordersV2/SET_SEARCH_QUERY',
    SET_ORDERS_STATE: 'ordersV2/SET_ORDERS_STATE',
    SET_SPECIAL: 'ordersV2/SET_SPECIAL',
    SET_SORT: 'ordersV2/SET_SORT',
    SET_USA_TOOLS: 'ordersV2/SET_USA_TOOLS',
}

export const ordersV2ActionTypes = {
    FETCH_ORDERS: 'ordersV2/FETCH_ORDERS',
    FETCH_ORDERS_BY_STATE: 'ordersV2/FETCH_ORDERS_BY_STATE',
    SET_CURRENT_PAGE: 'ordersV2/SET_CURRENT_PAGE',
    SET_USA_MANAGERS: 'ordersV2/SET_USA_MANAGERS',
    SET_SEARCH_QUERY: 'ordersV2/SET_SEARCH_QUERY',
    SET_ORDERS_STATE: 'ordersV2/SET_ORDERS_STATE',
    SET_SPECIAL: 'ordersV2/SET_SPECIAL',
    SET_SORT: 'ordersV2/SET_SORT',
    SET_USA_TOOLS: 'ordersV2/SET_USA_TOOLS',
}
