<template>
    <section class="clients">
        <SubHeader :title="textContent.title" :crumbs="textContent.crumbs" />
        <div class="clients__body">
            <div class="clients__search">
                <ClientsSearch
                    :searched="searched"
                    :total="getTotal"
                    @search-handle="searchUpdate"
                    @filter-handle="filterUpdate"
                />
            </div>
            <div class="clients__list">
                <transition name="fade">
                    <div
                        v-if="clientsIsFetching"
                        class="clients__list-loading-bar"
                    >
                        <v-progress-linear
                            indeterminate
                            color="#967adc"
                        ></v-progress-linear>
                    </div>
                </transition>
                <ClientsTable
                    :clients="getAllClientsV2"
                    :page="getCurrentPage"
                    :item-per-page="itemsPerPage"
                    :total="getTotal"
                    @context-handle="contextHandle"
                    @change-page-handle="changePageHandle"
                />
            </div>
        </div>
    </section>
</template>

<script>
import SubHeader from '@/components/common/sub-header'
import ClientsSearch from '@/components/clients/clients-search'
import ClientsTable from '@/components/clients/clients-table'
import { mapActions, mapGetters } from 'vuex'
import {
    clientsV2GetterTypes,
    clientsV2ActionTypes,
} from '@/store/modules/clients_v2/types'
import { actionTypes as clientsActionTypes } from '@/store/modules/clients'
import { actionTypes as actionTypesConfirmer } from '@/store/modules/confirmer'
import { actionTypes as actionTypesModalNotifications } from '@/store/modules/modal-notifications'
import languageMixin from '@/mixins/language.mixin'
import getClientsStatusByFilter from '@/helpers/get-clients-status-by-filter.utils'

export default {
    name: 'ClientsList',
    components: {
        SubHeader,
        ClientsSearch,
        ClientsTable,
    },
    mixins: [languageMixin],
    data() {
        return {
            selectedUser: {
                id: null,
                isActive: false,
            },
            count: 10,
            searched: '',
            sorted: 'by-name',
            activeFilters: ['existing'],
            inputTimeout: null,
        }
    },
    computed: {
        idIsAlready: function () {
            return this.$route?.params?.id || undefined
        },
        clientModalContextIsActive: function () {
            const atLeast =
                this.addAndChangeClient.isActive || this.deleteClient.isActive
            return this.selectedUser.isActive && !atLeast
        },
        ...mapGetters('clientsV2', {
            getAllClientsV2: clientsV2GetterTypes.GET_ITEMS,
            getCurrentPage: clientsV2GetterTypes.GET_CURRENT_PAGE,
            getTotal: clientsV2GetterTypes.GET_TOTAL_ITEMS,
            itemsPerPage: clientsV2GetterTypes.GET_ITEMS_PER_PAGE,
            clientsIsFetching: clientsV2GetterTypes.GET_LOADING,
            getClientsStatus: clientsV2GetterTypes.GET_CLIENTS_STATUS,
        }),
    },
    mounted() {
        this.loadAllClientsV2()

        if (this.idIsAlready) {
            this.openClient(this.idIsAlready)
        }
    },
    methods: {
        ...mapActions('clients', {
            getClient: clientsActionTypes.getClient,
        }),
        ...mapActions('clientsV2', {
            loadAllClientsV2: clientsV2ActionTypes.FETCH_ITEMS,
            setCurrentPage: clientsV2ActionTypes.SET_CURRENT_PAGE,
            setSearchQuery: clientsV2ActionTypes.SET_SEARCH_QUERY,
            setClientsStatus: clientsV2ActionTypes.SET_CLIENTS_STATUS,
        }),
        ...mapActions('confirmer', {
            confrimHandle: actionTypesConfirmer.confirmHandle,
        }),
        ...mapActions('modalNotifications', {
            success: actionTypesModalNotifications.success,
        }),
        searchUpdate: function (str) {
            if (this.inputTimeout) {
                clearTimeout(this.inputTimeout)
            }

            this.inputTimeout = setTimeout(() => {
                this.setSearchQuery(str)
            }, 700)
        },
        filterUpdate: function (value) {
            this.activeFilters = value
            let newStatus = getClientsStatusByFilter(value)

            if (this.getClientsStatus !== newStatus) {
                this.setClientsStatus(newStatus)
            }
        },
        contextHandle: function (id) {
            this.getClient(id)
        },
        changePageHandle: function (page) {
            this.setCurrentPage(page)
        },
    },
}
</script>

<style scoped lang="scss">
.clients {
    &__body {
        display: flex;
        flex-direction: column;

        @include tablet-up {
            flex-direction: row;
        }
    }

    &__search {
        background: $color-primary-light;
        min-width: 200px;
        height: fit-content;
        box-shadow: 0 2px 1px rgb(0 0 0 / 5%);
        margin: 20px 0;

        @include tablet-up {
            margin: 0 20px 0 0;

            @include for-hebrew {
                margin: 0 0 0 20px;
            }
        }
    }

    &__list {
        background: $color-primary-light;
        box-shadow: 0 2px 1px rgb(0 0 0 / 5%);
        flex-shrink: 0;
        flex-grow: 1;
        padding: 20px;
        overflow: auto;
        position: relative;

        @include tablet-up {
            max-width: calc(100% - 230px);
            margin-right: 15px;
        }
    }

    &__list-loading-bar {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 5px;
    }
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 150ms;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
    opacity: 0;
}
</style>
