<template>
    <div class="messages">
        <div class="messages__head">
            <h3 class="messages__title">{{ textContent['notifications'] }}:</h3>
            <p class="messages__new">{{ quantity }} {{ textContent['new'] }}</p>
        </div>
        <transition-group name="list" tag="ul" class="notification-list">
            <li
                v-for="ntf in notifications"
                :key="ntf.id"
                class="notification-list__item"
            >
                <NotificationItem :ntf="ntf" @read-handle="readHandle" />
            </li>
        </transition-group>
    </div>
</template>

<script>
import { mapActions } from 'vuex'
import { actionTypes } from '@/store/modules/auth'
import { sortBy } from 'lodash'

import NotificationItem from '@/components/notifications/notification-item'
import languageMixin from '@/mixins/language.mixin'

export default {
    name: 'UserMessages',
    components: {
        NotificationItem,
    },
    mixins: [languageMixin],
    props: {
        messages: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        textContent: function () {
            return this.getTextForSelected(this.lang, 'Notification') || ''
        },
        quantity: function () {
            return this.notifications.reduce((count, ntf) => {
                return !ntf.read ? count + 1 : count
            }, 0)
        },
        notifications: function () {
            return sortBy(this.messages, ['date']).reverse()
        },
    },
    methods: {
        ...mapActions('auth', { onRead: actionTypes.readNotification }),
        readHandle: function (id) {
            this.onRead(id)
        },
    },
}
</script>

<style lang="scss" scoped>
.messages {
    width: 400px;
    background: $color-primary-light;
    background: transparent;
    backdrop-filter: blur(10px);
    padding: 5px 10px;
    max-height: 500px;
    overflow: auto;

    &__head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 5px;
    }

    &__title {
        font-size: 13px;
    }

    &__new {
        padding: 2px 5px;
        background: #f6bb42;
        text-align: center;
        font-size: 12px;
        border-radius: 5px;
        margin-bottom: 0;
    }
}

.notification-list {
    padding: 0;
}

.list-enter-active,
.list-leave-active {
    transition: all 1s;
}

.list-enter, .list-leave-to /* .list-leave-active до версии 2.1.8 */ {
    opacity: 0;
    transform: translate(-30px, 30px);
}
</style>
