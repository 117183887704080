export default {
    employees: 'Employees',
    payments: 'Payments',
    remainder: 'Remainder',
    totally: 'Totally',
    kind: 'Kind',
    rowsPerPage: 'Rows per page',
    action: 'Action',
    all: 'All',
    any: 'Any',
    reload: 'Reload',
    noDataAvailable: 'No data available',
    source: 'Source',
    status: 'Status',
    client: 'Client',
    created: 'Created',
    employee: 'Employee',
    card: 'Card',
    cash: 'Cash',
    payoutCheck: 'Payout check',
    wireTransfer: 'Wire transfer',
    reports: 'Reports',
    UPDATE_WIG_STATE: 'Update wig state',
    UPDATE_WIG_STATUS: 'Update wig status',
    CREATE_WIG: 'Create wig',
    //states
    archived: 'Archived',
    allStates: 'All statuses',
    draft: 'Draft',
    wigsInProduction: 'Wigs in Production',
    readyNewWig: 'Ready New Wig',
    repairWigs: 'Repair Wigs',
    readyRepairWigs: 'Ready Repair Wigs',
    cancelledWigs: 'Cancelled Wigs',
    wigsInStock: 'Wigs in Stock',
    storage: 'Storage',
    deliveredWigs: 'Delivered Wigs',
    rework: 'Re-work',
    wigBuilding: 'Wig building',
    sawingWeft: 'Sawing weft',
    sawingWeftOnCap: 'Sawing weft on cap',
    skinMatching: 'Skin Matching',
    skinsConnect: 'Skins / Tops connect',
    frontHandTie: 'Classic hairline',
    firstHairline: 'First hairline tracing',
    firstHairlineTracing: 'First hairline tracing',
    secondHairline: 'Second hairline ( finishes )',
    secondHairlineFinishes: 'Second hairline ( finishes )',
    underLaceMaterialSawing: 'Under lace material sawing',
    underLaceMaterialClosing: 'Under lace material closing',
    highlightsIncludeWashing: 'Highlights, include washing',
    specialShampoo: 'Special Shampoo',
    washing: 'Washing and Shrinkage',
    cutAndSet: 'Pre-Cut and Set',
    productionStart: 'Production Start',
    productionComplete: 'Production complete',
    repairComplete: 'Repair complete',
    delivery: 'Delivery',
    newOrder: 'New order',
    newRepair: 'New repair',
}
