const addPrefix = (num) => {
    return num.toString().length === 1 ? `0${num}` : num
}

const reformat = (date) => {
    const con = /\s[0-9]:/.test(date)
    return con ? date.replace(/\s[0-9]:/, ' 09:') : date
}

const types = [
    {
        type: 'parseFromMilliseconds',
        condition: (date) => typeof date === 'number',
    },
    {
        type: 'parseFromV1',
        condition: (date) =>
            /^[0-9]{2}-[0-9]{2}-[0-9]{4}\s[0-9]{2}:[0-9]{2}$/.test(date),
    },
    {
        type: 'parseFromV2',
        condition: (date) =>
            /^[0-9]{4}-[0-9]{2}-[0-9]{2}\s[0-9]{2}:[0-9]{2}/.test(date),
    },
    {
        type: 'parseFromV3',
        condition: (date) =>
            /^[0-9]{4}-[0-9]{2}-[0-9]{2}\s[0-9]:[0-9]{2}/.test(date),
    },
    {
        type: 'parseFromMonth',
        condition: (date) => /^[0-9]{4}-[0-9]{1,2}$/.test(date),
    },
]

//input - 1647009941826
// eslint-disable-next-line no-unused-vars
const parseFromMilliseconds = (date) => {
    return new Date(date)
}

// input - 03-15-2022 10:00
// eslint-disable-next-line no-unused-vars
const parseFromV1 = (date) => {
    const dateAsArray = date ? [...date] : ''

    const dayParse = dateAsArray.slice(3, 5).join('')
    const monthParse = dateAsArray.slice(0, 2).join('')
    const yearParse = dateAsArray.slice(6, 10).join('')
    const timeParse = dateAsArray.slice(11, 16).join('')

    return `${yearParse}-${monthParse}-${dayParse}T${timeParse}`
}

// input - 2022-03-24 10:00:00
// eslint-disable-next-line no-unused-vars
const parseFromV2 = (date) => {
    const dateAsArray = date ? [...date] : ''

    const dayParse = dateAsArray.slice(8, 10).join('')
    const monthParse = dateAsArray.slice(5, 7).join('')
    const yearParse = dateAsArray.slice(0, 4).join('')
    const timeParse = dateAsArray.slice(11, 16).join('')

    return `${yearParse}-${monthParse}-${dayParse}T${timeParse}`
}
const parseFromV3 = (date) => {
    const dateAsArray = date ? [...date] : ''

    const dayParse = dateAsArray.slice(8, 10).join('')
    const monthParse = dateAsArray.slice(5, 7).join('')
    const yearParse = dateAsArray.slice(0, 4).join('')
    const timeParse = dateAsArray.slice(11, 15).join('')

    return `${yearParse}-${monthParse}-${dayParse}T${timeParse}`
}
const parseFromMonth = (date) => {
    const dateAsArray = date ? [...date] : ''
    const monthParse = dateAsArray.slice(5, 7).join('')
    const yearParse = dateAsArray.slice(0, 4).join('')

    return `${yearParse}-${monthParse}`
}

const checkTypes = (date) => {
    const type = types.reduce((ak, item) => {
        return item.condition(date) ? item.type : ak
    }, '')

    switch (type) {
        case 'parseFromMilliseconds':
            return parseFromMilliseconds(date)
        case 'parseFromV1':
            return parseFromV1(date)
        case 'parseFromV2':
            return parseFromV2(date)
        case 'parseFromV3':
            return parseFromV3(date)
        case 'parseFromMonth':
            return parseFromMonth(date)
        default:
            return new Date()
    }
}

const converter = (date = '', type = '') => {
    date = reformat(date)
    if (!date) return new Date()

    const timeZoneCorrection = new Date().getTimezoneOffset() * -1 * 60 * 1000

    const currentDate = date ? new Date(checkTypes(date)) : new Date()
    const currentDateFromUTC = date
        ? new Date(+new Date(checkTypes(date)) + timeZoneCorrection)
        : new Date()

    const year = currentDate.getFullYear()
    const month = currentDate.getMonth() + 1
    const day = currentDate.getDate()
    const hour = currentDate.getHours()
    let minutes = currentDate.getMinutes()
    minutes = minutes.toString().length === 1 ? '0' + minutes : minutes
    const sec = currentDate.getSeconds()

    const yearUtc = currentDateFromUTC.getFullYear()
    const monthUtc = currentDateFromUTC.getMonth() + 1
    const dayUtc = currentDateFromUTC.getDate()
    const hourUtc = currentDateFromUTC.getHours()
    let minutesUtc = currentDateFromUTC.getMinutes()
    minutesUtc =
        minutesUtc.toString().length === 1 ? '0' + minutesUtc : minutesUtc
    // const secUtc = currentDate.getUTCSeconds()

    switch (type) {
        case 'dayFirst':
            return `${day}-${month}-${year}`
        case 'dayFirstFull':
            return `${dayUtc}-${monthUtc}-${yearUtc} ${hourUtc}:${minutesUtc}`
        case 'dayFirstFullUTC':
            return `${dayUtc}-${monthUtc}-${yearUtc} ${hourUtc}:${minutesUtc}`
        case 'dayFirstFullStraight':
            return `${day}-${month}-${year} ${hour}:${minutes}`
        case 'time':
            return `${hour}:${minutes}`
        case 'range':
            return `${year}-${month}`
        case 'milliseconds':
            return Date.parse(currentDate)
        case 'for-client-card':
            return `${year}-${addPrefix(month)}-${addPrefix(day)} ${addPrefix(
                hour
            )}:${addPrefix(minutes)}`
        case 'date-with-first-day-of-month':
            return `${year}-${addPrefix(month)}-01`
        case 'date-with-last-day-of-month':
            return `${year}-${addPrefix(month)}-${new Date(
                year,
                month,
                0
            ).getDate()}`
        case 'yyyy-mm-dd':
            return `${year}-${addPrefix(month)}-${addPrefix(day)}`
        default:
            return `${year}-${month}-${day} ${hour}:${minutes}:${sec}`
    }
}

const getFullDate = (date) => converter(date)
const getFullDateForClient = (date) => converter(date, 'for-client-card')
const getFullDayFirst = (date) => converter(date, 'dayFirst')
const getFullDayFirstFull = (date) => converter(date, 'dayFirstFull')
const getFullDayFirstFullStraight = (date) =>
    converter(date, 'dayFirstFullStraight')
const getTimeHhMm = (date) => converter(date, 'time')
const getRange = (date) => converter(date, 'range')
const getInMilliseconds = (date) => converter(date, 'milliseconds')
const getDateWihFirstDayOfMonth = (date) =>
    converter(date, 'date-with-first-day-of-month')
const getDateWihLastDayOfMonth = (date) =>
    converter(date, 'date-with-last-day-of-month')
const getDateYyyyMmDd = (date) => converter(date, 'yyyy-mm-dd')

export {
    getFullDate,
    getTimeHhMm,
    getFullDayFirst,
    getRange,
    getFullDateForClient,
    getFullDayFirstFull,
    getInMilliseconds,
    getFullDayFirstFullStraight,
    getDateWihFirstDayOfMonth,
    getDateWihLastDayOfMonth,
    getDateYyyyMmDd,
}
