const state = () => ({
    message: '',
    modalIsActive: false,
    progress: null,
    type: 'regular',
    action: {
        res: null,
        rej: null,
    },
})

export const getterTypes = {
    getStatus: '[confirmer] get confirmer status',
    getMessage: '[confirmer] Get message for confirmer',
    getType: '[confirmer] Get type of confirmer',
}

export const mutationTypes = {
    closeModal: '[confirmer] Close modal',
    openModal: '[confirmer] Open modal',
    changeMessage: '[confirmer] Change message',
    changeResolve: '[confirmer] Change resolve',
    changeReject: '[confirmer] Change reject',
    changeType: '[confirmer] Change type of confirmer',
}

export const actionTypes = {
    confirmHandle: '[confirmer] Confirm handle',
    promptHandle: '[confirmer] Prompt handle',
    openModal: '[confirmer] Confirm handle',
    onAgree: '[confirmer] Agree handle',
    onCancel: '[confirmer] Cancel handle',
}

const getters = {
    [getterTypes.getStatus]: (state) => state.modalIsActive,
    [getterTypes.getMessage]: (state) => state.message,
    [getterTypes.getType]: (state) => state.type,
}

const mutations = {
    [mutationTypes.closeModal](state) {
        state.modalIsActive = false
        state.message = ''
    },
    [mutationTypes.openModal](state, text) {
        state.message = text
        setTimeout(() => {
            state.modalIsActive = true
        }, 300)
    },
    [mutationTypes.changeResolve](state, res) {
        state.action.res = res
    },
    [mutationTypes.changeReject](state, rej) {
        state.action.rej = rej
    },
    [mutationTypes.changeType](state, type) {
        state.type = type
    },
}

const actions = {
    [actionTypes.confirmHandle]({ commit }, text) {
        commit(mutationTypes.changeType, 'regular')
        return new Promise((res, rej) => {
            commit(mutationTypes.changeResolve, res)
            commit(mutationTypes.changeReject, rej)
            commit(mutationTypes.openModal, text)
        })
    },
    [actionTypes.promptHandle]({ commit }, text) {
        commit(mutationTypes.changeType, 'with-prompt')
        return new Promise((res, rej) => {
            commit(mutationTypes.changeResolve, res)
            commit(mutationTypes.changeReject, rej)
            commit(mutationTypes.openModal, text)
        })
    },
    [actionTypes.onAgree]({ commit, state }, value) {
        state.action.res(value)
        commit(mutationTypes.closeModal)
        commit(mutationTypes.changeResolve, null)
        commit(mutationTypes.changeReject, null)
    },
    [actionTypes.onCancel]({ commit, state }) {
        state.action.rej()
        commit(mutationTypes.closeModal)
        commit(mutationTypes.changeResolve, null)
        commit(mutationTypes.changeReject, null)
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
