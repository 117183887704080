<template>
    <div class="header">
        <div class="header__logo">
            <template v-if="isDemo">
                <LogoPrimary />
            </template>
        </div>
        <button class="header__toggle-btn" @click.prevent="toggleMenu">
            <v-icon>mdi-menu</v-icon>
        </button>
        <button class="header__extra-btn" @click="mobileMenuSwitchHandle">
            <v-icon>mdi-dots-vertical</v-icon>
        </button>
        <div
            class="header__action"
            :class="{ 'header__action--is-active': mobileMenuIsVisible }"
        >
            <div class="languages">
                <HeaderLanguages />
            </div>
            <div v-if="getAllowed(['admin', 'manager'])" class="messages">
                <NotificationHeaderList />
            </div>
            <div class="user">
                <template v-if="userName">
                    <v-menu v-model="menu.user" offset-y nudge-bottom="30">
                        <template #activator="{ on, attrs }">
                            <h3 v-bind="attrs" class="user__name" v-on="on">
                                {{ userName }}
                            </h3>
                        </template>
                        <UserActionList />
                    </v-menu>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import LogoPrimary from '@/components/common/logo-primary'
import UserActionList from '@/components/users-list/user-action-list'
import HeaderLanguages from '@/components/common/header-languages'
import NotificationHeaderList from '@/components/notifications/notification-header-list'
import { mapGetters, mapMutations } from 'vuex'
import { mutationTypes } from '@/store/modules/main-menu'
import { gettersTypes } from '@/store/modules/auth'

export default {
    name: 'HeaderPrimary',
    components: {
        LogoPrimary,
        UserActionList,
        HeaderLanguages,
        NotificationHeaderList,
    },
    data() {
        return {
            menu: {
                user: false,
                messages: false,
            },
            mobileMenuIsVisible: false,
        }
    },
    computed: {
        ...mapGetters('auth', {
            userName: gettersTypes.getName,
        }),
        isDemo() {
            return process.env.VUE_APP_TARGET !== 'demo'
        },
    },
    methods: {
        ...mapMutations('mainMenu', { toggleMenu: mutationTypes.toggle }),
        mobileMenuSwitchHandle: function () {
            this.mobileMenuIsVisible = !this.mobileMenuIsVisible
        },
    },
}
</script>

<style scoped lang="scss">
.header {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    font-size: 14px;
    background: $color-for-side-bar;

    @include tablet-up {
        background: $color-primary-light;
    }

    &__action {
        position: absolute;
        width: 100%;
        top: 75px;
        height: 50px;
        padding: 0 15px;
        justify-content: space-between;
        left: 0;
        background: #fff;
        z-index: -1;
        transition: transform 500ms ease-in-out;
        transform: translateY(-110%);

        display: flex;
        align-items: center;

        @include tablet-up {
            position: static;
            width: auto;
            top: auto;
            left: auto;
            height: auto;
            padding: 0;
            background: transparent;
            z-index: 0;
            transform: translateY(0);
            justify-content: flex-start;
        }

        &--is-active {
            transform: translateY(0);
        }
    }

    &__logo {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        @include tablet-up {
            display: none;
        }
    }

    &__toggle-btn i,
    &__extra-btn i {
        color: $color-primary-light;

        @include tablet-up {
            color: $color-primary-dark;
        }
    }

    &__extra-btn {
        @include tablet-up {
            display: none;
        }
    }
}

.user {
    margin-left: auto;
    text-align: right;

    @include for-hebrew {
        margin-left: 0;
        margin-right: auto;
    }

    @include tablet-up {
        margin-left: 35px;
        position: static;
        visibility: visible;
        opacity: unset;

        @include for-hebrew {
            margin-left: 35px;
            margin-right: 35px;
        }
    }

    &__name {
        position: relative;

        &:before {
            content: '';
            display: block;
            position: absolute;
            top: 50%;
            left: -5px;
            transform: translate(-100%, -50%);
            width: 10px;
            height: 10px;
            background: #37bc9b;
            border-radius: 50%;

            @include for-hebrew {
                left: auto;
                right: -5px;
                transform: translate(100%, -50%);
            }
        }
    }

    &__logout {
        cursor: pointer;
        opacity: 0.75;
        transition: opacity 100ms ease-in-out;
        font-size: 12px;

        &:hover {
            opacity: unset;
        }

        i {
            font-size: 10px;
            line-height: 10px;
        }
    }
}

.languages {
    height: fit-content;
}

.messages {
    margin-left: 15px;
    @include for-hebrew {
        margin-left: 5px;
        margin-right: 15px;
    }
    order: 1;

    @include tablet-up {
        order: 0;
        margin-left: 15px;
    }
}
</style>
