<template>
    <v-dialog v-model="isActive" width="500" @click:outside="closeHandle">
        <div class="add-meeting">
            <div class="add-meeting__head">
                <h3 class="add-meeting__title">
                    {{ textContent['meeting-schedule'] }}
                </h3>
                <v-btn icon color="#55595c" @click="closeHandle">
                    <v-icon>mdi-close-circle-outline</v-icon>
                </v-btn>
            </div>
            <div class="add-meeting__body">
                <div class="tabs__input-wrapper">
                    <v-text-field
                        v-model="fields['date']"
                        dense
                        outlined
                        type="date"
                        name="date"
                        :label="textContent['date']"
                        :error-messages="errors['date']"
                        @input="errors['date'] = ''"
                    ></v-text-field>
                </div>
                <div class="tabs__input-wrapper">
                    <v-text-field
                        v-model="fields['subject']"
                        dense
                        outlined
                        type="text"
                        name="subject"
                        :label="textContent['subject']"
                        :error-messages="errors['subject']"
                        @input="errors['subject'] = ''"
                    ></v-text-field>
                </div>
            </div>
            <div class="add-meeting__foot">
                <v-btn color="#55595c" dark small @click="closeHandle">
                    {{ textContent['close'] }}
                </v-btn>
                <v-btn color="#967adc" dark small @click="onAdd">
                    {{ textContent['update'] }}
                </v-btn>
            </div>
        </div>
    </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { getterTypes as getterTypesClients } from '@/store/modules/clients'
import { find } from 'lodash/collection'
import languageMixin from '@/mixins/language.mixin'
import popUpTypes from '@/types/pop-up-types'
import modalsMixin from '@/mixins/modals.mixin'
import {
    actionTypes,
    getterTypes as getterTypesCalendar,
} from '@/store/modules/calendar'
import { getRange } from '@/helpers/getDateInFormat'
import { actionTypes as actionTypesOrders } from '@/store/modules/orders'

const { calendarAddForUsa } = popUpTypes

export default {
    name: 'CalendarModalAddForUsa',
    mixins: [modalsMixin, languageMixin],
    emits: ['close-handle', 'on-add'],
    data() {
        return {
            selfName: calendarAddForUsa,
            tabs: '',
            clientFullName: '',
            fields: {},
            errors: {},
            contentType: 'CalendarModalAdd',
        }
    },
    computed: {
        ...mapGetters('clients', {
            getAllClients: getterTypesClients.getAllClients,
        }),
        ...mapGetters('calendar', {
            getMeetingPerDay: getterTypesCalendar.getMeetingsPerDay,
        }),
        time: function () {
            let time = []
            let date = new Date()
            date.setHours(9)
            date.setMinutes(0)
            const halfHour = 1800000

            for (let i = 0; i < 12; i++) {
                const h = date.getHours()
                const m =
                    date.getMinutes().toString().length === 1
                        ? date.getMinutes() + '0'
                        : date.getMinutes()
                time = [...time, `${h}:${m}`]
                date = new Date(+date + halfHour)
            }
            return time
        },
        isnUsaManager: function () {
            return !this.getAllowed(['usa_manager'])
        },
    },
    watch: {
        isActive: function (value) {
            if (!value) return
            this.initHandle()
            this.fields['subject'] = ''
            this.fields['date'] = ''
        },
    },
    mounted() {
        if (this.fieldsData?.fields?.wig_id) {
            this.tabs = 1
            this.fields['client_id'] = this.fields['wig_id']
        }
    },
    updated() {
        if (this.fieldsData?.fields?.wig_id) {
            this.tabs = 1
        }
    },
    methods: {
        ...mapActions('calendar', {
            getMeeting: actionTypes.getAllForSelected,
            addMeeting: actionTypes.addNewOne,
        }),
        ...mapActions('orders', {
            updateCompleteDate: actionTypesOrders.updateCompleteDate,
        }),
        selectedClientHandle: function ({ name, id, fullName }) {
            this.fields['client_id'] = id
            this.fields.client = fullName
            this.clientFullName = name
        },
        selectedWigHandle: function ({ id, clientId }) {
            this.fields['wig_id'] = id
            this.fields['client_id'] = clientId
            this.clientFullName = find(this.getAllClients, {
                id: clientId,
            })?.full_name
            this.fields.client = this.clientFullName
        },
        onAdd: async function () {
            //:todo need refactor

            const times = [
                '9:00',
                '9:30',
                '10:00',
                '10:30',
                '11:00',
                '11:30',
                '12:00',
                '12:30',
                '13:00',
                '13:30',
                '14:00',
            ]

            await this.getMeeting({
                type: 'us_agent',
                range: getRange(this.fields.date),
            })
            const busyTimes = (
                await this.getMeetingPerDay('us_agent', this.fields.date)
            ).map(({ start_at }) => start_at.split('').slice(11).join(''))

            let freeTime = '14:00'

            times
                .reverse()
                .forEach(
                    (time) =>
                        (freeTime = busyTimes.includes(time) ? freeTime : time)
                )

            this.fields = { ...this.fields, time: freeTime }

            await this.addMeeting(this.fields)
            await this.updateCompleteDate({
                id: this.fields.wig_id,
                completeData: `${this.fields.date} ${freeTime}`,
            })
            this.closeHandle()
        },
        initHandle: function () {
            const calendar = 'US_AGENT'
            const wig_id = this.extraData.wig_id
            const client_id = this.extraData.client_id
            const time = '10:00'
            const client = 'usa'
            this.fields = {
                ...this.fields,
                calendar,
                wig_id,
                client_id,
                time,
                client,
            }
        },
    },
}
</script>

<style scoped lang="scss">
.add-meeting {
    background: $color-primary-light;

    &__head {
        padding: 10px;
        border-bottom: 1px solid #eceeef;
        display: flex;
        justify-content: space-between;
    }

    &__title {
        font-weight: 500;
    }

    &__body {
        padding: 0 10px;
    }

    &__foot {
        padding: 10px;
        display: flex;
        justify-content: space-between;
    }
}

.tabs {
    &__title {
    }

    &__input-wrapper--client {
        margin-bottom: 25px;
    }

    &__icon {
    }

    &__body {
    }
}
</style>
