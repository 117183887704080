import getColorScheme from '@/helpers/calendar-get-color-scheme.utils'
import { getFullDate } from '@/helpers/getDateInFormat'

export const serializeHolidaysData = (holidays) => {
    return holidays.map((holiday) => {
        let startAt = holiday['start_at'].replace(/-|\s|:/gi, ',').split(',')
        startAt[1] -= 1

        const endAt = holiday['start_at']

        return {
            startAt: holiday['start_at'],
            endAt,
            text: holiday.text,
            color: '#787878',
            category: 'holidays',
        }
    })
}

const serializeMeetingData = (meetings) => {
    return meetings.map((meeting) => {
        const { color, iconColor } = getColorScheme(meeting.calendarType)
        const duration = 1800000
        let startAt = meeting.startAt.replace(/-|\s|:/gi, ',').split(',')
        startAt[1] -= 1
        const endAt = getFullDate(+new Date(...startAt) + duration)

        return {
            id: meeting.id,
            startAt: meeting.startAt,
            client: {
                fullName: meeting.client.fullName,
                id: meeting.client.id,
                phoneNumber: meeting.client.phone,
            },
            meetingManager: meeting.meetingManager,
            calendar: meeting.calendarType,
            subject: meeting.subject || '',
            'event-width': 40,
            color: color,
            iconColor: iconColor,
            endAt,
            wigIsReady: meeting?.wig?.isReady || false,
            wigId: meeting?.wig?.id || null,
            previousDates: meeting.previousDates || [],
        }
    })
}

export default serializeMeetingData
