import axios from '@/api/axios'

export default {
    getEmployees: ({ from, to }) =>
        axios
            .get(`/base/api/report/employees?from=${from}&to=${to}`)
            .then((res) => res.data),
    getEmployeesPdf: ({ from, to }) =>
        axios
            .get(
                `/base/api/report/employees/pdf?from=${from}&to=${to}&user_id=1`
            )
            .then((res) => res.data),
    getPayments: ({ from, to }) =>
        axios
            .get(`/base/api/report/payments?from=${from}&to=${to}`)
            .then((res) => res.data),
    getPaymentsPdf: ({ from, to }) =>
        axios
            .get(
                `/base/api/report/payments/pdf?from=${from} 00:00:00&to=${to} 23:59:59`
            )
            .then((res) => res.data),
}
