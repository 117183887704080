import getters from './getters'
import mutations from './mutations'
import actions from './actions'
export * from './getters'
export * from './mutations'

const state = {
    selectedComponent: null,
    props: null,
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
