<template>
    <ul class="wigs">
        <li
            v-for="{ id, createdAt, paymentStatus, state, details } of wigsList"
            :key="id"
            class="wigs__item"
            @click="
                () => {
                    goToSelectedOrder(id)
                }
            "
        >
            <div class="wigs__info">
                <div>
                    <p v-if="details.barcode">
                        {{ $t('pages.wigPage.generalList.items.barcode') }}:
                        <span>{{ details.barcode }}</span>
                    </p>
                    <p>
                        {{
                            $t(
                                'pages.wigPage.generalList.items.additionalWigID'
                            )
                        }}:
                        <span>{{ details.user_generated_id }}</span>
                    </p>
                    <p>
                        {{ $t('pages.wigPage.generalList.items.status') }}:
                        <span>{{ $t(`shared.wigStates.${state}`) }}</span>
                        <v-icon
                            v-if="details.is_repair"
                            color="#967adc"
                            small
                            class="wigs__repair"
                        >
                            mdi-wrench-outline
                        </v-icon>
                    </p>
                </div>
                <div class="wigs__info-payment">
                    <v-progress-circular
                        :value="paymentStatus"
                        :rotate="270"
                        color="#3CBD51"
                        :width="5"
                        :size="24"
                    >
                    </v-progress-circular>
                    <p>{{ paymentStatus }}%</p>
                </div>
            </div>
            <div class="wigs__foot">
                <p>
                    {{ $t('pages.wigPage.generalList.items.createdAt') }}:
                    <span>
                        {{ createdAt }}
                    </span>
                </p>
                <p v-if="details.createdByUsaManager">
                    {{ $t('pages.wigPage.generalList.items.manager') }}:
                    <span>{{ details.manager }}</span>
                </p>
            </div>
        </li>
    </ul>
</template>

<script>
import { mapActions } from 'vuex'
import { actionTypes } from '@/store/modules/orders'
import wigTransitions from '@/helpers/transitions/wig-transitions'

export default {
    name: 'ClientWigsList',
    props: {
        wigs: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        wigsList: function () {
            return this.wigs.map(
                ({
                    id,
                    created_at,
                    deposit,
                    totalPrice,
                    state,
                    ...details
                }) => ({
                    id,
                    createdAt: this.formatDateTime(created_at),
                    state: this.convertToKebabCase(state),
                    details,
                    paymentStatus: !totalPrice
                        ? 0
                        : Math.floor((deposit / totalPrice) * 100),
                })
            )
        },
    },
    methods: {
        ...mapActions('orders', { getOrder: actionTypes.loadOrder }),
        goToSelectedOrder: function (id) {
            wigTransitions.moveToWigPageInNewTab(id)
        },
        formatDateTime: function (dateString) {
            const date = new Date(dateString)
            const formattedDate = `${('0' + date.getDate()).slice(-2)}-${(
                '0' +
                (date.getMonth() + 1)
            ).slice(-2)}-${date.getFullYear()}`

            const formattedTime = `${('0' + date.getHours()).slice(-2)}:${(
                '0' + date.getMinutes()
            ).slice(-2)}`

            return `${formattedDate} ${formattedTime}`
        },
        convertToKebabCase: function (str) {
            return str.toLowerCase().replace(/ /g, '-')
        },
    },
}
</script>

<style lang="scss" scoped>
.wigs {
    padding: 16px;
    &__item {
        align-items: center;
        font-size: 14px;
        border: 2px solid #967adc;
        border-radius: 16px;
        padding: 8px 16px;
        cursor: pointer;
        & + & {
            margin-top: 16px;
        }
    }
    &__repair {
        margin-left: 10px;
    }
    &__info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        p {
            margin-bottom: 8px;
            font-weight: bold;
            span {
                font-weight: normal;
            }
        }
        &-payment {
            position: relative;
            display: flex;
            flex-direction: column;
            gap: 4px;
            align-items: center;
            p {
                font-weight: normal;
                margin-bottom: 0;
            }
        }
    }

    &__desc {
    }

    &__link {
        margin-right: 5px;

        i {
            color: #3bafda;
        }
    }
    &__foot {
        display: flex;
        justify-content: space-between;
        border-top: 1px dotted #c5c5c5;
        font-size: 0.8em;
        padding-top: 5px;

        span {
            font-weight: 700;
        }

        p {
            margin-bottom: 0;
        }
    }
}
</style>
