import api from '@/api/dashboard'

const state = () => ({
    totally: {
        clients: {
            title: 'Clients',
            count: 0,
        },
        ordersTotal: {
            title: 'Orders total',
            count: 0,
        },
        ordersInProgress: {
            title: 'Orders in progress',
            count: 0,
        },
    },
    yourOrders: [],
})

export const getterTypes = {
    getTotally: '[dashboard] Get all counts',
    getYourOrders: '[dashboard] Get all your orders',
}

export const mutationTypes = {
    refreshAllTotally: '[dashboard] Refresh all info',
}

export const actionTypes = {
    getAllTotally: '[dashboard] Load all total info',
}

const getters = {
    [getterTypes.getTotally]: ({ totally }) => totally,
    [getterTypes.getYourOrders]: ({ yourOrders }) => yourOrders,
}

const mutations = {
    [mutationTypes.refreshAllTotally](state, data) {
        state.totally = {
            clients: {
                title: 'Clients',
                count: data['clients_count'],
            },
            ordersTotal: {
                title: 'Orders total',
                count: data['orders_total'],
            },
            ordersInProgress: {
                title: 'Orders in progress',
                count: data['orders_in_progress'],
            },
        }
        state.yourOrders = data['your_orders']
    },
}

const actions = {
    [actionTypes.getAllTotally]({ commit }) {
        return new Promise((res, rej) => {
            api.dashboard
                .dashboard()
                .then(({ data }) => {
                    commit(mutationTypes.refreshAllTotally, data)
                    res(data)
                })
                .catch((error) => {
                    rej(error)
                })
        })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
