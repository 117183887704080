const getCurrentProductionsStepName = (wig) => {
    /*
          0: "Draft"
          1: "Wigs in Production"
          2: "Ready New Wig"
          3: "Repair Wigs"
          4: "Ready Repair Wigs"
          5: "Cancelled Wigs"
          6: "Wigs In Stock"
          7: "Storage"
          8: "Delivered Wigs"
        */
    if (!wig?.state) return ''

    let keyWord = ''

    switch (wig.state.toLowerCase()) {
        case 'draft':
            keyWord = 'production-start'
            break
        case 'wigs in production':
            keyWord = 'production-complete'
            break
        case 'rework':
            keyWord =
                wig['is_repair'] === 1
                    ? 'repair-complete'
                    : 'production-complete'
            break
        case 'repair wigs':
            keyWord = 'repair-complete'
            break
        case 'ready new wig':
        case 'ready repair wigs':
            keyWord = 'storage'
            break
        case 'storage':
            keyWord = 'delivery'
            break
        case 'wigs in stock':
            keyWord = 'restoreWig'
            break
        default:
            keyWord = ''
            break
    }

    if (keyWord === '') {
        console.log(wig.state.toLowerCase())
    }

    return keyWord
}

export default getCurrentProductionsStepName
