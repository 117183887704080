import { mutationTypesOrdersReworkProperties } from '@/store/modules/orders-rework-properties/types'
import { setStorage } from '@/helpers/Local'
import { find } from 'lodash'

export default {
    [mutationTypesOrdersReworkProperties.initProperties]: (
        state,
        properties
    ) => {
        state.ordersReworkProperties = properties
    },
    [mutationTypesOrdersReworkProperties.createProperties]: (
        state,
        newProperties
    ) => {
        state.ordersReworkProperties = [
            ...state.ordersReworkProperties,
            ...newProperties,
        ]
    },
    [mutationTypesOrdersReworkProperties.deleteProperties]: (state, ids) => {
        state.ordersReworkProperties = state.ordersReworkProperties.filter(
            ({ id }) => !ids.includes(id)
        )
    },
    [mutationTypesOrdersReworkProperties.checkProperties]: (
        state,
        { ids, propertiesData }
    ) => {
        state.ordersReworkProperties = state.ordersReworkProperties.map(
            (property) => {
                const updatedProperty = find(propertiesData, {
                    id: property.id,
                })
                return ids.includes(property.id)
                    ? {
                          ...property,
                          done: true,
                          done_at: updatedProperty.done_at,
                          done_by: updatedProperty.done_by,
                      }
                    : property
            }
        )
        setStorage('reworkProperties', state.ordersReworkProperties)
    },
    [mutationTypesOrdersReworkProperties.uncheckProperties]: (state, ids) => {
        state.ordersReworkProperties = state.ordersReworkProperties.map(
            (property) => {
                return ids.includes(property.id)
                    ? {
                          ...property,
                          done: false,
                          done_at: null,
                          done_by: null,
                      }
                    : property
            }
        )
        setStorage('reworkProperties', state.ordersReworkProperties)
    },
}
