<template>
    <div class="wig-page">
        <Transition>
            <div
                v-if="!pageIsMounted || orderIsLoading"
                class="wig-page__loader"
            >
                <v-progress-circular
                    indeterminate
                    color="primary"
                ></v-progress-circular>
            </div>
        </Transition>
        <div class="wig-page__head">
            <button
                class="wig-page__back-btn wig-page__back-btn--mobile"
                @click="goBack"
            >
                <v-icon v-if="isRtl">mdi-chevron-right</v-icon>
                <v-icon v-else>mdi-chevron-left</v-icon>
                {{ $t('pages.wigPage.returnBtn') }}
            </button>
            <WigPageHeader :wig="order" />
        </div>
        <div class="wig-page__body">
            <div class="wig-page__side-container">
                <button class="wig-page__back-btn" @click="goBack">
                    <v-icon v-if="isRtl">mdi-chevron-right</v-icon>
                    <v-icon v-else>mdi-chevron-left</v-icon>
                    {{ $t('pages.wigPage.returnBtn') }}
                </button>
                <WigPageGeneralList
                    :wig="{ ...order, payments }"
                    :wig-payments="payments"
                    :wig-payments-total="sumOfPayments"
                />
                <WigPagePayments :total-price="order.totalPrice" />
            </div>
            <div
                ref="mainHTMLElement"
                class="wig-page__main-container main-container"
                :class="{ ['is-sticky']: headIsSticky }"
            >
                <div class="main-container__head">
                    <v-tabs
                        v-model="tabs"
                        color="#3bafda"
                        centered
                        :show-arrows="isMobile"
                        height="50"
                    >
                        <v-tab
                            v-for="(
                                { titleKey, title, icon, href }, index
                            ) in tabHeaders"
                            :key="index"
                            :data-test-id="`wig-page--tabs--${titleKey}`"
                            :href="`#${href}`"
                        >
                            <p class="tabs__title">
                                <v-icon class="tabs__icon">{{ icon }} </v-icon>
                                <span>{{
                                    $t(`pages.wigPage.tabs.${titleKey}`)
                                }}</span>
                            </p>
                        </v-tab>
                    </v-tabs>
                </div>
                <div
                    ref="bodyHTMLElement"
                    class="main-container__body"
                    :style="`--vr-body-height:${bodyHeight}px`"
                >
                    <v-tabs-items v-model="tabs">
                        <v-tab-item
                            v-if="isRework"
                            value="rework"
                            height="100%"
                        >
                            <WigPageTabContainer>
                                <WigPageTabDevinder>
                                    <template #main>
                                        <OrdersReworkTab is-rework-properties />
                                    </template>
                                    <template #gallery>
                                        <OrdersGallery
                                            :files="order.files"
                                            :state="order.state"
                                        />
                                    </template>
                                </WigPageTabDevinder>
                            </WigPageTabContainer>
                        </v-tab-item>
                        <v-tab-item value="main">
                            <WigPageTabContainer>
                                <WigPageTabDevinder>
                                    <template #main>
                                        <template v-if="order.is_repair === 1">
                                            <OrdersReworkTab />
                                        </template>
                                        <template
                                            v-else-if="order.is_repair !== 1"
                                        >
                                            <OrdersPropertiesList
                                                :data-properties="properties"
                                                :is-repair="
                                                    order.is_repair === 1
                                                "
                                            />
                                        </template>
                                    </template>
                                    <template #gallery>
                                        <OrdersGallery
                                            :files="order.files"
                                            :state="order.state"
                                        />
                                    </template>
                                </WigPageTabDevinder>
                            </WigPageTabContainer>
                        </v-tab-item>
                        <v-tab-item value="meeting">
                            <template v-if="order.meetings">
                                <ClientMeetingsList
                                    :meetings="order.meetings"
                                />
                            </template>
                        </v-tab-item>
                        <v-tab-item value="logs">
                            <WigPageTabContainer>
                                <OrderListOfLogs
                                    :wig="order"
                                    :logs="order['stateLog']"
                                ></OrderListOfLogs>
                            </WigPageTabContainer>
                        </v-tab-item>
                    </v-tabs-items>
                </div>
                <div
                    class="main-container__foot"
                    :class="{ ['is-sticky']: headIsSticky }"
                >
                    <WigPageMainActions :wig="order" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import index, { mapActions, mapGetters } from 'vuex'
import {
    actionTypes as actionTypesOrders,
    getterTypes as getterTypesOrders,
} from '@/store/modules/orders'
import OrdersReworkTab from '@/components/orders/orders-rework-tab.vue'
import OrdersGallery from '@/components/orders/orders-gallery.vue'
import OrderListOfLogs from '@/components/orders/order-list-of-logs.vue'
import ClientMeetingsList from '@/components/clients/client-meetings-list.vue'
import languageMixin from '@/mixins/language.mixin'
import languageOrderExtraMixin from '@/mixins/language-order-extra.mixin'
import { gettersTypes as getterTypesForAuth } from '@/store/modules/auth'
import regularToSnakeCase from '@/helpers/regular-to-snake-case'
import { getterTypes as getterTypesPayments } from '@/store/modules/payments'
import OrdersPageGeneralList from '@/components/orders/orders-page-general-list.vue'
import popUpTypes from '@/types/pop-up-types'
import { actionTypes as actionTypesModals } from '@/store/modules/modals'
import OrdersDetailsPageMainActions from '@/components/orders/orders-details-page-main-actions.vue'
import OrdersPropertiesList from '@/components/orders/orders-properties-list.vue'
import OrdersPagePayments from '@/components/orders/orders-page-payments.vue'
import getWigColorByState from '@/helpers/getWigColorByState'
import WigPageHeader from '@/features/wig-page/components/wig-page-header.vue'
import WigPageGeneralList from '@/features/wig-page/components/wig-page-general-list.vue'
import WigPagePayments from '@/features/wig-page/components/wig-page-payments.vue'
import WigPageMainActions from '@/features/wig-page/components/wig-page-main-actions.vue'
import WigPageTabContainer from '@/features/wig-page/components/wig-page-tab-container.vue'
import WigPageTabDevinder from '@/features/wig-page/components/wig-page-tab-devinder.vue'
import SlideOutV2 from '@/components/common/slide-out-v2.vue'
const { orderGeneralUpdate, orderClientUpdate } = popUpTypes

export default {
    components: {
        SlideOutV2,
        WigPageTabDevinder,
        WigPageTabContainer,
        WigPageMainActions,
        WigPagePayments,
        WigPageGeneralList,
        WigPageHeader,
        OrdersPagePayments,
        OrdersPropertiesList,
        OrdersDetailsPageMainActions,
        OrdersPageGeneralList,
        OrdersReworkTab,
        OrdersGallery,
        OrderListOfLogs,
        ClientMeetingsList,
    },
    mixins: [languageMixin, languageOrderExtraMixin],
    data() {
        return {
            tabs: '',
            isNewDesing: true,
            bodyHeight: 0,
            pageIsMounted: false,
            headIsSticky: false,
        }
    },
    computed: {
        properties: function () {
            if (this.order.is_repair === 1) {
                return this.order.additional_properties
            }
            if (!this.order.properties) return {}
            const res = {}
            Object.keys(this.order.properties).forEach((key) => {
                if (this.order.properties[key])
                    res[key] = this.order.properties[key]
            })
            return res
        },
        status: function () {
            if (!this.getAllowed(['admin', 'manager', 'worker']))
                return this.extraContent[regularToSnakeCase(this.order.state)]
            const status = this.isProduction
                ? this.order.currentStatus
                : this.order.state
            return this.extraContent[regularToSnakeCase(status)]
        },
        index() {
            return index
        },
        ...mapGetters('orders', {
            order: getterTypesOrders.getOrder,
            orderIsLoading: getterTypesOrders.isLoading,
        }),
        ...mapGetters('auth', { getAllowed: getterTypesForAuth.getAllowed }),
        ...mapGetters('payments', {
            payments: getterTypesPayments.getPayments,
            sumOfPayments: getterTypesPayments.getSum,
        }),
        permissions: function () {
            const btnDelete = this.getAllowed(['admin'])
            const afterStart = this.getAllowed([
                'admin',
                'manager',
                this.roleUsaManagerAfterStartProduction,
            ])
            return { btnDelete, afterStart }
        },
        reworkBtnIsActive: function () {
            return (
                this.order['state'] &&
                (this.order['state'].toLowerCase() === 'ready new wig' ||
                    this.order['state'].toLowerCase() === 'ready repair wigs')
            )
        },
        roleUsaManagerAfterStartProduction: function () {
            return this.order?.state?.toLowerCase() === 'draft'
                ? 'usa_manager'
                : ''
        },
        actionTypeForBtn: function () {
            /*
          0: "Draft"
          1: "Wigs in Production"
          2: "Ready New Wig"
          3: "Repair Wigs"
          4: "Ready Repair Wigs"
          5: "Cancelled Wigs"
          6: "Wigs In Stock"
          7: "Storage"
          8: "Delivered Wigs"
        */
            if (!this.order['state']) return ''

            switch (this.order['state'].toLowerCase()) {
                case 'draft':
                    return 'Production Start'
                case 'wigs in production':
                    return 'Production complete'
                case 'rework':
                    return this.order['is_repair'] === 1
                        ? 'Repair complete'
                        : 'Production complete'
                case 'repair wigs':
                    return 'Repair complete'
                case 'ready new wig':
                case 'ready repair wigs':
                    return 'Storage'
                case 'storage':
                    return 'Delivery'
                default:
                    return ''
            }
        },
        wigID() {
            return this.$route.params.wigID
        },
        userGeneratedId: function () {
            return this.order['user_generated_id']
        },
        isRepair: function () {
            return !!this.order['is_repair']
        },
        isRework: function () {
            return (
                this.order.state === 'Rework' ||
                this?.order?.rework_additional_properties?.length > 0
            )
        },
        isMobile: function () {
            return this.$resize && !this.$mq.above(1023)
        },
        deliveryBtnIsActive: function () {
            return (
                this.order['state'] &&
                (this.order['state'].toLowerCase() === 'ready new wig' ||
                    this.order['state'].toLowerCase() === 'ready repair wigs')
            )
        },
        tabHeaders: function () {
            return [
                this.isRework
                    ? {
                          titleKey: 'reworkWorkflow',
                          title: 'Rework workflow',
                          icon: 'mdi-repeat',
                          href: 'rework',
                      }
                    : undefined,
                {
                    titleKey:
                        this.order.is_repair === 1 ? 'workflow' : 'information',
                    title:
                        this.order.is_repair === 1 ? 'Workflow' : 'Information',
                    icon: 'mdi-image-outline',
                    href: 'main',
                },
                {
                    titleKey: 'meetings',
                    title: this.textContent['meetings'],
                    icon: 'mdi-handshake-outline',
                    href: 'meeting',
                },
                {
                    titleKey: 'logs',
                    title: this.textContent['logs'],
                    icon: 'mdi-format-list-bulleted',
                    href: 'logs',
                },
            ].filter((i) => i)
        },
        wigIsLoaded: function () {
            return !!this.order?.id
        },
        changeClientBtnIsActive: function () {
            if (!this.getAllowed(['admin', 'manager'])) {
                return false
            }

            const state = this.order?.state?.toLowerCase() || ''
            return state !== 'archived'
        },
    },
    watch: {
        isRework: function () {
            this.tabs = null
        },
        // wigIsLoaded: function (wigIsLoaded) {
        //     if (wigIsLoaded) {
        //         setTimeout(() => {
        //             this.pageIsMounted = true
        //         }, 1250)
        //     }
        // },
        orderIsLoading: function (orderIsLoading) {
            if (orderIsLoading) {
                this.pageIsMounted = false
            } else {
                setTimeout(() => {
                    this.pageIsMounted = true
                }, 650)
            }
        },
    },
    beforeMount() {
        this.loadOrder({
            id: this.$route.params.wigID,
            withoutOpening: true,
        })
    },
    mounted() {
        this.updateBodyHeight()
        window.addEventListener('resize', this.updateBodyHeight)
        window.addEventListener(
            'scroll',
            this.mobileStickyHead.bind(null, this.$refs.footHTMLElement)
        )
    },
    unmounted() {
        window.removeEventListener('resize', this.updateBodyHeight)
    },
    methods: {
        ...mapActions('modals', { onOpen: actionTypesModals.openPopUp }),
        mobileStickyHead: function () {
            this.headIsSticky =
                this.$refs.mainHTMLElement?.getBoundingClientRect().top <= -8 ||
                false
        },
        closeHandle: function () {
            this.$router.push({ path: '/orders' })
        },
        moveToStockHandle: function () {
            console.log('moveToStockHandle')
        },
        stateUpdateHandle: function () {
            console.log('stateUpdateHandle')
        },
        statusOnChange: function (params) {
            console.log(params)
        },
        changeStatusHandle: function () {
            console.log('changeStatusHandle')
        },
        btnText: function (text) {
            return this.extraContent[regularToSnakeCase(text)]
        },
        openGeneralUpdate: function () {
            this.onOpen(orderGeneralUpdate)
        },
        deleteHandle: function () {
            console.log('deleteHandle')
        },
        ...mapActions('orders', {
            addWeight: actionTypesOrders.updateProperties,
            loadOrder: actionTypesOrders.loadOrder,
        }),
        getWigColorByState: function (state) {
            return getWigColorByState(state)
        },
        changeClientHandle: function () {
            this.onOpen(orderClientUpdate)
        },
        updateBodyHeight: function () {
            this.bodyHeight = this.$refs.bodyHTMLElement.offsetHeight
        },
        goBack: function () {
            const tabHasHistory = window.history.length > 1

            if (tabHasHistory) {
                return this.$router.back()
            }

            return this.$router.push('/orders')
        },
    },
}
</script>

<style lang="scss">
.wig-page {
    width: 100%;
    $head-height: 43px;
    position: relative;

    @include tablet-large-up {
        height: calc(100vh - 75px);
    }

    &__loader {
        background: rgba(255, 255, 255, 1);
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 300;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__head {
        height: fit-content;
        overflow: hidden;
        padding: 16px 0 0;

        @include tablet-large-up {
            height: $head-height;
            margin-bottom: 0;
            padding: 0;
        }
    }

    &__body {
        display: flex;
        flex-direction: column-reverse;

        @include tablet-large-up {
            height: calc(100% - $head-height);
            flex-direction: row;
            gap: 8px;
        }
    }

    &__side-container {
        padding: 0 16px 8px;
        min-width: 100%;

        @include tablet-large-up {
            padding: 0 16px 8px;
            min-width: 415px;
            overflow: auto;
        }
    }

    &__main-container {
        height: 100%;
    }

    &__back-btn {
        min-height: 30px;
        display: none;
        margin: 8px 0 16px;
        border: 1px solid #b9b9b9;
        border-radius: 16px;
        padding: 2px 24px 2px 16px;

        @include for-hebrew {
            padding: 2px 16px 2px 24px;
        }

        @include tablet-large-up {
            margin: 8px 0;
            border: none;
            border-radius: 0;
            padding: 0;
            display: block;
        }

        &--mobile {
            display: block;
            margin: 0 16px 16px;

            @include tablet-large-up {
                display: none;
            }
        }
    }
}

.main-container {
    $head_height: 50px;
    $foot_height: 80px;
    width: 100%;
    background: #fff;
    padding-top: 8px;
    display: flex;
    flex-direction: column;

    &.is-sticky {
        padding-top: 68px;
    }

    @include tablet-large-up {
        display: block;
    }

    &__head {
        position: relative;
        height: $head_height;
        width: 100%;
        box-shadow: 0 5px 5px rgba(0, 0, 0, 0.065);
        z-index: 2;
    }

    &__body {
        position: relative;
        height: calc(100% - $foot_height - $head_height);
        overflow: hidden;
        z-index: 1;
    }

    &__foot {
        width: 100%;
        padding: 16px;
        padding-right: 32px;
        order: -1;

        @include tablet-large-up {
            padding: 8px;
            padding-right: 16px;
            height: $foot_height;
            order: initial;
            position: relative;
            box-shadow: 0 -5px 5px rgba(0, 0, 0, 0.065);
            z-index: 2;
        }

        &.is-sticky {
            position: fixed;
            top: 0;
            z-index: 199;
            background: linear-gradient(0deg, #f4f4f4 0%, #fff 100%);
            box-shadow: 0 5px 5px rgba(0, 0, 0, 0.065);

            button {
                transform: translateX(24px);
                @include for-hebrew {
                    transform: translateX(-24px);
                }
            }
        }
    }
}

.v-enter-active,
.v-leave-active {
    transition: opacity 750ms ease;
}

.v-enter-from,
.v-leave-to {
    opacity: 0;
}
</style>
