<template>
    <header class="header">
        <router-link to="/" class="header__logo">
            <div v-if="false">
                <img
                    src="@/assets/common/logo-light-sm.png"
                    alt="logo"
                    class="header__logo-img"
                />
                <h3 class="header__logo-title">YAFI KAMINER</h3>
            </div>
        </router-link>
        <router-link to="#" class="header__extra">
            <v-icon>mdi-lock-outline</v-icon>
        </router-link>
    </header>
</template>

<script>
export default {
    name: 'Header',
    computed: {
        isDemo() {
            return process.env.VUE_APP_TARGET !== 'demo'
        },
    },
}
</script>

<style lang="scss" scoped>
.header {
    position: fixed;
    top: 0;
    left: 0;
    height: 62px;
    width: 100%;
    background: #1d2b36;
    font-size: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;

    &__logo {
        color: $color-primary-light;
        font-family: $font-muli;
        display: flex;
        align-items: center;
        text-decoration: none;
    }

    &__logo-title {
        margin-left: 15px;
        font-weight: 500;
    }

    &__extra {
        padding-right: 20px;
        text-decoration: none;

        i {
            color: $color-primary-light;
        }
    }
}
</style>
