var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"orders-context-info"},[_c('div',{staticClass:"orders-context-info__column"},[_c('h3',{staticClass:"orders-context-info__title"},[_vm._v(" "+_vm._s(_vm.textContent['barcode'])+" ")]),_c('p',{staticClass:"orders-context-info__desc"},[_vm._v(_vm._s(_vm.info.barcode))])]),_c('div',{staticClass:"orders-context-info__column"},[_c('h3',{staticClass:"orders-context-info__title"},[_vm._v(" "+_vm._s(_vm.textContent['status'])+" "),(
                    !_vm.isRepair &&
                    _vm.getAllowed([
                        'admin',
                        'manager',
                        'worker',
                        _vm.roleUsaManagerAfterStartProduction,
                    ])
                )?_c('v-btn',{attrs:{"x-small":"","color":"#f6bb42","dark":""},on:{"click":_vm.openStatusUpdate}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pencil-outline")])],1):_vm._e(),(
                    _vm.isRepair &&
                    _vm.getAllowed([
                        'admin',
                        'manager',
                        'worker',
                        _vm.roleUsaManagerAfterStartProduction,
                    ])
                )?_c('v-btn',{attrs:{"x-small":"","color":"#967adc","dark":""},on:{"click":_vm.openStatusUpdate}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pencil-outline")])],1):_vm._e()],1),_c('p',{staticClass:"orders-context-info__desc"},[_vm._v(" "+_vm._s(_vm.status)+" ")])]),_c('div',{staticClass:"orders-context-info__column"},[_c('h3',{staticClass:"orders-context-info__title"},[_vm._v(" "+_vm._s(_vm.textContent['deposit'])+" "),(
                    _vm.getAllowed(['admin', 'manager']) &&
                    _vm.info.state !== 'Archived'
                )?_c('v-btn',{attrs:{"x-small":"","dark":"","color":"#4caf50"},on:{"click":_vm.openDepositUpdate}},[_c('v-icon',{attrs:{"x-small":"","dark":""}},[_vm._v("mdi-plus")])],1):_vm._e()],1),_c('p',{staticClass:"orders-context-info__desc"},[_vm._v(_vm._s(_vm.sumOfPayments))])]),_c('div',{staticClass:"orders-context-info__column"},[_c('h3',{staticClass:"orders-context-info__title"},[_vm._v(" "+_vm._s(_vm.textContent['client'])+" "),(
                    _vm.getAllowed([
                        'ADMIN',
                        'manager',
                        _vm.roleUsaManagerAfterStartProduction,
                    ]) && _vm.info.state !== 'Archived'
                )?_c('v-btn',{attrs:{"x-small":"","color":"#f6bb42","dark":""},on:{"click":_vm.clientUpdateHandle}},[_vm._v(" "+_vm._s(_vm.textContent['change'])+" ")]):_vm._e()],1),_c('p',{staticClass:"orders-context-info__desc",on:{"click":() => _vm.goToClient(_vm.info.client_id)}},[_vm._v(" "+_vm._s(_vm.info.client)+" ")])]),_c('div',{staticClass:"orders-context-info__column"},[_c('h3',{staticClass:"orders-context-info__title"},[_vm._v(" "+_vm._s(_vm.textContent['deposit-history'])+" ")]),_c('p',{staticClass:"orders-context-info__desc"},[_c('OrderDepositHistory')],1)]),_c('div',{staticClass:"orders-context-info__column"},[_c('h3',{staticClass:"orders-context-info__title"},[_vm._v(" "+_vm._s(_vm.textContent['creator'])+" "),(
                    _vm.getAllowed(['ADMIN', 'manager']) &&
                    _vm.info.state !== 'Archived'
                )?_c('v-btn',{attrs:{"x-small":"","dark":"","color":"#967adc"},on:{"click":_vm.openAssignCreator}},[_vm._v(" "+_vm._s(_vm.textContent['assign'])+" ")]):_vm._e()],1),_c('p',{staticClass:"orders-context-info__desc"},[_vm._v(_vm._s(_vm.info.creator))])]),_c('div',{staticClass:"orders-context-info__column"},[_c('h3',{staticClass:"orders-context-info__title"},[_vm._v(" "+_vm._s(_vm.textContent['info'])+" ")]),_c('p',{staticClass:"orders-context-info__desc"},[_vm._v(_vm._s(_vm.info.info))])]),_c('div',{staticClass:"orders-context-info__column"},[_c('h3',{staticClass:"orders-context-info__title"},[_vm._v(" "+_vm._s(_vm.textContent['details'])+" ")]),_c('p',{staticClass:"orders-context-info__desc orders-context-info__desc--details"},[_vm._v(" "+_vm._s(_vm.info.details)+" ")])]),_c('div',{staticClass:"orders-context-info__column"},[_c('h3',{staticClass:"orders-context-info__title"},[_vm._v(" "+_vm._s(_vm.textContent['total-price'])+" ")]),_c('p',{staticClass:"orders-context-info__desc"},[_vm._v(_vm._s(_vm.info.totalPrice))])]),_c('div',{staticClass:"orders-context-info__column"},[_c('h3',{staticClass:"orders-context-info__title"},[_vm._v(" "+_vm._s(_vm.info.is_repair === 1 ? _vm.propertiesContent['repair-properties'] : _vm.propertiesContent['properties'])+" "),(
                    _vm.getAllowed([
                        'ADMIN',
                        'manager',
                        _vm.roleUsaManagerAfterStartProduction,
                    ]) && _vm.info.state !== 'Archived'
                )?_c('v-btn',{attrs:{"x-small":"","color":"#967adc","dark":""},on:{"click":_vm.openPropertiesUpdate}},[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pencil-outline")])],1):_vm._e()],1),_c('OrdersPropertiesList',{attrs:{"data-properties":_vm.properties,"is-repair":_vm.info.is_repair === 1}})],1),_c('div',{staticClass:"orders-context-info__column"},[_c('h3',{staticClass:"orders-context-info__title"},[_vm._v(" "+_vm._s(_vm.textContent['created'])+" ")]),_c('p',{staticClass:"orders-context-info__desc orders-context-info__desc--date"},[_vm._v(" "+_vm._s(_vm.dateFormatHandle(_vm.info['created_at']))+" ")])]),_c('div',{staticClass:"orders-context-info__column"},[_c('h3',{staticClass:"orders-context-info__title"},[_vm._v(" "+_vm._s(_vm.textContent['updated'])+" ")]),_c('p',{staticClass:"orders-context-info__desc orders-context-info__desc--date"},[_vm._v(" "+_vm._s(_vm.dateFormatHandle(_vm.info['updated_at']))+" ")])]),_c('div',{staticClass:"orders-context-info__column"},[_c('h3',{staticClass:"orders-context-info__title"},[_vm._v(" "+_vm._s(_vm.textContent['complete-date'])+" "),(
                    _vm.getAllowed(['ADMIN', 'manager']) &&
                    _vm.info.state !== 'Archived'
                )?_c('v-icon',{staticClass:"orders-context-info__icon-btn",attrs:{"color":"#3bafda"},on:{"click":_vm.moveToMeeting}},[_vm._v(" mdi-calendar-month-outline ")]):(
                    _vm.getAllowed(['usa_manager']) && !_vm.info['complete_data']
                )?_c('v-icon',{staticClass:"orders-context-info__icon-btn",attrs:{"color":"rgb(176, 84, 84)"},on:{"click":_vm.createMeetingForUsa}},[_vm._v(" mdi-calendar-month-outline ")]):_vm._e(),(
                    _vm.getAllowed(['ADMIN', 'manager']) &&
                    _vm.info.state === 'Rework'
                )?_c('v-icon',{staticClass:"orders-context-info__icon-btn",attrs:{"color":"#4caf50"},on:{"click":_vm.goToMeeting}},[_vm._v(" mdi-transfer-right ")]):_vm._e()],1),(_vm.info.state !== 'Archived')?_c('p',{staticClass:"orders-context-info__desc orders-context-info__desc--date"},[_vm._v(" "+_vm._s(_vm.dateFormatHandle(_vm.info['complete_data'], true))+" ")]):_vm._e()]),_c('div',{staticClass:"orders-context-info__column"},[_c('h3',{staticClass:"orders-context-info__title"},[_vm._v(" "+_vm._s(_vm.textContent['storage-info'])+" ")]),_c('p',{staticClass:"orders-context-info__desc"},[_vm._v(_vm._s(_vm.info.shippingInfo))])])])
}
var staticRenderFns = []

export { render, staticRenderFns }