<template>
    <component
        :is="getSelectedComponent"
        v-if="getSelectedComponent"
    ></component>
</template>
<script>
import WigPageAdditionalPropertiesUpdateSlideOut from '@/features/wig-page/components/wig-page-additional-properties-update-slide-out.vue'
import { mapGetters } from 'vuex'
import { getterTypesSlideOuts } from '@/store/modules/slide-outs/types'

export default {
    name: 'SlideOutContainer',
    components: {
        WigPageAdditionalPropertiesUpdateSlideOut,
    },
    data() {
        return {
            componentsMapper: {},
        }
    },
    computed: {
        ...mapGetters('slideOuts', {
            getSelectedComponent: getterTypesSlideOuts.GET_SELECTED_SLIDE_OUT,
        }),
        isSlideOutOpen: function () {
            return !!this.getSelectedComponent
        },
    },
    watch: {
        isSlideOutOpen: function (value) {
            if (value) {
                document
                    .querySelector('html')
                    .classList.add('overflow-y-hidden-v2')
            } else {
                document
                    .querySelector('html')
                    .classList.remove('overflow-y-hidden-v2')
            }
        },
    },
}
</script>

<style lang="scss" scoped></style>
