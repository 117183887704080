<template>
    <v-dialog v-model="isActive" width="420" @click:outside="closeHandle">
        <div class="creator">
            <div class="creator__head">
                <h4 class="creator__title">
                    {{ textContent['assign-creator'] }}
                </h4>
                <v-btn icon color="#55595c" @click="closeHandle">
                    <v-icon>mdi-close-circle-outline</v-icon>
                </v-btn>
            </div>
            <div class="creator__body">
                <div class="creator__user-list">
                    <v-autocomplete
                        v-model="user"
                        :items="users"
                        dense
                        outlined
                        :label="textContent['users']"
                        item-color="#967adc"
                        item-text="nickName"
                        item-value="id"
                        hide-details
                        :loading="isUserFetching"
                    >
                    </v-autocomplete>
                </div>
            </div>
            <div class="creator__foot">
                <div class="creator__actions">
                    <v-btn color="#55595c" dark small @click="closeHandle">
                        {{ textContent['close'] }}
                    </v-btn>
                    <v-btn color="#967adc" dark small @click="updateHandle">
                        {{ textContent['add'] }}
                    </v-btn>
                </div>
            </div>
        </div>
    </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { getterTypes } from '@/store/modules/users'
import { actionTypes as actionTypesUsers } from '@/store/modules/users'
import {
    actionTypes,
    getterTypes as getterTypesOrders,
} from '@/store/modules/orders'
import { actionTypes as actionTypesModalNotifications } from '@/store/modules/modal-notifications'

import { filter } from 'lodash'

import modalsMixin from '@/mixins/modals.mixin'
import popUpTypes from '@/types/pop-up-types'
import languageMixin from '@/mixins/language.mixin'
import { ordersV2ActionTypes } from '@/store/modules/orders_v2/types'

const { orderAssignCreator } = popUpTypes

export default {
    name: 'OrderModalAssignCreator',
    mixins: [modalsMixin, languageMixin],
    data() {
        return {
            selfName: orderAssignCreator,
            user: '',
        }
    },
    computed: {
        ...mapGetters('users', {
            users: getterTypes.getAllUsers,
            isUserFetching: getterTypes.isFetching,
        }),
        ...mapGetters('orders', { order: getterTypesOrders.getOrder }),
    },
    watch: {
        isActive: function (value) {
            if (!value) {
                this.user = ''
            } else {
                if (!this.users || this.users.length === 0) {
                    this.loadAllUsers()
                }
            }
        },
    },
    methods: {
        ...mapActions('users', { loadAllUsers: actionTypesUsers.loadAllUsers }),
        ...mapActions('orders', { onAssign: actionTypes.assignCreator }),
        ...mapActions('modalNotifications', {
            success: actionTypesModalNotifications.success,
        }),
        ...mapActions('ordersV2', {
            loadAllV2: ordersV2ActionTypes.FETCH_ORDERS,
        }),
        updateHandle: function () {
            const selectedUser = this.user
            const data = {
                orderId: this.order.id,
                userId: this.user,
                name: filter(this.users, function (o) {
                    return o.id === selectedUser
                })[0].login,
            }
            this.onAssign(data)
                .then(() => {
                    this.closeHandle()
                    this.success('Wig has got new creator')
                    this.loadAllV2()
                })
                .catch((er) => {
                    console.log(er)
                })
        },
    },
}
</script>

<style scoped lang="scss">
.creator {
    background: $color-primary-light;
    padding: 15px 0;

    &__head {
        border-bottom: 1px solid #eceeef;
        padding: 0 15px 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__body {
        padding: 15px;
    }

    &__foot {
        padding: 0 15px;
    }

    &__actions {
        display: flex;
        justify-content: space-between;
    }
}
</style>
