import api from '@/api/calendar'
import { meetingListActionTypes, meetingListMutationTypes } from './types'

export default {
    async [meetingListActionTypes.FETCH_MEETINGS]({ commit, state }) {
        const params = {
            page: state.page,
            per_page: state.itemPerPage,
            calendar: state.calendars,
            search: state.searchQuery,
        }

        try {
            commit(meetingListMutationTypes.SET_FETCHING, true)

            const { data } = await api.getMeetingList(params)

            commit(meetingListMutationTypes.SET_MEETINGS, data.meetings)
            commit(meetingListMutationTypes.SET_TOTAL, data.pagination.total)
            commit(
                meetingListMutationTypes.SET_PAGE_COUNT,
                data.pagination.total
            )
        } catch (error) {
            console.error('Error fetching meetings:', error)
        } finally {
            commit(meetingListMutationTypes.SET_FETCHING, false)
        }
    },
    [meetingListActionTypes.SET_PAGE]({ commit, dispatch }, page) {
        commit(meetingListMutationTypes.SET_PAGE, page)
        dispatch(meetingListActionTypes.FETCH_MEETINGS)
    },
    [meetingListActionTypes.SET_SEARCH_QUERY](
        { commit, dispatch },
        searchQuery
    ) {
        commit(meetingListMutationTypes.SET_SEARCH_QUERY, searchQuery)
        commit(meetingListMutationTypes.SET_PAGE, 1)
        dispatch(meetingListActionTypes.FETCH_MEETINGS)
    },
    [meetingListActionTypes.SET_CALENDARS]({ commit, dispatch }, calendars) {
        commit(meetingListMutationTypes.SET_CALENDARS, calendars)
        commit(meetingListMutationTypes.SET_PAGE, 1)
        dispatch(meetingListActionTypes.FETCH_MEETINGS)
    },
}
