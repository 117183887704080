<template>
    <v-expansion-panel>
        <v-expansion-panel-header
            color="#f7f7f9"
            data-test-id="wigs--new-wig-properties--wig-type--wig-type-drop-down-btn"
        >
            <h4 class="order-property__sub-title">
                1. {{ propertiesContent['wig-type'] }}
            </h4>
        </v-expansion-panel-header>
        <v-expansion-panel-content color="#f7f7f9">
            <div
                id="order-property--wig-type--wig-type-input"
                class="order-property__input-wrapper"
            >
                <v-autocomplete
                    v-model="properties.fields['wigType']"
                    outlined
                    :items="items"
                    :label="propertiesContent['wig-type']"
                    dense
                    attach="#order-property--wig-type--wig-type-input"
                    :error-messages="properties.errors['wigType']"
                    data-test-id="wigs--new-wig-properties--wig-type--wig-type-select"
                    @input="properties.errors['wigType'] = ''"
                >
                </v-autocomplete>
            </div>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
import languagePropertiesMixin from '@/mixins/language-properties.mixin'

export default {
    name: 'OrderFormPropertyWigType',
    mixins: [languagePropertiesMixin],
    props: {
        dataProperties: {
            type: Object,
            default: () => ({
                fields: {
                    ['wigType']: '',
                },
                errors: {},
            }),
        },
    },
    computed: {
        items: function () {
            return [
                { text: this.propertiesContent['none'], value: 'none' },
                { text: this.propertiesContent['full'], value: 'Full' },
                { text: this.propertiesContent['fall'], value: 'Fall' },
                {
                    text: this.propertiesContent['kipa-fall'],
                    value: 'Kipa Fall',
                },
                {
                    text: this.propertiesContent['hand-made'],
                    value: 'Hand Made',
                },
            ]
        },
        properties: function () {
            return this.dataProperties
        },
    },
}
</script>

<style lang="scss" scoped>
.order-property {
    &__sub-title {
        color: $color-primary-dark;
    }

    &__input-wrapper {
        padding: 15px 15px 0;
    }
}
</style>
