import calendar from './calendar'
import wigPage from './wig-page'
import wigs from './wigs'
import orders from './orders'
import users from './users'
import report from './report'

export default {
    calendar,
    wigPage,
    wigs,
    orders,
    users,
    report,
}
