<template>
    <v-dialog v-model="isActive" transition="opacity" fullscreen>
        <div class="loader">
            <div class="loader__circle"></div>

            <div class="loader__progress">
                <v-progress-circular
                    indeterminate
                    :size="75"
                    width="5"
                    :value="100"
                    color="primary"
                ></v-progress-circular>
            </div>
        </div>
    </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { getterTypes } from '@/store/modules/modal-loader-progress'

export default {
    name: 'LoaderProgressCircle',
    computed: {
        ...mapGetters('modalLoaderProgress', {
            isActive: getterTypes.getIsActive,
        }),
    },
}
</script>

<style scoped lang="scss">
.loader {
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: none;

    &__circle {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 75px;
        height: 75px;
        border-radius: 50%;
        background: rgba(255, 255, 255, 0.95);
        box-shadow: 0 0 20px rgba(0, 0, 0, 0.55);
        z-index: 1;
    }

    &__progress {
        position: relative;
        z-index: 2;
    }
}
</style>
