<template>
    <div class="actions">
        <ul class="actions__list">
            <li class="actions__item">
                <OrdersPrintInfo>
                    <template #activator>
                        <v-btn
                            min-width="50"
                            color="#967adc"
                            small
                            dark
                            outlined
                        >
                            <v-icon small>fa-print</v-icon>
                        </v-btn>
                    </template>
                </OrdersPrintInfo>
            </li>
            <li
                v-for="(action, index) in actions"
                :key="index"
                class="actions__item"
            >
                <v-btn
                    :color="action.color"
                    dark
                    small
                    :data-test-id="action.dataTestID || '-'"
                    @click="action.handle"
                >
                    <template v-if="action.icon">
                        <v-icon small>{{ action.icon }}</v-icon>
                    </template>
                    {{ $t(`pages.wigPage.mainActions.${action.labelKey}`) }}
                </v-btn>
            </li>
        </ul>
        <div class="mobile">
            <v-menu offset-y>
                <template #activator="{ on, attrs }">
                    <v-btn color="primary" small dark v-bind="attrs" v-on="on">
                        {{ $t(`pages.wigPage.mainActions.mobileButton`) }}
                    </v-btn>
                </template>
                <ul class="mobile__list">
                    <li
                        v-for="(action, index) in actions"
                        :key="index"
                        class="mobile__item"
                        :class="{
                            ['mobile__item--danger']:
                                action.color === '#da4453',
                        }"
                    >
                        <button class="mobile__btn" @click="action.handle">
                            {{
                                $t(
                                    `pages.wigPage.mainActions.${action.labelKey}`
                                )
                            }}
                        </button>
                    </li>
                </ul>
            </v-menu>
        </div>
    </div>
</template>

<script>
import { wigDetailsMainActions } from '@/helpers/wig-details-page-main-actions.utils'
import OrdersPrintInfo from '@/components/orders/orders-print-info.vue'

export default {
    name: 'OrdersDetailsPageMainActions',
    components: { OrdersPrintInfo },
    props: {
        wig: {
            type: Object,
            required: true,
        },
    },
    computed: {
        actions: function () {
            return wigDetailsMainActions(this.wig)
        },
    },
}
</script>

<style lang="scss">
.actions {
    &__list {
        display: none;

        @include tablet-up {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-end;
            gap: 16px 8px;
        }
    }

    &__item {
    }
}

.mobile {
    @include tablet-up {
        display: none;
    }
    &__list {
        min-width: 50vw;
        margin: 0 !important;
        background: #fff;
        padding: 0 !important;
    }

    &__item {
        padding: 12px 16px;

        &--danger {
            background: #da4453;
            color: #fff;
        }

        &:hover {
        }

        & + & {
            border-top: 1px dotted #c8c8c8;
        }
    }

    &__btn {
        width: 100%;
        text-align: left;
    }
}
</style>
