import { clientsStatusTypes } from '@/store/modules/clients_v2/types'

const getClientsStatusByFilter = (enabledFiltes) => {
    if (
        enabledFiltes.includes('existing') &&
        enabledFiltes.includes('deleted')
    ) {
        return clientsStatusTypes.BOTH
    }

    if (enabledFiltes.includes('existing')) {
        return clientsStatusTypes.ACTIVE
    }

    if (enabledFiltes.includes('deleted')) {
        return clientsStatusTypes.DELETED
    }
}

export default getClientsStatusByFilter
