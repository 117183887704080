import store from '@/store'
import { actionTypes } from '@/store/modules/modals'
import popUpTypes from '@/types/pop-up-types'
import { actionTypes as actionTypesConfirmer } from '@/store/modules/confirmer'
import { actionTypes as actionTypesOrders } from '@/store/modules/orders'
import { actionTypes as actionTypesModalNotifications } from '@/store/modules/modal-notifications'
import { mutationTypes as mutationTypesCalendar } from '@/store/modules/calendar'
import router from '@/router'
const { orderGeneralUpdate, clientSelect, orderChangeStateToAny } = popUpTypes
import {
    updateState,
    wigDetailsPageWigWorkflow,
} from './wig-details-page-wig-workflow'
import getCurrentProductionsStepName from '@/helpers/get-current-productions-step-name'
import getAllowedByRole from '@/helpers/getAllowedByRole'
import { ordersV2ActionTypes } from '@/store/modules/orders_v2/types'

const confirmHandle = async (message) =>
    await store.dispatch(
        `confirmer/${actionTypesConfirmer.confirmHandle}`,
        message
    )

const isCanceled = (wig) => wig.state === 'Cancelled Wigs'

export const wigDetailsMainActions = (wig) => {
    return [
        {
            color: '#6b1b00',
            label: 'Change to any state',
            labelKey: 'moveToAny',
            icon: '',
            dataTestID: 'wig-page--main-actions--move-to-stock-btn',
            conditional: () => getAllowedByRole(['admin']),
            handle: async () => {
                store.dispatch(`modals/${actionTypes.openPopUp}`, {
                    name: orderChangeStateToAny,
                })
            },
        },
        {
            color: '#0065c5',
            label: 'Restore the wig',
            labelKey: 'restoreWig',
            icon: 'mdi-restore',
            conditional: (wig) => {
                if (getAllowedByRole(['worker'])) {
                    return false
                }

                return (
                    wig.state === 'Wigs In Stock' &&
                    !getAllowedByRole(['usa_manager'])
                )
            },
            dataTestID: 'wig-page--main-actions--restore-wig-btn',
            handle: async () => {
                try {
                    const clientId = (
                        await store.dispatch(
                            `modals/${actionTypes.openPopUp}`,
                            {
                                name: clientSelect,
                            }
                        )
                    ).clientId

                    if (!clientId) {
                        throw new Error('error')
                    }

                    const oldId = wig.id

                    await store.dispatch(
                        `orders/${actionTypesOrders.updateState}`,
                        { ...wig, state: 'Archived' }
                    )

                    const newWig = await store.dispatch(
                        `orders/${actionTypesOrders.createWigFromStock}`,
                        { stockWigID: oldId, clientID: clientId }
                    )

                    // clear meeting list
                    await store.commit(
                        `calendar/${mutationTypesCalendar.clearAllMeetings}`
                    )

                    await store.dispatch(
                        `orders/${actionTypesOrders.loadOrder}`,
                        {
                            id: newWig.id,
                            withoutOpening: true,
                        }
                    )
                    await router.push({ path: `/orders/${newWig.id}` })

                    //     CHANGE STATE FOR OLD WIG INTO ARCHIVED
                } catch (e) {
                    console.error(e)
                }
            },
        },
        {
            color: '#0065c5',
            label: 'Move to stock',
            labelKey: 'moveToStock',
            icon: 'mdi-inbox-arrow-down',
            dataTestID: 'wig-page--main-actions--move-to-stock-btn',
            conditional: () => {
                if (getAllowedByRole(['worker'])) {
                    return false
                }

                return (
                    !isCanceled(wig) &&
                    wig['state'].toLowerCase() !== 'wigs in stock' &&
                    !getAllowedByRole(['usa_manager'])
                )
            },
            handle: async () => {
                return updateState({ id: wig.id, state: 'Wigs In Stock' })
            },
        },
        {
            color: '#b09789',
            label: 'Delivery',
            labelKey: 'delivery',
            icon: '',
            conditional: (wig) => {
                if (getAllowedByRole(['worker'])) {
                    return false
                }

                return (
                    !getAllowedByRole(['usa_manager']) &&
                    wig['state'] &&
                    (wig['state'].toLowerCase() === 'ready new wig' ||
                        wig['state'].toLowerCase() === 'ready repair wigs')
                )
            },
            handle: () => {
                updateState({ id: wig.id, state: 'Delivered Wigs' })
            },
        },
        {
            color: '#f6bb42',
            label: 'Production steps',
            labelKey: getCurrentProductionsStepName(wig),
            dataTestID: 'wig-page--main-actions--production-step-btn',
            icon: '',
            conditional: () => {
                if (wig.state === 'Archived' || getAllowedByRole(['worker'])) {
                    return false
                }

                return (
                    !getAllowedByRole(['usa_manager']) &&
                    !(wig.state === 'Delivered Wigs') &&
                    !isCanceled(wig) &&
                    !(wig.state === 'Wigs In Stock')
                )
            },
            handle: () => {
                alert('Not implemented yet.')
            },
            ...wigDetailsPageWigWorkflow(wig),
        },
        {
            color: '#3bafda',
            label: 'Edit',
            labelKey: 'edit',
            icon: '',
            conditional: () => {
                if (getAllowedByRole(['worker'])) {
                    return false
                }

                if (getAllowedByRole(['usa_manager'])) {
                    return wig.state === 'Draft'
                }
                return !isCanceled(wig)
            },
            handle: () => {
                store.dispatch(`modals/${actionTypes.openPopUp}`, {
                    name: orderGeneralUpdate,
                })
            },
        },
        {
            color: '#f6bb42',
            label: 'Rework',
            labelKey: 'rework',
            icon: 'mdi-cached',
            conditional: (wig) => {
                if (getAllowedByRole(['worker'])) {
                    return false
                }

                return (
                    !getAllowedByRole(['usa_manager']) &&
                    wig['state'] &&
                    (wig['state'].toLowerCase() === 'ready new wig' ||
                        wig['state'].toLowerCase() === 'ready repair wigs')
                )
            },
            handle: () => {
                updateState({ id: wig.id, state: 'Rework' })
            },
        },
        {
            color: '#da4453',
            label: 'Move to draft',
            labelKey: 'moveToDraft',
            icon: 'mdi-arrow-left-bold',
            conditional: () => {
                if (getAllowedByRole(['worker'])) {
                    return false
                }
                return !getAllowedByRole(['usa_manager']) && true
            },
            handle: () => {
                updateState({ id: wig.id, state: 'Draft' })
            },
        },
        {
            color: '#da4453',
            label: 'Cancel order',
            labelKey: 'cancelOrder',
            icon: 'mdi-close-thick',
            conditional: () => {
                if (getAllowedByRole(['worker'])) {
                    return false
                }

                if (getAllowedByRole(['usa_manager'])) {
                    return wig.state === 'Draft'
                }
                return wig.state !== 'Cancelled Wigs'
            },
            handle: () => {
                updateState({ id: wig.id, state: 'Cancelled Wigs' })
            },
        },
        {
            color: '#da4453',
            label: 'Delete',
            labelKey: 'delete',
            icon: 'mdi-trash-can-outline',
            conditional: () => getAllowedByRole(['admin']),
            handle: () => {
                store.dispatch(
                    `confirmer/${actionTypesConfirmer.confirmHandle}`
                )
                confirmHandle('Delete this?')
                    .then(() => {
                        store.dispatch(
                            `orders/${actionTypesOrders.deleteOrder}`,
                            wig.id
                        )
                        store.dispatch(
                            `modalNotifications/${actionTypesModalNotifications.success}`,
                            'Order has been deleted'
                        )
                        store.dispatch(
                            `ordersV2/${ordersV2ActionTypes.FETCH_ORDERS}`
                        )
                        wig.onClose()
                    })
                    .catch(() => {})
            },
        },
    ].filter((action) => {
        return action.conditional(wig)
    })
}
