<template>
    <v-dialog v-model="isActive" width="820" @click:outside="closeHandle">
        <div class="modal__wrapper" :class="{ ['is-hidden']: isLoading }">
            <div v-if="isLoading" class="modal__loader">
                <v-progress-circular
                    size="100"
                    width="10"
                    indeterminate
                    color="#967adc"
                ></v-progress-circular>
            </div>
            <div
                class="order-context"
                :class="{ ['is-archived']: order.state === 'Archived' }"
                style="--bg: #fff"
            >
                <div class="order-context__wrapper">
                    <div class="order-context__body">
                        <div class="order-context__head">
                            <h3 class="order-context__title">
                                <p>{{ textContent['title'] }}</p>
                                <p>{{ order['client'] }}</p>
                                <span
                                    v-if="userGeneratedId"
                                    class="order-context__id"
                                >
                                    {{ ' ' + userGeneratedId }}
                                </span>
                                <p
                                    v-if="isRepair"
                                    class="order-context__is-repair"
                                >
                                    <v-icon small color="red"
                                        >mdi-wrench</v-icon
                                    >
                                </p>
                                <p
                                    v-if="isRework"
                                    class="order-context__is-rework"
                                >
                                    <v-icon small color="red"
                                        >mdi-cached</v-icon
                                    >
                                </p>
                            </h3>
                            <v-btn icon color="#55595c" @click="closeHandle">
                                <v-icon>mdi-close-circle-outline</v-icon>
                            </v-btn>
                        </div>
                        <v-divider></v-divider>
                        <div class="tabs">
                            <v-tabs
                                v-model="tabs"
                                color="#3bafda"
                                centered
                                :show-arrows="isMobile"
                            >
                                <v-tab
                                    v-for="({ title, icon }, index) in tabsText"
                                    :key="index"
                                >
                                    <p class="tabs__title">
                                        <v-icon class="tabs__icon"
                                            >{{ icon }}
                                        </v-icon>
                                        <span>{{ title }}</span>
                                    </p>
                                </v-tab>
                            </v-tabs>

                            <v-tabs-items v-model="tabs">
                                <v-tab-item>
                                    <div class="tabs__body">
                                        <OrdersContextInfo :info="order" />
                                    </div>
                                </v-tab-item>
                                <v-tab-item>
                                    <div class="tabs__body">
                                        <OrdersGallery
                                            :files="order.files"
                                            :state="order.state"
                                        />
                                    </div>
                                </v-tab-item>
                                <v-tab-item>
                                    <div class="tabs__body">
                                        <template v-if="order.meetings">
                                            <ClientMeetingsList
                                                :meetings="order.meetings"
                                            />
                                        </template>
                                        <h3 v-else>This wig hasn't meetings</h3>
                                    </div>
                                </v-tab-item>
                                <v-tab-item>
                                    <div class="tabs__body">
                                        <OrderListOfLogs
                                            :wig="order"
                                            :logs="order['stateLog']"
                                        ></OrderListOfLogs>
                                    </div>
                                </v-tab-item>
                                <v-tab-item>
                                    <div class="tabs__body">
                                        <OrdersReworkTab />
                                    </div>
                                </v-tab-item>
                            </v-tabs-items>
                        </div>
                    </div>
                    <v-divider v-if="tabs !== 4"></v-divider>
                    <div
                        v-if="order.state !== 'Archived' && tabs !== 4"
                        class="order-context__footer"
                    >
                        <div
                            v-if="
                                getAllowed(['admin', 'manager', 'usa_manager'])
                            "
                            class="order-context__actions"
                        >
                            <v-btn
                                v-if="
                                    order.state === 'Wigs In Stock' &&
                                    getAllowed(['admin', 'manager'])
                                "
                                color="#0065c5"
                                dark
                                small
                                @click="moveToStockHandle"
                            >
                                <v-icon small>mdi-restore</v-icon>
                                {{ textContent['restore-the-wig'] }}
                            </v-btn>
                            <v-btn
                                v-else-if="getAllowed(['admin', 'manager'])"
                                color="#0065c5"
                                dark
                                small
                                @click="() => stateUpdateHandle('to-stock')"
                            >
                                <v-icon small>mdi-inbox-arrow-down</v-icon>
                                {{ textContent['move-to-stock'] }}
                            </v-btn>
                            <v-btn
                                v-if="
                                    deliveryBtnIsActive &&
                                    getAllowed(['admin', 'manager'])
                                "
                                color="#b09789"
                                dark
                                small
                                @click="() => statusOnChange('Delivered Wigs')"
                            >
                                {{ extraContent['delivery'] }}
                            </v-btn>
                            <v-btn
                                v-if="
                                    actionTypeForBtn &&
                                    getAllowed(['admin', 'manager'])
                                "
                                color="#f6bb42"
                                dark
                                small
                                @click="changeStatusHandle"
                            >
                                {{ btnText(actionTypeForBtn) }}
                            </v-btn>
                            <v-btn
                                v-if="
                                    getAllowed([
                                        'admin',
                                        'manager',
                                        roleUsaManagerAfterStartProduction,
                                    ])
                                "
                                color="#3bafda"
                                dark
                                small
                                @click="openGeneralUpdate"
                            >
                                <v-icon small>mdi-pencil-outline</v-icon>
                                {{ textContent['edit'] }}
                            </v-btn>
                        </div>
                        <div
                            v-if="
                                getAllowed(['admin', 'manager', 'usa_manager'])
                            "
                            class="order-context__actions"
                        >
                            <v-btn
                                v-if="
                                    reworkBtnIsActive &&
                                    getAllowed(['admin', 'manager'])
                                "
                                color="#f6bb42"
                                dark
                                small
                                @click="() => stateUpdateHandle('rework')"
                            >
                                <v-icon small>mdi-cached</v-icon>
                                {{ textContent['rework'] }}
                            </v-btn>
                            <v-btn
                                v-if="getAllowed(['admin', 'manager'])"
                                color="#da4453"
                                dark
                                small
                                @click="() => stateUpdateHandle('draft')"
                            >
                                <v-icon small>mdi-arrow-left-bold</v-icon>
                                {{ textContent['move-to-draft'] }}
                            </v-btn>
                            <v-btn
                                v-if="
                                    getAllowed([
                                        'admin',
                                        'manager',
                                        roleUsaManagerAfterStartProduction,
                                    ])
                                "
                                color="#da4453"
                                dark
                                small
                                @click="() => stateUpdateHandle('cancel')"
                            >
                                <v-icon small>mdi-close-thick</v-icon>
                                {{ textContent['cancel-order'] }}
                            </v-btn>
                            <v-btn
                                v-if="permissions.btnDelete"
                                color="#da4453"
                                dark
                                small
                                @click="deleteHandle"
                            >
                                <v-icon small>mdi-trash-can-outline</v-icon>
                            </v-btn>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </v-dialog>
</template>

<script>
import OrdersContextInfo from '@/components/orders/orders-context-info'
import OrdersGallery from '@/components/orders/orders-gallery'
import OrderListOfLogs from '@/components/orders/order-list-of-logs'
import ClientMeetingsList from '@/components/clients/client-meetings-list'
import OrdersPrintInfo from '@/components/orders/orders-print-info'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import {
    actionTypes as actionTypesOrders,
    actionTypes,
    getterTypes as getterTypesOrders,
} from '@/store/modules/orders'
import { actionTypes as actionTypesModalNotifications } from '@/store/modules/modal-notifications'
import { actionTypes as actionTypesPayments } from '@/store/modules/payments'
import { actionTypes as actionTypesConfirmer } from '@/store/modules/confirmer'
import { mutationTypes as mutationTypesCalendar } from '@/store/modules/calendar'

import {
    actionTypes as actionTypesModals,
    getterTypes as getterTypesModals,
    mutationTypes,
} from '@/store/modules/modals'

import popUpTypes from '@/types/pop-up-types'
import languageMixin from '@/mixins/language.mixin'
import regularToSnakeCase from '@/helpers/regular-to-snake-case'
import languageOrderExtraMixin from '@/mixins/language-order-extra.mixin'
import { prepareGeneral, prepareProperties } from '@/helpers/orderPrepareData'
import OrdersReworkTab from '@/components/orders/orders-rework-tab.vue'

const {
    orderContext,
    orderGeneralUpdate,
    orderStorage,
    orderClientUpdate,
    clientSelect,
} = popUpTypes

export default {
    name: 'OrderModalContext',
    components: {
        OrdersReworkTab,
        OrdersContextInfo,
        OrdersGallery,
        OrderListOfLogs,
        ClientMeetingsList,
        OrdersPrintInfo,
        // OrderOldClientsList,
    },
    mixins: [languageMixin, languageOrderExtraMixin],
    emits: ['state-update-handle'],
    data() {
        return {
            tabs: '',
        }
    },
    computed: {
        ...mapGetters('modals', {
            checkIsActive: getterTypesModals.isActive,
            isLoading: getterTypesModals.getLoadingStatus,
        }),
        ...mapGetters('orders', { order: getterTypesOrders.getOrder }),
        isRework: function () {
            return this.order.state === 'Rework'
        },
        tabsText: function () {
            return [
                { title: this.textContent['info'], icon: 'mdi-home' },
                {
                    title: this.textContent['photo'],
                    icon: 'mdi-image-outline',
                },
                {
                    title: this.textContent['meetings'],
                    icon: 'mdi-handshake-outline',
                },
                {
                    title: this.textContent['logs'],
                    icon: 'mdi-format-list-bulleted',
                },
                this.isRework
                    ? {
                          title: 'Rework',
                          icon: 'mdi-repeat',
                      }
                    : undefined,
            ].filter((i) => i)
        },
        roleUsaManagerAfterStartProduction: function () {
            return this.order?.state?.toLowerCase() === 'draft'
                ? 'usa_manager'
                : ''
        },
        permissions: function () {
            const btnDelete = this.getAllowed(['admin'])
            const afterStart = this.getAllowed([
                'admin',
                'manager',
                this.roleUsaManagerAfterStartProduction,
            ])
            return { btnDelete, afterStart }
        },
        userGeneratedId: function () {
            return this.order['user_generated_id']
        },
        isActive: function () {
            return this.checkIsActive(orderContext)
        },
        isMobile: function () {
            return this.$resize && !this.$mq.above(1023)
        },
        isRepair: function () {
            return !!this.order['is_repair']
        },
        toStockBtnIsActive: function () {
            return (
                this.order['state'] &&
                (this.order['state'].toLowerCase() === 'ready new wig' ||
                    this.order['state'].toLowerCase() === 'ready repair wigs')
            )
        },
        deliveryBtnIsActive: function () {
            return (
                this.order['state'] &&
                (this.order['state'].toLowerCase() === 'ready new wig' ||
                    this.order['state'].toLowerCase() === 'ready repair wigs')
            )
        },
        reworkBtnIsActive: function () {
            return (
                this.order['state'] &&
                (this.order['state'].toLowerCase() === 'ready new wig' ||
                    this.order['state'].toLowerCase() === 'ready repair wigs')
            )
        },
        actionTypeForBtn: function () {
            /*
              0: "Draft"
              1: "Wigs in Production"
              2: "Ready New Wig"
              3: "Repair Wigs"
              4: "Ready Repair Wigs"
              5: "Cancelled Wigs"
              6: "Wigs In Stock"
              7: "Storage"
              8: "Delivered Wigs"
            */
            if (!this.order['state']) return ''

            switch (this.order['state'].toLowerCase()) {
                case 'draft':
                    return 'Production Start'
                case 'wigs in production':
                    return 'Production complete'
                case 'rework':
                    return this.order['is_repair'] === 1
                        ? 'Repair complete'
                        : 'Production complete'
                case 'repair wigs':
                    return 'Repair complete'
                case 'ready new wig':
                case 'ready repair wigs':
                    return 'Storage'
                case 'storage':
                    return 'Delivery'
                default:
                    return ''
            }
        },
    },
    methods: {
        ...mapActions('orders', {
            updateState: actionTypes.updateState,
            deleteOrder: actionTypes.deleteOrder,
            createOrder: actionTypesOrders.createOrder,
            moveToArchive: actionTypesOrders.moveOrderToArchive,
            loadOrder: actionTypesOrders.loadOrder,
        }),
        ...mapActions('modalNotifications', {
            success: actionTypesModalNotifications.success,
            error: actionTypesModalNotifications.error,
        }),
        ...mapActions('confirmer', {
            confrimHandle: actionTypesConfirmer.confirmHandle,
        }),
        ...mapActions('modals', {
            onOpen: actionTypesModals.openPopUp,
            onClose: actionTypesModals.closePopUp,
        }),
        ...mapActions('payments', {
            markAllPayments: actionTypesPayments.markedAllAsTerminated,
        }),
        ...mapMutations('modals', {
            startLoading: mutationTypes.startLoading,
            endLoading: mutationTypes.endLoading,
        }),
        ...mapMutations('calendar', {
            clearMeetings: mutationTypesCalendar.clearAllMeetings,
        }),
        closeHandle: function () {
            this.onClose(orderContext)
            this.tabs = 0
        },
        openGeneralUpdate: function () {
            this.onOpen(orderGeneralUpdate)
        },
        stateUpdateHandle: async function (action) {
            let text = ''
            let newState = ''

            switch (action) {
                case 'to-stock':
                    text = this.textContent['message-move-to-stock']
                    newState = 'Wigs In Stock'
                    await this.onChangeState({ text, newState })
                    // this.markAllPayments()
                    return null
                case 'draft':
                    text = this.textContent['message-move-to-draft']
                    newState = 'Draft'
                    break
                case 'cancel':
                    text = this.textContent['message-cancel-wig']
                    newState = 'Cancelled Wigs'
                    break
                case 'rework':
                    text = this.textContent['message-rework-wig']
                    newState = 'Rework'
                    break
                //todo: need to implement logic of comeback
                case 'restore':
                    text = this.textContent['message-restore-wig']
                    newState = 'Draft'
                    await this.onChangeState({ text, newState })
                    this.onOpen(orderClientUpdate)
                    return null
                default:
                    return null
            }
            await this.onChangeState({ text, newState })
        },
        onChangeState: async function ({ text, newState }) {
            if (!newState) return null
            return this.confrimHandle(text)
                .then(() => {
                    const res = { id: this.order.id, state: newState }
                    this.updateState(res).then(() => {
                        this.success('Status has been changed')
                    })
                })
                .catch(() => {})
        },
        btnText: function (text) {
            return this.extraContent[regularToSnakeCase(text)]
        },
        changeStatusHandle: function () {
            let newState = ''
            switch (this.actionTypeForBtn) {
                case 'Production Start':
                    newState =
                        this.order['is_repair'] === 1
                            ? 'Repair Wigs'
                            : 'Wigs in Production'
                    break
                case 'Production complete':
                    newState = 'Ready New Wig'
                    break
                case 'Repair complete':
                    newState = 'Ready Repair Wigs'
                    break
                case 'Storage':
                    this.storageHandle()
                    break
                case 'Delivery':
                    newState = 'Delivered Wigs'
                    break
                default:
                    console.log('actionTypeForBtn has error')
            }

            if (!newState) return null
            this.statusOnChange(newState)
        },
        statusOnChange: function (newState) {
            this.confrimHandle(this.textContent['message-change-state'])
                .then(() => {
                    const res = { id: this.order.id, state: newState }
                    this.updateState(res).then(() => {
                        this.success('Status has been changed')
                    })
                })
                .catch(() => {})
        },
        storageHandle: function () {
            this.onOpen(orderStorage)
        },
        deleteHandle: function () {
            this.confrimHandle(this.textContent['message-delete-order'])
                .then(() => {
                    this.deleteOrder(this.order.id).then(() => {
                        this.success('Order has been deleted')
                        this.closeHandle()
                    })
                })
                .catch(() => {})
        },
        moveToStockHandle: async function () {
            try {
                const clientId = (await this.onOpen(clientSelect)).clientId
                if (!clientId) throw 'error'
                const oldId = this.order.id
                this.startLoading()
                const general = {
                    ...prepareGeneral(this.order),
                    client_id: clientId,
                }
                const properties = prepareProperties(this.order.properties)

                const json = JSON.stringify({ ...general, properties })

                const form = new FormData()
                form.append('data', json)

                const newWig = await this.createOrder({
                    form,
                    newOrder: { ...general, properties },
                    withoutOpening: true,
                })

                await this.moveToArchive({ wigId: oldId, newId: newWig.id })
                await this.loadOrder({
                    id: newWig.id,
                    withoutOpening: true,
                })
                this.clearMeetings()
            } catch (e) {
                this.closeHandle()
                this.error(e?.response?.data?.message || 'Something went wrong')
            } finally {
                this.endLoading()
            }
        },
    },
}
</script>

<style scoped lang="scss">
.modal {
    &__wrapper {
        max-height: 89vh;
        position: relative;

        &.is-hidden {
            overflow: hidden;
        }
    }

    &__loader {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.85);
        z-index: 9999;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.order-context {
    background: $color-primary-light;
    padding: 15px;
    border-radius: 10px;

    &.is-archived {
        filter: grayscale(50%);
    }

    &__wrapper {
        height: fit-content;
    }

    &__title {
        display: flex;
        flex-direction: column;
        align-items: center;
        @include tablet-up {
            flex-direction: row;
        }

        p {
            margin-bottom: 0;
        }

        p + p {
            margin-left: 10px;
            @include for-hebrew {
                margin-left: 0;
                margin-right: 10px;
            }
        }
    }

    &__id {
        margin-left: 10px;
        font-size: 0.9em;
        height: fit-content;
        line-height: 1;
        opacity: 0.85;
        border: 1px solid #5d5d5d;
        border-radius: 4px;
        padding: 3px 5px;

        @include for-hebrew {
            margin-left: 0;
            margin-right: 10px;
        }
    }

    &__head {
        display: flex;
        justify-content: space-between;
        align-items: center;

        button {
            margin-bottom: 35px;
            @include tablet-up {
                margin-bottom: 0;
            }
        }
    }

    &__is-repair,
    &__is-rework {
        font-size: 0.85em;
        margin-left: 5px;
        font-weight: 400;
    }

    &__actions {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        button {
            margin-bottom: 10px;
        }

        @include tablet-up {
            margin-top: 15px;
            justify-content: flex-end;
            * + * {
                margin-left: 15px;

                @include for-hebrew {
                    margin-left: 0;
                    margin-right: 15px;
                }
            }

            button {
                margin-bottom: 0;
            }
        }
    }
}

.tabs {
    padding: 10px 0 0;

    &__title {
        margin-bottom: 0;
    }

    &__icon {
    }

    &__body {
        @include tablet-up {
            padding: 0 15px;
        }
    }
}
</style>
