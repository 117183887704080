export default {
    archived: 'Archived',
    ['all-states']: 'All statuses',
    draft: 'Draft',
    ['wigs-in-production']: 'Wigs in Production',
    ['ready-new-wig']: 'Ready New Wig',
    ['repair-wigs']: 'Repair Wigs',
    ['ready-repair-wigs']: 'Ready Repair Wigs',
    ['cancelled-wigs']: 'Cancelled Wigs',
    ['wigs-in-stock']: 'Wigs in Stock',
    storage: 'Storage',
    ['delivered-wigs']: 'Delivered Wigs',
    rework: 'Re-work',
    ['wig-building']: 'Wig building',
    ['sawing-weft']: 'Sawing weft',
    ['sawing-weft-on-cap']: 'Sawing weft on cap',
    ['skin-matching']: 'Skin Matching',
    ['skins-connect']: 'Skins / Tops connect',
    ['front-hand-tie']: 'Classic hairline',
    ['first-hairline']: 'First hairline tracing',
    ['first-hairline-tracing']: 'First hairline tracing',
    ['second-hairline']: 'Second hairline ( finishes )',
    ['second-hairline-(-finishes-)']: 'Second hairline ( finishes )',
    ['under-lace-material-sawing']: 'Under lace material sawing',
    ['under-lace-material-closing']: 'Under lace material closing',
    ['highlights-include-washing']: 'Highlights, include washing',
    ['special-shampoo']: 'Special Shampoo',
    ['washing']: 'Washing and Shrinkage',
    ['cut-and-set']: 'Pre-Cut and Set',
    ['production-start']: 'Production Start',
    ['production-complete']: 'Production complete',
    ['repair-complete']: 'Repair complete',
    delivery: 'Delivery',
}
