import { render, staticRenderFns } from "./wig-page-additional-properties-by-date-list.vue?vue&type=template&id=7e35d2c0&scoped=true"
import script from "./wig-page-additional-properties-by-date-list.vue?vue&type=script&lang=js"
export * from "./wig-page-additional-properties-by-date-list.vue?vue&type=script&lang=js"
import style0 from "./wig-page-additional-properties-by-date-list.vue?vue&type=style&index=0&id=7e35d2c0&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7e35d2c0",
  null
  
)

export default component.exports