import api from '@/api/countries'

const state = () => ({
    countries: [],
    cities: [],
    flags: {},
    codes: {},
})

export const getterTypes = {
    getCountries: '[countries] Get all countries',
    getCities: '[countries] Get all cities',
    getFlag: '[countries] Get selected flag',
    getCode: '[countries] Get selected code',
}

export const mutationTypes = {
    addCountries: '[countries] Push all countries',
    addCities: '[countries] Push all cities',
}

export const actionTypes = {
    getCountries: '[countries] Load all countries',
    getCities: '[countries] Load all cities',
}

const getters = {
    [getterTypes.getCountries]: ({ countries }) => countries,
    [getterTypes.getCities]: ({ cities }) => cities,
    [getterTypes.getFlag]: (state) => (flag) => state.flags[flag],
    [getterTypes.getCode]: (state) => (code) => state.codes[code] || '',
}

const mutations = {
    [mutationTypes.addCountries](state, { countries, flags }) {
        const defaultCountries = [
            'Israel',
            'united states',
            'Canada',
            'united kingdom',
            'Switzerland',
            'Belgium',
            'Germany',
            'France',
        ]

        flags.forEach((flag) => {
            const name = flag.name.toLowerCase()
            state.flags = { ...state.flags, [name]: flag.unicodeFlag }
        })
        state.countries = [
            ...defaultCountries,
            ...countries.map((country) => {
                state.codes = {
                    ...state.codes,
                    [country.name.toLowerCase()]: country.dial_code,
                }
                return country.name
            }),
        ]
    },
    [mutationTypes.addCities](state, cities) {
        state.cities = cities.map((city) => city)
    },
}

const actions = {
    [actionTypes.getCountries]({ commit, state }) {
        if (state.countries.length > 0) Promise.resolve()
        return new Promise((res, rej) => {
            api.countries
                .getCountries()
                .then(async ({ data }) => {
                    const flags = (await api.countries.getFlags()) || []
                    commit(mutationTypes.addCountries, {
                        countries: data,
                        flags,
                    })
                    res(data.data)
                })
                .catch((error) => {
                    rej(error)
                })
        })
    },
    [actionTypes.getCities]({ commit }, country) {
        return new Promise((res, rej) => {
            api.countries
                .getCities(country)
                .then(({ data }) => {
                    commit(mutationTypes.addCities, data)
                    res(data)
                })
                .catch((error) => {
                    rej(error)
                })
        })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
