export default (status) => {
    switch (status) {
        case 'baseColor':
            return { text: 'Base Color', value: 'BASE_COLOR' }
        case 'capSize':
            return { text: 'Cap Size', value: 'CAP_SIZE' }
        case 'front':
            return { text: 'Front', value: 'FRONT' }
        case 'hairTextureOnWefts':
            return {
                text: 'Hair Texture On Wefts',
                value: 'HAIR_TEXTURE_ON_WEFTS',
            }
        case 'length':
            return { text: 'Length', value: 'LENGTH' }
        case 'topType':
            return { text: 'Top Type', value: 'TOP_TYPE' }
        case 'wigFullness':
            return { text: 'Wig Fullness', value: 'WIG_FULLNESS' }
        case 'wigType':
            return { text: 'Wig Type', value: 'WIG_TYPE' }
        case 'weight':
            return { text: 'Weight', value: 'WEIGHT' }
        default:
            return null
    }
}
