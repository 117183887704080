<template>
    <Transition name="overlay" :duration="600" appear mode="out-in">
        <div class="overlay" :style="`--vr-height:${height}`">
            <div class="slide-out" :class="{ ['is-mounted']: isMounted }">
                <Transition name="loader" :duration="600" appear mode="out-in">
                    <div v-if="isLoading" class="slide-out__loader">
                        <v-progress-circular
                            indeterminate
                            color="primary"
                        ></v-progress-circular>
                    </div>
                </Transition>
                <div class="slide-out__body body">
                    <slot />
                </div>
                <div class="slide-out__foot foot">
                    <div class="foot__btn">
                        <v-btn
                            small
                            class="mx-2"
                            dark
                            color="#967adc"
                            @click="onSubmit"
                        >
                            {{ $t('pages.wigPage.slideOuts.save') }}
                        </v-btn>
                    </div>
                    <div class="foot__btn">
                        <v-btn
                            small
                            class="mx-2"
                            dark
                            color="#979ea3"
                            @click="onClose"
                        >
                            {{ $t('pages.wigPage.slideOuts.close') }}
                        </v-btn>
                    </div>
                </div>
            </div>
        </div>
    </Transition>
</template>

<script>
import { mapMutations } from 'vuex'
import { mutationTypesSlideOuts } from '@/store/modules/slide-outs/types'

export default {
    name: 'SlideOutV2',
    props: {
        isLoading: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['on-submit', 'on-close'],
    data() {
        return {
            isMounted: false,
            height: 0,
        }
    },
    mounted() {
        setTimeout(() => {
            this.isMounted = true
            this.calcHeight()
        }, 0)
        addEventListener('resize', this.calcHeight)
    },
    unmounted() {
        removeEventListener('resize', this.calcHeight)
    },
    beforeUnmount() {
        this.isMounted = false
    },
    methods: {
        ...mapMutations('slideOuts', {
            setSelectedSlideOut: mutationTypesSlideOuts.SET_SELECTED_SLIDE_OUT,
        }),
        onClose: function () {
            this.setSelectedSlideOut({ slideOutComponent: null, props: null })
            this.$emit('on-close')
        },
        onSubmit: function () {
            this.$emit('on-submit')
        },
        calcHeight: function () {
            this.height = window.innerHeight
                ? `${window.innerHeight}px`
                : '100vh'
        },
    },
}
</script>

<style lang="scss">
.overlay {
    position: fixed;
    z-index: 200;
    background: rgba(0, 0, 0, 0.28);
    width: 100%;
    height: var(--vr-height);
    top: 0;
    left: 0;
    will-change: opacity;
}

.slide-out {
    position: absolute;
    top: 0;
    right: 0;
    width: 550px;
    max-width: 99vw;
    background: #fff;
    height: 100%;
    transition: transform 500ms ease-in-out;
    will-change: transform;

    &__loader {
        position: absolute;
        top: 0;
        left: 0;
        height: calc(100% - 55px);
        width: 100%;
        background: #fff;
        z-index: 2;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.body {
    width: 100%;
    height: calc(100% - 60px);
    overflow: auto;
    padding: 16px;
}

.foot {
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding: 0 16px;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        height: 2px;
        width: calc(100% - 32px - 16px);
        background: #c5c5c5;
        box-shadow: 0 -4px 15px rgba(0, 0, 0, 0.5);
        border-radius: 16px;
    }
}

//provide overlay animation
.overlay-enter-active {
    transition: opacity 100ms;
}

.overlay-leave-active {
    transition: opacity 100ms 500ms;
}

.overlay-enter,
.overlay-leave-to {
    opacity: 0;
}

//provide slide-out animation
.overlay-enter-active .slide-out {
    transition: transform 500ms 100ms cubic-bezier(0.36, 0.42, 0.44, 0.91);
}

.overlay-leave-active .slide-out {
    transition: transform 500ms cubic-bezier(0.57, 0.38, 1, 0.63);
}

.overlay-enter .slide-out,
.overlay-leave-to .slide-out {
    transform: translateX(100%);
}

//provide loader animation
.loader-enter-active,
.loader-leave-active {
    transition: opacity 150ms;
}

.loader-enter,
.loader-leave-to {
    opacity: 0;
}
</style>
