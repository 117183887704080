var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"reports-table"},[_c('div',{staticClass:"reports-table__actions"},[_c('div',{staticClass:"reports-table__filter"},[_c('v-autocomplete',{attrs:{"search-input":_vm.search,"loading":_vm.clientsIsFetching,"items":_vm.filtersItems.clients,"dense":"","label":_vm.$t('pages.report.client'),"hide-details":"","hide-no-data":"","placeholder":"Client"},on:{"update:searchInput":function($event){_vm.search=$event},"update:search-input":function($event){_vm.search=$event}},model:{value:(_vm.filters.client.value),callback:function ($$v) {_vm.$set(_vm.filters.client, "value", $$v)},expression:"filters.client.value"}})],1),_c('div',{staticClass:"reports-table__filter"},[_c('v-autocomplete',{attrs:{"items":_vm.filtersItems.users,"dense":"","label":_vm.$t('pages.report.employee'),"hide-details":"","placeholder":"Employee"},model:{value:(_vm.filters.user.value),callback:function ($$v) {_vm.$set(_vm.filters.user, "value", $$v)},expression:"filters.user.value"}})],1)]),_c('div',{staticClass:"reports-table__table"},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.filteredEmployees,"items-per-page":-1,"footer-props":{
                'items-per-page-text': _vm.$t('pages.report.rowsPerPage'),
            },"no-data-text":_vm.$t('pages.report.noDataAvailable'),"dense":""},on:{"click:row":_vm.clickHandle},scopedSlots:_vm._u([{key:"item.isRepairWig",fn:function({ item }){return [(item.isRepairWig)?[_c('v-icon',{attrs:{"small":"","color":"red"}},[_vm._v("mdi-wrench")])]:_vm._e()]}},{key:"item.activity_title",fn:function({ item }){return [_c('div',{staticClass:"reports-table__action"},[_c('p',{staticClass:"reports-table__action-title"},[_vm._v(" "+_vm._s(_vm.$t(`pages.report.${item.activity_title}`))+" ")]),_c('p',{staticClass:"reports-table__action-desc"},[_vm._v(" "+_vm._s(_vm.getActivityDescriptionByTranslateHandle( item.activity_description ))+" ")])])]}},{key:"item.created_at",fn:function({ item }){return [_c('span',{staticStyle:{"direction":"ltr","display":"inline-block"}},[_c('DateCell',{attrs:{"value":item.created_at}})],1)]}},{key:"item.totally",fn:function({ item }){return [(
                        (item.totally || item.totally === 0) &&
                        item.total_cost
                    )?_c('div',{staticClass:"reports-table__status"},[_c('div',{staticClass:"reports-table__status-circle"},[_c('v-progress-circular',{attrs:{"rotate":270,"size":45,"width":6,"value":(item.totally / item.total_cost) * 100,"color":_vm.getColor(
                                    (item.totally / item.total_cost) * 100
                                )}}),_c('div',{staticClass:"value"},[_vm._v(" "+_vm._s(Math.ceil( (item.totally / item.total_cost) * 100 ))+"% ")])],1),(item.total_cost - item.totally !== 0)?_c('div',{staticClass:"reports-table__status-debt"},[_c('p',[_vm._v("debt")]),_vm._v(" "+_vm._s(item.total_cost - item.totally)+" ")]):_c('div',{staticClass:"reports-table__status-check"},[_c('v-icon',{attrs:{"color":"#3cc516"}},[_vm._v(" mdi-check-decagram-outline ")])],1)]):_vm._e()]}}])})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }