<template>
    <section class="login">
        <div class="login__form">
            <LoginForm />
        </div>
    </section>
</template>

<script>
import LoginForm from '@/components/login/login-form'
import { mapGetters } from 'vuex'

export default {
    name: 'Login',
    components: { LoginForm },
    computed: {
        ...mapGetters('auth', ['getStatus']),
        checkDevMode: function () {
            return (
                window.location.host === 'wigs.qualiteamdev.com' &&
                !window.localStorage.getItem('debugging')
            )
        },
    },
}
</script>

<style lang="scss" scoped>
.login {
    padding: 95px 0 0;
    min-height: 100vh;
    @include primary-bg;

    &__error-message {
        background: #fff;
        text-align: center;
        border-radius: 20px;
        width: 100%;
        max-width: 500px;
        height: 180px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: absolute;
        z-index: 100;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        font-size: 23px;
    }

    &__form {
        position: absolute;
        z-index: 100;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        margin: 0 auto;
        width: 90%;
        max-width: 400px;
    }

    &__message {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background: rgba(29, 43, 54, 0.49);
        display: flex;
        justify-content: center;
        align-items: center;
        min-width: 400px;
        min-height: 250px;
        border-radius: 15px;
        font-size: 22px;
        color: #fff;
    }
}
</style>
