var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-expansion-panel',{attrs:{"disabled":_vm.disableProperties}},[_c('v-expansion-panel-header',{attrs:{"color":"#f7f7f9"}},[_c('h4',{class:{
                'order-property__sub-title_grey': _vm.disableProperties,
                'order-property__sub-title': !_vm.disableProperties,
            }},[_vm._v(" 4. "+_vm._s(_vm.propertiesContent['front'])+" ")])]),_c('v-expansion-panel-content',{attrs:{"color":"#f7f7f9"}},[_c('div',{staticClass:"order-property__input-wrapper",attrs:{"id":"order-property--front--front-select"}},[_c('v-autocomplete',{attrs:{"outlined":"","items":_vm.items,"label":_vm.propertiesContent['front'],"dense":"","attach":"#order-property--front--front-select","error-messages":_vm.properties.errors['front']},on:{"input":function($event){_vm.properties.errors['front'] = ''},"change":() => {
                        _vm.clearSub()
                        _vm.changeHandle()
                    }},model:{value:(_vm.mainValue),callback:function ($$v) {_vm.mainValue=$$v},expression:"mainValue"}}),(_vm.mainValue)?[_c('v-autocomplete',{attrs:{"outlined":"","items":_vm.subItems[_vm.mainValue],"label":_vm.label(_vm.mainValue),"dense":"","attach":"#order-property--front--front-select","error-messages":_vm.properties.errors['front']},on:{"input":function($event){_vm.properties.errors['front'] = ''},"change":_vm.changeHandle},model:{value:(_vm.subValue),callback:function ($$v) {_vm.subValue=$$v},expression:"subValue"}})]:_vm._e(),_c('v-text-field',{attrs:{"light":"","outlined":"","dense":"","type":"text","name":"front-comments","label":_vm.propertiesContent['front-comments'],"error-messages":_vm.properties.errors['frontComments']},on:{"input":function($event){_vm.properties.errors['frontComments'] = ''}},model:{value:(_vm.properties.fields['frontComments']),callback:function ($$v) {_vm.$set(_vm.properties.fields, 'frontComments', $$v)},expression:"properties.fields['frontComments']"}})],2)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }