import { clientsV2MutationTypes } from '@/store/modules/clients_v2/types'

const mutations = {
    [clientsV2MutationTypes.SET_ITEMS](state, items) {
        state.items = items
    },
    [clientsV2MutationTypes.SET_TOTAL_ITEMS](state, total) {
        state.total = total
    },
    [clientsV2MutationTypes.SET_CURRENT_PAGE](state, page) {
        state.currentPage = page
    },
    [clientsV2MutationTypes.SET_SEARCH_QUERY](state, query) {
        state.search = query
    },
    [clientsV2MutationTypes.SET_SORT_OPTIONS](state, sort) {
        state.sort = sort
    },
    [clientsV2MutationTypes.SET_CLIENTS_STATUS](state, status) {
        state.status = status
    },
    [clientsV2MutationTypes.SET_LOADING](state, loading) {
        state.loading = loading
    },
    [clientsV2MutationTypes.SET_ERROR](state, error) {
        state.error = error
    },
}

export default mutations
