import { clientsV2ActionTypes } from '@/store/modules/clients_v2/types'
import { clientsV2MutationTypes } from '@/store/modules/clients_v2/types'
import api from '@/api/clients'

export default {
    [clientsV2ActionTypes.FETCH_ITEMS]: async ({ commit, state }) => {
        commit(clientsV2MutationTypes.SET_LOADING, true)
        commit(clientsV2MutationTypes.SET_ERROR, null)

        const params = {
            itemsPerPage: state.itemsPerPage,
            currentPage: state.currentPage,
            sort: state.sort,
            search: state.search,
            status: state.status,
        }

        try {
            const response = await api.clients.getAllV2(params)
            commit(clientsV2MutationTypes.SET_ITEMS, response.clients)
            commit(
                clientsV2MutationTypes.SET_TOTAL_ITEMS,
                response.pagination.total
            )
        } catch (error) {
            commit(clientsV2MutationTypes.SET_ERROR, error)
        } finally {
            commit(clientsV2MutationTypes.SET_LOADING, false)
        }
    },
    [clientsV2ActionTypes.SET_CURRENT_PAGE]: ({ commit, dispatch }, page) => {
        commit(clientsV2MutationTypes.SET_CURRENT_PAGE, page)
        dispatch(clientsV2ActionTypes.FETCH_ITEMS)
    },
    [clientsV2ActionTypes.SET_SEARCH_QUERY]: ({ commit, dispatch }, query) => {
        commit(clientsV2MutationTypes.SET_CURRENT_PAGE, 1)
        commit(clientsV2MutationTypes.SET_SEARCH_QUERY, query)
        dispatch(clientsV2ActionTypes.FETCH_ITEMS)
    },
    [clientsV2ActionTypes.SET_SORT_OPTIONS]: ({ commit, dispatch }, sort) => {
        commit(clientsV2MutationTypes.SET_SORT_OPTIONS, sort)
        dispatch(clientsV2ActionTypes.FETCH_ITEMS)
    },
    [clientsV2ActionTypes.SET_CLIENTS_STATUS]: (
        { commit, dispatch },
        status
    ) => {
        commit(clientsV2MutationTypes.SET_CLIENTS_STATUS, status)
        dispatch(clientsV2ActionTypes.FETCH_ITEMS)
    },
}
