import { getStorage, setStorage } from '@/helpers/Local'
import { i18n } from '@/plugins/i18n'

const state = () => ({
    language: getStorage('language') || 'Hebrew',
})

export const getterTypes = {
    getLanguage: '[configuration] get selected language',
    getLanguageShort: '[configuration] get short type of selected language',
}

export const mutationTypes = {
    changeLanguage: '[configuration] Change selected language',
}

const getters = {
    [getterTypes.getLanguage]: (state) => state.language,
    [getterTypes.getLanguageShort]: ({ language }) => {
        switch (language) {
            case 'Hebrew':
                return 'he'
            case 'English':
            default:
                return 'en'
        }
    },
}

const mutations = {
    [mutationTypes.changeLanguage](state, newLanguage) {
        let htmlEl = document.querySelector('html')
        switch (newLanguage) {
            case 'Hebrew':
                htmlEl.setAttribute('dir', 'rtl')
                htmlEl.setAttribute('lang', 'he')
                setStorage('language', 'Hebrew')
                i18n.locale = 'he'
                break
            case 'English':
            default:
                htmlEl.setAttribute('dir', 'ltr')
                htmlEl.setAttribute('lang', 'en')
                setStorage('language', 'English')
                i18n.locale = 'en'
                break
        }
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
}
