<template>
    <div class="client-filter">
        <h3 class="client-filter__title">Extra filters</h3>
        <ul class="client-filter__list">
            <li
                v-for="({ title, id }, index) in filters"
                :key="id"
                class="client-filter__item"
            >
                <v-checkbox
                    v-model="filters[index].isActive"
                    readonly
                    dense
                    hide-details
                    :label="title"
                    @click="() => checkHandle(id)"
                >
                </v-checkbox>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'ClientFilter',
    emits: ['change-handle'],
    data() {
        return {
            filters: [
                {
                    id: '01',
                    title: 'Existing clients',
                    isActive: true,
                    value: 'existing',
                },
                {
                    id: '02',
                    title: 'Deleted clients',
                    isActive: false,
                    value: 'deleted',
                },
            ],
        }
    },
    computed: {
        activeFilters: function () {
            return this.filters.filter(({ isActive }) => isActive)
        },
    },
    watch: {
        activeFilters: function (newValue) {
            const filters = newValue
                .map((filter) => {
                    return filter.isActive ? filter.value : undefined
                })
                .filter((i) => i)
            this.$emit('change-handle', filters)
        },
    },
    methods: {
        checkHandle: function (value) {
            const previousState = this.filters
            this.filters = this.filters.map((filter) =>
                filter.id === value
                    ? { ...filter, isActive: !filter.isActive }
                    : filter
            )

            const onlyOneIsChecked =
                this.filters.reduce((ak, filter) => {
                    return filter.isActive ? (ak = ak + 1) : ak
                }, 0) === 0

            if (onlyOneIsChecked) this.filters = previousState
        },
    },
}
</script>

<style lang="scss">
.client-filter {
    border: 1px;

    &__list {
        padding-bottom: 10px;
    }
}
</style>
