<template>
    <form class="client-add">
        <div class="client-add__input-wrapper client-add__input-wrapper--name">
            <v-text-field
                v-model="fields.name"
                append-icon="mdi-account"
                dense
                outlined
                type="text"
                name="Name-for-new"
                data-test-id="clients--add-client-pop-up--name-text-input"
                :label="textContent['first-name']"
                :error-messages="errors['name']"
                @input="errors['name'] = ''"
            ></v-text-field>
        </div>

        <div class="client-add__input-wrapper client-add__input-wrapper--name">
            <v-text-field
                v-model="fields.surname"
                append-icon="mdi-account"
                dense
                outlined
                type="text"
                name="surname-for-new"
                data-test-id="clients--add-client-pop-up--sur-name-text-input"
                :label="textContent['last-name']"
                :error-messages="errors['surname']"
                @input="errors['surname'] = ''"
            ></v-text-field>
        </div>

        <div class="client-add__input-wrapper">
            <v-text-field
                v-model="fields.phone"
                v-mask="phoneMask"
                append-icon="mdi-phone"
                dense
                outlined
                type="text"
                name="phone-for-new"
                data-test-id="clients--add-client-pop-up--phone-text-input"
                :label="textContent['Phone']"
                :error-messages="errors['phone']"
                @input="onValidatePhone"
            >
                <template #prepend-inner>
                    <div class="client-add__phone-flag">
                        {{ flag }}
                    </div>
                </template>
            </v-text-field>
        </div>

        <div class="client-add__input-wrapper">
            <v-text-field
                v-model="fields.additionalPhone"
                append-icon="mdi-phone"
                dense
                outlined
                type="text"
                name="additional-phone-for-new"
                :label="textContent['additional-phone']"
                :error-messages="errors['additionalPhone']"
                @input="errors['additionalPhone'] = ''"
            ></v-text-field>
        </div>

        <div class="client-add__input-wrapper">
            <v-text-field
                v-model="fields.email"
                append-icon="mdi-email"
                dense
                outlined
                type="text"
                name="Email-for-new"
                :label="textContent['Email']"
                :error-messages="errors['email']"
                @input="errors['email'] = ''"
            ></v-text-field>
        </div>

        <div
            class="client-add__input-wrapper client-add__input-wrapper--country"
        >
            <v-autocomplete
                v-model="fields.country"
                append-icon="mdi-earth"
                :items="countries"
                dense
                outlined
                :label="textContent['Country']"
                :error-messages="errors['country']"
                @input="selectCountyHandle"
            ></v-autocomplete>
        </div>
        <div
            class="client-add__input-wrapper client-add__input-wrapper--country"
        >
            <v-autocomplete
                v-model="fields.city"
                append-icon="mdi-map-marker"
                :items="cities"
                dense
                outlined
                :label="textContent['City']"
                :error-messages="errors['city']"
                no-data-text="Choose country"
                @input="errors['city'] = ''"
            ></v-autocomplete>
        </div>
    </form>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import {
    actionTypes as actionTypeCountries,
    getterTypes as getterTypesCountries,
} from '@/store/modules/countries'
import { getterTypes as getterTypesClients } from '@/store/modules/clients'
import languageMixin from '@/mixins/language.mixin'
import countryNameConvert from '@/helpers/country-name-convert'

export default {
    name: 'ClientFormAdd',
    mixins: [languageMixin],
    props: {
        isActive: {
            type: Boolean,
            default: false,
        },
        data: {
            type: Object,
            default: () => ({
                fields: {
                    name: '',
                    surname: '',
                    email: '',
                    phone: '',
                    additionalPhone: '',
                    country: '',
                    city: '',
                },
                errors: {
                    name: '',
                    surname: '',
                    email: '',
                    phone: '',
                    additionalPhone: '',
                    country: '',
                    city: '',
                },
                maskDisable: false,
            }),
        },
    },
    data() {
        return {
            userWasChanged: false,
        }
    },
    computed: {
        ...mapGetters('clients', { getClient: getterTypesClients.getClient }),
        ...mapGetters('countries', {
            getAllCountries: getterTypesCountries.getCountries,
            cities: getterTypesCountries.getCities,
            getFlag: getterTypesCountries.getFlag,
            getCode: getterTypesCountries.getCode,
        }),
        countries: function () {
            return this.getAllCountries.map((country) => ({
                text: countryNameConvert(country, this.isRtl),
                value: country,
            }))
        },
        flag: function () {
            return this.fields?.country
                ? this.getFlag(this.fields.country.toLowerCase())
                : '🇮🇱'
        },
        fields: function () {
            return this.data.fields
        },
        errors: function () {
            return this.data.errors
        },
        phoneMask: function () {
            if (!this.fields.country || this.maskDisable) return null
            const code = this.getCode(this.fields.country.toLowerCase())
            const numLength = this.phoneAmount(code) - code.length
            let number = ''

            for (let i = 0; i < numLength; i++) {
                number += '#'
            }

            return `${code} ${number}`
        },
    },
    watch: {
        isActive(value) {
            if (value) return null
            this.userWasChanged = false
        },
        phoneMask: async function (value) {
            if (!value) return null
            this.maskDisable = true
            await this.$nextTick()
            this.fields.phone =
                Object.keys(this.getClient).length !== 0 && this.userWasChanged
                    ? this.getClient.phone
                    : this.getCode(this.fields.country.toLowerCase())
            this.maskDisable = false
            this.userWasChanged = false
        },
        getClient(user, old) {
            if (Object.keys(user).length === 0 || user.id === old.id)
                return null
            this.userWasChanged = true
        },
    },
    mounted() {
        if (this.getClient) return null
        this.getCountries().then(() => {
            this.fields.country = 'Israel'
            this.getCities(this.fields.country.toLowerCase()).then(() => {
                this.fields.city = 'Jerusalem'
            })
        })
    },
    methods: {
        ...mapActions('countries', {
            getCountries: actionTypeCountries.getCountries,
            getCities: actionTypeCountries.getCities,
        }),
        selectCountyHandle: function () {
            this.errors['country'] = ''
            this.getCities(this.fields.country.toLowerCase())
            this.fields.city = ''
        },
        onValidatePhone: function (str) {
            this.errors.phone = ''
            const regx = /^\+[0-9]{1,5}\s0/
            if (regx.test(str))
                this.errors.phone = 'Please remove "0" after code'
        },
        phoneAmount: function (code) {
            switch (code) {
                case '+972':
                    return 13
                default:
                    return 17
            }
        },
    },
}
</script>

<style scoped lang="scss">
.client-add {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    &__input-wrapper {
        width: 100%;

        &--name,
        &--country {
            width: 48%;
        }
    }

    &__phone-flag {
        padding-top: 5px;
        min-width: 21px;
    }
}
</style>
