const keyMap = {
    baseColor: 'base_color',
    delivery: 'date',
}

const prepareSortOptions = (keys, desc) => {
    let sortOptions = []

    keys.forEach((key, index) => {
        let direction = desc[index] ? 'desc' : 'asc'
        sortOptions.push(`${keyMap[key]}_${direction}`)
    })
    return sortOptions
}

export default prepareSortOptions
