<template>
    <div class="permission">
        <div class="permission__go-back">
            <v-btn dark small color="#967adc" outlined @click="goBack">
                <v-icon class="btn-icon">mdi-arrow-left-circle</v-icon>
                <span class="btn-text">go back</span>
            </v-btn>
        </div>
        <h1 class="permission__title">
            You do not have permission to view this page
        </h1>
    </div>
</template>

<script>
export default {
    name: 'PermissionsDenied',
    methods: {
        goBack: function () {
            this.$router.push({ path: '/' })
        },
    },
}
</script>

<style lang="scss" scoped>
.permission {
    padding: 15px;

    &__go-back {
        margin-bottom: 15px;
    }

    &__title {
    }
}
</style>
