var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ul',{staticClass:"meetings"},_vm._l((_vm.meetingsList),function({
            id,
            startAt,
            calendar,
            comment,
            createdAt,
            createdBy,
            meetingManager,
            isCanceled,
            canceledBy,
            canceledAt,
        }){return _c('li',{key:id,staticClass:"meetings__item",class:{ canceled: isCanceled },on:{"click":() => {
                if (isCanceled) return
                _vm.goToSelectedMeeting({ startAt, calendar })
            }}},[_c('div',{staticClass:"meetings__body"},[_c('div',[_c('div',{staticClass:"meetings__headline"},[_c('div',{staticClass:"meetings__icon"},[_c('v-icon',{attrs:{"color":isCanceled ? '#da4453' : '#967adc'}},[_vm._v("mdi-calendar-clock")])],1),_c('p',[_c('DateCell',{attrs:{"value":startAt,"utc":false}})],1)]),(meetingManager?.id)?_c('div',{staticClass:"meetings__headline"},[_c('div',{staticClass:"meetings__icon"},[_c('v-icon',{attrs:{"color":isCanceled ? '#da4453' : '#967adc'}},[_vm._v("mdi-calendar-clock")])],1),_c('p',[_vm._v(" "+_vm._s(meetingManager.nickName)+" ")])]):_vm._e(),_c('p',{staticClass:"meetings__comment"},[_vm._v(_vm._s(comment))])]),(isCanceled)?_c('div',{staticClass:"meetings__cancel"},[_vm._v(" "+_vm._s(_vm.$t('shared.clientMeetingList.canceled'))+" "),_c('v-icon',{attrs:{"color":"#da4453"}},[_vm._v("mdi-cancel")])],1):_vm._e()]),_c('div',{staticClass:"meetings__foot"},[_c('div',{staticClass:"meetings__foot-item"},[_c('p',[_vm._v(" "+_vm._s(_vm.$t('shared.clientMeetingList.createdAt'))+": "),_c('span',{staticClass:"date-container"},[_c('DateCell',{attrs:{"value":createdAt}})],1)]),_c('p',[_vm._v(" "+_vm._s(_vm.$t('shared.clientMeetingList.createdBy'))+": "),_c('span',[_vm._v(_vm._s(createdBy))])])]),(isCanceled)?_c('div',{staticClass:"meetings__foot-item"},[_c('p',[_vm._v(" "+_vm._s(_vm.$t('shared.clientMeetingList.canceledAt'))+": "),_c('span',{staticClass:"date-container"},[_c('DateCell',{attrs:{"value":canceledAt}})],1)]),_c('p',[_vm._v(" "+_vm._s(_vm.$t('shared.clientMeetingList.canceledBy'))+": "),_c('span',[_vm._v(_vm._s(canceledBy))])])]):_vm._e()])])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }