import store from '@/store'
import { actionTypes as actionTypesOrders } from '@/store/modules/orders'

const loadingDecorator = async (action) => {
    try {
        await store.dispatch(`orders/${actionTypesOrders.startFetching}`)

        // provide main logic
        await action()

        await store.dispatch(`orders/${actionTypesOrders.reloadSelectedOrder}`)
    } catch (e) {
        console.error(e)
    } finally {
        setTimeout(async () => {
            await store.dispatch(`orders/${actionTypesOrders.stopFetching}`)
        }, 500)
    }
}

export default loadingDecorator
