<template>
    <nav class="main-menu" :class="{ ['is-active']: isActive }">
        <div class="main-menu__logo">
            <template v-if="isDemo">
                <LogoPrimary />
            </template>
        </div>

        <ul class="main-menu__list">
            <li
                v-for="(link, index) in links"
                :key="index"
                class="main-menu__item"
                @click="closeMenuHandle"
            >
                <router-link
                    v-if="link.type === 'link'"
                    class="main-menu__link"
                    :to="{ path: link.path }"
                >
                    <div class="icon">
                        <div class="badge">{{ link.title }}</div>
                        <i :class="link.icon" />
                    </div>
                    <div class="badge">{{ link.title }}</div>
                    <span class="text">{{ link.title }}</span>
                </router-link>
                <div v-else class="main-menu__heading">
                    <div class="icon">
                        <div class="badge">{{ link.title }}</div>
                        <i :class="link.icon" />
                    </div>
                    <div class="badge">{{ link.title }}</div>
                    <span class="text">{{ link.title }}</span>
                </div>
            </li>
        </ul>
    </nav>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import LogoPrimary from '@/components/common/logo-primary'
import { getterTypes, mutationTypes } from '@/store/modules/main-menu'
import { getterTypes as getterTypesConfiguration } from '@/store/modules/configuration'

export default {
    name: 'MenuMain',
    components: { LogoPrimary },
    data() {
        return {
            permissions: {
                ['Dashboard']: ['all'],
                ['Orders']: ['all'],
                ['Orders list']: ['all'],
                ['Meetings calendar']: ['admin', 'manager', 'usa_manager'],
                ['Clients']: ['admin', 'usa_manager', 'manager'],
                ['Clients list']: ['admin', 'usa_manager', 'manager'],
                ['Users']: ['admin'],
                ['Users list']: ['admin'],
                ['Reports']: ['admin'],
                //todo: needs refact
                ['לוח בקרה']: ['all'],
                ['הזמנות']: ['all'],
                ['רשימת הזמנות']: ['all'],
                ['יומנים']: ['admin', 'manager', 'usa_manager'],
                ['לקוחות']: ['admin', 'usa_manager', 'manager'],
                ['רשימת לקוחות']: ['admin', 'usa_manager', 'manager'],
                ['משתמשים']: ['admin'],
                ['רשימת משתמשים']: ['admin'],
                ['דו"חות']: ['admin'],
            },
            linksData: {
                en: [
                    {
                        type: 'link',
                        title: 'Dashboard',
                        icon: 'icon-home-outline',
                        path: '/',
                    },
                    {
                        type: 'heading',
                        title: 'Orders',
                        icon: 'icon-dots-three-horizontal1',
                        path: '/',
                    },
                    {
                        type: 'link',
                        title: 'Orders list',
                        icon: 'icon-shopping-bag',
                        path: '/orders',
                    },
                    {
                        type: 'link',
                        title: 'Meetings calendar',
                        icon: 'icon-calendar',
                        path: '/meetings-calendar',
                    },
                    {
                        type: 'heading',
                        title: 'Clients',
                        icon: 'icon-dots-three-horizontal1',
                        path: '/',
                    },
                    {
                        type: 'link',
                        title: 'Clients list',
                        icon: 'icon-user',
                        path: '/clients-list',
                    },
                    {
                        type: 'heading',
                        title: 'Users',
                        icon: 'icon-dots-three-horizontal1',
                        path: '/',
                    },
                    {
                        type: 'link',
                        title: 'Users list',
                        icon: 'icon-life-buoy',
                        path: '/users-list',
                    },
                    {
                        type: 'link',
                        title: 'Reports',
                        icon: 'mdi-archive-outline',
                        path: '/report',
                    },
                ],
                he: [
                    {
                        type: 'link',
                        title: 'לוח בקרה',
                        icon: 'icon-home-outline',
                        path: '/',
                    },
                    {
                        type: 'heading',
                        title: 'הזמנות',
                        icon: 'icon-dots-three-horizontal1',
                        path: '/',
                    },
                    {
                        type: 'link',
                        title: 'רשימת הזמנות',
                        icon: 'icon-shopping-bag',
                        path: '/orders',
                    },
                    {
                        type: 'link',
                        title: 'יומנים',
                        icon: 'icon-calendar',
                        path: '/meetings-calendar',
                    },
                    {
                        type: 'heading',
                        title: 'לקוחות',
                        icon: 'icon-dots-three-horizontal1',
                        path: '/',
                    },
                    {
                        type: 'link',
                        title: 'רשימת לקוחות',
                        icon: 'icon-user',
                        path: '/clients-list',
                    },
                    {
                        type: 'heading',
                        title: 'משתמשים',
                        icon: 'icon-dots-three-horizontal1',
                        path: '/',
                    },
                    {
                        type: 'link',
                        title: 'רשימת משתמשים',
                        icon: 'icon-life-buoy',
                        path: '/users-list',
                    },
                    {
                        type: 'link',
                        title: 'דו"חות',
                        icon: 'mdi-archive-outline',
                        path: '/report',
                    },
                ],
            },
        }
    },
    computed: {
        ...mapGetters('mainMenu', { isActive: getterTypes.getStatus }),
        ...mapGetters('configuration', {
            getLanguage: getterTypesConfiguration.getLanguage,
        }),
        isDemo() {
            return process.env.VUE_APP_TARGET !== 'demo'
        },
        links: function () {
            const lang = this.getLanguage === 'Hebrew' ? 'he' : 'en'

            return this.linksData[lang]
                .map((item) => ({
                    ...item,
                    permission: this.permissions[item.title],
                }))
                .filter((link) => {
                    return link.permission
                        ? this.getAllowed(link.permission)
                        : true
                })
        },
    },
    methods: {
        closeMenuHandle: function () {
            this.closeMenu()
        },
        ...mapMutations('mainMenu', { closeMenu: mutationTypes.close }),
    },
}
</script>

<style scoped lang="scss">
.main-menu {
    padding: 0 0 0;
    min-width: 240px;

    &__logo {
        width: 100%;
        margin-bottom: 15px;
        display: none;
        min-height: 66px;

        @include tablet-up {
            display: block;
        }
    }

    &__list {
        padding: 0;
    }

    &__heading,
    &__link {
        display: flex;
        font-size: 15px;
        font-weight: 400;
        font-family: 'Muli', sans-serif;
        color: $color-for-nav-link;
        padding: 10px 20px;
    }

    &__heading {
        font-size: 17px;
        text-transform: uppercase;

        .icon {
            position: relative;
            display: block;
            width: 20px;
            height: 20px;
            font-size: 20px;
            line-height: 20px;

            .is-active & {
                display: none;
            }

            &:hover + .badge {
                display: block;
            }
        }

        .text {
            display: none;

            .is-active & {
                display: block;
            }
        }

        .badge {
            z-index: 1000;
            display: none;
            position: absolute;
            padding: 4px;
            background: #000;
            font-size: 10px;
            top: 50%;
            right: 0;
        }
    }

    &__link {
        cursor: pointer;
        transition: background 150ms ease-out;
        border-right: 4px solid transparent;
        text-decoration: none;

        @include for-hebrew {
            border-left: 4px solid transparent;
            border-right: none;
        }

        &:hover {
            color: $color-primary-light;
            background: $color-for-nav-item-hover;
        }

        &.router-link-exact-active {
            color: $color-primary-light;
            background: $color-for-nav-item-hover;
            border-color: $color-for-nav-border;
        }

        .icon {
            width: 20px;
            height: 20px;
            font-size: 20px;
            line-height: 20px;
            border-radius: 50%;
            margin-right: 10px;

            @include for-hebrew {
                margin-right: 0;
                margin-left: 10px;
            }
        }

        .text {
            display: none;

            .is-active & {
                display: block;
            }
        }

        .badge {
            display: none;
        }
    }
}
</style>
