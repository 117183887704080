import {
    actionTypesSlideOuts,
    mutationTypesSlideOuts,
} from '@/store/modules/slide-outs/types'

export default {
    [actionTypesSlideOuts.SET_SELECTED_SLIDE_OUT]: ({ commit }, payload) => {
        commit(mutationTypesSlideOuts.SET_SELECTED_SLIDE_OUT, payload)
    },
}
