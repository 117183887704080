import axios from '@/api/axios'

export default {
    clients: {
        getAll: () =>
            axios.get(`/base/api/clients/100000`).then((res) => res.data),
        getAllV2: ({ itemsPerPage, currentPage, sort, search, status }) =>
            axios
                .get(
                    `/base/api/clients/v2?per_page=${itemsPerPage}&page=${currentPage}&sort=${sort}&status=${status}&search=${search}`
                )
                .then((res) => {
                    return res.data
                }),
        getAllDeleted: () =>
            axios.get(`/base/api/clients/getDeleted`).then((res) => res.data),
        getAllByUsaManager: () =>
            axios.get(`/base/api/clients/getMy`).then((res) => res.data),
        getClient: (id) =>
            axios.get(`/base/api/client/${id}`).then((res) => res.data),
        addClient: (client) =>
            axios
                .post('/base/api/client', { ...client })
                .then((res) => res.data),
        updateClient: (client, id) =>
            axios
                .put(`/base/api/client/${id}`, { ...client })
                .then((res) => res.data),
        deleteClient: (id) =>
            axios.delete(`/base/api/client/${id}`).then((res) => res.data),
        restoreClient: (id) =>
            axios.put(`/base/api/client/${id}/restore`).then((res) => res.data),
        checkPhone: (phone) =>
            axios
                .get(`/base/api/client/getByPhone?phone=${phone}`)
                .then((res) => res.data),
    },
}
