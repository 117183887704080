const arraysEqual = (...arrays) => {
    if (arrays[0].length !== arrays[1].length) {
        return false
    }

    for (let i = 0; i < arrays[1].length; i++) {
        if (arrays[0][i] !== arrays[1][i]) {
            return false
        }
    }

    return true
}

export default arraysEqual
