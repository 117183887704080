var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"calendar-event-expanded-card"},[_c('div',{staticClass:"calendar-event-expanded-card__body"},[_c('p',{staticClass:"calendar-event-expanded-card__time"},[_vm._v(" "+_vm._s(_vm.getTime(_vm.eventDetails.startAt))+" ")]),_c('p',{staticClass:"calendar-event-expanded-card__client"},[_vm._v(" "+_vm._s(_vm.eventDetails.client.fullName)+" ")]),(_vm.shouldShowManager)?_c('p',{staticClass:"calendar-event-expanded-card__manager"},[_vm._v(" "+_vm._s(_vm.eventDetails.manager)+" ")]):_c('p',{staticClass:"calendar-event-expanded-card__phone"},[_vm._v(" "+_vm._s(_vm.toHebrewPhoneFormat(_vm.eventDetails.client.phoneNumber))+" ")]),_c('p',{staticClass:"calendar-event-expanded-card__desc"},[_vm._v(" "+_vm._s(_vm.eventDetails.subject)+" ")])]),_c('div',{staticClass:"calendar-event-expanded-card__actions"},[_c('v-btn',{attrs:{"dark":"","x-small":"","dense":"","color":"#3bafda"},on:{"click":_vm.updateHandle}},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-square-edit-outline")])],1),(_vm.eventDetails.wigId || _vm.eventDetails.wig?.id)?_c('v-btn',{attrs:{"dark":"","x-small":"","dense":"","color":"#37bc9b"},on:{"click":_vm.goToWigs}},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-archive-arrow-up-outline")])],1):_vm._e(),_c('v-btn',{attrs:{"dark":"","x-small":"","dense":"","color":"#f6bb42"},on:{"click":_vm.goToClient}},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-account-arrow-right")])],1),_c('v-btn',{attrs:{"dark":"","x-small":"","dense":"","color":"#da4453"},on:{"click":_vm.deleteHandle}},[_c('v-icon',{attrs:{"x-small":""}},[_vm._v("mdi-delete-forever")])],1)],1),(
            _vm.eventDetails.previousDates &&
            _vm.getUniqueDatesByDate(
                _vm.eventDetails.previousDates,
                _vm.eventDetails.startAt
            )?.length > 0
        )?_c('div',{staticClass:"calendar-event-expanded-card__old-list"},[_c('CalendarOldDatesList',{attrs:{"old-dates":_vm.getUniqueDatesByDate(
                    _vm.eventDetails.previousDates,
                    _vm.eventDetails.startAt
                )}})],1):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }