import store from '@/store'
import { gettersTypes as getterTypesForAuth } from '@/store/modules/auth'
const getAllowed = (roles) => {
    return store.getters[`auth/${getterTypesForAuth.getAllowed}`](roles)
}

export const decorateWithDependency = (actions, dependency) => {
    return actions.map((action) => {
        return {
            ...action,
            handle: action.handle.bind(null, dependency, store, getAllowed),
        }
    })
}

export const decorateWithValidation = (actions, dependency) => {
    return actions.filter((action) => {
        return action.validator(dependency, getAllowed)
    })
}
