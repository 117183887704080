<template>
    <v-dialog v-model="modalIsActive" width="500" @click:outside="closeHandle">
        <div class="qr-code-scanner">
            <button class="qr-code-scanner__close" @click="closeHandle">
                <v-icon dark>mdi-window-close</v-icon>
            </button>
            <QrCode
                :camera="isActive ? 'auto' : 'off'"
                @on-detect="onDetect"
            ></QrCode>
        </div>
    </v-dialog>
</template>

<script>
import QrCode from '@/components/qr-code/qr-code'
import popUpTypes from '@/types/pop-up-types'
const { qrCodeScan } = popUpTypes

export default {
    name: 'QrCodeModalScan',
    components: {
        QrCode,
    },
    props: {
        isActive: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['on-detect', 'on-close'],
    data() {
        return {
            selfName: qrCodeScan,
        }
    },
    computed: {
        modalIsActive: {
            get: function () {
                return this.isActive
            },
            set: function (value) {
                if (!value) this.closeHandle()
            },
        },
    },
    methods: {
        onDetect: function (e) {
            this.$emit('on-detect', e)
            this.closeHandle()
        },
        closeHandle: function () {
            this.$emit('on-close')
        },
    },
}
</script>

<style scoped lang="scss">
.qr-code-scanner {
    position: relative;

    &__close {
        z-index: 2;
        width: 35px;
        height: 35px;
        top: 10px;
        right: 10px;
        position: absolute;
        background: rgba(0, 0, 0, 0.25);
        border-radius: 50%;
        padding: 5px;
    }
}
</style>
