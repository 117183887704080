<template>
    <v-expansion-panel>
        <v-expansion-panel-header color="#f7f7f9">
            <h4 class="order-property__sub-title">
                5. {{ propertiesContent['base-color'] }}
            </h4>
        </v-expansion-panel-header>
        <v-expansion-panel-content color="#f7f7f9">
            <div class="order-property__input-wrapper">
                <v-text-field
                    v-model="properties.fields['baseColor']"
                    light
                    outlined
                    dense
                    type="text"
                    name="base-color"
                    :label="propertiesContent['base-color']"
                    :error-messages="properties.errors['baseColor']"
                    @input="properties.errors['baseColor'] = ''"
                >
                </v-text-field>

                <v-text-field
                    v-model="properties.fields['skinColor']"
                    light
                    outlined
                    dense
                    type="text"
                    name="skin-color"
                    :label="propertiesContent['skin-color']"
                    :error-messages="properties.errors['skinColor']"
                    @input="properties.errors['skinColor'] = ''"
                >
                </v-text-field>

                <div class="order-property__inner">
                    <v-text-field
                        v-model="properties.fields['highlightsColor']"
                        light
                        outlined
                        dense
                        type="text"
                        name="highlights-сolor"
                        :label="propertiesContent['highlights-color']"
                        hide-details
                        :error-messages="properties.errors['highlightsColor']"
                        @input="properties.errors['highlightsColor'] = ''"
                    >
                    </v-text-field>

                    <v-radio-group
                        v-model="properties.fields['highlightsColorSelect']"
                        :value="'Honey'"
                        :error-messages="
                            properties.errors['highlightsColorSelect']
                        "
                        @change="
                            properties.errors['highlightsColorSelect'] = ''
                        "
                    >
                        <v-radio
                            :label="propertiesContent['honey']"
                            value="Honey"
                        ></v-radio>
                        <v-radio
                            :label="propertiesContent['ash']"
                            value="Ash"
                        ></v-radio>
                    </v-radio-group>
                </div>

                <div
                    id="order-property--base-color--base-color-select"
                    class="order-property__input-wrapper"
                >
                    <v-autocomplete
                        v-model="properties.fields['typeOfHighlights']"
                        outlined
                        :items="items"
                        :label="propertiesContent['type-of-highlights']"
                        dense
                        attach="#order-property--base-color--base-color-select"
                        :error-messages="properties.errors['typeOfHighlights']"
                        @input="properties.errors['typeOfHighlights'] = ''"
                    >
                    </v-autocomplete>

                    <v-autocomplete
                        v-model="properties.fields['skinTopHighlights']"
                        outlined
                        :items="itemsSkinTop"
                        :label="propertiesContent['skin-top-highlights']"
                        dense
                        attach="#order-property--base-color--base-color-select"
                        :error-messages="properties.errors['skinTopHighlights']"
                        @input="properties.errors['skinTopHighlights'] = ''"
                    >
                    </v-autocomplete>
                </div>

                <div class="order-property__inner">
                    <v-text-field
                        v-model="properties.fields['frontColor']"
                        light
                        outlined
                        dense
                        type="text"
                        name="front-color"
                        :label="propertiesContent['front-color']"
                        hide-details
                        :error-messages="properties.errors['frontColor']"
                        @input="properties.errors['frontColor'] = ''"
                    >
                    </v-text-field>

                    <v-radio-group
                        v-model="properties.fields['withHighlights']"
                    >
                        <v-radio
                            :label="propertiesContent['with-highlights']"
                            :value="true"
                        ></v-radio>
                        <v-radio
                            :label="propertiesContent['non-highlights']"
                            :value="false"
                        ></v-radio>
                    </v-radio-group>

                    <div
                        v-if="properties.fields['withHighlights']"
                        class="order-property__inner"
                    >
                        <v-text-field
                            v-model="properties.fields['highlightsColoring']"
                            light
                            outlined
                            dense
                            type="text"
                            name="highlights-color"
                            :label="propertiesContent['highlights-color']"
                            :error-messages="
                                properties.errors['highlightsColoring']
                            "
                            @input="
                                properties.errors['highlightsColoring'] = ''
                            "
                        >
                        </v-text-field>

                        <v-radio-group
                            v-model="
                                properties.fields['highlightsColoringSelect']
                            "
                            :error-messages="
                                properties.errors['highlightsColoringSelect']
                            "
                            @change="
                                properties.errors['highlightsColoringSelect'] =
                                    ''
                            "
                        >
                            <v-radio
                                :label="propertiesContent['honey']"
                                value="Honey"
                            ></v-radio>
                            <v-radio
                                :label="propertiesContent['ash']"
                                value="Ash"
                            ></v-radio>
                        </v-radio-group>
                    </div>
                </div>

                <v-text-field
                    v-model="properties.fields['colorComments']"
                    light
                    outlined
                    dense
                    type="text"
                    name="color-comments"
                    :label="propertiesContent['color-comments']"
                    :error-messages="properties.errors['colorComments']"
                    @input="properties.errors['colorComments'] = ''"
                >
                </v-text-field>
            </div>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
import languagePropertiesMixin from '@/mixins/language-properties.mixin'

export default {
    name: 'OrderFormPropertyBaseColor',
    mixins: [languagePropertiesMixin],
    props: {
        dataProperties: {
            type: Object,
            default: () => ({
                fields: {
                    ['baseColor']: '',
                    ['skinColor']: '',
                    ['highlightsColor']: '',
                    ['highlightsColorSelect']: 'Honey',
                    ['typeOfHighlights']: '',
                    ['skinTopHighlights']: '',
                    ['frontColor']: '',
                    ['withHighlights']: '',
                    ['highlightsColoring']: '',
                    ['highlightsColoringSelect']: 'Honey',
                    ['colorComments']: '',
                },
                errors: {},
            }),
        },
    },
    data() {
        return {
            frontColorHighlights: false,
        }
    },
    computed: {
        items: function () {
            return [
                { text: this.propertiesContent['none'], value: null },
                {
                    text: this.propertiesContent['half-blonde'],
                    value: 'Half Blonde',
                },
                {
                    text: this.propertiesContent['gentle-mixed'],
                    value: 'Gentle Mixed',
                },
                {
                    text: this.propertiesContent['special-yafi-order'],
                    value: "Special Yafi's Orders",
                },
            ]
        },
        itemsSkinTop: function () {
            return [
                { text: this.propertiesContent['none'], value: null },
                { text: this.propertiesContent['non'], value: 'Non' },
                {
                    text: this.propertiesContent['on-the-bottom'],
                    value: 'On The Bottom',
                },
                {
                    text: this.propertiesContent['from-the-root'],
                    value: 'From The Root',
                },
                { text: this.propertiesContent['blended'], value: 'Blended' },
                { text: this.propertiesContent['streaky'], value: 'Streaky' },
            ]
        },
        properties: function () {
            return this.dataProperties
        },
    },
    methods: {},
}
</script>

<style lang="scss" scoped>
.order-property {
    &__sub-title {
        color: $color-primary-dark;
    }

    &__input-wrapper {
        padding: 15px 15px 0;
        position: relative;
    }

    &__inner {
        border: 1px solid #626161;
        padding: 15px;
        margin-bottom: 15px;
    }
}
</style>
