import axios from '@/api/axios'
import rangeFromDate from '@/helpers/rangeFromDate'

export default {
    getMeetings: ({ type, range }) => {
        return axios
            .get(
                `/base/api/meetings/v2/filterByType?date_from=${rangeFromDate(
                    range
                )}&calendar=${type.toUpperCase()}`
            )
            .then((res) => res.data)
    },
    //todo: need only this one
    getMeetingsV2: ({ type, range, isUsa = false, search }) => {
        return axios
            .get(
                `/base/api/meetings/v2/filterByType?date_from=${
                    range.dateFrom
                }&date_to=${range.dateTo}&calendar=${type.toUpperCase()}${
                    isUsa ? '&only_my=1' : ''
                }&search=${search}`
            )
            .then((res) => res.data)
    },
    getMeetingList: (params) =>
        axios.get(`/base/api/meetings/v2/filterFutureMeetings`, { params }),
    addNewOne: (data) =>
        axios.post('/base/api/meeting', data).then((res) => res.data),
    deleteOne: (id) =>
        axios.delete(`/base/api/meeting/${id}`).then((res) => res.data),
    updateOne: ({ data, id }) =>
        axios.put(`/base/api/meeting/${id}`, data).then((res) => res.data),
}
