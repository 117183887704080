<template>
    <div class="print">
        <div
            v-for="(meetingsPart, index) in meetings"
            :key="index"
            class="print__content"
        >
            <div class="print__header">
                <h3 class="print__page-num">page {{ index + 1 }}</h3>
                <div class="print__header-title">
                    <h1 class="print__title-of-calendar">
                        {{ text(kind) }}
                    </h1>
                    <h1 class="print__title">Queues on {{ date }}</h1>
                </div>
            </div>
            <div class="print__table">
                <ul class="table list-group">
                    <li class="table__headers">
                        <p class="table__time">Time</p>
                        <div
                            class="table__devinder table__devinder--header"
                        ></div>
                        <p class="table__desc">Description</p>
                    </li>

                    <li
                        v-for="(meeting, subIndex) in meetingsPart"
                        :key="subIndex"
                        class="table__item list-group-item"
                    >
                        <p class="table__time">{{ time(meeting.startAt) }}</p>
                        <div
                            class="table__devinder"
                            :class="{ ['is-ready']: hasReadyWig(meeting) }"
                        ></div>
                        <template
                            v-if="containsHebrew(meeting.client.fullName)"
                        >
                            <p class="table__desc">
                                {{ meeting.client.phone }} |

                                <span
                                    v-if="isRepair(meeting)"
                                    class="table__kind-icon"
                                >
                                    repair
                                </span>
                                <span v-if="isManager(meeting)">
                                    {{ meeting.meetingManager.shortNickName }}
                                    |
                                </span>
                                <span v-if="meeting.subject">
                                    {{ meeting.subject }} |
                                </span>
                                {{ meeting.client.fullName }}
                            </p>
                        </template>
                        <template v-else>
                            <p class="table__desc">
                                {{ meeting.client.phone }} |
                                {{ meeting.client.fullName }} |
                                {{ meeting.subject }}
                                <span
                                    v-if="isRepair(meeting)"
                                    class="table__kind-icon"
                                >
                                    repair
                                </span>
                                <span v-if="isManager(meeting)">
                                    {{ meeting.meetingManager.shortNickName }}
                                </span>
                            </p>
                        </template>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
import modalsMixin from '@/mixins/modals.mixin'
import { mapActions, mapGetters } from 'vuex'
import { actionTypes, getterTypes } from '@/store/modules/modals'

import arraySliceHandle from '@/helpers/array-slice-handle'
import popUpTypes from '@/types/pop-up-types'
import { getInMilliseconds, getTimeHhMm } from '@/helpers/getDateInFormat'
import regularToSnakeCase from '@/helpers/regular-to-snake-case'
import languageMixin from '@/mixins/language.mixin'

const { printPage } = popUpTypes

export default {
    name: 'CalendarPrintPage',
    mixins: [modalsMixin, languageMixin],
    props: {
        date: {
            type: String,
            default: '',
        },
        typeOfMeetings: {
            type: String,
            default: 'orders',
        },
        getMeetingPerDay: {
            type: Array,
            default: () => [],
        },
    },
    emits: ['next-handle', 'prev-handle'],
    computed: {
        ...mapGetters('modals', { checkIsActive: getterTypes.isActive }),
        meetings: function () {
            return arraySliceHandle(
                this.getMeetingPerDay
                    .map((meeting) => ({
                        ...meeting,
                        date: getInMilliseconds(meeting.startAt),
                    }))
                    .sort((a, b) => a.date - b.date),
                17
            )
        },
        isActive: function () {
            return this.checkIsActive(printPage)
        },
        kind: function () {
            switch (this.typeOfMeetings.toLowerCase()) {
                case 'pick_up_new':
                    return 'Pick Up New'
                case 'pick_up_repairs':
                    return 'Pick Up Repairs'
                case 'repairs':
                    return 'Repairs'
                case 'us_agent':
                    return 'US Agent'
                default:
                    return 'Orders'
            }
        },
    },
    mounted() {
        setTimeout(() => {
            // window.print()
        }, 500)
    },
    methods: {
        ...mapActions('modals', { onClose: actionTypes.closePopUp }),
        closeHandle: function () {
            this.onClose(printPage)
        },
        text: function (text) {
            return this.textContent[regularToSnakeCase(text)]
        },
        time: function (date) {
            const start = getTimeHhMm(date)
            const end = getTimeHhMm(getInMilliseconds(date) + 1800000)
            return `${start} -  ${end}`
        },
        print: function () {
            window.print()
        },
        onNext: function () {
            this.$emit('next-handle')
        },
        onPrev: function () {
            this.$emit('prev-handle')
        },
        hasReadyWig: function (meeting) {
            const wigIsReady = [
                'Ready New Wig',
                'Delivered Wigs',
                'Storage',
                'Ready Repair Wigs',
            ].includes(meeting?.wig?.state)

            return wigIsReady
        },
        isRepair: function (meeting) {
            return this.kind === 'US Agent' && meeting?.wig?.is_repair === 1
        },
        isManager: function (meeting) {
            return meeting.meetingManager !== null
        },
        containsHebrew: function (str) {
            const regex = /[\u0590-\u05FF]/
            return regex.test(str)
        },
    },
}
</script>

<style lang="scss" scoped>
.print {
    background: #fff;
    width: 100%;
    min-height: 100%;
    height: fit-content;
    padding: 0 15px;
    overflow: visible;

    &__content {
        padding-top: 50px;
    }

    &__header {
        position: relative;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
    }

    &__page-num {
        position: absolute;
        display: block;
        top: 0;
        right: 0;
    }

    &__header-btn {
        @media print {
            display: none;
        }
    }

    &__header-title {
        padding: 0 30px;
    }

    &__title {
        font-size: 19px;
        margin: 0 10px;
    }

    &__title-of-calendar {
        text-align: center;
    }

    &__table {
        width: 100%;
        margin: 0 auto;
        padding: 15px;
        border: 1px solid #000;
    }

    &__action {
        margin-left: auto;
        width: 150px;
        display: flex;
        justify-content: space-between;

        @media print {
            display: none;
        }
    }
}

.table {
    font-size: 13px;

    &__headers {
        display: flex;
        font-weight: 700;
        align-items: center;
    }

    &__item {
        display: flex;
        border-bottom: 1px solid #000;
        padding-top: 10px;
        margin-bottom: 10px;
        align-items: center;
    }

    &__time {
        width: 85px;
        padding-bottom: 0;
        margin-bottom: 0;
    }

    &__desc {
        padding-bottom: 0;
        margin-bottom: 0;
    }

    &__devinder {
        position: relative;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        margin: 0 10px;
        -webkit-print-color-adjust: exact;

        &.is-ready {
            background: #46b777;

            @media print {
                background: #46b777;
            }
        }

        &:before {
            content: '';
            width: 13px;
            height: 13px;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            border: 1px solid #46b777;
            border-radius: 50%;
        }

        &--header {
            background-color: #777777;

            &:before {
                border-color: #777777;
            }
        }
    }
}

@page {
    size: A4;
    margin: 0;
}

@media print {
    .print {
        width: 210mm;
        height: 297mm;
    }
    .print__content {
        margin: 0;
        border: initial;
        border-radius: initial;
        width: initial;
        min-height: initial;
        box-shadow: initial;
        background: initial;
        page-break-after: always;
    }
}
</style>
