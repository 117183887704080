import api from '@/api/orders'
import properties from '@/assets/data/properties.json'
import { actionTypes as actionTypesFromPayments } from '@/store/modules/payments'
import { actionTypes as actionTypesFromModals } from '@/store/modules/modals'
import { actionTypes as actionTypesOrderRepairsStages } from '@/store/modules/order-repairs-stages'
import firstToLowerCase from '@/helpers/firstToLowerCase'
import { getFullDate, getInMilliseconds } from '@/helpers/getDateInFormat'
import { uniqBy } from 'lodash'
import popUpTypes from '@/types/pop-up-types'
import { mutationTypesOrdersReworkProperties } from '@/store/modules/orders-rework-properties/types'
import { actionTypes as actionTypesModalsNotification } from '@/store/modules/modal-notifications'
import { i18n } from '@/plugins/i18n'

const { orderContext } = popUpTypes

const state = () => ({
    orders: [],
    selectedOrder: {},
    setters: [],
    properties: properties.items,
    isLoading: false,
    oldClients: [],
    isSubmitting: false,
})

export const getterTypes = {
    getAll: '[orders] Get all orders',
    getProperties: '[orders] Get all properties',
    getOrder: '[orders] Get selected order',
    getOrderWithPayments:
        '[orders] Get selected order which includes all payments',
    getOldClients: '[orders] Get previous clients',
    isLoading: '[orders] Get status of loading',
    isSubmitting: '[orders] Get status of submitting',
}
export const mutationTypes = {
    loadAll: '[orders] Load all orders',
    addNewOrder: '[orders] Add new order',
    selectOrder: '[orders] Select the one order',
    updateState: '[orders] Update state for selected order',
    updateOrder: '[orders] Update general info for selected order',
    updateProperties: '[orders] Update properties for selected order',
    updatePhotos: '[orders] Update photos',
    deletePhoto: '[orders] Delete selected photo',
    deleteOrder: '[orders] Delete selected order',
    updateDate: '[orders] Update date for selected order',
    updateStatus: '[orders] Update status for selected order',
    addStatus: '[orders] Add status for selected order',
    updateCompleteDate:
        '[orders] Mutation for update complete date for selected order',
    assignCreator: '[orders] Assign creator for selected order',
    clearSelected: '[orders] Clear fields for selected order',
    addNewMeeting: '[orders] Add new meeting for order',
    startFetching: '[orders] Start fetching',
    stopFetching: '[orders] Stop fetching',
    clientUpdate: '[orders] Client update mutation',
    putOldClients: '[orders] Put old clients',
    deleteMeeting: '[orders] Delete selected meeting mutation',
    startSubmitting: '[orders] Start to submit',
    stopSubmitting: '[orders] Stop to submit',
    updateClient: '[orders] Update client mutation',
    updatePreviousClient:
        '[orders] Update previous client for stock flow mutation',
    addSetter: '[orders] Add specific function for modifying order list',
    removeSetter: '[orders] Remove specific function for modifying order list',
    updateMeetingManager: '[orders][mutation] update meeting manager',
    associateWig: '[orders] Asociate wig',
}
export const actionTypes = {
    loadAll: '[orders] Load all orders',
    getDelivered: '[orders] Load all delivered orders',
    getDeliveredByName: '[orders] Load delivered orders by name',
    getWigsBySearchParameters: '[orders] Load all wigs by search parameter',
    getWigsByState: '[orders] Load all wigs by specific state',
    loadOrder: '[orders] Load the new one order',
    reloadSelectedOrder: '[orders] Reload selected orders',
    loadProperties: '[orders] Load all properties',
    createOrder: '[orders] Create the new one order',
    updateProperties: '[orders] Update properties for selected order',
    updateState: '[orders] Update state for selected order',
    updateOrder: '[orders] Update general info for selected order',
    updateCompleteDate:
        '[orders] Action for update complete date for selected order',
    addNewPhoto: '[orders] Add new photo',
    deletePhoto: '[orders] Delete selected photo',
    deleteOrder: '[orders] Delete selected order',
    updateStatus: '[orders] Update status for selected order',
    assignCreator: '[orders] Assign creator for selected order',
    addNewMeeting: '[orders] Add new meeting for order',
    clientUpdate: '[orders] Client update action',
    loadOldClients: '[orders] Load old clients',
    meetingDelete: '[orders] Delete selected meetings',
    updateClient: '[orders] Update client action',
    moveOrderToArchive: '[orders][mutation] move order to archive',
    createWigFromStock: '[orders][action] create wig from stock',
    startFetching: '[orders][action] Start fetching',
    stopFetching: '[orders][action] Stop fetching',
    filterByBarcodeOrClient: '[orders] Load all wigs by barcode or client',
    associateWig: '[orders] Associate wig',
    updateMeetingManager: '[orders][action] update meeting manager',
}

const getters = {
    [getterTypes.getAll]: ({ orders, setters }) => {
        const uniqOrders = uniqBy(orders, 'id')
        return setters.reduce((ak, setter) => {
            return setter.setter(ak)
        }, uniqOrders)
    },
    [getterTypes.getProperties]: ({ properties }) => properties,
    [getterTypes.getOrder]: ({ selectedOrder }) => selectedOrder,
    [getterTypes.getOrderWithPayments]: ({ selectedOrder }) => {
        return selectedOrder
    },
    [getterTypes.getOldClients]: ({ oldClients }) => oldClients,
    [getterTypes.isLoading]: ({ isLoading }) => isLoading,
    [getterTypes.isSubmitting]: ({ isSubmitting }) => isSubmitting,
}
const mutations = {
    [mutationTypes.startFetching](state) {
        state.isLoading = true
    },
    [mutationTypes.stopFetching](state) {
        state.isLoading = false
    },
    [mutationTypes.loadAll](state, orders) {
        //cals each state
        /*
        const res = orders.reduce((ak, { state }) => {
            return { ...ak, [state]: ak[state] ? ak[state] + 1 : 1 }
        }, {})

        console.log(res)
        */
        state.orders = [...state.orders, ...orders]
    },
    [mutationTypes.addNewOrder](state, order) {
        const newOne = {
            barcode: order.barcode,
            baseColor: order.properties?.baseColor || null,
            client: order.client,
            client_id: order.client_id,
            complete_data: '',
            created_at: order.created_at,
            has_wig_building: false,
            id: order.id,
            is_repair: order.is_repair,
            lengthFromNape: order.properties?.lengthFromNape || null,
            manager_nickname: order.creator,
            state: 'Draft',
            status: 'Draft',
            updated_at: order.updated_at,
            user_generated_id: order.user_generated_id,
            previous_client: order.previous_client || '',
        }

        state.orders = [...state.orders, newOne]
    },
    [mutationTypes.selectOrder](state, order) {
        let properties = {}
        Object.keys(order.properties || {}).forEach((key) => {
            if (order.properties[key] && order.properties[key] !== 'none')
                properties[key] = order.properties[key]
        })

        // Update table with updated wig

        const clientNumber = (
            order?.client?.match(/\(\+[0-9]*\)|((([0-9]*)-?)*)/)[0] || ''
        ).trim()
        const clientFullName = (
            order?.client?.replace(/\(\+[0-9]*\)/, '') || ''
        ).trim()

        const updatedTableOptions = {
            barcode: order.barcode,
            state: order.state,
            client: `${clientFullName}${clientNumber}`,
            status: order.status || order.state,
            complete_data: order.complete_data,
            baseColor: order.properties?.baseColor || '',
            lengthFromNape: order.properties?.lengthFromNape || '',
        }

        state.selectedOrder = {}
        state.selectedOrder = { ...order, properties }

        state.orders = state.orders.map((givenOrder) => {
            return givenOrder.id === order.id
                ? { ...givenOrder, ...updatedTableOptions }
                : givenOrder
        })
    },
    [mutationTypes.updateState](state, data) {
        state.selectedOrder['state'] = data.state
        state.orders = state.orders.map((order) =>
            order.id === data.id ? { ...order, state: data.state } : order
        )
    },
    [mutationTypes.updateProperties]({ selectedOrder }, { properties }) {
        selectedOrder.properties = {
            ...selectedOrder.properties,
            ...properties,
        }
    },
    [mutationTypes.updatePhotos]({ selectedOrder }, files) {
        selectedOrder.files = files
    },
    [mutationTypes.deletePhoto]({ selectedOrder }, id) {
        selectedOrder.files = selectedOrder.files.filter(
            (file) => file.id !== id
        )
    },
    [mutationTypes.updateOrder](state, updated) {
        state.selectedOrder = updated

        const { user_generated_id, barcode } = updated

        state.orders = state.orders.map((order) =>
            order.id === updated.id
                ? { ...order, user_generated_id, barcode }
                : order
        )
    },
    [mutationTypes.deleteOrder](state, id) {
        // eslint-disable-next-line no-unused-vars
        state.orders = state.orders.filter((order) => order.id !== id)
        // eslint-disable-next-line no-unused-vars
        state.selectedOrder = {}
    },
    // eslint-disable-next-line no-unused-vars
    [mutationTypes.addStatus](state, { status, id }) {
        const existingStatuses = Object.keys(
            state?.selectedOrder?.['status_array']
        )
        const newStatuses = status.state
        const newStatus = newStatuses.filter(
            (status) => !existingStatuses.includes(status)
        )[0]
        const newLine = {
            [newStatus]: {
                updated_at: status.created_at,
                user: status.user,
            },
        }
        state.selectedOrder['status_array'] = {
            ...state.selectedOrder['status_array'],
            ...newLine,
        }
        state.selectedOrder = {
            ...state.selectedOrder,
            currentStatus: newStatus,
        }
        if (!id) return null
        state.orders = state.orders.map((order) => {
            return order.id !== id
                ? order
                : { ...order, status: firstToLowerCase(newStatus) }
        })
    },
    [mutationTypes.updateStatus](state, { status, id }) {
        state.selectedOrder['status_array'] = status
        if (status.length === 0)
            return (state.selectedOrder['currentStatus'] =
                state.selectedOrder.state)

        const currentStatus =
            Object.keys(status)[Object.keys(status).length - 1]
        state.selectedOrder = {
            ...state.selectedOrder,
            currentStatus,
        }
        if (!id) return null
        state.orders = state.orders.map((order) => {
            return order.id !== id
                ? order
                : { ...order, status: firstToLowerCase(currentStatus) }
        })
    },
    [mutationTypes.assignCreator](state, { name }) {
        state.selectedOrder.creator = name
    },
    [mutationTypes.clearSelected](state) {
        state.selectedOrder = {}
    },
    [mutationTypes.updateCompleteDate](state, data) {
        const { id, completeData } = data

        state.selectedOrder['complete_data'] = completeData
            ? getInMilliseconds(completeData)
            : ''
        state.orders = state.orders.map((order) => {
            return order.id === id
                ? {
                      ...order,
                      ['complete_data']: completeData ? completeData : '',
                  }
                : order
        })
    },
    [mutationTypes.addNewMeeting](state, { id, data }) {
        let correctType = ''
        switch (data.calendar) {
            case 'PICK_UP_NEW':
                correctType = 'Pick-up New Wigs'
                break
            case 'PICK_UP_REPAIRS':
                correctType = 'Pick-up Repairs'
                break
            default:
                correctType = data.calendar
        }
        const newMeeting = {
            calendar: correctType,
            client_id: data.client_id,
            comment: '',
            client: data.client,
            created_at: getFullDate(),
            date_from: data.date,
            id: id,
            start_at: `${data.date} ${data.time}:00`,
            subject: data.subject,
            updated_at: '',
            visible: 1,
            wig_id: data['wig_id'],
        }

        state.selectedOrder = {
            ...state.selectedOrder,
            meetings: [...state.selectedOrder.meetings, newMeeting],
        }
    },
    [mutationTypes.clientUpdate](state, { wigId, newClient, clientId }) {
        state.selectedOrder.client = newClient
        state.selectedOrder.client_id = clientId
        state.orders = state.orders.map((order) =>
            order.id === wigId
                ? { ...order, client: newClient, client_id: clientId }
                : order
        )
    },
    [mutationTypes.putOldClients](state, clients) {
        state.oldClients = clients
    },
    [mutationTypes.deleteMeeting](state, { id }) {
        state.selectedOrder = {
            ...state.selectedOrder,
            meetings: state.selectedOrder.meetings.filter((meeting) => {
                return meeting.id !== id
            }),
        }
    },
    [mutationTypes.startSubmitting](state) {
        state.isSubmitting = true
    },
    [mutationTypes.stopSubmitting](state) {
        state.isSubmitting = false
    },
    [mutationTypes.updateClient](state, { name, surname, phone, id }) {
        const newName = `${name} ${surname}(${phone})`
        const newNameForCard = `(${phone}) ${name} ${surname}`

        state.selectedOrder = {
            ...state.selectedOrder,
            client: newNameForCard,
        }
        state.orders = state.orders.map((order) => {
            const { client_id } = order
            return client_id === id ? { ...order, client: newName } : order
        })
    },
    [mutationTypes.updatePreviousClient](state, { wigId, newId }) {
        const previousClient =
            state.orders.filter((order) => order.id === wigId)?.[0]
                ?.previous_client || ''

        state.orders = state.orders.map((order) => {
            return order.id === newId
                ? { ...order, previous_client: previousClient }
                : order
        })
    },
    [mutationTypes.addSetter](state, setter) {
        state.setters = [...state.setters, setter]
    },
    [mutationTypes.removeSetter](state, setterName) {
        state.setters = state.setters.filter(
            (setter) => setter.name !== setterName
        )
    },
}

const actions = {
    [actionTypes.loadAll]({ commit, state, rootState }) {
        const role = rootState?.auth?.user?.role?.name
        if (!role) return null

        return new Promise((res, rej) => {
            const { isLoading, orders } = state
            if (isLoading || orders.length !== 0) return res(orders)
            commit(mutationTypes.startFetching)

            switch (role) {
                case 'USA_MANAGER':
                    api.getAllByUsaManager()
                        .then(({ data: wigsCreatedByUsa }) => {
                            commit(mutationTypes.loadAll, wigsCreatedByUsa)
                            commit(mutationTypes.stopFetching)
                            res(wigsCreatedByUsa)
                        })
                        .catch((er) => {
                            rej(er)
                        })
                    break

                default:
                    api.getAllByRegular()
                        .then(({ data }) => {
                            commit(mutationTypes.loadAll, data)
                            commit(mutationTypes.stopFetching)
                            res(data)
                        })
                        .catch((er) => {
                            rej(er)
                        })
            }
        })
    },
    [actionTypes.getDelivered]({ commit }) {
        return new Promise((res, rej) => {
            api.getAllByDelivered()
                .then(({ data }) => {
                    commit(mutationTypes.loadAll, data)
                    res(data)
                })
                .catch((er) => {
                    rej(er)
                })
        })
    },
    [actionTypes.getDeliveredByName]({ commit }, name) {
        return new Promise((res, rej) => {
            api.getDeliveredByName(name)
                .then(({ data }) => {
                    commit(mutationTypes.loadAll, data)
                    res(data)
                })
                .catch((er) => {
                    rej(er)
                })
        })
    },
    [actionTypes.getWigsBySearchParameters]({ commit }, searchParameters) {
        return new Promise((res, rej) => {
            api.getWigsBySearchParameter(searchParameters)
                .then(({ data }) => {
                    commit(mutationTypes.loadAll, data)
                    res(data)
                })
                .catch((er) => {
                    rej(er)
                })
        })
    },
    [actionTypes.getWigsByState]({ commit, rootState }, state) {
        const role = rootState?.auth?.user?.role?.name
        if (!role) return null

        if (role === 'USA_MANAGER') {
            return new Promise((res, rej) => {
                api.getWigsByStateByUsaManager(state)
                    .then(({ data }) => {
                        commit(mutationTypes.loadAll, data)
                        res(data)
                    })
                    .catch((er) => {
                        rej(er)
                    })
            })
        }

        // if any others role
        return new Promise((res, rej) => {
            api.getWigsByState(state)
                .then(({ data }) => {
                    commit(mutationTypes.loadAll, data)
                    res(data)
                })
                .catch((er) => {
                    rej(er)
                })
        })
    },
    [actionTypes.reloadSelectedOrder]({ state, commit, dispatch }) {
        const id = state.selectedOrder.id

        return new Promise((res, rej) => {
            commit(mutationTypes.startFetching)
            commit(mutationTypes.clearSelected)
            api.getOne(id)
                .then(({ data }) => {
                    commit(mutationTypes.selectOrder, data)

                    dispatch(
                        `payments/${actionTypesFromPayments.loadAllForSelected}`,
                        id,
                        {
                            root: true,
                        }
                    )
                    dispatch(
                        `orderRepairsStages/${actionTypesOrderRepairsStages.putLoadedProperties}`,
                        data?.additional_properties,
                        {
                            root: true,
                        }
                    )
                    dispatch(actionTypes.loadOldClients, id)
                    commit(
                        `ordersReworkProperties/${mutationTypesOrdersReworkProperties.initProperties}`,
                        data?.rework_additional_properties || [],
                        {
                            root: true,
                        }
                    )
                    commit(mutationTypes.updateStatus, {
                        status: data['status_array'],
                    })
                    commit(mutationTypes.stopFetching)
                    return res(data)
                })
                .catch((er) => {
                    rej(er)
                })
        })
    },
    [actionTypes.loadOrder]({ commit, dispatch }, id) {
        let withoutOpening = undefined
        if (typeof id === 'object') {
            withoutOpening = id.withoutOpening
            id = id.id
        }

        commit(mutationTypes.startFetching)
        return new Promise((res, rej) => {
            commit(mutationTypes.clearSelected)
            api.getOne(id)
                .then(({ data }) => {
                    commit(mutationTypes.selectOrder, data)
                    if (!withoutOpening) {
                        dispatch(
                            `modals/${actionTypesFromModals.openPopUp}`,
                            orderContext,
                            {
                                root: true,
                            }
                        )
                    }
                    dispatch(
                        `payments/${actionTypesFromPayments.loadAllForSelected}`,
                        id,
                        {
                            root: true,
                        }
                    )
                    dispatch(
                        `orderRepairsStages/${actionTypesOrderRepairsStages.putLoadedProperties}`,
                        data?.additional_properties,
                        {
                            root: true,
                        }
                    )
                    dispatch(actionTypes.loadOldClients, id)
                    commit(
                        `ordersReworkProperties/${mutationTypesOrdersReworkProperties.initProperties}`,
                        data?.rework_additional_properties || [],
                        {
                            root: true,
                        }
                    )
                    commit(mutationTypes.updateStatus, {
                        status: data['status_array'],
                    })
                    res(data)
                    commit(mutationTypes.stopFetching)
                })
                .catch((er) => {
                    rej(er)
                })
        })
    },
    [actionTypes.loadProperties]() {
        return new Promise((res, rej) => {
            api.getProperties()
                .then(({ data }) => {
                    res(data)
                })
                .catch((er) => {
                    rej(er)
                })
        })
    },
    [actionTypes.createOrder]({ commit }, { form }) {
        return new Promise((res, rej) => {
            commit(mutationTypes.startSubmitting)
            api.createOrder(form)
                .then(({ data }) => {
                    setTimeout(() => {
                        res(data)
                        commit(mutationTypes.addNewOrder, {
                            ...data,
                        })
                        commit(mutationTypes.stopSubmitting)
                    }, 500)
                })
                .catch((er) => {
                    rej(er.response.data.errors)
                    commit(mutationTypes.stopSubmitting)
                })
        })
    },
    [actionTypes.updateProperties]({ commit }, data) {
        return new Promise((res, rej) => {
            api.updateProperties(data)
                .then(() => {
                    commit(mutationTypes.updateProperties, data)
                    res()
                })
                .catch((er) => {
                    rej(er.response.data.errors)
                })
        })
    },
    [actionTypes.updateState]({ commit, dispatch }, data) {
        return new Promise((res, rej) => {
            api.updateState(data)
                .then((response) => {
                    if (res?.status === 207) {
                        dispatch(
                            `modalNotifications/${actionTypesModalsNotification.warning}`,
                            i18n.t(
                                `shared.warningMessages.${response.data.warning_message}`
                            ),
                            { root: true }
                        )
                    }
                    commit(mutationTypes.updateState, data)
                    dispatch(actionTypes.loadOrder, {
                        id: data.id,
                        withoutOpening: true,
                    })
                    res(data)
                })
                .catch((er) => {
                    rej(er?.response?.data?.errors || '')
                })
        })
    },
    [actionTypes.updateCompleteDate]({ commit, dispatch }, data) {
        return new Promise((res, rej) => {
            api.updateCompleteDate({
                ...data,
                completeData: data.completeData.replace(/9:/, '09:'),
            })
                .then(() => {
                    commit(mutationTypes.updateCompleteDate, data)
                    dispatch(actionTypes.reloadSelectedOrder)
                    res()
                })
                .catch((er) => {
                    commit(mutationTypes.updateCompleteDate, data)
                    rej(er.response.data.errors)
                })
        })
    },
    [actionTypes.updateOrder]({ commit, dispatch }, data) {
        return new Promise((res, rej) => {
            api.updateOrder(data)
                .then(({ data }) => {
                    commit(mutationTypes.updatePhotos, data.files)
                    commit(mutationTypes.updateOrder, data)
                    dispatch(actionTypes.loadOrder, {
                        id: data.id,
                        withoutOpening: true,
                    })
                    res(data)
                })
                .catch((er) => {
                    rej(er.response.data.errors)
                })
        })
    },
    [actionTypes.addNewPhoto]({ commit, state }, file) {
        const currentOrder = state.selectedOrder

        const oldData = {
            totalPrice: currentOrder.totalPrice,
            barcode: currentOrder.barcode,
            details: currentOrder.details,
            info: currentOrder.info,
            shippingInfo: currentOrder.shippingInfo,
        }

        let res = {}

        Object.keys(oldData).forEach((key) => {
            if (oldData[key]) res = { ...res, [key]: oldData[key] }
        })

        const form = new FormData()
        const json = JSON.stringify(res)
        form.append('files[]', file)
        form.append('data', json)

        const data = { id: currentOrder.id, form }

        return new Promise((res, rej) => {
            api.updateOrder(data)
                .then(({ data }) => {
                    commit(mutationTypes.updatePhotos, data.files)
                    res(data)
                })
                .catch((er) => {
                    rej(er)
                })
        })
    },
    [actionTypes.deletePhoto]({ commit }, id) {
        return new Promise((res, rej) => {
            api.deletePhoto(id)
                .then(() => {
                    res()
                    commit(mutationTypes.deletePhoto, id)
                })
                .catch((er) => {
                    rej(er)
                })
        })
    },
    [actionTypes.deleteOrder]({ commit }, id) {
        return new Promise((res, rej) => {
            api.deleteOrder(id)
                .then(() => {
                    res()
                    commit(mutationTypes.deleteOrder, id)
                })
                .catch((er) => {
                    rej(er)
                })
        })
    },
    [actionTypes.updateStatus]({ commit }, { status, id }) {
        commit(mutationTypes.addStatus, { status, id })
    },
    [actionTypes.assignCreator]({ commit, dispatch }, credentials) {
        return new Promise((res, rej) => {
            api.assignCreator(credentials)
                .then(() => {
                    res()
                    commit(mutationTypes.assignCreator, credentials)
                    dispatch(actionTypes.loadOrder, {
                        id: credentials.orderId,
                        withoutOpening: true,
                    })
                })
                .catch((er) => {
                    rej(er)
                })
        })
    },
    [actionTypes.addNewMeeting]({ commit }, { id, data }) {
        commit(mutationTypes.addNewMeeting, { id, data })
    },
    [actionTypes.clientUpdate]({ commit, dispatch }, payload) {
        const { wigId, clientId } = payload

        return new Promise((res, rej) => {
            api.clientUpdate({ wigId, clientId })
                .then(() => {
                    res()
                    commit(mutationTypes.clientUpdate, payload)
                    dispatch(actionTypes.loadOrder, {
                        id: wigId,
                        withoutOpening: true,
                    })
                })
                .catch((er) => {
                    rej(er)
                })
        })
    },
    [actionTypes.loadOldClients]({ commit }, id) {
        return new Promise((res, rej) => {
            api.getOldClients(id)
                .then(({ data }) => {
                    res(data)
                    commit(mutationTypes.putOldClients, data)
                })
                .catch((er) => {
                    console.log(er)
                    rej(er)
                })
        })
    },
    [actionTypes.meetingDelete]({ commit }, id) {
        commit(mutationTypes.deleteMeeting, id)
    },
    [actionTypes.updateClient]({ commit }, client) {
        commit(mutationTypes.updateClient, client)
    },
    // eslint-disable-next-line no-unused-vars
    async [actionTypes.moveOrderToArchive]({ commit }, { wigId, newId }) {
        const res = await api.moveOrderToArchive({ wigId, newId })
        commit(mutationTypes.updateState, { id: wigId, state: 'Archived' })
        commit(mutationTypes.updateCompleteDate, {
            id: wigId,
            completeData: '',
        })
        commit(mutationTypes.updatePreviousClient, { wigId, newId })
        return res
    },
    async [actionTypes.createWigFromStock](
        { commit },
        { stockWigID, clientID }
    ) {
        const newCreatedWig = (
            await api.createdOrderFromStock({ stockWigID, clientID })
        ).data
        commit(mutationTypes.addNewOrder, {
            ...newCreatedWig,
        })
        return newCreatedWig
    },
    async [actionTypes.startFetching]({ commit }) {
        commit(mutationTypes.startFetching)
    },
    async [actionTypes.stopFetching]({ commit }) {
        commit(mutationTypes.stopFetching)
    },
    [actionTypes.filterByBarcodeOrClient]({ commit }, searchParam) {
        return new Promise((res, rej) => {
            api.filterByBarcodeOrClient(searchParam)
                .then(({ data }) => {
                    res(data)
                })
                .catch((er) => {
                    rej(er)
                })
        })
    },
    [actionTypes.associateWig]({ dispatch }, payload) {
        return new Promise((res, rej) => {
            api.associateWig(payload)
                .then(() => {
                    dispatch(actionTypes.updateState, {
                        id: payload.wig_id,
                        state: 'Archived',
                    })
                    setTimeout(() => {
                        dispatch(actionTypes.reloadSelectedOrder)
                        dispatch(actionTypes.loadOrder, {
                            id: payload.wig,
                            withoutOpening: true,
                        })
                        res()
                    }, 350)
                })
                .catch((er) => {
                    rej(er)
                })
        })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
