<template>
    <portal to="slide-out-container">
        <div class="overlay" :style="`--vr-height:${height}`">
            <div class="slide-out" :class="{ ['is-mounted']: isMounted }">
                <div class="slide-out__header"></div>
                <div class="slide-out__body">
                    <slot :onClose="onClose"></slot>
                </div>
            </div>
        </div>
    </portal>
</template>

<script>
export default {
    name: 'SlideOut',
    emits: ['on-close'],
    data() {
        return {
            isMounted: false,
            height: 0,
        }
    },
    mounted() {
        setTimeout(() => {
            this.isMounted = true
            this.calcHeight()
        }, 0)
        addEventListener('resize', this.calcHeight)
    },
    unmounted() {
        removeEventListener('resize', this.calcHeight)
    },
    beforeUnmount() {
        this.isMounted = false
    },
    methods: {
        onClose: function () {
            this.$emit('on-close')
        },
        calcHeight: function () {
            this.height = window.innerHeight
                ? `${window.innerHeight}px`
                : '100vh'
        },
    },
}
</script>

<style lang="scss" scoped>
.overlay {
    position: fixed;
    z-index: 201;
    background: rgba(0, 0, 0, 0.28);
    width: 100%;
    height: var(--vr-height);
    top: 0;
    left: 0;
}

.slide-out {
    position: absolute;
    top: 0;
    right: 0;
    width: 550px;
    max-width: 90vw;
    background: #fff;
    height: 100%;
    transform: translateX(110%);
    transition: transform 500ms ease-in-out;
    //  android fast fix
    padding-bottom: 32px;

    @include tablet-large-up {
        padding-bottom: 0;
    }

    &.is-mounted {
        transform: translateX(0);
    }

    &__header {
    }

    &__body {
        height: 100%;
        max-height: 100%;
        overflow: auto;
        padding: 24px;
    }
}
</style>
