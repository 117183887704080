<template>
    <v-dialog v-model="isActive" width="375" @click:outside="closeHandle">
        <div class="barcode">
            <button class="barcode__close" @click="closeHandle">
                <v-icon dark>mdi-window-close</v-icon>
            </button>
            <div class="barcode__wrapper">
                <!--                <VueBarcodeTest @detected="detectHandle" />-->
                <QuaggaBarcodeScanner
                    :is-active="isActive"
                    @on-detected="detectHandle"
                />
            </div>
        </div>
    </v-dialog>
</template>

<script>
import QuaggaBarcodeScanner from '@/components/common/quagga-barcode-scanner'

export default {
    name: 'OrderModalBarcodeScanner',
    components: {
        QuaggaBarcodeScanner,
    },
    props: {
        isActiveData: {
            type: Boolean,
            default: true,
        },
    },
    emits: ['close-handle', 'barcode-detect-handle'],
    data() {
        return {
            barcode: 'empty',
        }
    },
    computed: {
        isActive: {
            get: function () {
                return this.isActiveData
            },
            set: function () {
                this.closeHandle()
            },
        },
    },
    methods: {
        closeHandle: function () {
            this.$emit('close-handle')
        },
        detectHandle: function ({ barcode }) {
            this.$emit('barcode-detect-handle', barcode)
            this.closeHandle()
        },
    },
}
</script>

<style scoped lang="scss">
.barcode {
    position: relative;
    min-height: 350px;
    margin: 0 auto;
    background: $color-primary-light;
    max-width: 90vw;
    overflow: hidden;
    width: 100%;

    &__close {
        z-index: 2;
        top: 5px;
        right: 5px;
        position: absolute;
        background: rgba(0, 0, 0, 0.25);
        border-radius: 50%;
        padding: 5px;
    }

    &__wrapper {
        position: relative;
        z-index: 1;
        width: 100%;
    }
}
</style>
