const state = () => ({
    isActive: false,
})

export const getterTypes = {
    getStatus: '[main-menu] Get status',
}

export const mutationTypes = {
    open: '[main-menu] Open menu',
    close: '[main-menu] Close menu',
    toggle: '[main-menu] Toggle menu',
}

const getters = {
    [getterTypes.getStatus]: (state) => state.isActive,
}

const mutations = {
    [mutationTypes.open](state) {
        state.isActive = true
    },
    [mutationTypes.close](state) {
        state.isActive = false
    },
    [mutationTypes.toggle](state) {
        state.isActive = !state.isActive
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
}
