<template>
    <main class="main">
        <Header />
        <router-view> </router-view>
        <FooterForProtectedEmpty />
    </main>
</template>

<script>
import Header from '@/components/login/header'
import FooterForProtectedEmpty from '@/components/common/footer-for-protected-empty.vue'
import { mapActions } from 'vuex'
import { actionTypes as actionTypesAuth } from '@/store/modules/auth'

export default {
    name: 'EmptyProtectedLayout',
    components: {
        Header,
        FooterForProtectedEmpty,
    },
    mounted() {
        this.dataLoading()
    },
    methods: {
        ...mapActions('auth', {
            getCurrentUser: actionTypesAuth.getCurrentUser,
        }),
        dataLoading: async function () {
            await this.getCurrentUser()
        },
    },
}
</script>
