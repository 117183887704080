var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"orders-gallery"},[_c('OrderModalWebcam',{attrs:{"is-active-data":_vm.modals.webcam.isActive},on:{"close-handle":_vm.webcamModalClose,"on-capture":_vm.onCaptureHandle}}),(
            _vm.getAllowed(['ADMIN', 'manager', 'usa_manager']) &&
            _vm.state !== 'Archived'
        )?_c('div',{staticClass:"orders-gallery__header"},[_c('div',{staticClass:"orders-gallery__actions"},[_c('input',{ref:"input",staticClass:"orders-gallery__file-input",attrs:{"type":"file","multiple":""},on:{"input":_vm.changeFilesHandle}}),_c('v-btn',{attrs:{"color":"#967adc","dark":"","x-small":!_vm.isMobile,"small":_vm.isMobile},on:{"click":_vm.openUpload}},[_vm._v(" "+_vm._s(_vm.textContent['upload-photo'])+" ")]),_c('v-btn',{attrs:{"color":"#967adc","dark":"","x-small":!_vm.isMobile,"small":_vm.isMobile},on:{"click":_vm.webcamModalOpen}},[_vm._v(" "+_vm._s(_vm.textContent['take-a-photo'])+" ")])],1)]):_vm._e(),_c('ul',{staticClass:"orders-gallery__thumbs-list"},_vm._l((_vm.files),function({ url, id, created_at, created_by }){return _c('li',{key:id,staticClass:"orders-gallery__item"},[_c('a',{attrs:{"target":"_blank","href":url}},[_c('div',{staticClass:"orders-gallery__img-bx"},[_c('img',{staticClass:"orders-gallery__img",attrs:{"src":url,"alt":id}})]),_c('ul',{staticClass:"orders-gallery__img-info"},[_c('li',[_c('p',[_c('DateCell',{attrs:{"value":created_at}})],1)]),_c('li',[_c('p',[_vm._v(_vm._s(created_by || 'N/A'))])])])]),(
                    _vm.getAllowed([
                        'ADMIN',
                        _vm.roleUsaManagerAfterStartProduction,
                        'manager',
                    ]) && _vm.state !== 'Archived'
                )?_c('v-btn',{staticClass:"orders-gallery__btn-remove",attrs:{"color":"#da4453","dark":"","x-small":""},on:{"click":() => _vm.removeHandle(id)}},[_c('v-icon',{attrs:{"x-small":"","dark":""}},[_vm._v("mdi-close")])],1):_vm._e()],1)}),0)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }