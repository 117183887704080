import router from '@/router'

const wigTransitions = {
    moveToWigPage: (wigID) => {
        router.push({ path: `/orders/${wigID}` })
    },
    moveToWigPageInNewTab: (wigID) => {
        let route = router.resolve({ path: `/orders/${wigID}` })
        window.open(route.href, '_blank')
    },
    moveToWigPrintPageInNewTab: (wigID) => {
        let route = router.resolve({ path: `/orders/print/${wigID}` })
        window.open(route.href, '_blank')
    },
}

export default wigTransitions
