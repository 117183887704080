import { meetingListGetterTypes } from './types'

export default {
    [meetingListGetterTypes.GET_ALL_MEETINGS](state) {
        return state.meetings
    },
    [meetingListGetterTypes.GET_IS_FETCHING](state) {
        return state.isFetching
    },
    [meetingListGetterTypes.GET_PAGE_COUNT](state) {
        return state.pageCount
    },
    [meetingListGetterTypes.GET_SELECTED_ITEM](state) {
        return state.selectedItem
    },
    [meetingListGetterTypes.GET_TOTAL](state) {
        return state.total
    },
}
