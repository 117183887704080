<template>
    <div class="notification" :class="[{ ['is-read']: ntf.read }, typeClass]">
        <div v-if="!ntf.read" class="notification__read">
            <v-btn x-small icon color="#212121" @click="readHandle">
                <v-icon small>mdi-close-circle-outline</v-icon>
            </v-btn>
        </div>
        <div class="notification__date">{{ time }}</div>
        <div class="notification__icon">
            <v-icon>mdi-{{ icon }}</v-icon>
        </div>
        <div class="notification__content">
            <h4 class="notification__title">{{ mainTitle }}</h4>
            <p class="notification__text">{{ mainText }}</p>

            <template v-if="currentExtraTemplate">
                <v-expansion-panels v-model="panel" flat inset>
                    <v-expansion-panel class="notification__panel">
                        <v-expansion-panel-content color="transparent">
                            <component
                                :is="currentExtraTemplate"
                                :ntf="ntf"
                            ></component>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </template>

            <v-btn
                v-if="!ntf.read"
                x-small
                :color="color"
                dark
                @click="seeMoreHandle"
            >
                {{ textContent['see-more'] }}
            </v-btn>
        </div>
    </div>
</template>

<script>
import timeAgo from '@/helpers/time-ago'

import NotificationTemplateNewOrder from '@/components/notifications/notification-template-new-order'
import NotificationTemplateNewOrderStart from '@/components/notifications/notification-template-new-order-start'
import NotificationTemplateSevenDaysBefore from '@/components/notifications/notification-template-seven-days-before'
import { mapGetters } from 'vuex'
import { getterTypes } from '@/store/modules/users'
import { find } from 'lodash'
import languageMixin from '@/mixins/language.mixin'

export default {
    name: 'NotificationItem',
    components: {
        NotificationTemplateNewOrder,
        NotificationTemplateSevenDaysBefore,
        NotificationTemplateNewOrderStart,
    },
    mixins: [languageMixin],
    props: {
        ntf: {
            type: Object,
            default: () => {},
        },
    },
    emits: ['read-handle'],
    data() {
        return {
            panel: 1,
            typeClass: '',
            icon: '',
            currentExtraTemplate: '',
            color: '',
            mainText: '',
            mainTitle: '',
        }
    },
    computed: {
        ...mapGetters('users', { users: getterTypes.getAllUsers }),
        textContent: function () {
            return this.getTextForSelected(this.lang, 'Notification') || ''
        },
        time: function () {
            return timeAgo(new Date(this.ntf.date))
        },
        userName: function () {
            const { user_id } = this.ntf
            if (Object.keys(this.users).length === 0) return 'some user'
            const user = find(this.users, { id: user_id })
            return user ? user.nickName : 'some user'
        },
    },
    watch: {
        users: function () {
            this.typeHandle()
        },
        isRtl: function () {
            this.typeHandle()
        },
    },
    mounted() {
        this.typeHandle()
    },
    methods: {
        typeHandle: function () {
            const { type } = this.ntf

            switch (type) {
                case 'App\\Notifications\\WigProcessedInComplete':
                    this.typeClass = 'new-order'
                    this.icon = 'folder-plus-outline'
                    this.currentExtraTemplate = 'NotificationTemplateNewOrder'
                    this.mainText = `${this.userName} ${this.textContent['notification-wig-ready-desc']}`
                    this.mainTitle =
                        this.textContent['notification-wig-ready-title']
                    this.color = '#37bc9b'
                    break
                case 'App\\Notifications\\WigProcessedInProduction':
                    this.typeClass = 'new-order-start'
                    this.icon = 'folder-plus-outline'
                    this.currentExtraTemplate =
                        'NotificationTemplateNewOrderStart'
                    this.mainText = `${this.userName} ${this.textContent['notification-wig-start-desc']}`
                    this.mainTitle =
                        this.textContent['notification-wig-start-title']
                    this.color = '#3bafda'
                    break
                case 'App\\Notifications\\SevenDaysBeforeMeeting':
                    this.typeClass = 'seven-days-before'
                    this.icon = 'alert-decagram-outline'
                    this.currentExtraTemplate =
                        'NotificationTemplateSevenDaysBefore'
                    this.mainText =
                        this.textContent['notification-seven-days-desc']
                    this.mainTitle =
                        this.textContent['notification-seven-days-title']
                    this.color = '#bc7e37'
                    break
                default:
                    this.typeClass = ''
                    this.icon = ''
            }
            if (this.ntf.read) this.icon = 'checkbox-multiple-marked-outline'
        },
        seeMoreHandle: function () {
            if (this.panel === 1) return (this.panel = 0)
            this.panel = 1
        },
        readHandle: function () {
            this.$emit('read-handle', this.ntf.id)
        },
    },
}
</script>
<style lang="scss" scoped>
.notification {
    width: 100%;
    height: 100%;
    padding: 10px 10px;
    cursor: pointer;
    background: $color-primary-light;
    transition: background-color 150ms linear;
    border-left: 4px solid transparent;
    border-radius: 3px;
    margin-bottom: 10px;
    box-shadow: 0 0 7px rgba(0, 0, 0, 0.25);
    display: flex;
    position: relative;

    @include for-hebrew {
        border-left: none;
        border-right: 4px solid transparent;
    }

    &.new-order {
        border-color: #37bc9b;
    }

    &.new-order-start {
        border-color: #3bafda;
    }

    &.seven-days-before {
        border-color: #bc7e37;
    }

    &.is-read {
        border-color: rgba(0, 0, 0, 0.5);
    }

    &:last-child {
    }

    &:hover {
        background: #e3e3e3;
    }

    &__read {
        position: absolute;
        top: 5px;
        right: 5px;
        opacity: 0.5;
        transition: opacity 250ms ease-in-out;

        @include for-hebrew {
            right: auto;
            left: 5px;
        }

        &:hover {
            opacity: unset;
        }
    }

    &__date {
        position: absolute;
        bottom: 5px;
        right: 5px;
        font-size: 10px;
        opacity: 0.8;

        @include for-hebrew {
            right: auto;
            left: 5px;
        }
    }

    &__icon {
        margin-right: 15px;
        height: fit-content;
        line-height: 0;
    }

    &__content {
        flex-grow: 2;
        height: 100%;
        font-size: 12px;
        line-height: 13px;
        padding: 2px 25px 0 0;
        min-height: 40px;

        .is-read & {
            text-decoration: line-through;
            color: rgba(0, 0, 0, 0.5);
        }
    }

    &__text {
        font-size: 0.9em;
        color: rgba(0, 0, 0, 0.67);
        margin-bottom: 10px;

        a {
            color: rgba(0, 0, 0, 0.67);
            transition: color 100ms linear;

            &:hover {
                color: rgba(0, 0, 0, 1);
            }
        }
    }

    &__title {
        .new-order & {
            color: #37bc9b;
        }

        .new-order-start & {
            color: #3bafda;
        }

        .seven-days-before & {
            color: #bc7e37;
        }

        .is-read & {
            color: rgba(0, 0, 0, 0.5);
        }

        margin-bottom: 3px;
    }

    &__link {
    }

    &__panel {
        background: transparent !important;
    }
}
</style>
