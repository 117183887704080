const convertBase64ToFile = (file) => {
    let block = file.split(';')
    let contentType = block[0].split(':')[1]
    let b64Data = block[1].split(',')[1]

    contentType = contentType || ''
    let sliceSize = 512

    let byteCharacters = atob(b64Data)
    let byteArrays = []

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        let slice = byteCharacters.slice(offset, offset + sliceSize)

        let byteNumbers = new Array(slice.length)
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i)
        }

        let byteArray = new Uint8Array(byteNumbers)

        byteArrays.push(byteArray)
    }

    return new File(byteArrays, 'foo.jpg', { type: contentType })
}

export default convertBase64ToFile
