<template>
    <v-dialog v-model="isActive" width="400" @click:outside="closeHandle">
        <div class="deposit">
            <div class="deposit__header header">
                <h3 class="header__title">{{ textContent['deposit'] }}</h3>
                <v-btn
                    icon
                    color="#55595c"
                    data-test-id="wig-page--payments--add-payment-pop-up--close-btn"
                    @click="closeHandle"
                >
                    <v-icon>mdi-close-circle-outline</v-icon>
                </v-btn>
            </div>
            <div class="deposit__body body">
                <div class="body__fields-wrapper">
                    <v-text-field
                        ref="input"
                        v-model="fields.amount"
                        dense
                        outlined
                        type="number"
                        name="amount"
                        :label="textContent['amount']"
                        :error-messages="errors['amount']"
                        data-test-id="wig-page--payments--add-payment-pop-up--number-amount-input"
                        @input="errors['amount'] = ''"
                    >
                        <template #append>
                            <span class="icon-container">
                                <v-icon>fas fa-shekel-sign</v-icon>
                            </span>
                        </template>
                    </v-text-field>
                </div>

                <div class="body__fields-wrapper">
                    <v-autocomplete
                        v-model="fields['type']"
                        outlined
                        :items="types"
                        :label="textContent['type']"
                        dense
                        :error-messages="errors['source']"
                        data-test-id="wig-page--payments--add-payment-pop-up--type-autocomplete"
                        @input="errors['source'] = ''"
                    >
                    </v-autocomplete>
                </div>

                <div class="body__fields-wrapper">
                    <v-text-field
                        v-model="fields.comment"
                        dense
                        outlined
                        name="comment"
                        :label="textContent['comment']"
                        maxlength="20"
                        :error-messages="errors['comment']"
                        data-test-id="wig-page--payments--add-payment-pop-up--comment-text-input"
                        @input="errors['comment'] = ''"
                    >
                    </v-text-field>
                </div>
            </div>
            <div class="deposit__footer footer">
                <div class="footer__actions">
                    <v-btn
                        dark
                        color="#55595c"
                        data-test-id="wig-page--payments--add-payment-pop-up--cancel-btn"
                        @click="closeHandle"
                    >
                        {{ textContent['close'] }}
                    </v-btn>
                    <v-btn
                        dark
                        color="#967adc"
                        data-test-id="wig-page--payments--add-payment-pop-up--submit-btn"
                        @click="onUpdate"
                        >{{ textContent['add'] }}
                    </v-btn>
                </div>
            </div>
        </div>
    </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { actionTypes } from '@/store/modules/payments'
import { actionTypes as actionTypesModalNotifications } from '@/store/modules/modal-notifications'

import modalsMixin from '@/mixins/modals.mixin'

import popUpTypes from '@/types/pop-up-types'
import { getterTypes } from '@/store/modules/orders'
import languageMixin from '@/mixins/language.mixin'

const { orderDeposit } = popUpTypes

export default {
    name: 'OrderModalDepositUpdate',
    mixins: [modalsMixin, languageMixin],
    data() {
        return {
            selfName: orderDeposit,
            fields: {
                type: '',
                amount: null,
                comment: '',
            },
            errors: {
                source: '',
                amount: '',
                comment: '',
            },
        }
    },
    computed: {
        ...mapGetters('orders', { order: getterTypes.getOrder }),
        selectedId: function () {
            return this.order.id
        },
        types: function () {
            return [
                { text: this.textContent['card'], value: 'Card' },
                { text: this.textContent['cash'], value: 'Cash' },
                {
                    text: this.textContent['payout-check'],
                    value: 'Payout check',
                },
                {
                    text: this.textContent['wire'],
                    value: 'Wire Transfer',
                },
            ]
        },
    },
    watch: {
        isActive: function (newValue) {
            if (!newValue) return null
            this.focus()
        },
    },
    mounted() {
        this.focus()
    },
    methods: {
        ...mapActions('payments', { addNewPayment: actionTypes.addNewPayment }),
        ...mapActions('modalNotifications', {
            success: actionTypesModalNotifications.success,
        }),
        onUpdate: function () {
            if (this.fields.amount === 0)
                return (this.errors.amount = 'Deposits cannot be 0')
            const res = {
                wig_id: this.selectedId,
                amount: this.fields.amount,
                source: this.fields.type,
                comment: this.fields.comment || null,
            }
            this.addNewPayment(res)
                .then(() => {
                    this.success('Deposit has been added')
                    this.fields = {
                        type: '',
                        amount: null,
                        comment: '',
                    }
                    this.closeHandle()
                })
                .catch((errors) => {
                    this.errors = errors
                })
        },
        focus: async function () {
            this.$nextTick(() => {
                setTimeout(() => {
                    if (!this.$refs.input) return null
                    this.$refs.input.focus()
                })
            })
        },
    },
}
</script>

<style scoped lang="scss">
.deposit {
    background: $color-primary-light;

    &__header {
        padding: 10px;
        border-bottom: 1px solid #eceeef;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__body {
        padding: 10px;
    }

    &__footer {
        padding: 0 10px 10px;
    }
}

.header {
    &__title {
    }
}

.body {
    &__fields-wrapper {
    }

    &__title {
    }
}

.footer {
    &__actions {
        display: flex;
        justify-content: space-between;
    }
}
</style>
