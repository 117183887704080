import store from '@/store'
import { actionTypes as actionTypesOrders } from '@/store/modules/orders'
import { actionTypes as actionTypesConfirmer } from '@/store/modules/confirmer'
import { i18n } from '@/plugins/i18n'
import { ordersV2ActionTypes } from '@/store/modules/orders_v2/types'

const confirmHandle = async (message) =>
    await store.dispatch(
        `confirmer/${actionTypesConfirmer.confirmHandle}`,
        message
    )
export const updateState = async (newState) => {
    try {
        await confirmHandle(i18n.t('pages.wigPage.mainActions.message'))
        await store.dispatch(
            `orders/${actionTypesOrders.updateState}`,
            newState
        )
        await store.dispatch(`ordersV2/${ordersV2ActionTypes.FETCH_ORDERS}`)
    } catch (e) {
        /* empty */
    }
}

export const wigDetailsPageWigWorkflow = (wig) => {
    const state = wig.state.toLowerCase()
    let payload = { id: wig.id }

    switch (state) {
        case 'draft':
            payload.state = payload.state =
                wig['is_repair'] === 1 ? 'Repair Wigs' : 'Wigs in Production'
            return {
                label: 'Production start',
                handle: updateState.bind(null, payload),
            }
        case 'wigs in production':
            payload.state = 'Ready New Wig'
            return {
                label: 'Production complete',
                handle: updateState.bind(null, payload),
            }
        case 'rework':
            payload.state =
                wig['is_repair'] === 1 ? 'Ready Repair Wigs' : 'Ready New Wig'
            return {
                label:
                    wig['is_repair'] === 1
                        ? 'Repair complete'
                        : 'Production complete',
                handle: updateState.bind(null, payload),
            }
        case 'repair wigs':
            payload.state = 'Ready Repair Wigs'
            return {
                label: 'Repair complete',
                handle: updateState.bind(null, payload),
            }
        case 'ready new wig':
        case 'ready repair wigs':
            payload.state = 'Storage'
            return {
                label: 'Storage',
                handle: updateState.bind(null, payload),
            }
        case 'storage':
            payload.state = 'Delivered Wigs'
            return {
                label: 'Delivery',
                handle: updateState.bind(null, payload),
            }
    }
}
