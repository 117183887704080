<template>
    <div class="clients-search">
        <div class="clients-search__input-wrapper">
            <v-text-field
                v-model="search"
                :placeholder="textContent['place-holder']"
                append-icon="mdi-magnify"
                dense
                outlined
            >
            </v-text-field>
        </div>
        <div class="clients-search__filter">
            <ClientFilter @change-handle="filterHandle" />
        </div>
        <div class="clients-search__total">
            {{ textContent['total-clients'] }}: <span>{{ total }}</span>
        </div>
    </div>
</template>

<script>
import languageMixin from '@/mixins/language.mixin'
import ClientFilter from '@/components/clients/client-filter'

export default {
    name: 'ClientsSearch',
    components: { ClientFilter },
    mixins: [languageMixin],
    props: {
        searched: {
            type: String,
            default: '',
        },
        total: {
            type: Number,
            default: 0,
        },
    },
    emits: ['search-handle', 'filter-handle'],
    computed: {
        search: {
            get: function () {
                return this.searched
            },
            set: function (newValue) {
                this.$emit('search-handle', newValue)
            },
        },
    },
    methods: {
        filterHandle: function (value) {
            this.$emit('filter-handle', value)
        },
    },
}
</script>

<style scoped lang="scss">
.clients-search {
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    &__total {
        width: 100%;
        text-align: center;

        span {
            font-weight: 700;
        }
    }

    &__filter {
        margin-bottom: 10px;
    }
}
</style>
