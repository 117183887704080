<template>
    <a
        :href="linkCellProps.href"
        :target="linkCellProps.target"
        class="link-cell"
    >
        {{ linkCellProps.label }}
    </a>
</template>

<script>
export default {
    name: 'LinkCell',
    props: {
        linkCellProps: {
            type: Object,
            default: () => ({ label: '', href: '#' }),
            target: '_self',
        },
    },
}
</script>

<style lang="scss">
.link-cell {
    color: inherit;
}
</style>
