const state = () => ({
    en: {},
    he: {},
})

import getters from '@/store/modules/jewish-calendar/getters'
import mutations from '@/store/modules/jewish-calendar/mutations'
import actions from '@/store/modules/jewish-calendar/actions'

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
