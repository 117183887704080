import axios from 'axios'
import camelToSnakeCase from '@/helpers/camelToSnakeCase'

const state = () => ({
    languagePack: {},
})

export const getterTypes = {
    getContent: '[language-pack] Get content for selected component',
    pageIsReady: '[auth] Get status of loading page',
}

export const mutationTypes = {
    addLanguagePack: '[language-pack] Add the new one',
}

export const actionTypes = {
    getLanguagePack: '[language-pack] Load all packages',
}

const getters = {
    [getterTypes.pageIsReady](state) {
        return Object.keys(state.languagePack).length !== 0
    },
    [getterTypes.getContent]:
        ({ languagePack }) =>
        (lang, name) => {
            let pack = {}
            let common = {}

            if (!languagePack.common && !languagePack[name]) return null

            if (languagePack.common) {
                Object.keys(languagePack.common).map((key) => {
                    common = {
                        ...common,
                        [key]: languagePack.common[key][lang],
                    }
                })
            }

            if (languagePack[name]) {
                const section = languagePack[name]
                Object.keys(section).forEach((key) => {
                    pack = {
                        ...pack,
                        [key]: section[key][lang],
                    }
                })
            }
            return { ...pack, ...common }
        },
}

const mutations = {
    [mutationTypes.addLanguagePack](state, pack) {
        Object.keys(pack).forEach((key) => {
            state.languagePack = {
                ...state.languagePack,
                [key]: { ...state.languagePack[key], ...pack[key] },
            }
        })
        // let res = []
        // Object.keys(state.languagePack).forEach((key) => {
        //     res = [...res, { name: key, he: '', en: '' }]
        //     const subItem = state.languagePack[key]
        //     Object.keys(subItem).forEach((subKey) => {
        //         if (subKey === 'crumbs') return null
        //         res = [
        //             ...res,
        //             {
        //                 name: subKey,
        //                 en: subItem[subKey]['en'],
        //                 he: subItem[subKey]['he'],
        //             },
        //         ]
        //     })
        // })
        //
        // function downloadAsFile(data) {
        //     let a = document.createElement('a')
        //     let file = new Blob([data], { type: 'application/json' })
        //     a.href = URL.createObjectURL(file)
        //     a.download = 'example.json'
        //     a.click()
        // }
        //
        // if (res.length === 273) {
        //     let text = JSON.stringify(res)
        //     downloadAsFile(text)
        // }
    },
}

const actions = {
    [actionTypes.getLanguagePack]({ commit, state }, type) {
        if (state.languagePack?.[type]) return null
        axios.get(`/data/${camelToSnakeCase(type)}.json`).then(({ data }) => {
            commit(mutationTypes.addLanguagePack, data)
        })
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
