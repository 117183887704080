<template>
    <div class="search">
        <QrCodeModalScan
            :is-active="qrCodeIsActive"
            @on-detect="qrCodeDetectHandle"
            @on-close="qrCloseHandle"
        />
        <div class="search__input-wrapper">
            <v-text-field
                v-model="searchField"
                :placeholder="textContent['search-orders']"
                outlined
                dense
                data-test-id="wigs--main--search-text-input"
                @keypress="keyPressHandle"
            >
                <template #append>
                    <v-icon>mdi-magnify</v-icon>
                </template>
            </v-text-field>
        </div>

        <div class="search__bar-code-btn">
            <v-btn x-small @click="qrOpenHandle"
                >{{ textContent['barcode-scanner'] }}
            </v-btn>
        </div>

        <div class="search__filters">
            <h4 class="search__filters-title">
                {{ textContent['filter-by-state'] }}
            </h4>
            <template v-if="$resize && $mq.above(1023)">
                <v-list>
                    <v-list-item-group
                        v-model="selectedFilter"
                        class="search__filters-list"
                        :class="{ ['is-disabled']: isDisabled }"
                        value="title"
                        mandatory
                        @click="filterHandle"
                    >
                        <v-list-item
                            v-for="({ title, value }, i) in filters"
                            :key="i"
                            active-class="is-active"
                            exact-active-class="is-active"
                            class="search__filters-item"
                            dark
                            @click="() => filterHandle(value)"
                        >
                            <v-list-item-content>
                                <v-list-item-title
                                    class="search__filters-item-title"
                                    v-text="title"
                                ></v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </template>
            <template v-else>
                <v-select
                    v-model="selectedFilter"
                    item-text="title"
                    item-value="value"
                    :items="filters"
                    @change="() => filterHandle(selectedFilter)"
                ></v-select>
            </template>
        </div>
    </div>
</template>

<script>
import QrCodeModalScan from '@/components/modals/qr-code-modal-scan'
import languageMixin from '@/mixins/language.mixin'
import { mapActions } from 'vuex'
import { actionTypes } from '@/store/modules/modals'
import { actionTypes as actionTypesOrders } from '@/store/modules/orders'
import { getOrdersSearchSecondaryLoadingStates } from '@/helpers/orders-search-secondary-loading-states.utils'

export default {
    name: 'OrdersSearch',
    components: {
        QrCodeModalScan,
    },
    mixins: [languageMixin],
    props: {
        searchData: {
            type: String,
            default: '',
        },
        isDisabled: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['filter-handle', 'search-handle', 'open-barcode'],
    data() {
        return {
            selectedFilter: 'All states',
            qrCodeIsActive: false,
            searchField: '',
            isBusy: false,
            searchTimeout: null,
        }
    },
    computed: {
        filters: function () {
            return [
                {
                    title: this.extraContent['all-states'],
                    value: 'All states',
                },
                {
                    title: this.extraContent['draft'],
                    value: 'Draft',
                },
                {
                    title: this.extraContent['wigs-in-production'],
                    value: 'Wigs in Production',
                },
                {
                    title: this.extraContent['rework'],
                    value: 'Rework',
                },
                {
                    title: this.extraContent['ready-new-wig'],
                    value: 'Ready New Wig',
                },
                {
                    title: this.extraContent['repair-wigs'],
                    value: 'Repair Wigs',
                },
                {
                    title: this.extraContent['ready-repair-wigs'],
                    value: 'Ready Repair Wigs',
                },
                {
                    title: this.extraContent['cancelled-wigs'],
                    value: 'Cancelled Wigs',
                },
                {
                    title: this.extraContent['wigs-in-stock'],
                    value: 'Wigs In Stock',
                },
                {
                    title: this.extraContent['storage'],
                    value: 'Storage',
                },
                {
                    title: this.extraContent['delivered-wigs'],
                    value: 'Delivered Wigs',
                },
                {
                    title: this.extraContent['archived'],
                    value: 'Archived',
                },
            ]
        },
        extraContent: function () {
            return this.getTextForSelected(this.lang, 'orderExtra') || ''
        },
        search: {
            get: function () {
                return this.searchData
            },
            set: function (newValue) {
                this.$emit('search-handle', newValue)
            },
        },
    },
    watch: {
        searchField: function () {
            if (this.searchTimeout) {
                clearTimeout(this.searchTimeout)
                this.searchTimeout = null
            }

            this.searchTimeout = setTimeout(() => {
                this.searchHandle()
                this.searchTimeout = null
            }, 500)
        },
        searchData: function (newValue) {
            this.searchField = newValue
        },
    },
    methods: {
        ...mapActions('modals', { onOpen: actionTypes.openPopUp }),
        ...mapActions('orders', {
            getWigsBySearchParameters:
                actionTypesOrders.getWigsBySearchParameters,
            getWigsByState: actionTypesOrders.getWigsByState,
            getDeliveredByName: actionTypesOrders.getDeliveredByName,
            getDelivered: actionTypesOrders.getDelivered,
        }),
        qrCloseHandle: function () {
            this.qrCodeIsActive = false
        },
        qrOpenHandle: function () {
            this.qrCodeIsActive = true
        },
        filterHandle: async function (state) {
            if (this.isDisabled) return null

            this.$emit('filter-handle', state)
        },
        qrCodeDetectHandle: function (code) {
            this.$emit('search-handle', code)
        },
        searchHandle: function () {
            this.$emit('search-handle', this.searchField)
        },
        keyPressHandle: function (e) {
            if (e.key === 'Enter') this.searchHandle()
        },
    },
}
</script>

<style lang="scss" scoped>
.search {
    padding: 15px;

    &__input-wrapper {
        max-height: 40px;
        overflow: visible;
        margin-bottom: 10px;
    }

    &__bar-code-btn {
        text-align: center;
        margin-bottom: 30px;
    }

    &__filters {
    }

    &__filters-title {
        font-size: 18px;
        color: rgb(85, 89, 92);
        font-weight: 400;
        margin-bottom: 10px;
    }

    &__filters-list {
        border: 1px solid #e4e7ed;
        border-bottom: none;
    }

    &__filters-item {
        border-bottom: 1px solid #e4e7ed;

        &.is-active {
            &:before {
                opacity: 0;
            }

            background: #3bafda;
        }

        .is-disabled & {
            background: #fff;
        }
    }

    &__filters-item-title {
        color: #3bafda;
        font-size: 14px;

        .is-active & {
            color: $color-primary-light;
        }

        .is-disabled & {
            color: #5d5d5d;
        }
    }

    &__filters-btn {
    }
}
</style>
