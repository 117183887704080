<template>
    <section class="wigs-details-print-page">
        <section class="wigs-details-print-page__body">
            <ul
                v-if="wigIsLoaded"
                class="wigs-details-print-page__table print-table"
            >
                <li class="print-table__row">
                    <div
                        class="print-table__cell print-table__cell--client-cell"
                    >
                        <div
                            class="print-table__value print-table__value--client-cell"
                        >
                            <WigsDetailsPrintPageClientCell :wig="info" />
                        </div>
                    </div>
                </li>
                <li
                    v-for="(row, index) in tableRows"
                    :key="index"
                    class="print-table__row"
                >
                    <div
                        v-for="(column, indexJ) in row"
                        :key="indexJ"
                        class="print-table__cell"
                        :class="{ ['is-empty']: !column }"
                    >
                        <template v-if="column">
                            <div class="print-table__value">
                                <template v-if="showLabel(column.label)">
                                    <b> {{ column.label }}: </b>
                                </template>
                                <template v-if="column.component">
                                    <component
                                        :is="column.component"
                                        v-bind="column.options"
                                    />
                                </template>
                                <template v-else> {{ column.value }} </template>
                            </div>
                        </template>
                    </div>
                </li>
            </ul>
        </section>
    </section>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { getterTypes } from '@/store/modules/payments'
import {
    actionTypes as actionTypesOrders,
    getterTypes as getterTypesOrders,
} from '@/store/modules/orders'
import firstToLowerCase from '@/helpers/firstToLowerCase'
import DateCell from '@/components/common/cells/date-cell.vue'
import WigsDetailsPrintPagePropertiesList from '@/features/wig-page/components/wigs-details-print-page-properties-list.vue'
import getWigsType from '@/features/wig-page/utils/get-wigs-type.utils'
import WigsDetailsPrintPageClientCell from '@/features/wig-page/components/wigs-details-print-page-client-cell.vue'
import Header from '@/components/login/header.vue'
import generateTable from '@/features/wig-page/utils/generate-wig-details-table.utils'

export default {
    name: 'WigsDetailsPrintPage',
    components: {
        Header,
        WigsDetailsPrintPageClientCell,
        WigsDetailsPrintPagePropertiesList,
        DateCell,
    },
    data() {
        return {
            wigIsLoaded: false,
        }
    },
    computed: {
        WigsDetailsPrintPageClientCell() {
            return WigsDetailsPrintPageClientCell
        },
        ...mapGetters('payments', {
            payments: getterTypes.getPayments,
            sumOfPayments: getterTypes.getSum,
        }),
        ...mapGetters('orders', { info: getterTypesOrders.getOrder }),
        isProduction: function () {
            return (
                this.info.state &&
                this.info.state.toLowerCase() === 'wigs in production'
            )
        },
        status: function () {
            return firstToLowerCase(
                this.isProduction ? this.info.currentStatus : this.info.state
            )
        },
        properties: function () {
            if (!this.info.properties) return {}
            const res = {}
            Object.keys(this.info.properties).forEach((key) => {
                if (this.info.properties[key])
                    res[key] = this.info.properties[key]
            })
            return res
        },
        tableRows: function () {
            return generateTable(this.info)
        },
    },
    async beforeMount() {
        await this.loadOrder({
            id: this.$route.params.wigID,
            withoutOpening: true,
        })
        this.wigIsLoaded = true
        await this.$nextTick()
        window.print()
    },
    methods: {
        ...mapActions('orders', {
            addWeight: actionTypesOrders.updateProperties,
            loadOrder: actionTypesOrders.loadOrder,
        }),
        showLabel(label) {
            const labelsToShow = [
                'תאריך יצירה',
                'סוג הפאה',
                'פרטים',
                'מוכן לתאריך',
                'מזהה פאה נוסף',
            ]
            return labelsToShow.includes(label)
        },
        getWigsType,
    },
}
</script>

<style scoped lang="scss">
$border: 1px solid #686868;

.wigs-details-print-page {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    aspect-ratio: 1/1.41;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: #fff;
    z-index: 999;

    &__footer {
        margin-bottom: 24px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        font-family: $font-bank-gothic;

        &:before {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 95%;
            height: 0;
            background: #373333;
            border: 1px solid #000;
            z-index: 1;
        }

        p {
            position: relative;
            background: #fff;
            font-weight: 700;
            font-size: 50px;
            padding: 0 36px;
            line-height: 40px;
            margin-bottom: 0;
            z-index: 2;
            width: fit-content;
            letter-spacing: 0.3em;
        }
    }
}

.print-table {
    padding: 0;
    font-size: 10px;
    border-collapse: collapse;
    box-sizing: border-box;
    border-left: $border;
    position: absolute;
    width: calc(50% - 4em);
    right: 2em;
    top: 2em;

    &__row {
        display: flex;
        border-top: $border;

        & + & {
            border-top: none;
        }
    }

    &__cell {
        display: flex;
        padding: 0;
        width: 50%;
        position: relative;
        border-bottom: $border;

        &:empty {
            border-bottom: none;
        }

        &--client-cell {
            width: 100%;
        }
    }

    &__cell-wrapper {
        display: flex;
        align-self: stretch;
    }

    &__label {
        width: 35%;
        overflow: hidden;
        position: relative;
        border-left: $border;
        padding: 8px 16px;
        align-self: stretch;
        display: flex;
        font-weight: 700;
        align-items: center;

        &--client-cell {
            width: calc(100% / 2 * 0.35);
        }

        &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            border: 500px solid #f6f6f6;
            z-index: -1;
        }
    }

    &__value {
        width: auto;
        padding: 8px 16px;
        flex: 1;
        border-right: $border;
        &--client-cell {
        }
    }
}

.print-table__cell:first-child .print-table__label,
.print-table__cell.is-empty + .print-table__cell .print-table__label {
    border-right: $border;
}

.print-table__cell.is-empty + .print-table__cell .print-table__label {
    width: calc(35% + 1px);
    margin: -1px;
}
</style>
