<template>
    <div class="stages">
        <div class="body">
            <ul class="categories-list">
                <li
                    v-for="({ title, items }, i) in itemsForRender"
                    :key="i"
                    class="category"
                >
                    <h4>{{ title }}</h4>
                    <ul class="status-list">
                        <li
                            v-for="(
                                // eslint-disable-next-line
                                { tittle, id, done, done_by, done_at },
                                j
                            ) in items"
                            :key="j"
                            class="status-list__item"
                        >
                            <div class="status-list__main">
                                <v-checkbox
                                    v-model="selectedItems"
                                    class="status-list__checkbox"
                                    :label="tittle"
                                    :value="id"
                                    :success="done"
                                    :readonly="readonly"
                                    hide-details
                                    dense
                                >
                                </v-checkbox>
                            </div>
                            <template v-if="done && done_by">
                                <div class="status-list__extra">
                                    <div class="status-list__date">
                                        <DateCell :value="done_at" />
                                    </div>
                                    <div class="status-list__user">
                                        {{ done_by }}
                                    </div>
                                </div>
                            </template>
                        </li>
                    </ul>
                </li>
            </ul>
            <div v-if="!readonly && !isActionsHide" class="actions">
                <v-btn color="#967adc" dark small @click="updateStatusHandle">
                    {{ $t('pages.wigPage.slideOuts.update') }}
                </v-btn>
                <v-btn color="#979ea3" dark small @click="closeHandle">
                    {{ $t('pages.wigPage.slideOuts.close') }}
                </v-btn>
            </div>
        </div>
    </div>
</template>

<script>
import languageMixin from '@/mixins/language.mixin'
import formatDate from '@/helpers/format-date'
import DateCell from '@/components/common/cells/date-cell.vue'
import WigPageAdditionalPropertiesByDateList from '@/features/wig-page/components/wig-page-additional-properties-by-date-list.vue'
import prepareAdditionalPropertiesByDateUtils from '@/features/wig-page/utils/prepare-additinal-properties-by-date.utils'

export default {
    name: 'OrdersReworkPropertiesUpdate',
    components: { WigPageAdditionalPropertiesByDateList, DateCell },
    mixins: [languageMixin],
    props: {
        reworkProperties: {
            type: Array,
            default: () => [],
        },
        readonly: {
            type: Boolean,
            default: false,
        },
        isActionsHide: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['close-handle', 'update-handle'],
    data() {
        return {
            defaultSate: [],
            stage: {
                title: '',
                comment: '',
                category: '',
            },
            selectedItems: [],
        }
    },
    computed: {
        itemsData: function () {
            return this.reworkProperties
        },
        newItemsForRender: function () {
            return prepareAdditionalPropertiesByDateUtils(this.itemsData)
        },
        itemsForRender: function () {
            let categories = {}
            this.itemsData.forEach((item) => {
                const category = item?.category || 'Common'
                categories[category] = {
                    title: category,
                    items: [...(categories?.[category]?.items || []), item],
                }
            })
            return categories
        },
    },
    watch: {
        itemsData: function () {
            this.selectedItems = this.itemsData
                .map((i) => (i.done ? i.id : null))
                .filter((i) => i)
        },
    },
    mounted() {
        this.defaultSate = this.itemsData

        this.selectedItems = this.itemsData
            .map((i) => (i.done ? i.id : null))
            .filter((i) => i)
    },
    methods: {
        updateStatusHandle: function () {
            let forUnDone = []
            let forDone = []

            this.itemsData.forEach((items) => {
                if (items.done && !this.selectedItems.includes(items.id))
                    forUnDone = [...forUnDone, items.id]
                if (!items.done && this.selectedItems.includes(items.id))
                    forDone = [...forDone, items.id]
            })

            this.$emit('update-handle', { forUnDone, forDone })
            this.$emit('close-handle')
            return { forUnDone, forDone }
        },
        dateFormat: function (date) {
            return formatDate(date)
        },
        closeHandle: function () {
            this.$emit('close-handle')
        },
    },
}
</script>

<style lang="scss">
.categories-list {
    margin: 0 !important;
    padding: 0 !important;
}

.stages {
    &__action {
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
    }

    &__text-fields {
        flex-grow: 1;
        margin-right: 15px;
    }

    &__input {
        flex-grow: 1;
    }

    &__button {
        margin-left: 10px;
        margin-bottom: 31px;
    }

    &__title {
        font-weight: 400;
        display: flex;
        align-items: center;

        span {
            margin-left: 5px;
            margin-top: 4px;
        }
    }
}

.status-list {
    margin-bottom: 20px;
    padding: 0 !important;

    &__item {
        min-height: 44px;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px dotted #888888;
        padding: 3px 0;
        align-items: center;

        label {
            font-weight: 700;
            @include for-hebrew {
                text-align: right !important;
            }
        }
    }

    &__main {
        max-width: 80%;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        height: 100%;

        label {
        }
    }

    &__comment {
        padding-left: 31px;
        margin-bottom: 0 !important;
        font-size: 13px;
        opacity: 0.75;
        min-height: 33px;
    }

    &__extra {
        font-size: 13px;
        opacity: 0.75;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &__date {
        margin-bottom: 5px;
    }
}

.actions {
    display: flex;
    gap: 16px;
}
</style>
