import { getStorage, removeStorage, setStorage } from '@/helpers/Local'

const initTokenExpire = (time) => {
    removeStorage('expire_time')
    const endDate = Date.now() + time * 1000
    setStorage('expire_time', endDate)
}

const tokenIsAvailable = () => {
    //todo next section must be removed
    // console.log('________________________________________________')
    // console.log(
    //     '%cTokken - ' + `%c${!!getStorage('access_token')} `,
    //     'color:red;',
    //     'color:orange;'
    // )
    // console.log(
    //     '%cExpire - ' + `%c${Date.now() <= getStorage('expire_time')} `,
    //     'color:red;',
    //     'color:orange;'
    // )
    // console.log('________________________________________________')

    if (!getStorage('access_token')) return false
    const endDate = getStorage('expire_time')
    return Date.now() <= endDate
}

export { initTokenExpire, tokenIsAvailable }
