<template>
    <SlideOutV2 ref="slideOut" :is-loading="isLoading" @on-submit="onSubmit">
        <OrdersReworkPropertiesUpdate
            ref="ordersReworkPropertiesUpdate"
            :rework-properties="propertiesToUpdate"
            is-actions-hide
        />
    </SlideOutV2>
</template>

<script>
import SlideOutV2 from '@/components/common/slide-out-v2.vue'
import OrdersReworkPropertiesUpdate from '@/components/orders/orders-rework-properties-update.vue'
import { mapActions, mapGetters } from 'vuex'
import { getterTypes as getterTypesOrders } from '@/store/modules/orders'
import {
    actionTypesOrdersReworkProperties,
    getterTypesOrdersReworkProperties,
} from '@/store/modules/orders-rework-properties/types'
import {
    actionTypes as actionTypesRepairProperties,
    getterTypes as getterTypesOrdersRepairProperties,
} from '@/store/modules/order-repairs-stages'
import { getterTypesSlideOuts } from '@/store/modules/slide-outs/types'

export default {
    name: 'WigPageAdditionalPropertiesUpdateSlideOut',
    components: {
        OrdersReworkPropertiesUpdate,
        SlideOutV2,
    },
    data() {
        return {
            isLoading: false,
        }
    },
    computed: {
        ...mapGetters('slideOuts', {
            getProps: getterTypesSlideOuts.GET_PROPS,
        }),
        ...mapGetters('orders', {
            selectedWig: getterTypesOrders.getOrder,
        }),
        ...mapGetters('ordersReworkProperties', {
            reworkProperties: getterTypesOrdersReworkProperties.getProperties,
        }),
        ...mapGetters('orderRepairsStages', {
            repairProperties:
                getterTypesOrdersRepairProperties.getAdditionalProperties,
        }),
        propertiesToUpdate: function () {
            return this.isReworkProperties
                ? this.reworkProperties
                : this.repairProperties
        },
        isReworkProperties: function () {
            return this.getProps.isReworkProperties
        },
    },
    methods: {
        ...mapActions('orderRepairsStages', {
            doneRepairProperty: actionTypesRepairProperties.onDone,
            undoneRepairProperty: actionTypesRepairProperties.onUndone,
        }),
        ...mapActions('ordersReworkProperties', {
            doneReworkProperties:
                actionTypesOrdersReworkProperties.checkProperties,
            undoneReworkProperties:
                actionTypesOrdersReworkProperties.uncheckProperties,
        }),
        onClose: function () {
            this.$refs.slideOut.onClose()
        },
        onSubmit: async function () {
            try {
                // define type of handler
                const doneProperties = this.isReworkProperties
                    ? this.doneReworkProperties
                    : this.doneRepairProperty

                const undoneProperties = this.isReworkProperties
                    ? this.undoneReworkProperties
                    : this.undoneRepairProperty

                this.isLoading = true
                const { forDone, forUnDone } =
                    this.$refs.ordersReworkPropertiesUpdate.updateStatusHandle()

                // provide VUEX ACTIONS
                await doneProperties({
                    wigID: this.selectedWig.id,
                    ids: forDone,
                })

                await undoneProperties({
                    wigID: this.selectedWig.id,
                    ids: forUnDone,
                })

                this.isLoading = false
                setTimeout(() => {
                    this.onClose()
                }, 150)
            } catch (e) {
                this.isLoading = false
                console.log(e)
            }
        },
    },
}
</script>

<style lang="scss" scoped></style>
