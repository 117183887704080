import axios from '@/api/axios'

export default {
    getDate: ({ year, month, lang }) =>
        axios
            .get(
                `/base/api/getHebcalEvents?year=${year}&month=${month}&lg=${lang}`
            )
            .then((res) => res.data),
}
