<template>
    <div class="meeting-list">
        <div class="meeting-list__container">
            <div class="meeting-list__side-bar">
                <v-text-field
                    v-model="searchQuery"
                    append-icon="mdi-magnify"
                    :label="$t('pages.wigPage.associatePopup.search')"
                    dense
                    hide-details
                    outlined
                    clearable
                >
                </v-text-field>
                <br />
                <v-select
                    v-model="calendars"
                    :items="calendarItems"
                    :menu-props="{ maxHeight: '400' }"
                    dense
                    label="Calendars"
                    clearable
                    outlined
                    multiple
                    hide-details
                ></v-select>
            </div>
            <div class="meeting-list__table">
                <v-data-table
                    calculate-widths
                    mobile-breakpoint="767"
                    :headers="headers"
                    :page="page"
                    :items="meetings"
                    disable-sort
                    :hide-default-footer="true"
                    :server-items-length="total"
                    :loading="isFetching"
                    @page-count="pageCount = $event"
                    @click:row="onRowClick"
                >
                    <!--    eslint-disable-next-line -->
                    <template #item.calendarType="{ item }">
                        <v-chip :color="getColor(item.calendarType)" dark>
                            {{ getCalendarTypeByLocal(item.calendarType) }}
                        </v-chip> </template
                    ><!--    eslint-disable-next-line -->
                    <template #item.startAt="{ item }">
                        <div style="direction: ltr">
                            {{ getDate(item.startAt) }}
                        </div>
                    </template>
                </v-data-table>
                <template>
                    <v-pagination
                        :value="page"
                        :length="pageCount"
                        :total-visible="7"
                        color="#3bafda"
                        @input="onPageChange"
                    ></v-pagination>
                </template>
                <v-menu
                    v-model="showMenu"
                    max-width="200"
                    min-width="200"
                    offset-y
                    :position-x="menuX"
                    :position-y="menuY"
                    :close-on-click="false"
                    absolute
                >
                    <div class="meeting-list__details">
                        <div class="meeting-list__details-head">
                            <v-btn icon x-small dense @click="closeDetailsMenu">
                                <v-icon small>mdi-close-circle</v-icon>
                            </v-btn>
                        </div>

                        <CalendarEventExpandedCard
                            :event-details="selectedItem"
                            @update-handle="onUpdate"
                            @delete-handle="onDelete"
                        />
                    </div>
                </v-menu>
            </div>
        </div>
    </div>
</template>

<script>
import CalendarEventExpandedCard from '@/components/calendar/calendar-event-expanded-card.vue'
import { format, parseISO } from 'date-fns'
import { mapActions, mapGetters, mapMutations } from 'vuex'
import {
    meetingListActionTypes,
    meetingListGetterTypes,
    meetingListMutationTypes,
} from '@/store/modules/meeting-list/types'
import { FULL_DATE_FORMAT } from '@/consts'
import languageMixin from '@/mixins/language.mixin'

export default {
    name: 'CalendarMeetingList',
    components: { CalendarEventExpandedCard },
    mixins: [languageMixin],
    emits: ['on-update', 'on-delete'],
    data() {
        return {
            searchQuery: '',
            page: 1,
            pageCount: 0,
            itemPerPage: 10,
            searchTimeout: null,
            calendars: [],
            showMenu: false,
            menuX: 0,
            menuY: 0,
        }
    },
    computed: {
        ...mapGetters('meetingList', {
            meetings: meetingListGetterTypes.GET_ALL_MEETINGS,
            total: meetingListGetterTypes.GET_TOTAL,
            isFetching: meetingListGetterTypes.GET_IS_FETCHING,
            selectedItem: meetingListGetterTypes.GET_SELECTED_ITEM,
        }),
        headers: function () {
            return [
                { text: this.textContent['date'], value: 'startAt' },
                { text: 'Calendar', value: 'calendarType' },
                { text: this.textContent['client'], value: 'client.fullName' },
                { text: this.textContent['phone'], value: 'client.phone' },
            ]
        },
        calendarItems: function () {
            return [
                { text: this.textContent['orders'], value: 'ORDERS' },
                { text: this.textContent['pick-up-new'], value: 'PICK_UP_NEW' },
                { text: this.textContent['repairs'], value: 'REPAIRS' },
                {
                    text: this.textContent['pick-up-repairs'],
                    value: 'PICK_UP_REPAIRS',
                },
                { text: this.textContent['us-agent'], value: 'US_AGENT' },
            ]
        },
    },
    watch: {
        page: function (page) {
            this.setPage(page)
        },
        searchQuery: function (searchQuery) {
            if (this.searchTimeout) {
                clearTimeout(this.searchTimeout)
            }

            this.searchTimeout = setTimeout(async () => {
                await this.setSearchQuery(searchQuery)
                this.searchTimeout = null
            }, 500)
        },
        calendars: function (calendars) {
            this.setCalendars(calendars)
        },
    },
    mounted() {
        this.fetchMeetings()
    },
    methods: {
        ...mapActions('meetingList', {
            fetchMeetings: meetingListActionTypes.FETCH_MEETINGS,
            setPage: meetingListActionTypes.SET_PAGE,
            setSearchQuery: meetingListActionTypes.SET_SEARCH_QUERY,
            setCalendars: meetingListActionTypes.SET_CALENDARS,
        }),
        ...mapMutations('meetingList', {
            setSelectedItem: meetingListMutationTypes.SET_SELECTED_ITEM,
        }),
        getColor: function (value) {
            switch (value) {
                case 'Orders':
                    return '#967adc'
                case 'Pick-up New Wigs':
                    return '#37bc9b'
                case 'Repairs':
                    return '#f6bb42'
                case 'Pick-up Repairs':
                    return '#f6bb42'
                case 'us_agent':
                    return '#b05454'
                default:
                    return null
            }
        },
        getCalendarTypeByLocal: function (type) {
            switch (type) {
                case 'Orders':
                    return this.textContent['orders']
                case 'Pick-up New Wigs':
                    return this.textContent['pick-up-new']
                case 'Repairs':
                    return this.textContent['repairs']
                case 'Pick-up Repairs':
                    return this.textContent['pick-up-repairs']
                case 'us_agent':
                    return this.textContent['us-agent']
                default:
                    return ''
            }
        },
        getDate: function (date) {
            return format(parseISO(date, 1), FULL_DATE_FORMAT)
        },
        onRowClick: function (item, _details, event) {
            this.setSelectedItem(item)
            this.menuX = event.clientX
            this.menuY = event.clientY
            this.showMenu = true
        },
        onUpdate: function () {
            if (!this.selectedItem) {
                return
            }

            this.$emit('on-update', this.selectedItem)
        },
        onDelete: function () {
            this.$emit(
                'on-delete',
                this.selectedItem.id,
                this.selectedItem.wig?.id || ''
            )
            alert('Will be implement soon')
        },
        closeDetailsMenu: function () {
            this.setSelectedItem({})
            this.menuX = 0
            this.menuY = 0
        },
        onPageChange: function (page) {
            if (page === this.page) {
                return
            }

            this.page = page
        },
    },
}
</script>

<style lang="scss" scoped>
.meeting-list {
    &__container {
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding-bottom: 16px;

        @include tablet-up {
            flex-direction: row;
        }
    }

    &__side-bar {
        width: 100%;
        background: #fff;
        border-radius: 8px;
        height: fit-content;
        padding: 16px;

        @include tablet-up {
            max-width: 350px;
            width: 25%;
        }
    }

    &__table {
        position: relative;
        flex-grow: 1;
    }

    &__details {
        background: #fff;
        z-index: 9999;
        padding: 5px;
        border-radius: 5px;
        background: $color-primary-light;
        width: 100%;
        font-size: 13px;
        position: relative;
    }

    &__details-head {
        display: flex;
        justify-content: flex-end;
    }
}
</style>
