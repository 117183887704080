import generalList from './general-list'
import ordersPagePayments from './orders-page-payments'
import slideOuts from './slide-outs'
import logs from './logs'
import associatePopup from './associate-popup'

export default {
    returnBtn: 'Back to wigs table',
    workflowActionsMenu: {
        header: 'workflow actions',
        update: 'Update',
        edit: 'Edit',
        editNewWigProperties: 'Edit',
    },
    tabs: {
        reworkWorkflow: 'Rework workflow',
        workflow: 'Workflow',
        information: 'Information',
        meetings: 'Meetings',
        logs: 'Logs',
    },
    mainActions: {
        mobileButton: 'Actions',
        print: 'Print',
        restoreWig: 'Restore the wig',
        moveToStock: 'Move to stock',
        moveToAny: 'Move to any',
        delivery: 'Delivery',
        productionSteps: 'Production steps',
        edit: 'Edit',
        rework: 'Rework',
        moveToDraft: 'Move to draft',
        cancelOrder: 'Cancel order',
        delete: 'Delete',
        ['production-start']: 'Production Start',
        ['production-complete']: 'Production complete',
        ['repair-complete']: 'Repair complete',
        storage: 'Storage',
        message: 'Do you really want to change state?',
        changeStateToAnyByList: 'Change State to any by list:',
    },
    generalList,
    ordersPagePayments,
    slideOuts,
    logs,
    associatePopup,
}
