<template>
    <footer class="footer" :class="{ 'is-dark': isDark }">
        <div class="footer__content">
            <h4 class="footer__title">
                Powered by:
                <a class="footer__extra-link" href="https://kamitech.co.il">
                    <span>
                        <img
                            :src="require(`@/assets/common/logo-kamitech.svg`)"
                            alt="kamitech-logo"
                        />
                    </span>
                    <span>Kamitech</span>
                </a>
            </h4>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'Footer',
    computed: {
        loadDuration: function () {
            return Math.round(parseFloat(Math.random()) * 10000) / 10000
        },
        isDark: function () {
            return this.$route.path.includes('login')
        },
    },
}
</script>

<style lang="scss" scoped>
.footer {
    position: absolute;
    bottom: 0;
    height: 62px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $color-primary-dark;

    @media print {
        display: none;
    }

    &.is-dark {
        position: fixed;
        bottom: 0;
        left: 0;
        color: $color-primary-light;
    }

    &__content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        text-align: center;
        align-items: center;
    }

    &__title {
        direction: ltr;
        display: flex;
        gap: 6px;
        font-weight: 400;
        font-size: 14px;
    }

    &__extra-link {
        color: #fff;
        text-decoration: none;
        display: flex;
        gap: 6px;

        &:hover {
            text-decoration: underline;
        }
    }

    &__extra {
        font-size: 14px;
        margin: 0;
    }
}
</style>
