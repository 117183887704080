import { clientsStatusTypes } from './types'

const state = () => ({
    items: [],
    total: 0,
    currentPage: 1,
    itemsPerPage: 15,
    search: '',
    sort: 'date_desc',
    status: clientsStatusTypes.ACTIVE,
    loading: false,
    error: null,
})

import getters from './getters'
import mutations from './mutations'
import actions from './actions'

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
