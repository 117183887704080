<template>
    <div class="order-general">
        <QrCodeModalScan
            :is-active="qrCodeIsActive"
            @on-detect="detectHandle"
            @on-close="closeBarcode"
        />
        <div class="order-general__input-wrapper">
            <v-text-field
                v-model="fields.totalPrice"
                dense
                outlined
                type="number"
                name="price-for-new"
                data-test-id="wigs--add-new-wig-form--price-input"
                :label="textContent['price']"
                :error-messages="errors['totalPrice']"
                @input="errors['totalPrice'] = ''"
            >
                <template #append>
                    <span class="icon-container">
                        <v-icon>fas fa-shekel-sign</v-icon>
                    </span>
                </template>
            </v-text-field>
        </div>

        <div class="order-general__input-wrapper">
            <v-text-field
                v-model="fields.barcode"
                dense
                outlined
                type="text"
                name="barcode-for-new"
                data-test-id="wigs--add-new-wig-form--barcode-input"
                :label="textContent['barcode']"
                :error-messages="errors['barcode']"
                @input="errors['barcode'] = ''"
            >
                <template #append>
                    <button class="icon-container" @click="scanBarcode">
                        <v-icon>fas fa-barcode</v-icon>
                    </button>
                </template>
            </v-text-field>
        </div>
        <div class="order-general__input-wrapper">
            <v-text-field
                v-model="fields.user_generated_id"
                dense
                outlined
                type="text"
                name="uniqID-for-new"
                :label="textContent['uniq-id']"
                :error-messages="errors['user_generated_id']"
                @input="errors['user_generated_id'] = ''"
            >
            </v-text-field>
        </div>

        <div class="order-general__input-wrapper">
            <v-textarea
                v-model="fields.info"
                outlined
                name="info"
                :label="textContent['info']"
                rows="2"
            >
            </v-textarea>
        </div>
        <div class="order-general__input-wrapper">
            <v-textarea
                v-model="fields['details']"
                outlined
                name="details"
                data-test-id="wigs--add-new-wig-form--details-input"
                :label="textContent['details']"
                rows="2"
                :error-messages="errors['shippingInfo']"
                @input="errors['shippingInfo'] = ''"
            >
            </v-textarea>
        </div>
        <div class="order-general__input-wrapper">
            <v-textarea
                v-model="fields['shippingInfo']"
                outlined
                name="details"
                :label="textContent['storage-info']"
                rows="2"
                :error-messages="errors['shippingInfo']"
                @input="errors['shippingInfo'] = ''"
            >
            </v-textarea>
        </div>
        <div
            class="order-general__input-wrapper order-general__input-wrapper--half"
        ></div>
    </div>
</template>

<script>
import QrCodeModalScan from '@/components/modals/qr-code-modal-scan'
import languageMixin from '@/mixins/language.mixin'
import { mapActions } from 'vuex'
import { actionTypes } from '@/store/modules/modals'

export default {
    name: 'OrderFormGeneral',
    components: {
        QrCodeModalScan,
    },
    mixins: [languageMixin],
    props: {
        data: {
            type: Object,
            default: () => ({
                fields: {
                    totalPrice: '',
                    barcode: '',
                    state: '',
                    info: false,
                    shippingInfo: '',
                    is_repair: false,
                    is_reword: false,
                },
                errors: {
                    totalPrice: '',
                    barcode: '',
                    state: '',
                    info: false,
                    shippingInfo: '',
                    is_repair: false,
                    is_reword: false,
                },
            }),
        },
    },
    data() {
        return {
            modals: {
                barcode: {
                    isActive: false,
                },
            },
            details: '',
            qrCodeIsActive: false,
        }
    },
    computed: {
        fields: function () {
            return this.data.fields
        },
        errors: function () {
            return this.data.errors
        },
    },
    watch: {
        data: function () {
            return (this.fields.info = this.fields.shippingInfo.length !== 0)
        },
    },
    methods: {
        ...mapActions('modals', { onOpen: actionTypes.openPopUp }),
        scanBarcode: function () {
            this.qrCodeIsActive = true
        },
        detectHandle: function (con) {
            this.fields.barcode = con
        },
        closeBarcode: function () {
            this.qrCodeIsActive = false
        },
    },
}
</script>

<style scoped lang="scss">
.order-general {
    display: flex;
    flex-wrap: wrap;
    padding: 25px 15px 0;

    &__input-wrapper {
        width: 100%;

        &--half {
            width: 48%;
        }

        .icon-container {
            height: 24px;
            display: flex;
            align-items: center;

            i {
                font-size: 18px;
            }
        }
    }
}
</style>
