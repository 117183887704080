<template>
    <div class="additional-properties">
        <div ref="properties" class="additional-properties__body">
            <ul class="additional-properties__list">
                <li
                    v-for="(category, index) in propertiesByCategories"
                    :key="index"
                    class="additional-properties__property"
                    :class="{
                        ['is-existed']: categoryHasExisted(category.category),
                    }"
                >
                    <OrdersAdditionalPropertiesCategory
                        :category="category"
                        @property-remove-handle="propertyRemoveHandle"
                        @property-edit-handle="propertyEditHandle"
                        @category-edit-handle="categoryEditHandle"
                    >
                        <div class="additional-properties__add-new-property">
                            <v-text-field
                                ref="titles"
                                v-model="property.title[category.category]"
                                :placeholder="textContent['properties-add']"
                                dense
                                hide-details="auto"
                                @keyup.enter="() => propertyAddHandle(category)"
                            ></v-text-field>
                            <div
                                class="additional-properties__add-new-property-action"
                            >
                                <v-btn
                                    x-small
                                    class="mx-2"
                                    dark
                                    color="#4ca363"
                                    @click="() => propertyAddHandle(category)"
                                >
                                    <v-icon small dark> mdi-plus</v-icon>
                                    {{ textContent['add'] }}
                                </v-btn>
                                <v-btn
                                    v-if="categoryHasExisted(category.category)"
                                    x-small
                                    class="mx-2"
                                    dark
                                    color="#d95a5a"
                                    @click="
                                        () =>
                                            categoryRemoveHandleWithConfirm(
                                                category.category
                                            )
                                    "
                                >
                                    <v-icon small dark> mdi-minus</v-icon>
                                    {{ textContent['remove-category'] }}
                                </v-btn>
                                <v-btn
                                    v-else
                                    x-small
                                    class="mx-2"
                                    dark
                                    color="#d95a5a"
                                    @click="
                                        () => categoryRemoveHandle(category)
                                    "
                                >
                                    <v-icon small dark> mdi-minus</v-icon>
                                    {{ textContent['remove-category'] }}
                                </v-btn>
                            </div>
                        </div>
                    </OrdersAdditionalPropertiesCategory>
                </li>
            </ul>
        </div>
        <div class="additional-properties__foot">
            <p class="additional-properties__add-category-title">
                {{ textContent['add-new-category'] }}
            </p>
            <v-text-field
                v-model="categoryName"
                :placeholder="textContent['category']"
                dense
                hide-details="auto"
            ></v-text-field>
            <div class="additional-properties__foot-actions">
                <v-btn
                    x-small
                    class="mx-2"
                    dark
                    color="#1976d2"
                    @click="categoryAddHandle"
                >
                    {{ textContent['continue'] }}
                </v-btn>
            </div>
        </div>
    </div>
</template>

<script>
import OrdersAdditionalPropertiesCategory from '@/components/orders/orders-additional-properties-category'

import { find } from 'lodash'
import shortid from 'shortid'
import { mapGetters, mapActions } from 'vuex'
import { actionTypes, getterTypes } from '@/store/modules/order-repairs-stages'
import { actionTypes as actionTypesConfirmer } from '@/store/modules/confirmer'
import languageMixin from '@/mixins/language.mixin'

export default {
    name: 'OrdersAdditionalProperties',
    components: { OrdersAdditionalPropertiesCategory },
    mixins: [languageMixin],
    props: {
        onDispatch: {
            type: Number,
            default: 0,
        },
        createOrder: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['dispatch-cancel-handle'],
    data() {
        return {
            titles: [],
            propertiesForDeleting: [],
            categoryName: '',
            firstProperty: '',
            property: {
                input: {},
                title: {},
                comment: '',
            },
            panel: false,
            newPropertyIsOpen: [],
            properties: [],
        }
    },
    computed: {
        ...mapGetters('orderRepairsStages', {
            additionalProperties: getterTypes.getAdditionalProperties,
        }),
        propertiesByCategories: function () {
            let categories = []
            this.properties.forEach((property) => {
                const categoryExicted = categories
                    .map(({ category_id }) => category_id)
                    .includes(property.category_id)

                if (categoryExicted) {
                    categories = categories.map((item) =>
                        item.category_id === property.category_id
                            ? {
                                  ...item,
                                  properties: [...item.properties, property],
                              }
                            : item
                    )
                } else {
                    categories = [
                        ...categories,
                        {
                            category: property.category,
                            category_id: property.category_id,
                            properties: [property],
                        },
                    ]
                }
            })
            return categories
        },
        textContent: function () {
            return this.getTextForSelected(
                this.lang,
                'OrdersAdditionalProperties'
            )
        },
    },
    watch: {
        onDispatch: function (value) {
            if (value === 0) return
            this.dispatchHandle()
        },
        additionalProperties: function (properties) {
            if (this.createOrder) return
            this.properties = [...this.preparePropertiesForUpdate(properties)]
        },
    },
    mounted() {
        if (this.createOrder) return
        this.properties = [
            ...this.preparePropertiesForUpdate(this.additionalProperties),
        ]
    },
    methods: {
        ...mapActions('orderRepairsStages', {
            onPut: actionTypes.addNewProperties,
            onDelete: actionTypes.onDelete,
        }),
        ...mapActions('confirmer', {
            confirmHandle: actionTypesConfirmer.confirmHandle,
        }),
        newPropertySwitchHandle: function (index) {
            let found = false

            this.property.title = ''
            this.property.comment = ''

            this.newPropertyIsOpen = this.newPropertyIsOpen.map((item) => {
                found = item.id === index
                return item.id === index
                    ? { ...item, value: !item.value }
                    : { ...item, value: false }
            })

            if (!found)
                this.newPropertyIsOpen = [
                    ...this.newPropertyIsOpen,
                    { id: index, value: true },
                ]
        },
        checkIsOpen: function (index) {
            return find(this.newPropertyIsOpen, { id: index })?.value
        },
        propertyAddHandle: function (payload) {
            const { category, category_id } = payload
            const comment = this.property.comment
            const title = this.property.title[category]
            const id = shortid()

            this.properties = [
                ...this.properties,
                {
                    id,
                    category_id,
                    category,
                    title,
                    comment,
                    isNew: true,
                },
            ]

            this.property.title[category] = ''
            this.property.comment = ''
        },
        propertyRemoveHandle: async function (id) {
            const message = 'Are you sure about deleting this stage?'

            const isNew =
                this.properties.filter((item) => item.id === id)?.[0]?.isNew ||
                false

            if (isNew) {
                this.properties = this.properties.filter((item) => {
                    if (item.id === id && !item.isNew)
                        this.propertiesForDeleting = [
                            ...this.propertiesForDeleting,
                            id,
                        ]
                    return item.id !== id
                })
            } else {
                try {
                    await this.confirmHandle(message)

                    this.properties = this.properties.filter((item) => {
                        if (item.id === id && !item.isNew)
                            this.propertiesForDeleting = [
                                ...this.propertiesForDeleting,
                                id,
                            ]
                        return item.id !== id
                    })
                } catch (e) {
                    console.log(e)
                }
            }
        },
        categoryAddHandle: function () {
            this.property.title = {
                ...this.property.title,
                [this.categoryName]: '',
            }
            this.property.input = {
                ...this.property.input,
                [this.categoryName]: null,
            }

            this.properties = [
                ...this.properties,
                {
                    category: this.categoryName,
                    category_id: shortid(),
                    title: this.firstProperty,
                    comment: '',
                    isNew: true,
                    id: shortid(),
                },
            ]
            this.firstProperty = ''
            this.categoryName = ''
            this.focus()
            this.scrollToLast()
        },
        categoryRemoveHandle: function (payload) {
            const { category } = payload
            this.properties = this.properties.filter((item) => {
                if (item.category === category) {
                    this.propertiesForDeleting = [
                        ...this.propertiesForDeleting,
                        item.id,
                    ]
                }
                return item.category !== category
            })
        },
        categoryRemoveHandleWithConfirm: async function (category) {
            const message = 'Are you sure about deleting this category?'

            try {
                await this.confirmHandle(message)

                this.properties = this.properties.filter((item) => {
                    if (item.category === category) {
                        this.propertiesForDeleting = [
                            ...this.propertiesForDeleting,
                            item.id,
                        ]
                    }
                    return item.category !== category
                })
            } catch (e) {
                console.log(e)
            }
        },
        categoryEditHandle: async function (payload) {
            this.properties.map((item) => {
                if (this.isSelectedCategory(item, payload)) {
                    item.category = payload.updatedTitle
                }
            })
        },
        isSelectedCategory: function (item, payload) {
            return item.category_id === payload.categoryId
        },
        propertyEditHandle: async function (payload) {
            this.properties.map((item) => {
                if (this.isSelectedProperty(item, payload)) {
                    item.title = payload.updatedTitle
                }
            })
        },
        isSelectedProperty: function (item, payload) {
            return item.id === payload.propertyId
        },
        onLoad: async function () {
            try {
                this.propertiesByCategories.forEach((item) => {
                    if (this.property.title[item.category]) {
                        this.propertyAddHandle(item)
                    }
                })

                const propertiesForUploading = this.properties
                    .filter((property) => property.title && property.isNew)
                    .map((property) => {
                        return {
                            tittle: property.title,
                            comment: 'none',
                            category: property.category,
                        }
                    })

                this.categoryName = ''
                if (propertiesForUploading.length !== 0) {
                    await this.onPut({
                        wigID: this.onDispatch,
                        newProperties: propertiesForUploading,
                    })
                }

                await this.propertiesForDeleting.reduce((ak, item) => {
                    return ak.then(() =>
                        this.onDelete({
                            id: this.onDispatch,
                            property_id: item,
                        })
                    )
                }, Promise.resolve())
            } catch (e) {
                console.log(e)
            } finally {
                this.propertiesForDeleting = []
                this.$emit('dispatch-cancel-handle')
            }
        },
        dispatchHandle: function () {
            if (this.property.title) {
                Object.keys(this.property.title).forEach((item) => {
                    this.propertyAddHandle(item)
                })
            }
            this.onLoad()
        },
        preparePropertiesForUpdate: function (properties) {
            return properties.map(({ id, category, comment, tittle }) => {
                const title = tittle
                return { id, category, title, comment, isNew: false }
            })
        },
        keyEvent: function (e) {
            console.log(e)
        },
        focus: async function () {
            this.$nextTick(() => {
                setTimeout(() => {
                    if (!this.$refs.titles) return null
                    this.$refs.titles[this.$refs.titles.length - 1].focus()
                })
            })
        },
        scrollToLast: function () {
            const posY = this.$refs.properties.scrollHeight
            this.$refs.properties.scrollTo(0, posY)
        },
        categoryHasExisted: function (category) {
            return this.properties.reduce((ak, item) => {
                if (item.category !== category) return ak
                return !item?.isNew ? (ak = true) : ak
            }, false)
        },
    },
}
</script>

<style lang="scss" scoped>
.additional-properties {
    height: 100%;
    padding: 15px 5px 35px;

    @include tablet-up {
        padding: 15px 10px 35px;
    }

    &__head {
        border-bottom: 1px solid rgba(0, 0, 0, 0.4);
        padding-bottom: 4px;
        margin-bottom: 15px;
        height: 40px;
    }

    &__title {
        font-size: 20px;
    }

    &__list {
        padding: 0;
    }

    &__property {
        margin-bottom: 10px;
        padding: 10px 15px 5px;
        background: rgba(26, 255, 0, 0.1);
        box-shadow: 0 5px 5px rgba(0, 0, 0, 0.15);

        &.is-existed {
            background: rgba(85, 0, 255, 0.1);
        }
    }

    &__add-new-property {
        padding: 5px 15px;
    }

    &__add-new-property-title {
        margin-bottom: 7px;
    }

    &__add-new-property-action {
        margin: 10px -8px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__body {
        overflow: auto;

        @include tablet-up {
            padding: 0 20px;
        }
    }

    &__foot {
        padding-top: 15px;
        height: 140px;
    }

    &__foot-actions {
        padding-top: 5px;
        margin-left: -8px;
    }
}
</style>
