<template>
    <div class="general-list">
        <h3 class="wig-page-new__general-title">
            {{ $t('pages.wigPage.generalList.header') }}
        </h3>
        <ul class="wig-page-new__general-list">
            <li
                v-for="(item, index) in list"
                :key="index"
                :data-test-id="item?.testID || undefined"
                class="wig-page-new__general-item"
            >
                <div class="wig-page-new__general-item-wrapper">
                    <div class="wig-page-new__general-item-icon">
                        <v-icon color="#6A4BC0FF" small>{{ item.icon }}</v-icon>
                    </div>
                    <span class="wig-page-new__general-item-label">
                        {{
                            $t(
                                `pages.wigPage.generalList.items.${item.labelKey}`
                            )
                        }}:
                    </span>
                    <span
                        v-if="item.typeCell && item.typeCell === 'date'"
                        class="wig-page-new__general-item-value wig-page-new__general-item-value--date"
                    >
                        <DateCell :value="item.value" />
                    </span>
                    <span
                        v-else-if="
                            item.typeCell && item.typeCell === 'date-utc-not'
                        "
                        class="wig-page-new__general-item-value wig-page-new__general-item-value--date"
                    >
                        <DateCell :value="item.value" :utc="false" />
                    </span>
                    <span
                        v-else-if="item.typeCell && item.typeCell === 'warning'"
                        class="wig-page-new__general-item-value wig-page-new__general-item-value--date"
                    >
                        <WarningCell :value="item.value" />
                    </span>
                    <span v-else class="wig-page-new__general-item-value">
                        {{ item.value }}
                    </span>
                    <div class="wig-page-new__general-item-actions">
                        <template v-if="item.hasActions">
                            <v-menu offset-y left>
                                <template #activator="{ on, attrs }">
                                    <button
                                        class="wig-page-new__general-item-action-btn"
                                        v-bind="attrs"
                                        v-on="on"
                                    >
                                        <v-icon color="rgb(0, 101, 197)"
                                            >mdi-dots-vertical</v-icon
                                        >
                                    </button>
                                </template>
                                <ul class="action-menu">
                                    <li
                                        v-for="(item, index) in item.actions"
                                        :key="index"
                                        class="action-menu__item"
                                        @click="item.handle"
                                    >
                                        <button class="action-menu__btn">
                                            {{
                                                $t(
                                                    `pages.wigPage.generalList.extraActions.${item.titleKey}`
                                                )
                                            }}
                                        </button>
                                    </li>
                                </ul>
                            </v-menu>
                        </template>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
import OrderDepositHistory from '@/components/orders/order-deposit-history.vue'
import popUpTypes from '@/types/pop-up-types'
import { mapActions } from 'vuex'
import { actionTypes } from '@/store/modules/modals'
import { completeDateActions } from '@/helpers/wig-details-page-complete-date.utils'
import {
    getFullDayFirstFull,
    getFullDayFirstFullStraight,
} from '@/helpers/getDateInFormat'
import {
    decorateWithDependency,
    decorateWithValidation,
} from '@/helpers/wig-details-page.decorators'
import prepareBeforeTranslation from '@/helpers/prepareBeforeTranslation'
import DateCell from '@/components/common/cells/date-cell.vue'
import WarningCell from '@/components/common/cells/warning-cell.vue'
import { changeManagerDateActions } from '@/helpers/wig-details-page-manager.utils'
import getWigStatusByState from '@/helpers/getWigStatusByState'
const {
    orderDeposit,
    orderAssignCreator,
    orderClientUpdate,
    orderStatusUpdate,
    commonModal,
} = popUpTypes

export default {
    name: 'OrdersPageGeneralList',
    components: { WarningCell, DateCell },
    props: {
        wig: {
            type: Object,
            default: () => ({}),
        },
        wigPayments: {
            type: Array,
            default: () => [],
        },
        wigPaymentsTotal: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            key: 'value',
        }
    },
    //Customer,
    // Barcode,
    // Additional ID,
    // Details,
    // State,
    // Status,
    // Storage notes,
    // Complete Date,
    computed: {
        list: function () {
            return this.wig.id
                ? [
                      {
                          icon: 'mdi-barcode',
                          labelKey: 'barcode',
                          value: this.wig.barcode,
                      },
                      {
                          icon: 'mdi-shield-alert-outline',
                          labelKey: 'additionalWigID',
                          value: this.wig.user_generated_id,
                      },
                      {
                          icon: 'mdi-shield-alert-outline',
                          labelKey: 'details',
                          value: this.wig.details,
                          typeCell: 'warning',
                      },
                      {
                          icon: 'mdi-barcode',
                          labelKey: 'state',
                          value:
                              this.wig.state &&
                              this.$t(
                                  `shared.wigStates.${prepareBeforeTranslation(
                                      this.wig.state
                                  )}`
                              ),
                      },
                      {
                          icon: 'mdi-shield-alert-outline',
                          labelKey: 'status',
                          value: getWigStatusByState(this.wig),
                      },
                      {
                          icon: 'mdi-shield-alert-outline',
                          labelKey: 'storageNotes',
                          value: this.wig.shippingInfo,
                      },
                      {
                          icon: 'mdi-calendar-multiple-check',
                          labelKey: 'completeDate',
                          value: this.wig['complete_data'],
                          testID: 'wig-page--general-list--completed-at-field',
                          actions: decorateWithDependency(
                              decorateWithValidation(
                                  completeDateActions,
                                  this.wig
                              ),
                              this.wig
                          ),
                          typeCell: 'date-utc-not',
                      },
                      {
                          icon: 'mdi-account-tie',
                          labelKey: 'manager',
                          value: this.wig.creator,
                          actions: decorateWithDependency(
                              decorateWithValidation(
                                  changeManagerDateActions,
                                  this.wig
                              ),
                              this.wig
                          ),
                      },
                      {
                          icon: 'mdi-calendar-range',
                          labelKey: 'created',
                          value: this.wig.created_at,
                          testID: 'wig-page--general-list--created-at-field',
                          typeCell: 'date',
                      },
                      {
                          icon: 'mdi-calendar-range',
                          labelKey: 'updated',
                          value: this.wig.updated_at,
                          testID: 'wig-page--general-list--updated-at-field',
                          typeCell: 'date',
                      },
                  ].map((row) => {
                      const detailsComponent = row?.detailsComponent || null
                      const editor = row?.editor || null
                      const actions = row.actions
                      return {
                          ...row,
                          hasDetails: !!detailsComponent,
                          hasEditor: !!editor,
                          hasActions: actions && actions?.length !== 0,
                      }
                  })
                : []
        },
    },
    methods: {
        ...mapActions('modals', { onOpen: actionTypes.openPopUp }),
        changeManager() {
            this.onOpen(orderAssignCreator)
        },
        changeClient() {
            this.onOpen(orderClientUpdate)
        },
        addPayment() {
            this.onOpen(orderDeposit)
        },
        openStatusUpdate: function () {
            this.onOpen(orderStatusUpdate)
        },
        showWigDepositDetails() {
            this.onOpen({
                name: commonModal,
                payload: { component: OrderDepositHistory },
            })
        },
        dateFormatHandle: function (date, straight = false) {
            if (straight) return date ? getFullDayFirstFullStraight(date) : ''
            return date ? getFullDayFirstFull(date) : ''
        },
    },
}
</script>

<style lang="scss" scoped>
.wig-page-new {
    display: grid;
    grid-template-columns: 415px 1fr;
    height: 100%;

    &__general-title {
        padding-bottom: 8px;

        &--deposit {
            margin-top: auto;
        }
    }
    &__general-list {
        padding: 0;
        margin: 0;
    }

    &__general-item-wrapper {
        margin: 0;
        display: flex;
        align-items: flex-end;
        line-height: 1;
        cursor: pointer;
        font-size: 14px;
        width: 100%;
    }

    &__general-item {
        margin: 0;
        line-height: 1;
        border-bottom: 1px dotted #ababab;
        padding: 8px 0;

        &:first-child {
            font-weight: 700;
            & > div {
                font-size: 1.05em;
            }
        }

        &:hover .wig-page-new__general-item-actions {
            //  TODO: add logic of editors
            opacity: unset;
        }
        & + & {
            margin-top: 4px;
        }
    }
    &__general-item-icon {
        margin-right: 8px;

        @include for-hebrew {
            margin-right: 0;
            margin-left: 8px;
        }
    }

    &__general-item-label {
        margin-right: 8px;
        font-weight: 700;

        @include for-hebrew {
            margin-right: 0;
            margin-left: 8px;
        }
    }

    &__general-item-actions {
        margin-left: auto;
        transition: opacity 250ms linear;

        @include for-hebrew {
            margin-left: 0;
            margin-right: auto;
        }
    }

    &__general-item-value {
        &--date {
            direction: ltr;
        }
    }

    &__general-item-action-btn {
        margin: 0 5px;
        opacity: 0.5;
    }

    &__general-item-details-wrapper {
        background: #fff;
        padding: 8px;
    }
}

.action-menu {
    background: #fff;

    &__item {
        min-width: 100px;
        padding: 8px 20px;
        background: #fff;

        &:hover {
            background: #967adc;
            color: #fff;
        }

        & + & {
            border-top: 1px dotted #d7d7d7;
        }
    }

    &__btn {
    }
}
</style>
