const arraySliceHandle = (arr, num) => {
    const partsNum = Math.floor(arr.length / num)
    let res = []

    for (let i = 0; i <= partsNum; i++) {
        const start = num * i
        const end = num * i + num
        res = [...res, arr.slice(start, end)]
    }
    return res
}

export default arraySliceHandle
