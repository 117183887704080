import axios from '@/api/axios'

export default {
    getAll: () => axios.get(`/base/api/wigs/`).then((res) => res.data),
    getAllV2: ({
        itemsPerPage,
        currentPage,
        state,
        search,
        special,
        managers,
        sort,
        usaToolsIsEnabled,
    }) => {
        let url = `/base/api/wigs/v2/all?per_page=${itemsPerPage}&page=${currentPage}`

        if (state) {
            url += `&state=${state}`
        }
        if (search) {
            url += `&search=${search}`
        }
        if (special) {
            url += `&special=${special}`
        }
        if (usaToolsIsEnabled && managers.length === 0) {
            url += `&usa_managers[]`
        }
        if (managers && managers.length > 0) {
            const managersParam = managers.join('&usa_managers[]=')
            url += `&usa_managers[]=${managersParam}`
        }
        if (sort.length !== 0) {
            sort.forEach((sortOption) => {
                url += `&sort[]=${sortOption}`
            })
        }

        return axios.get(url).then((res) => res.data)
    },
    getAllByRegular: () =>
        axios.get(`/base/api/wigs/getNotDelivered`).then((res) => res.data),
    getAllByDelivered: () =>
        axios.get(`/base/api/wigs/getDelivered`).then((res) => res.data),
    getDeliveredByName: (name) => {
        return axios
            .get(`/base/api/wigs/getDeliveredByClientName?full_name=${name}`)
            .then((res) => res.data)
    },
    getAllWithLimit: (count) =>
        axios.get(`/base/api/wigsWithLimit/${count}`).then((res) => res.data),
    //todo USA MANAGER FLOW
    getAllByUsaManager: () =>
        axios
            .get(`/base/api/wigs/getNotDeliveredByUsaManager`)
            .then((res) => res.data),
    getOne: (id) => axios.get(`/base/api/wig/${id}`).then((res) => res.data),
    getWigsBySearchParameter: (searchParameter) =>
        axios
            .get(
                `/base/api/wigs/getWigsBySearchParameter?search_parameter=${searchParameter}`
            )
            .then((res) => res.data),
    getWigsByState: (state) =>
        axios
            .get(`/base/api/wigs/getWigsByState?state=${state}`)
            .then((res) => res.data),
    getWigsByStateByUsaManager: (state) =>
        axios
            .get(`/base/api/wigs/getWigByStateByUsaManager?state=${state}`)
            .then((res) => res.data),
    getProperties: () =>
        axios.get('/data/properties.json').then((res) => res.data),
    createOrder: (order) => {
        return axios({
            url: '/base/api/wig/',
            method: 'post',
            data: order,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data',
            },
        }).then((res) => res.data)
    },
    createdOrderFromStock: ({ stockWigID, clientID }) =>
        axios
            .put(`/base/api/wig/${stockWigID}/createWigFromStock`, {
                client_id: clientID,
            })
            .then(({ data }) => data),
    updateProperties: ({ id, properties }) => {
        return axios
            .put(`/base/api/wig/${id}/wigUpdateProperties`, { ...properties })
            .then(({ data }) => data)
    },
    updateState: ({ id, state }) => {
        return axios
            .put(`/base/api/wig/${id}/updateState`, { state })
            .then((res) => res)
    },
    updateOrder: ({ id, form }) => {
        return axios({
            url: `/base/api/wig/${id}/updateWig`,
            method: 'post',
            data: form,
            headers: {
                Accept: 'application/json',
                'Content-Type': 'multipart/form-data',
            },
        }).then((res) => res.data)
    },
    updateCompleteDate: ({ id, completeData }) => {
        return axios
            .put(`/base/api/wig/${id}/setCompleteDate`, {
                complete_date: completeData,
            })
            .then(({ data }) => data)
    },
    deletePhoto: (id) => axios.delete(`/base/api/file/${id}`),
    deleteOrder: (id) => axios.delete(`/base/api/wig/${id}`),
    moveOrderToArchive: ({ wigId, newId }) =>
        axios.put(`/base/api/wig/${wigId}/updateStateArchive/`, {
            ['wig_id']: newId,
        }),
    assignCreator: ({ orderId, userId }) =>
        axios.put(`/base/api/wig/${orderId}/assignCreator`, {
            ['manager_id']: userId,
        }),
    clientUpdate: ({ wigId, clientId }) =>
        axios.put(`/base/api/wig/${wigId}/updateClient`, {
            client_id: clientId,
        }),
    getOldClients: (id) => axios.get(`/base/api/wig/${id}/clients`),
    associateWig: ({ wig, wig_id }) =>
        axios.put(`/base/api/wig/${wig}/associateWigFromStock`, {
            wig_id: wig_id,
        }),
    filterByBarcodeOrClient: (search) =>
        axios
            .get(`/base/api/wigs/filterByBarcodeOrClient?search=${search}`)
            .then((res) => res.data),
}
