<template>
    <div class="tab-devinder">
        <div class="tab-devinder__main-info">
            <slot name="main" />
        </div>
        <div class="tab-devinder__gallery">
            <slot name="gallery" />
        </div>
    </div>
</template>

<script>
export default {
    name: 'WigPageTabDevinder',
}
</script>

<style lang="scss" scoped>
.tab-devinder {
    @include desktop-up {
        display: flex;
    }

    &__main-info {
        @include desktop-up {
            position: relative;
            width: 50%;
            margin-right: 16px;

            @include for-hebrew {
                margin-right: 0;
                margin-left: 16px;
            }

            &:before {
                content: '';
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                height: 90%;
                width: 1px;
                background: #bababa;

                @include for-hebrew {
                    right: auto;
                    left: 0;
                }
            }
        }
    }

    &__gallery {
        @include desktop-up {
            width: 50%;
        }
    }
}
</style>
