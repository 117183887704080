import api from '@/api/payments'

const state = () => ({
    payments: [],
    sum: 0,
})

export const getterTypes = {
    getPayments: '[payments] Get all payments for selected wig',
    getSum: '[payments] Calculate sum of deposits',
}

export const mutationTypes = {
    updatePayments: '[payments] Update payments for current wig',
    calculateSum: '[payments] Calculate sum of deposits',
    addNewOne: '[payments] Add new one',
    markedAllAsTerminated:
        '[payments] Mark all payments as terminated mutation',
}

export const actionTypes = {
    loadAllForSelected: '[payments] Load all payments for selected wig',
    addNewPayment: '[payments] Add new payment',
    markedAllAsTerminated: '[payments] Mark all payments as terminated action',
}

const getters = {
    [getterTypes.getPayments]: ({ payments }) => payments,
    [getterTypes.getSum]: ({ payments }) =>
        payments.reduce((sum, payment) => {
            return (sum += payment.terminated !== 1 ? +payment.amount : 0)
        }, 0),
}

const mutations = {
    [mutationTypes.updatePayments](state, payments) {
        state.payments = []
        state.payments = payments
    },
    [mutationTypes.addNewOne](state, payment) {
        state.payments = [...state.payments, payment]
    },
    [mutationTypes.calculateSum](state) {
        state.sum = state.payments.reduce((ak, item) => {
            return (ak += +item.amount)
        }, 0)
    },
    [mutationTypes.markedAllAsTerminated](state) {
        state.payments = state.payments.map((payment) => ({
            ...payment,
            terminated: 1,
        }))
    },
}

const actions = {
    [actionTypes.loadAllForSelected]({ commit }, id) {
        return new Promise((res, rej) => {
            api.getAllForSelected(id)
                .then(({ data }) => {
                    commit(mutationTypes.updatePayments, data)
                    commit(mutationTypes.calculateSum)
                    res(data)
                })
                .catch((er) => {
                    rej(er)
                })
        })
    },
    [actionTypes.addNewPayment]({ commit }, payment) {
        return new Promise((res, rej) => {
            api.addNewPayment(payment)
                .then(({ data }) => {
                    res(data)
                    commit(mutationTypes.addNewOne, data)
                    commit(mutationTypes.calculateSum)
                })
                .catch((er) => {
                    rej(er.response.data.errors)
                })
        })
    },
    [actionTypes.markedAllAsTerminated]({ commit }) {
        commit(mutationTypes.markedAllAsTerminated)
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
