export default (str) => {
    if (!str) return ''
    const string = str.split('_')

    return string
        .map(
            (word) => word[0] + word.split('').splice(1).join('').toLowerCase()
        )
        .join(' ')
}
