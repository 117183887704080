import api from '@/api/roles'

const state = () => ({
    roles: [],
})

export const getterTypes = {
    getAllRoles: '[roles] Get all roles',
}

export const actionTypes = {
    loadAllRoles: '[roles] Load all roles',
}

export const mutationsTypes = {
    addAllRoles: '[roles] Add all roles',
}

const getters = {
    [getterTypes.getAllRoles]: ({ roles }) => roles,
    clicks: () => 3,
}

const mutations = {
    [mutationsTypes.addAllRoles](state, dataRoles) {
        state.roles = dataRoles.map((role) => ({
            value: role.id,
            text: role.name,
        }))
    },
}

const actions = {
    [actionTypes.loadAllRoles]({ commit }) {
        api.roles
            .getRoles()
            .then(({ data }) => {
                commit(mutationsTypes.addAllRoles, data)
            })
            .catch()
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
