<template>
    <v-expansion-panel>
        <v-expansion-panel-header color="#f7f7f9">
            <h4 class="order-property__sub-title">
                9. {{ propertiesContent['weight'] }}
            </h4>
        </v-expansion-panel-header>
        <v-expansion-panel-content color="#f7f7f9">
            <div class="order-property__input-wrapper">
                <v-text-field
                    v-model="properties.fields['weight']"
                    light
                    outlined
                    dense
                    type="text"
                    name="weight"
                    :label="propertiesContent['weight']"
                    :error-messages="properties.errors['weight']"
                    @input="properties.errors['weight'] = ''"
                >
                </v-text-field>
            </div>
        </v-expansion-panel-content>
    </v-expansion-panel>
</template>

<script>
import languagePropertiesMixin from '@/mixins/language-properties.mixin'

export default {
    name: 'OrderFormPropertyWeight',
    mixins: [languagePropertiesMixin],
    props: {
        dataProperties: {
            type: Object,
            default: () => ({
                fields: {
                    ['weight']: '',
                },
                errors: {},
            }),
        },
    },
    data() {
        return {}
    },
    computed: {
        properties: function () {
            return this.dataProperties
        },
    },
    methods: {},
}
</script>

<style lang="scss">
.order-property {
    &__sub-title {
        color: $color-primary-dark;
    }

    &__input-wrapper {
        padding: 15px 15px 0;
    }

    &__inner {
        border: 1px solid #626161;
        padding: 15px;
        margin-bottom: 15px;
    }
}
</style>
