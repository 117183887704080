import api from '@/api/status'
import { actionTypes as actionTypesFromOrders } from '@/store/modules/orders'

export const mutationTypes = {
    updateStatus: '[status] Update status for selected wig',
}

export const actionTypes = {
    updateStatus: '[status] Update status for selected wig',
}

const actions = {
    // eslint-disable-next-line no-unused-vars
    [actionTypes.updateStatus]({ commit, dispatch }, { status, id }) {
        return new Promise((res, rej) => {
            api.updateStatus({ status, id })
                .then((data) => {
                    res(data)
                    dispatch(
                        `orders/${actionTypesFromOrders.updateStatus}`,
                        { id, status: data },
                        {
                            root: true,
                        }
                    )
                })
                .catch((er) => {
                    rej(er)
                })
        })
    },
}

export default {
    namespaced: true,
    actions,
}
