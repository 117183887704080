export default {
    properties: 'פרטי הפאה',
    none: 'ללא',
    full: 'שלימה',
    fall: 'סרט',
    kipaFall: 'כיפה פול',
    handMade: 'עבודת יד',
    wigType: 'סוג הפאה',
    capSize: 'גודל רשת',
    capAdjustment: 'התאמת רשת',
    topType: 'סוג כיפה',
    regularSkin: 'סקין רגיל',
    laceTop: 'לייס טופ',
    ribben: 'ריבן',
    topTypeComments: 'הערות לייס טופ',
    laceTopComment: 'הערות לייס טופ',
    ribbenComment: 'הערות ריבן',
    weftTopInCircle: 'שבלול',
    skinHeights: 'גובה הסקין',
    regular: 'רגיל',
    american: 'אמריקאי',
    fine: 'פיין',
    curly: 'תלתלים',
    body: 'תנועה',
    straight: 'חלק גמיש',
    wavy: 'גלי',
    weftTopLikeFall: 'כמו סרט',
    flat: 'שטוח',
    withHeights: 'עם גובה',
    front: 'פרונט',
    frontComments: 'הערות לפרונט',
    closedLace: 'לייס סגור',
    thin: 'דק',
    height: 'גבוה',
    deepClosed: 'מקופל עמוק',
    openLace: 'לייס פתוח',
    handTie: 'עבודת יד',
    bangs: 'פוני',
    laceType: 'סוג לייס',
    baseColor: 'צבע בסיס',
    skinColor: 'צבע סקין',
    highlightsColor: 'צבע גוונים',
    highlightsColorSelect: 'צבע גוונים',
    highlightsColoringSelect: 'צבע גוונים',
    highlightsColoring: 'צבע גוונים',
    honey: 'דבש',
    ash: 'אשי',
    typeOfHighlights: 'סוג גוונים',
    halfBlonde: 'חצי בלונד',
    gentleMixed: 'עדינים משתלבים',
    specialYafiOrder: 'הוראות מיוחדת מיפי',
    specialYafiOrders: 'הוראות מיוחדת מיפי',
    skinTopHighlights: 'גוונים בסקין',
    non: 'ללא',
    onTheBottom: 'בקצוות',
    fromTheRoot: 'מהשורש',
    blended: 'עדין',
    streaky: 'הרבה',
    frontColor: 'צבע פרונט',
    withHighlights: 'עם גוונים',
    nonHighlights: 'בלי גוונים',
    colorComments: 'הערות לצבע',
    length: 'אורך',
    lengthFromNape: 'אורך מהעורף',
    lengthFromTop: 'אורך מהכיפה',
    sideLength: 'אורך בצדדים',
    lengthComments: 'הערות לאורך',
    hairTextureComments: 'הערות סוג שיער',
    hairTextureOnWefts: 'סוג שיער טרסים',
    straightWithBody: 'חלק עם תנועה',
    veryLightWave: 'גלי/תנועה',
    lightWave: 'גלי עדין',
    lightWavePlus: 'גלי עדין+',
    lightWithStrongWave: 'גלי עדין/בינוני',
    strongWave: 'גלי בינוני',
    hairTextureComment: 'הערות לסוג שיער טרסים',
    wigFullness: 'מלאות הפאה',
    standard: 'סטנדרט',
    fullAdditionalCharge: 'מלאה (בתוספת)',
    wigFullnessAdditionalAmount: 'תוספת סך',
    wigFullnessComments: 'הערות למלאות הפאה',
    weight: 'משקל',
    lengthUsed: 'אורך טרסים',
    complete_data: 'מוכן לתאריך',
    createdAt: 'נוצר בתאריך',
}
