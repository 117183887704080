<template>
    <main class="main">
        <Header />
        <router-view></router-view>
        <FooterForEmpty />
    </main>
</template>

<script>
import Header from '@/components/login/header'
import FooterForEmpty from '@/components/common/footer-for-empty.vue'

export default {
    name: 'EmptyLayout',
    components: {
        Header,
        FooterForEmpty,
    },
}
</script>
