var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"additional-properties"},[_c('div',{ref:"properties",staticClass:"additional-properties__body"},[_c('ul',{staticClass:"additional-properties__list"},_vm._l((_vm.propertiesByCategories),function(category,index){return _c('li',{key:index,staticClass:"additional-properties__property",class:{
                    ['is-existed']: _vm.categoryHasExisted(category.category),
                }},[_c('OrdersAdditionalPropertiesCategory',{attrs:{"category":category},on:{"property-remove-handle":_vm.propertyRemoveHandle,"property-edit-handle":_vm.propertyEditHandle,"category-edit-handle":_vm.categoryEditHandle}},[_c('div',{staticClass:"additional-properties__add-new-property"},[_c('v-text-field',{ref:"titles",refInFor:true,attrs:{"placeholder":_vm.textContent['properties-add'],"dense":"","hide-details":"auto"},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return (() => _vm.propertyAddHandle(category)).apply(null, arguments)}},model:{value:(_vm.property.title[category.category]),callback:function ($$v) {_vm.$set(_vm.property.title, category.category, $$v)},expression:"property.title[category.category]"}}),_c('div',{staticClass:"additional-properties__add-new-property-action"},[_c('v-btn',{staticClass:"mx-2",attrs:{"x-small":"","dark":"","color":"#4ca363"},on:{"click":() => _vm.propertyAddHandle(category)}},[_c('v-icon',{attrs:{"small":"","dark":""}},[_vm._v(" mdi-plus")]),_vm._v(" "+_vm._s(_vm.textContent['add'])+" ")],1),(_vm.categoryHasExisted(category.category))?_c('v-btn',{staticClass:"mx-2",attrs:{"x-small":"","dark":"","color":"#d95a5a"},on:{"click":() =>
                                        _vm.categoryRemoveHandleWithConfirm(
                                            category.category
                                        )}},[_c('v-icon',{attrs:{"small":"","dark":""}},[_vm._v(" mdi-minus")]),_vm._v(" "+_vm._s(_vm.textContent['remove-category'])+" ")],1):_c('v-btn',{staticClass:"mx-2",attrs:{"x-small":"","dark":"","color":"#d95a5a"},on:{"click":() =>
                                        _vm.categoryRemoveHandle(
                                            category.category
                                        )}},[_c('v-icon',{attrs:{"small":"","dark":""}},[_vm._v(" mdi-minus")]),_vm._v(" "+_vm._s(_vm.textContent['remove-category'])+" ")],1)],1)],1)])],1)}),0)]),_c('div',{staticClass:"additional-properties__foot"},[_c('p',{staticClass:"additional-properties__add-category-title"},[_vm._v(" "+_vm._s(_vm.textContent['add-new-category'])+" ")]),_c('v-text-field',{attrs:{"placeholder":_vm.textContent['category'],"dense":"","hide-details":"auto"},model:{value:(_vm.categoryName),callback:function ($$v) {_vm.categoryName=$$v},expression:"categoryName"}}),(!_vm.readonly)?_c('div',{staticClass:"additional-properties__foot-actions"},[_c('v-btn',{staticClass:"mx-2",attrs:{"x-small":"","dark":"","color":"#1976d2"},on:{"click":_vm.categoryAddHandle}},[_vm._v(" "+_vm._s(_vm.textContent['continue'])+" ")])],1):_vm._e(),(!_vm.hideActions)?_c('div',{staticClass:"additional-properties__foot-action-extra"},[_c('v-btn',{staticClass:"mx-2",attrs:{"small":"","dark":"","color":"#967adc"},on:{"click":_vm.onSave}},[_vm._v(" "+_vm._s(_vm.$t('pages.wigPage.slideOuts.save'))+" ")]),_c('v-btn',{staticClass:"mx-2",attrs:{"small":"","dark":"","color":"#979ea3"},on:{"click":_vm.closeHandle}},[_vm._v(" "+_vm._s(_vm.$t('pages.wigPage.slideOuts.close'))+" ")])],1):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }