import getters from '@/store/modules/orders-rework-properties/getters'
import actions from '@/store/modules/orders-rework-properties/actions'
import mutations from '@/store/modules/orders-rework-properties/mutations'

const state = {
    ordersReworkProperties: [],
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}
