import { ordersV2GetterTypes } from './types'
import { uniqBy } from 'lodash'

const getters = {
    [ordersV2GetterTypes.GET_ORDERS]: ({ orders, setters }) => {
        const uniqOrders = uniqBy(orders, 'id')
        return setters.reduce((ak, setter) => {
            return setter.setter(ak)
        }, uniqOrders)
    },
    [ordersV2GetterTypes.GET_ITEMS_PER_PAGE]: ({ itemsPerPage }) =>
        itemsPerPage,
    [ordersV2GetterTypes.GET_TOTAL]: ({ total }) => total,
    [ordersV2GetterTypes.GET_TOTAL_PAGES]: ({ total, itemsPerPage }) =>
        Math.ceil(total / itemsPerPage),
    [ordersV2GetterTypes.GET_CURRENT_PAGE]: ({ currentPage }) => currentPage,
    [ordersV2GetterTypes.GET_LOADING]: ({ loading }) => loading,
    [ordersV2GetterTypes.GET_USA_MANAGERS]: ({ selectedUsaManagers }) =>
        selectedUsaManagers,
}

export default getters
