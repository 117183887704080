export default function (state) {
    if (!state) return ''
    const hasSpace = /\s/.test(state)
    state = state.replace(/,/g, '')
    return !state
        ? ''
        : hasSpace
        ? state.replace(/\s/g, '-').toLowerCase()
        : state.toLowerCase()
        ? state.toLowerCase()
        : `*${state}`
}
