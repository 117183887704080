<template>
    <v-dialog v-model="isActive" width="400" @click:outside="closeHandle">
        <div class="payments-notes-modal">
            <div class="payments-notes-modal__head">
                <h3>
                    {{ $t('pages.wigPage.ordersPagePayments.header') }}
                </h3>
                <v-btn
                    class="payments-notes-modal__close-btn"
                    icon
                    color="#55595c"
                    data-test-id="wig-page--payments--add-note-pop-up--close-btn"
                    @click="closeHandle"
                >
                    <v-icon>mdi-close-circle-outline</v-icon>
                </v-btn>
            </div>
            <div class="payments-notes-modal__body">
                <v-text-field
                    v-model="totalPrice"
                    dense
                    outlined
                    type="number"
                    name="price-for-new"
                >
                    <template #append>
                        <span class="icon-container">
                            <v-icon>fas fa-shekel-sign</v-icon>
                        </span>
                    </template>
                </v-text-field>
            </div>
            <div class="payments-notes-modal__foot">
                <v-btn
                    color="#3bafda"
                    dark
                    small
                    data-test-id="wig-page--payments--add-note-pop-up--submit-btn"
                    @click="submit"
                >
                    {{ $t('pages.wigPage.slideOuts.update') }}
                </v-btn>
            </div>
        </div>
    </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { actionTypes } from '@/store/modules/orders'
import { actionTypes as actionTypesModalNotifications } from '@/store/modules/modal-notifications'

import modalsMixin from '@/mixins/modals.mixin'

import popUpTypes from '@/types/pop-up-types'
import { getterTypes } from '@/store/modules/orders'
import languageMixin from '@/mixins/language.mixin'

const { orderPaymentsTotalPrice } = popUpTypes

export default {
    name: 'OrderModalPaymentsTotalPrice',
    mixins: [modalsMixin, languageMixin],
    data() {
        return {
            selfName: orderPaymentsTotalPrice,
            totalPrice: 0,
        }
    },
    computed: {
        ...mapGetters('orders', { order: getterTypes.getOrder }),
        selectedId: function () {
            return this.order.id
        },
    },
    watch: {
        isActive: function (newValue) {
            if (!newValue) return null
            this.totalPrice = this.order.totalPrice
        },
    },
    methods: {
        ...mapActions('orders', { updateNotes: actionTypes.updateOrder }),
        ...mapActions('modalNotifications', {
            success: actionTypesModalNotifications.success,
        }),
        submit: async function () {
            try {
                const res = { totalPrice: this.totalPrice }
                const json = JSON.stringify(res)
                const form = new FormData()
                form.append('data', json)

                const data = { id: this.order.id, form }
                await this.updateNotes(data)
                this.closeHandle()
            } catch (e) {
                console.error(e)
            }
        },
    },
}
</script>

<style scoped lang="scss">
.payments-notes-modal {
    min-height: 100px;
    background: #fff;
    padding: 16px;
    width: 100%;

    &__head {
        margin-bottom: 8px;
        position: relative;
    }

    &__body {
        margin-bottom: 8px;
    }

    &__foot {
    }

    &__close-btn {
        position: absolute;
        top: -8px;
        right: -8px;

        @include for-hebrew {
            right: auto;
            left: -8px;
        }
    }
}
</style>
