<template>
    <span class="date-container date-cell">
        {{ formatDate(value) }}
    </span>
</template>

<script>
import formatDate from '../../../helpers/format-date'

export default {
    name: 'DateCell',
    props: {
        value: {
            type: [String, Number],
            default: '',
        },
        utc: {
            type: Boolean,
            default: true,
        },
        formatType: {
            type: String,
            default: 'dd-MM-yyyy HH:mm',
        },
    },
    methods: {
        formatDate: function (date) {
            return formatDate(date, this.utc, this.formatType)
        },
    },
}
</script>

<style lang="scss">
.date-cell {
    color: #181d23;
    font-weight: 300;
}
</style>
